import {
  Radio as BaseRadio,
  RadioGroup,
  RadioProps as BaseRadioProps,
} from "baseui/radio";
import { ChangeEvent } from "react";
import { Controller, ControllerProps } from "react-hook-form";

export default function Radio(props: BaseRadioProps): React.ReactElement {
  return (
    <BaseRadio
      {...props}
      overrides={{
        RadioMarkOuter: {
          style: {
            width: "24px",
            height: "24px",
            borderTopWidth: "1px",
            borderTopColor: "#E6EAF0",
            borderTopStyle: "solid",
            borderRightWidth: "1px",
            borderRightColor: "#E6EAF0",
            borderRightStyle: "solid",
            borderBottomWidth: "1px",
            borderBottomColor: "#E6EAF0",
            borderBottomStyle: "solid",
            borderLeftWidth: "1px",
            borderLeftColor: "#E6EAF0",
            borderLeftStyle: "solid",
            borderRadius: "50%",
            backgroundColor: "unset",
          },
        },
        RadioMarkInner: {
          style: ({ $checked }) => ({
            width: "16px",
            height: "16px",
            borderRadius: "50%",
            backgroundColor: $checked ? "#AF9883" : "unset",
          }),
        },
        Label: {
          style: {
            fontWeight: 400,
            fontSize: "14px",
          },
        },
      }}
    />
  );
}

export function ControlledRadioGroup({
  control,
  name,
  rules,
  ...rest
}: Omit<ControllerProps<typeof RadioGroup>, "render">): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ onChange, onBlur, value }) => (
        <RadioGroup
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChange(e.target.value)
          }
          onBlur={onBlur}
          value={value}
          {...rest}
        />
      )}
    />
  );
}
