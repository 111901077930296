import { memo } from "react";

import { CollageNode, CollageNodeType } from "../../CollageDesigner/types";
import { CommonNodeRendererProps } from "../types";
import DownloadNodeRenderer from "./DownloadNodeRenderer";
import PhotoNodeRenderer from "./PhotoNodeRenderer";
import SpacerNodeRenderer from "./SpacerNodeRenderer";
import TextNodeRenderer from "./TextNodeRenderer";
import VideoNodeRenderer from "./VideoNodeRenderer";

type NodeRendererProps = {
  node: CollageNode;
} & CommonNodeRendererProps;

function NodeRenderer({
  node,
  ...restProps
}: NodeRendererProps): React.ReactElement {
  switch (node.type) {
    case CollageNodeType.Photo:
      return <PhotoNodeRenderer {...node} {...restProps} />;

    case CollageNodeType.Video:
      return <VideoNodeRenderer {...node} {...restProps} />;

    case CollageNodeType.Text:
      return <TextNodeRenderer {...node} {...restProps} />;

    case CollageNodeType.Download:
      return <DownloadNodeRenderer {...node} {...restProps} />;

    case CollageNodeType.Spacer:
    default:
      return <SpacerNodeRenderer {...node} {...restProps} />;
  }
}

const MemoizedNodeRenderer = memo(NodeRenderer);

export default MemoizedNodeRenderer;
