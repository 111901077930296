import Button from "app/core/components/Button";
import { useStyletron } from "baseui";
import { Modal, ModalBody, ROLE, SIZE } from "baseui/modal";
import { ParagraphMedium } from "baseui/typography";
import { ReactElement } from "react";
import { useHistory } from "react-router-dom";

type MultipleInstancesModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export function MultipleInstancesModal({
  isOpen,
  onClose,
}: MultipleInstancesModalProps): ReactElement {
  const [css] = useStyletron();
  const history = useHistory();

  return (
    <Modal
      onClose={onClose}
      closeable={false}
      isOpen={isOpen}
      animate
      autoFocus
      size={SIZE.default}
      role={ROLE.dialog}
      overrides={{
        Root: {
          style: {
            zIndex: 10000,
          },
        },
        Dialog: {
          style: {
            borderRadius: "4px",
          },
        },
      }}
    >
      <ModalBody>
        <div
          className={css({
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          })}
        >
          <ParagraphMedium>
            We&apos;ve detected that this project is already open in another
            browser tab. Please use original one to proceed with editing.
          </ParagraphMedium>
          <Button
            onClick={() => {
              history.push("/projects");
              onClose();
            }}
          >
            Exit project
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
}
