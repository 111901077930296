import { gql, useMutation } from "@apollo/client";
import Button from "app/core/components/Button";
import { ControlledInput } from "app/core/components/Input";
import { ControlledRadioGroup } from "app/core/components/Radio";
import { Modal, ModalBody, ModalHeader, ROLE, SIZE } from "baseui/modal";
import { Radio } from "baseui/radio";
import { toaster } from "baseui/toast";
import { LabelLarge } from "baseui/typography";
import { CollageDesignerContext } from "contexts/CollageDesignerContext";
import { ReactElement, useContext } from "react";
import { useForm } from "react-hook-form";

import { CollageDesignerActionType } from "./types";
import FormControl from "./ui/FormControl";

const SET_COLLAGE_BACKGROUND_MUSIC = gql`
  mutation SetCollageBackgroundMusic($input: SetCollageBackgroundMusicInput!) {
    setCollageBackgroundMusic(input: $input) {
      draftBody
    }
  }
`;

type BackgroundMusicModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

type FormFields = {
  musicType: string;
  musicUrl?: string;
  musicFile?: FileList;
};

export default function BackgroundMusicModal({
  isOpen,
  onClose,
}: BackgroundMusicModalProps): ReactElement {
  const {
    state: {
      collageId,
      collageBody: { backgroundMusicType, backgroundMusicUrl },
    },
    dispatch,
  } = useContext(CollageDesignerContext);
  const [setCollageBackgroundMusic] = useMutation(
    SET_COLLAGE_BACKGROUND_MUSIC,
    {
      onCompleted: (data) => {
        dispatch({
          type: CollageDesignerActionType.ChangeCollageBody,
          payload: {
            backgroundMusicType:
              data.setCollageBackgroundMusic.draftBody?.backgroundMusicType ||
              null,
            backgroundMusicUrl:
              data.setCollageBackgroundMusic.draftBody?.backgroundMusicUrl ||
              null,
          },
        });
      },
    }
  );
  const {
    control,
    errors,
    register,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm<FormFields>({
    defaultValues: {
      musicType: backgroundMusicType ?? "",
      musicUrl:
        backgroundMusicType === "SPOTIFY" ? backgroundMusicUrl ?? "" : "",
    },
  });

  return (
    <Modal
      onClose={onClose}
      closeable={!isSubmitting}
      isOpen={isOpen}
      animate
      autoFocus
      size={SIZE.default}
      role={ROLE.dialog}
      overrides={{
        Root: {
          style: {
            zIndex: 10000,
          },
        },
        Dialog: {
          style: {
            borderRadius: "4px",
          },
        },
      }}
    >
      <ModalHeader>
        <LabelLarge color="#535A68" $style={{ fontWeight: 400 }}>
          Background music
        </LabelLarge>
      </ModalHeader>
      <ModalBody>
        <form
          onSubmit={handleSubmit(({ musicType, musicFile, musicUrl }) => {
            try {
              if (musicType === "CUSTOM" && musicFile) {
                setCollageBackgroundMusic({
                  variables: {
                    input: {
                      id: collageId,
                      backgroundMusicType: musicType,
                      backgroundMusicFile: musicFile[0],
                    },
                  },
                  context: {
                    fetchOptions: {
                      useUpload: true,
                    },
                  },
                });
              } else {
                setCollageBackgroundMusic({
                  variables: {
                    input: {
                      id: collageId,
                      backgroundMusicType: musicType !== "" ? musicType : null,
                      backgroundMusicUrl: musicUrl ?? null,
                    },
                  },
                });
              }

              toaster.positive("Saved successfully", {
                autoHideDuration: 4000,
              });
              onClose();
            } catch (error) {
              toaster.negative("Something went wrong", {
                autoHideDuration: 4000,
              });
            }
          })}
        >
          <FormControl error={errors.musicType && errors.musicType.message}>
            <ControlledRadioGroup
              control={control}
              name="musicType"
              error={!!errors.musicType}
              disabled={isSubmitting}
            >
              <Radio value="">None</Radio>
              <Radio value="CUSTOM">Custom</Radio>
              <Radio value="SPOTIFY">Spotify</Radio>
            </ControlledRadioGroup>
          </FormControl>
          {watch("musicType") === "CUSTOM" && (
            <FormControl
              label="File"
              error={errors.musicFile && errors.musicFile.message}
            >
              <input
                type="file"
                ref={register({
                  required:
                    watch("musicType") === "CUSTOM" && "This field is required",
                })}
                name="musicFile"
                accept=".mp3"
              />
            </FormControl>
          )}
          {watch("musicType") === "SPOTIFY" && (
            <FormControl
              label="Link"
              error={errors.musicUrl && errors.musicUrl.message}
            >
              <ControlledInput
                control={control}
                name="musicUrl"
                error={!!errors.musicUrl}
                rules={{ required: "This field is required" }}
                disabled={isSubmitting}
              />
            </FormControl>
          )}
          <Button
            type="submit"
            disabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Save
          </Button>
        </form>
      </ModalBody>
      {/* <ModalFooter>
        <ModalButton onClick={onClose} disabled={isSubmitting}>
          Zamknij
        </ModalButton>
      </ModalFooter> */}
    </Modal>
  );
}
