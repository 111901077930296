import { useEffect } from "react";

export function useOutsideClickAlerter(
  target: HTMLDocument | HTMLDivElement | null = document,
  ref: React.MutableRefObject<HTMLElement | HTMLDivElement | null>,
  callback: () => void
): void {
  const handleClick: EventListener = (e) => {
    if (ref.current && !ref.current.contains(e.target as Node)) {
      callback();
    }
  };

  useEffect(() => {
    target && target.addEventListener("click", handleClick);

    return () => {
      target && target.removeEventListener("click", handleClick);
    };
  });
}
