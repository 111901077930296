import { gql, useMutation } from "@apollo/client";
import { useAuth } from "app/auth/context";
import { useStyletron } from "baseui";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import { FormControl } from "baseui/form-control";
import { toaster } from "baseui/toast";
import { LabelMedium } from "baseui/typography";
import { ReactElement } from "react";
import { useForm } from "react-hook-form";

import Button from "./Button";
import { ControlledInput } from "./Input";
import { ControlledPhoneInput } from "./PhoneInput";

const UPDATE_ME = gql`
  mutation UpdateAccountData($input: UpdateAccountDataInput!) {
    updateAccountData(input: $input) {
      id
      email
      firstName
      lastName
      phone
      has2FaEnabled
      isAdmin
      availableFeatures
      collageExportCount
      publishedCollageCount
      hasRefLinkEnabled
    }
  }
`;

type FormInputs = {
  firstName: string;
  lastName: string;
  phone?: string;
};

export default function AccountForm(): ReactElement {
  const [updateAccountData] = useMutation(UPDATE_ME);
  const { user, setUser } = useAuth();

  const {
    handleSubmit,
    errors,
    control,
    formState: { isSubmitting },
  } = useForm<FormInputs>({
    defaultValues: {
      ...user,
    },
  });
  const [css] = useStyletron();

  return (
    <form
      onSubmit={handleSubmit(async (input) => {
        try {
          const response = await updateAccountData({
            variables: { input },
          });

          setUser(response.data.updateAccountData);

          toaster.positive("Saved successfully", {
            autoHideDuration: 4000,
          });
        } catch (error) {
          toaster.negative("The data provided is incorrect", {
            autoHideDuration: 4000,
          });
        }
      })}
    >
      <LabelMedium color="primary" marginBottom="scale400">
        Personal data change
      </LabelMedium>
      <FlexGrid flexGridColumnCount={2} flexGridColumnGap="scale400">
        <FlexGridItem>
          <FormControl
            label={() => "First Name"}
            error={errors.firstName && errors.firstName.message}
            overrides={{
              Caption: { style: { textAlign: "left" } },
              Label: {
                style: {
                  textTransform: "uppercase",
                  textAlign: "left",
                  fontSize: "12px",
                  color: "#8390AE",
                },
              },
            }}
          >
            <ControlledInput
              control={control}
              name="firstName"
              error={!!errors.firstName}
              rules={{ required: "This field is required" }}
              disabled={isSubmitting}
              placeholder="Enter your first name"
            />
          </FormControl>
        </FlexGridItem>
        <FlexGridItem>
          <FormControl
            label={() => "Last Name"}
            error={errors.lastName && errors.lastName.message}
            overrides={{
              Caption: { style: { textAlign: "left" } },
              Label: {
                style: {
                  textTransform: "uppercase",
                  textAlign: "left",
                  fontSize: "12px",
                  color: "#8390AE",
                },
              },
            }}
          >
            <ControlledInput
              control={control}
              name="lastName"
              error={!!errors.lastName}
              disabled={isSubmitting}
              placeholder="Enter your last name"
            />
          </FormControl>
        </FlexGridItem>
      </FlexGrid>

      <FormControl
        label={() => "Phone"}
        error={errors.phone && errors.phone.message}
        overrides={{
          Caption: { style: { textAlign: "left" } },
          Label: {
            style: {
              textTransform: "uppercase",
              textAlign: "left",
              fontSize: "12px",
              color: "#8390AE",
            },
          },
        }}
      >
        <ControlledPhoneInput
          control={control}
          name="phone"
          error={!!errors.phone}
          pattern="[+-]?\d+(?:[.,]\d+)?"
          disabled={isSubmitting}
        />
      </FormControl>

      <div
        className={css({
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "10px",
        })}
      >
        <Button type="submit" disabled={isSubmitting} isLoading={isSubmitting}>
          Save
        </Button>
      </div>
    </form>
  );
}
