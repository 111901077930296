import {
  Checkbox as BaseCheckbox,
  CheckboxProps as BaseCheckboxProps,
} from "baseui/checkbox";
import { ChangeEvent } from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { StyleObject } from "styletron-standard";

type CheckboxProps = {
  $checkmarkStyle?: StyleObject;
  $labelStyle?: StyleObject;
} & BaseCheckboxProps;

export default function Checkbox({
  $checkmarkStyle,
  $labelStyle,
  ...props
}: CheckboxProps): React.ReactElement {
  return (
    <BaseCheckbox
      overrides={{
        Root: {
          style: {
            alignItems: "center",
          },
        },
        Checkmark: {
          style: ({ $checked }) => ({
            width: "24px",
            height: "24px",
            borderTopWidth: "1px",
            borderTopColor: "#E6EAF0",
            borderTopStyle: "solid",
            borderRightWidth: "1px",
            borderRightColor: "#E6EAF0",
            borderRightStyle: "solid",
            borderBottomWidth: "1px",
            borderBottomColor: "#E6EAF0",
            borderBottomStyle: "solid",
            borderLeftWidth: "1px",
            borderLeftColor: "#E6EAF0",
            borderLeftStyle: "solid",
            borderRadius: "4px",
            backgroundImage: $checked
              ? `url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.6668 1.5L4.25016 7.91667L1.3335 5' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")`
              : "unset",
            backgroundColor: $checked ? "#AF9883" : "white",
            backgroundSize: "12px 9px",
            ...$checkmarkStyle,
          }),
        },
        Label: {
          style: {
            color: "#8390AE",
            fontSize: "0.85rem",
            fontWeight: 400,
            ...$labelStyle,
          },
        },
      }}
      {...props}
    />
  );
}

export function ControlledCheckbox({
  control,
  name,
  rules,
  ...rest
}: Omit<ControllerProps<typeof Checkbox>, "render">): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ onChange, onBlur, value }) => (
        <Checkbox
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChange(e.target.checked)
          }
          onBlur={onBlur}
          checked={value}
          {...rest}
        />
      )}
    />
  );
}
