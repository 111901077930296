/* eslint-disable @typescript-eslint/ban-ts-comment */
import { CollageDesignerActionType } from "app/collages/components/CollageDesigner/types";
import React, { useMemo, useReducer } from "react";

type StateWithUndoableProperty<T, P extends keyof T, A> = {
  value: Omit<T, P>;
  propertyHistory: T[P][];
  actionHistory: A[];
  currentIndex: number;
};

export enum UndoableActionType {
  Undo = "Undo",
  Redo = "Redo",
}

type BaseAction = {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
};

export type UndoableAction<A extends BaseAction> =
  | A
  | (
      | {
          type: UndoableActionType.Undo;
        }
      | {
          type: UndoableActionType.Redo;
        }
    );

export function useUndoableReducer<T, A extends BaseAction, P extends keyof T>(
  reducer: (state: T, action: A) => T,
  initialPresent: T,
  property: P
): {
  state: T;
  dispatch: React.Dispatch<UndoableAction<A>>;
  propertyHistory: T[P][];
  actionHistory: A[];
  canUndo: boolean;
  canRedo: boolean;
} {
  const { [property]: propertyValue, ...restState } = initialPresent;
  const initialState: StateWithUndoableProperty<T, P, A> = {
    value: restState,
    propertyHistory: [propertyValue],
    actionHistory: [{ type: "Init" } as A],
    currentIndex: 0,
  };

  const undoableReducer = useMemo(() => undoable<T, A, P>(reducer, property), [
    reducer,
    property,
  ]);

  const [state, dispatch] = useReducer(undoableReducer, initialState);

  const { value, propertyHistory, actionHistory, currentIndex } = state;

  const canUndo = currentIndex > 0;
  const canRedo = currentIndex < propertyHistory.length - 1;

  return {
    state: ({
      ...value,
      [property]: propertyHistory[currentIndex],
    } as unknown) as T,
    dispatch,
    propertyHistory,
    actionHistory,
    canUndo,
    canRedo,
  };
}

function undoable<T, A extends BaseAction, P extends keyof T>(
  reducer: (state: T, action: A) => T,
  property: P
) {
  // Return a reducer that handles undo and redo
  return function (
    state: StateWithUndoableProperty<T, P, A>,
    action: UndoableAction<A>
  ) {
    const { value, propertyHistory, actionHistory, currentIndex } = state;

    switch (action.type) {
      case UndoableActionType.Undo:
        return {
          ...state,
          currentIndex: currentIndex - 1,
        };
      case UndoableActionType.Redo:
        return {
          ...state,
          currentIndex: currentIndex + 1,
        };
      default: {
        const presentProperty = propertyHistory[currentIndex];
        const presentAction = actionHistory[currentIndex];

        // Delegate handling the action to the passed reducer
        const newValue = reducer(
          ({
            ...value,
            [property]: presentProperty,
          } as unknown) as T,
          action as A
        );

        const newPresentProperty = newValue[property];

        // Nothing's changed, don't update history
        if (presentProperty === newPresentProperty) {
          return {
            ...state,
            value: newValue,
          };
        }

        let newIndex = currentIndex + 1;

        if (
          action.type === CollageDesignerActionType.SetNodeDimensions &&
          presentAction.type === action.type &&
          presentAction.payload.targetId === action.payload.targetId
        ) {
          // Override the last node dimensions change
          newIndex = currentIndex;
        }

        const newPropertyHistory = propertyHistory.slice(0, newIndex);
        const newActionHistory = actionHistory.slice(0, newIndex);

        return {
          value: newValue,
          propertyHistory: [...newPropertyHistory, newPresentProperty],
          actionHistory: [...newActionHistory, action as A],
          currentIndex: newIndex,
        };
      }
    }
  };
}
