import {
  Button as BaseButton,
  ButtonProps as BaseButtonProps,
} from "baseui/button";
import React from "react";
import { StyleObject } from "styletron-standard";

type IconButtonProps = {
  children?: React.ReactNode;
  $style?: StyleObject;
  minimal?: boolean;
} & BaseButtonProps;

export default function IconButton({
  children,
  $style,
  minimal,
  isSelected,
  ...props
}: IconButtonProps): React.ReactElement {
  return (
    <BaseButton
      overrides={{
        BaseButton: {
          style: {
            lineHeight: "0",
            borderTopLeftRadius: "50%",
            borderTopRightRadius: "50%",
            borderBottomRightRadius: "50%",
            borderBottomLeftRadius: "50%",
            paddingLeft: "0px",
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            width: "32px",
            height: "32px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: minimal
              ? "unset"
              : "1.89147px 1.89147px 4.72868px rgba(0, 0, 0, 0.05)",
            ...(minimal && {
              backgroundColor: isSelected ? "#ededed" : "unset !important",
              ":hover": {
                backgroundColor: isSelected ? "#ededed" : "unset",
              },
            }),
            ...$style,
          },
        },
      }}
      isSelected={isSelected}
      {...props}
    >
      {children}
    </BaseButton>
  );
}
