import { gql, useQuery } from "@apollo/client";
import CollageCard, {
  CollageCardRoot,
} from "app/collages/components/CollageCard";
import Tabs from "app/collages/components/Tabs";
import Button from "app/core/components/Button";
import {
  ArrowDown,
  ArrowUp,
  PlusCircle,
  Search,
} from "app/core/components/Icon";
import { Check, X } from "app/core/components/Icon";
import Input from "app/core/components/Input";
import LoadingSpinner from "app/core/components/LoadingSpinner";
import Select from "app/core/components/Select";
import { useDebouncedEffect } from "app/core/hooks/useDebouncedEffect";
import AppLayout from "app/core/layouts/AppLayout";
import { useStyletron } from "baseui";
import { Cell, Grid } from "baseui/layout-grid";
import { StyledLink } from "baseui/link";
import { Pagination } from "baseui/pagination";
import { DisplayXSmall, LabelMedium } from "baseui/typography";
import { useMemo } from "react";
import { ReactElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { NumberParam, StringParam, useQueryParam } from "use-query-params";

const FETCH_MY_COLLAGES = gql`
  query MyCollages(
    $skip: Int
    $take: Int
    $orderBy: CollageOrderByInput
    $search: String
    $isPublished: Boolean
  ) {
    myCollages(
      skip: $skip
      take: $take
      orderBy: $orderBy
      search: $search
      isPublished: $isPublished
    ) {
      totalCount
      items {
        id
        name
        publishedAt
        thumbnailPhoto {
          url
        }
      }
    }
  }
`;

type SortingOption = {
  id: string;
  label: string;
};

const PAGE_SIZE = 12;

const SORTING_OPTIONS: SortingOption[] = [
  {
    id: "createdAt",
    label: "Creation date",
  },
  {
    id: "name",
    label: "Name",
  },
];

enum SortingDirection {
  Ascending = "asc",
  Descending = "desc",
}

type MyCollagesQueryArgs = {
  skip?: number;
  take?: number;
  orderBy?: {
    [x: string]: SortingDirection;
  };
  search?: string;
  isPublished?: boolean;
};

export default function ShowCollagesPage(): ReactElement {
  const [css] = useStyletron();
  const [orderByFieldId, setOrderByFieldId] = useQueryParam(
    "orderBy",
    StringParam
  );
  const orderByField = useMemo(
    () =>
      SORTING_OPTIONS.find((option) => option.id === orderByFieldId) ||
      SORTING_OPTIONS[0],
    [orderByFieldId]
  );
  const [orderByDirection, setOrderByDirection] = useQueryParam(
    "orderDirection",
    StringParam
  );
  const [currentPage, setCurrentPage] = useQueryParam("page", NumberParam);
  const [keyword, setKeyword] = useQueryParam("keyword", StringParam);
  const [currentTab, setCurrentTab] = useQueryParam("tab", NumberParam);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { data, refetch, loading } = useQuery<any, MyCollagesQueryArgs>(
    FETCH_MY_COLLAGES,
    {
      variables: {
        skip: ((currentPage ?? 1) - 1) * PAGE_SIZE,
        take: PAGE_SIZE,
        orderBy: {
          [orderByField.id]:
            (orderByDirection as SortingDirection) ??
            SortingDirection.Descending,
        },
        search: keyword ?? "",
        isPublished: (currentTab ?? 0) === 0 ? undefined : currentTab === 1,
      },
      nextFetchPolicy: "no-cache",
      fetchPolicy: "no-cache",
    }
  );
  const [intermediateKeyword, setIntermediateKeyword] = useState(keyword ?? "");
  const [isKeywordDirty, setIsKeywordDirty] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);

  useDebouncedEffect(
    () => {
      if (intermediateKeyword !== keyword && isKeywordDirty) {
        setKeyword(intermediateKeyword);
        setCurrentPage(null);
      }
    },
    1000,
    [intermediateKeyword]
  );

  const numPages =
    data && data.myCollages
      ? Math.ceil(data.myCollages.totalCount / PAGE_SIZE)
      : 0;

  useEffect(() => {
    setIsRefetching(true);
    refetch();
    setIsRefetching(false);
  }, []);

  const intermediateShouldShowLoadingSpinner = useMemo(
    () => loading || isRefetching,
    [loading, isRefetching]
  );

  const [shouldShowLoadingSpinner] = useDebounce(
    intermediateShouldShowLoadingSpinner,
    200
  );

  return (
    <AppLayout>
      <Grid>
        <Cell span={12}>
          <div
            className={css({
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "20px",
              paddingBottom: "20px",
            })}
          >
            <DisplayXSmall
              $style={{
                fontSize: "28px",
                fontWeight: 300,
              }}
            >
              Projects
            </DisplayXSmall>

            <Button
              $as={Link}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore // TO-DO
              to="/projects/design"
              startEnhancer={() => <PlusCircle stroke="currentColor" />}
              $style={{
                alignSelf: "center",
              }}
            >
              Create new project
            </Button>
          </div>
        </Cell>

        <Cell span={8}>
          <Input
            placeholder="Enter a keyword here"
            endEnhancer={() => <Search />}
            value={intermediateKeyword}
            clearable={true}
            disabled={loading}
            onChange={(event) => {
              setIntermediateKeyword(event.currentTarget.value);
              setIsKeywordDirty(true);
            }}
          />
        </Cell>
        <Cell span={3}>
          <Select
            options={SORTING_OPTIONS}
            value={[orderByField]}
            onChange={(params) =>
              params &&
              params.value &&
              params.value.length > 0 &&
              setOrderByFieldId(params.value[0].id as string)
            }
            backspaceClearsInputValue={false}
            backspaceRemoves={false}
          />
        </Cell>
        <Cell span={1}>
          <Button
            kind="secondary"
            onClick={() =>
              setOrderByDirection((direction) =>
                direction === SortingDirection.Ascending
                  ? SortingDirection.Descending
                  : SortingDirection.Ascending
              )
            }
          >
            {orderByDirection === SortingDirection.Ascending ? (
              <ArrowUp width={16} height={16} stroke="#9199A8" />
            ) : (
              <ArrowDown width={16} height={16} stroke="#9199A8" />
            )}
          </Button>
        </Cell>

        <Cell span={12}>
          <Tabs
            items={[
              "All projects",
              <>
                <Check /> Published
              </>,
              <>
                <X /> Unpublished
              </>,
            ]}
            active={currentTab ?? 0}
            onChange={setCurrentTab}
          />
        </Cell>

        {data &&
          data.myCollages &&
          data.myCollages.items.length === 0 &&
          !shouldShowLoadingSpinner && (
            <Cell span={[4, 4, 3]}>
              <StyledLink
                $as={Link}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                to={`/projects/design`}
                $style={{ textDecoration: "none" }}
              >
                <CollageCardRoot
                  $style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "calc(100% - 16px)",
                    flexDirection: "column",
                    minHeight: "200px",
                  }}
                >
                  <LabelMedium marginBottom="scale600">
                    Create New Project
                  </LabelMedium>
                  <PlusCircle
                    stroke="#A48D79"
                    width={28}
                    height={28}
                    strokeWidth={1}
                  />
                </CollageCardRoot>
              </StyledLink>
            </Cell>
          )}

        {shouldShowLoadingSpinner ? (
          <Cell span={12}>
            <div
              className={css({
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
              })}
            >
              <LoadingSpinner noWrapper />
            </div>
          </Cell>
        ) : (
          data.myCollages.items.map(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (item: any) => (
              <Cell span={[4, 4, 3]} key={item.id}>
                <StyledLink
                  $as={Link}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  to={`/projects/design/${item.id}`}
                  $style={{ textDecoration: "none" }}
                >
                  <CollageCard data={item} onChange={refetch} />
                </StyledLink>
              </Cell>
            )
          )
        )}

        {numPages > 1 && !shouldShowLoadingSpinner && (
          <Cell span={12}>
            <Pagination
              size="mini"
              numPages={numPages}
              currentPage={currentPage ?? 1}
              onPageChange={async ({ nextPage }) => {
                setCurrentPage(Math.min(Math.max(nextPage, 1), numPages));
              }}
              overrides={{ Root: { style: { marginTop: "20px" } } }}
            />
          </Cell>
        )}
      </Grid>
    </AppLayout>
  );
}
