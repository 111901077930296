import { gql, useMutation, useQuery } from "@apollo/client";
import ChangePlanModal from "app/account/components/ChangePlanModal";
import Button from "app/core/components/Button";
import { Download } from "app/core/components/Icon";
import AppLayout from "app/core/layouts/AppLayout";
import { styled, useStyletron } from "baseui";
import { Block } from "baseui/block";
import { ALIGNMENT, Cell, Grid } from "baseui/layout-grid";
import { Skeleton } from "baseui/skeleton";
import { toaster } from "baseui/toast";
import {
  DisplayXSmall,
  LabelMedium,
  LabelSmall,
  ParagraphSmall,
} from "baseui/typography";
import { format, parseISO } from "date-fns";
import { ReactNode, useEffect, useState } from "react";
import { useMemo } from "react";
import { Fragment, ReactElement } from "react";

const InfoRow = styled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  borderBottomStyle: "solid",
  borderBottomWidth: "1px",
  borderBottomColor: $theme.colors.borderOpaque,
  paddingTop: $theme.sizing.scale700,
  paddingBottom: $theme.sizing.scale700,
}));

type InfoRowData = {
  label: string;
  value: ReactNode;
  hint?: string;
  actionLabel?: string;
  actionOnClick?: () => void;
};

type Payment = {
  id: number;
  receiptUrl: string;
  currency: string;
  amount: number;
  createdAt: string;
};

type Subscription = {
  id: number;
  planId: number;
  plan: {
    name: string;
    price: number;
    type: string;
  };
  state: string;
  updateUrl: string;
  cancelUrl: string;
  paymentMethod: string;
  paymentCardType?: string;
  paymentCardNumberLastFourDigits?: string;
  paymentCardExpiresOn?: string;
  nextPaymentOn?: string;
  nextPaymentAmount?: number;
  activeUntil?: string;
  payments: Payment[];
};

const FETCH_MY_SUBSCRIPTION = gql`
  query MySubscription {
    mySubscription {
      id
      plan {
        name
        price
        type
      }
      state
      updateUrl
      cancelUrl
      paymentMethod
      paymentCardType
      paymentCardNumberLastFourDigits
      paymentCardExpiresOn
      nextPaymentOn
      activeUntil
      payments {
        id
        receiptUrl
        currency
        amount
        createdAt
      }
    }
  }
`;

const UPDATE_SUBSCRIPTION = gql`
  mutation UpdateSubscription($input: UpdateSubscriptionInput!) {
    updateSubscription(input: $input)
  }
`;

export default function BillingPage(): ReactElement {
  const [isChangePlanModalOpen, setIsChangePlanModalOpen] = useState(false);
  const [updateSubscription] = useMutation(UPDATE_SUBSCRIPTION);

  const { data, loading } = useQuery<{ mySubscription: Subscription }>(
    FETCH_MY_SUBSCRIPTION
  );

  const [, theme] = useStyletron();

  useEffect(() => {
    async function updateSubscriptionIfNecessary() {
      const paddlePlanIdToBuy = window.localStorage.getItem(
        "paddlePlanIdToBuy"
      );
      const coupon = window.localStorage.getItem("coupon");

      if (paddlePlanIdToBuy && data?.mySubscription.id) {
        try {
          const response = await updateSubscription({
            variables: {
              input: {
                subscriptionId: data?.mySubscription.id,
                planId: +paddlePlanIdToBuy,
                coupon,
              },
            },
          });

          if (response.data.updateSubscription) {
            toaster.positive("Saved successfully", {
              autoHideDuration: 4000,
            });
          } else {
            toaster.negative("Something went wrong", {
              autoHideDuration: 4000,
            });
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          if (
            error.graphQLErrors &&
            error.graphQLErrors[0] &&
            error.graphQLErrors[0].extensions?.code ===
              "INVALID_SUBSCRIPTION_ERROR"
          ) {
            toaster.negative("You can't update this subscription", {
              autoHideDuration: 4000,
            });
          } else {
            toaster.negative("The data provided is incorrect", {
              autoHideDuration: 4000,
            });
          }
        }

        window.localStorage.setItem("paddlePlanIdToBuy", "");
        window.localStorage.setItem("coupon", "");
      }
    }
    updateSubscriptionIfNecessary();
  }, [data]);

  const subscriptionData: InfoRowData[] = useMemo(
    () => [
      {
        label: "Product",
        value: "MyBlog",
      },
      {
        label: "Plan",
        value: data?.mySubscription?.plan.name || "FREE",
        ...(data?.mySubscription?.plan?.type !== "LIFETIME" && {
          actionLabel: "Change",
          actionOnClick: () => {
            setIsChangePlanModalOpen(true);
          },
        }),
      },
      ...(data && data.mySubscription
        ? [
            {
              label: "Billing interval",
              value: data.mySubscription.plan.type,
            },
            {
              label: "Price",
              value: "$" + data.mySubscription.plan.price.toString(),
            },
            {
              label: "Account status",
              value:
                data.mySubscription.state === "DELETED"
                  ? `ACTIVE UNTIL ${format(
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      parseISO(data.mySubscription.activeUntil!),
                      "LLLL d, y"
                    )}`
                  : data.mySubscription.state,
              ...((data.mySubscription.state === "ACTIVE" ||
                data.mySubscription.state === "PAST_DUE" ||
                data.mySubscription.state === "PAUSED") &&
                data.mySubscription.plan.type !== "LIFETIME" &&
                data.mySubscription.plan.price !== 0 &&
                data.mySubscription.cancelUrl && {
                  actionLabel: "Cancel",
                  actionOnClick: () => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (window as any).Paddle.Checkout.open({
                      override: data.mySubscription.cancelUrl,
                      success: "/",
                    });
                  },
                }),
              ...(data.mySubscription.state === "DELETED" && {
                hint:
                  "Your subscription will be automatically downgraded to FREE plan after current plan expires.",
              }),
            },
            ...(data.mySubscription.nextPaymentOn &&
            data.mySubscription.state !== "DELETED" &&
            data.mySubscription.plan.type !== "LIFETIME" &&
            data.mySubscription.plan.price > 0
              ? [
                  {
                    label: "Next payment",
                    value: data.mySubscription.nextPaymentOn
                      ? format(
                          parseISO(data.mySubscription.nextPaymentOn),
                          "LLLL d, y"
                        )
                      : "",
                  },
                ]
              : []),
            ...(data.mySubscription.nextPaymentOn &&
            data.mySubscription.state !== "DELETED" &&
            data.mySubscription.plan.type !== "LIFETIME" &&
            data.mySubscription.plan.price > 0
              ? [
                  {
                    label: "Payment method",
                    value:
                      data.mySubscription.paymentMethod === "CARD"
                        ? `${data.mySubscription.paymentCardType} ending with ${data.mySubscription.paymentCardNumberLastFourDigits}`
                        : "PayPal",
                    actionLabel: "Update",
                    actionOnClick: () => {
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      (window as any).Paddle.Checkout.open({
                        override: data.mySubscription.updateUrl,
                        success: "/",
                      });
                    },
                  },
                ]
              : []),
          ]
        : []),
    ],
    [data]
  );

  return (
    <AppLayout>
      <Grid>
        <Cell span={12}>
          <DisplayXSmall
            $style={{
              fontSize: "28px",
              fontWeight: 300,
            }}
          >
            Billing
          </DisplayXSmall>
        </Cell>
        <Cell span={12}>
          <Block marginTop="scale800">
            <LabelMedium color="primary" marginBottom="scale400">
              Current subscription
            </LabelMedium>
          </Block>
        </Cell>
        {subscriptionData &&
          subscriptionData.map((data, index) => (
            <Fragment key={index}>
              <Cell span={6}>
                <InfoRow>
                  <LabelSmall>{data.label}:</LabelSmall>
                  <ParagraphSmall marginTop="0" marginBottom="0">
                    {loading ? (
                      <Skeleton width="100px" height="20px" animation />
                    ) : (
                      data.value
                    )}
                  </ParagraphSmall>
                </InfoRow>
              </Cell>
              <Cell span={6} align={ALIGNMENT.center}>
                {data.actionLabel && (
                  <Button
                    kind="minimal"
                    onClick={data.actionOnClick}
                    $style={{
                      color: theme.colors.primary,
                      fontWeight: 400,
                      paddingLeft: 0,
                      paddingRight: 0,
                    }}
                  >
                    {data.actionLabel}
                  </Button>
                )}
                {data.hint && (
                  <ParagraphSmall marginTop="0" marginBottom="0">
                    {data.hint}
                  </ParagraphSmall>
                )}
              </Cell>
            </Fragment>
          ))}
        <Cell span={12}>
          <Block marginTop="scale1600">
            <LabelMedium color="primary" marginBottom="scale400">
              Invoices
            </LabelMedium>
          </Block>
        </Cell>
        {data &&
          data.mySubscription &&
          data.mySubscription.payments &&
          data.mySubscription.payments.map((payment) => (
            <Fragment key={payment.id}>
              <Cell span={6}>
                <InfoRow>
                  <LabelSmall>
                    {format(parseISO(payment.createdAt), "LLLL d, y")}
                  </LabelSmall>
                  <ParagraphSmall marginTop="0" marginBottom="0">
                    Paid&nbsp;
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: payment.currency,
                    }).format(payment.amount)}
                  </ParagraphSmall>
                </InfoRow>
              </Cell>
              <Cell span={6} align={ALIGNMENT.center}>
                {payment.receiptUrl && (
                  <Button
                    $as="a"
                    kind="minimal"
                    href={payment.receiptUrl}
                    target="_blank"
                    startEnhancer={() => <Download />}
                    $style={{
                      color: theme.colors.primary,
                      fontWeight: 400,
                      paddingLeft: 0,
                      paddingRight: 0,
                    }}
                  >
                    View invoice
                  </Button>
                )}
              </Cell>
            </Fragment>
          ))}
      </Grid>
      <ChangePlanModal
        isOpen={isChangePlanModalOpen}
        onClose={() => setIsChangePlanModalOpen(false)}
      />
    </AppLayout>
  );
}
