import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { LabelXSmall } from "baseui/typography";
import React from "react";

import { Info } from "./Icon";

type InfoBoxProps = {
  children?: React.ReactNode;
};

export default function InfoBox({
  children,
}: InfoBoxProps): React.ReactElement {
  const [css, theme] = useStyletron();

  return (
    <Block
      display="flex"
      marginTop="scale600"
      marginBottom="scale800"
      alignItems="flex-start"
      maxWidth="320px"
    >
      <Info
        className={css({
          flexShrink: 0,
          marginTop: theme.sizing.scale0,
        })}
      />
      <LabelXSmall
        marginLeft="scale300"
        color="#8390AE"
        $style={{ fontWeight: 400 }}
      >
        {children}
      </LabelXSmall>
    </Block>
  );
}
