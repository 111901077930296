import { useAuth } from "app/auth/context";
import { styled } from "baseui";
import { Theme } from "baseui/theme";
import { createElement } from "react";
import { ReactElement } from "react";
import { Link, useLocation } from "react-router-dom";

import { DollarSign, Help, Logout, Picture, Settings, User } from "../Icon";

const SideNavRoot = styled("div", ({ $theme }) => ({
  backgroundColor: $theme.colors.backgroundPrimary,
  width: "300px",
  flexShrink: 0,
  borderRightColor: "#D8D8D8",
  borderRightStyle: "solid",
  borderRightWidth: "1px",
  padding: "20px",
  display: "flex",
  flexDirection: "column",
}));

const TopNav = styled("div", () => ({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
}));

const BottomNav = styled("div", () => ({
  flexShrink: 0,
  borderTopColor: "#D8D8D8",
  borderTopStyle: "solid",
  borderTopWidth: "1px",
  paddingTop: "20px",
}));

const NavLink = styled<{ isActive: boolean }, "a", Theme>(
  "a",
  ({ isActive, $theme }) => ({
    display: "flex",
    marginBottom: $theme.sizing.scale400,
    padding: "14px 18px",
    fontSize: "14px",
    color: "#9199A8",
    borderRadius: "4px",
    textDecoration: "none",
    fontWeight: 500,
    transition: $theme.animation.timing200,
    ...(isActive && {
      backgroundColor: $theme.colors.backgroundSecondary,
      color: "#535A68",
    }),
    ":hover": {
      backgroundColor: $theme.colors.backgroundTertiary,
    },
  })
);

const NavLinkLabel = styled("span", ({ $theme }) => ({
  marginLeft: $theme.sizing.scale600,
}));

const TOP_NAV_LINKS = [
  {
    label: "Projects",
    icon: Picture,
    path: "/projects",
  },
  {
    label: "Account",
    icon: User,
    path: "/account",
  },
  {
    label: "Billing",
    icon: DollarSign,
    path: "/billing",
  },
  {
    label: "Admin",
    icon: Settings,
    path: "/admin",
    isAdmin: true,
  },
];

const BOTTOM_NAV_LINKS = [
  {
    label: "Help Center",
    icon: Help,
    path: "https://help.picnook.io/en/",
  },
  {
    label: "Sign out",
    icon: Logout,
    path: "/logout",
  },
];

export default function SideNav(): ReactElement {
  const location = useLocation();

  const { user } = useAuth();

  return (
    <SideNavRoot>
      <TopNav>
        {TOP_NAV_LINKS.filter(
          (link) => link.isAdmin === user?.isAdmin || link.isAdmin === undefined
        ).map((navLink) => {
          const isActive = location.pathname.indexOf(navLink.path) > -1;
          const icon = createElement(navLink.icon, {
            width: 16,
            height: 16,
            stroke: isActive ? "#A48D79" : "currentColor",
          });

          return (
            <NavLink
              key={navLink.path}
              $as={Link}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore // TO-DO
              to={navLink.path}
              isActive={isActive}
            >
              {icon} <NavLinkLabel>{navLink.label}</NavLinkLabel>
            </NavLink>
          );
        })}
      </TopNav>
      <BottomNav>
        {BOTTOM_NAV_LINKS.map((navLink) => {
          const isActive = location.pathname.indexOf(navLink.path) > -1;
          const icon = createElement(navLink.icon, {
            width: 16,
            height: 16,
            color: isActive ? "#A48D79" : "currentColor",
          });

          return navLink.path.startsWith("http") ? (
            <NavLink
              key={navLink.path}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore // TO-DO
              href={navLink.path}
              isActive={isActive}
              target="_blank"
            >
              {icon} <NavLinkLabel>{navLink.label}</NavLinkLabel>
            </NavLink>
          ) : (
            <NavLink
              key={navLink.path}
              $as={Link}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore // TO-DO
              to={navLink.path}
              isActive={isActive}
            >
              {icon} <NavLinkLabel>{navLink.label}</NavLinkLabel>
            </NavLink>
          );
        })}
      </BottomNav>
    </SideNavRoot>
  );
}
