import { styled, useStyletron } from "baseui";
import { LabelSmall } from "baseui/typography";
import React from "react";

const PanelContainer = styled("div", {
  display: "flex",
  justifyContent: "flex-start",
  flexDirection: "column",
  maxHeight: "100%",
});

type PanelProps = {
  title?: string;
  children?: React.ReactNode;
  labelEnhancer?: React.ReactNode;
  icon?: React.ReactElement<SVGSVGElement>;
};

export default function Panel({
  title,
  children,
  labelEnhancer,
  icon,
}: PanelProps): React.ReactElement {
  const [css] = useStyletron();

  return (
    <PanelContainer>
      <div
        className={css({
          textTransform: "uppercase",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          padding: "20px",
          borderBottom: "1px solid #E9ECF2",
          ...(!title && { height: "0px", padding: "0px" }),
        })}
      >
        {icon}
        <LabelSmall
          marginLeft="scale200"
          color="#8390AE"
          $style={{ fontWeight: 400 }}
        >
          {title}
        </LabelSmall>
        {labelEnhancer}
      </div>
      <div
        className={css({
          padding: "20px",
          flexGrow: 1,
          overflow: "auto",
        })}
      >
        {children}
      </div>
    </PanelContainer>
  );
}
