import { CollageCardRoot } from "app/collages/components/CollageCard";
import AppLayout from "app/core/layouts/AppLayout";
import { useStyletron } from "baseui";
import { Cell, Grid } from "baseui/layout-grid";
import { StyledLink } from "baseui/link";
import { DisplayXSmall, LabelMedium } from "baseui/typography";
import { ReactElement } from "react";
import { Link } from "react-router-dom";

export default function DashboardPage(): ReactElement {
  const [css] = useStyletron();

  return (
    <AppLayout>
      <Grid>
        <Cell span={12}>
          <div
            className={css({
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "20px",
              paddingBottom: "20px",
            })}
          >
            <DisplayXSmall
              $style={{
                fontSize: "28px",
                fontWeight: 300,
              }}
            >
              Admin Dashboard
            </DisplayXSmall>
          </div>
        </Cell>

        <Cell span={3}>
          <StyledLink
            $as={Link}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            to={`/admin/users`}
            $style={{ textDecoration: "none" }}
          >
            <CollageCardRoot
              $style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "calc(100% - 16px)",
                flexDirection: "column",
                minHeight: "200px",
              }}
            >
              <LabelMedium>Manage users</LabelMedium>
            </CollageCardRoot>
          </StyledLink>
        </Cell>

        <Cell span={3}>
          <StyledLink
            $as={Link}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            to={`/admin/plans`}
            $style={{ textDecoration: "none" }}
          >
            <CollageCardRoot
              $style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "calc(100% - 16px)",
                flexDirection: "column",
                minHeight: "200px",
              }}
            >
              <LabelMedium>Manage plans</LabelMedium>
            </CollageCardRoot>
          </StyledLink>
        </Cell>
      </Grid>
    </AppLayout>
  );
}
