import { styled } from "baseui";
import { Theme } from "baseui/theme";
import { ReactElement, ReactNode } from "react";

const TabsRoot = styled("div", ({ $theme }) => ({
  display: "flex",
  alignItems: "center",
  marginTop: $theme.sizing.scale800,
  marginBottom: $theme.sizing.scale800,
}));

const Tab = styled<{ isActive: boolean }, "a", Theme>(
  "a",
  ({ isActive, $theme }) => ({
    position: "relative",
    fontSize: "14px",
    fontWeight: isActive ? 500 : 400,
    textDecoration: "none",
    marginRight: $theme.sizing.scale800,
    paddingBottom: $theme.sizing.scale200,
    color: isActive
      ? $theme.colors.contentPrimary
      : $theme.colors.contentTertiary,
    ...(isActive && {
      ":after": {
        position: "absolute",
        content: "''",
        bottom: "0px",
        left: "0px",
        height: "2px",
        width: "30%",
        backgroundColor: $theme.colors.primary,
      },
    }),
  })
);

type TabsProps = {
  items: ReactNode[];
  active: number;
  onChange(active: number): void;
};

export default function Tabs({
  items,
  active,
  onChange,
}: TabsProps): ReactElement {
  return (
    <TabsRoot>
      {items.map((item, index) => (
        <Tab
          key={index}
          isActive={index === active}
          onClick={(event) => {
            event.preventDefault();
            onChange(index);
          }}
          href="#"
        >
          {item}
        </Tab>
      ))}
    </TabsRoot>
  );
}
