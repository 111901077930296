import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { MultipleInstancesModal } from "app/auth/components/MultipleInstancesModal";
import { useAuth } from "app/auth/context";
import { CollageDesignerActionType } from "app/collages/components/CollageDesigner/types";
import CollageDesigner from "app/collages/components/CollageDesigner2";
import LoadingSpinner from "app/core/components/LoadingSpinner";
import { toaster } from "baseui/toast";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";

const CORE_COLLAGE_FIELDS = gql`
  fragment CoreCollageFields on Collage {
    id
    name
    draftBody
    publishingToken
    publishedAt
    republishedAt
    unpublishedAt
    publishDisabledAt
    photos {
      id
      name
      url
      dimensions
      name
      rating
      createdAt
      updatedAt
    }
  }
`;

const FETCH_COLLAGE = gql`
  ${CORE_COLLAGE_FIELDS}

  query Collage($id: Id!) {
    collage(id: $id) {
      ...CoreCollageFields
    }
  }
`;

const UPSERT_COLLAGE = gql`
  ${CORE_COLLAGE_FIELDS}

  mutation UpsertCollage($input: UpsertCollageInput!) {
    upsertCollage(input: $input) {
      ...CoreCollageFields
    }
  }
`;

const PUBLISH_COLLAGE = gql`
  ${CORE_COLLAGE_FIELDS}

  mutation PublishCollage($input: PublishCollageInput!) {
    publishCollage(input: $input) {
      ...CoreCollageFields
    }
  }
`;

const UNPUBLISH_COLLAGE = gql`
  ${CORE_COLLAGE_FIELDS}

  mutation UnpublishCollage($input: UnpublishCollageInput!) {
    unpublishCollage(input: $input) {
      ...CoreCollageFields
    }
  }
`;

interface DesignCollagePageParams {
  id?: string;
}

export default function DesignCollagePage(): ReactElement {
  const history = useHistory();
  const { id } = useParams<DesignCollagePageParams>();
  const [upsertCollage] = useMutation(UPSERT_COLLAGE);
  const [publishCollage] = useMutation(PUBLISH_COLLAGE);
  const [unpublishCollage] = useMutation(UNPUBLISH_COLLAGE);

  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const [
    isMultipleInstancesModalOpen,
    setIsMultipleInstancesModalOpen,
  ] = useState(false);

  const [fetchCollage, { loading, data, error }] = useLazyQuery(FETCH_COLLAGE, {
    fetchPolicy: "no-cache",
  });

  const { refetch } = useAuth();

  useEffect(() => {
    refetch();

    if (id) {
      fetchCollage({
        variables: {
          id: parseInt(id),
        },
      });
    }

    function updateProjectTimestamp() {
      if (localStorage.getItem(`oP${id}`)) {
        localStorage.setItem(`oP${id}`, (+new Date()).toString());
      }
    }

    if (!localStorage.getItem(`oP${id}`)) {
      localStorage.setItem(`oP${id}`, (+new Date()).toString());

      intervalRef.current = setInterval(updateProjectTimestamp, 1000);

      window.addEventListener("beforeunload", () => {
        intervalRef.current && clearInterval(intervalRef.current);
        localStorage.removeItem(`oP${id}`);
      });

      return () => {
        intervalRef.current && clearInterval(intervalRef.current);
        localStorage.removeItem(`oP${id}`);
      };
    } else if (
      parseInt(localStorage.getItem(`oP${id}`) as string) >
      +new Date() - 2000
    ) {
      setIsMultipleInstancesModalOpen(true);
    }
  }, []);

  useEffect(() => {
    if (error) {
      toaster.negative("An unexpected error has occured.", {
        autoHideDuration: 4000,
      });

      history.push("/login");
      history.push("/projects");
    }
  }, [error]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {((id && !loading) || !id) && (
        <CollageDesigner
          initialState={{
            ...(data &&
              data.collage && {
                collageId: data.collage.id,
                collagePublishingToken: data.collage.publishingToken,
                collagePublishedAt: data.collage.publishedAt,
                collageRepublishedAt: data.collage.republishedAt,
                collageUnpublishedAt: data.collage.unpublishedAt,
                collagePublishDisabledAt: data.collage.publishDisabledAt,
                collageName: data.collage.name,
                ...(data.collage.draftBody && {
                  collageBody: data.collage.draftBody,
                }),
                photos: data.collage.photos,
              }),
          }}
          onSave={async (state, dispatch) => {
            try {
              const response = await upsertCollage({
                variables: {
                  input: {
                    ...(state.collageId && {
                      id: state.collageId,
                    }),
                    name: state.collageName,
                    body: state.collageBody,
                  },
                },
              });

              if (!id) {
                history.replace(
                  `/projects/design/${response.data.upsertCollage.id}`
                );

                dispatch({
                  type: CollageDesignerActionType.Change,
                  payload: {
                    collageId: response.data.upsertCollage.id,
                    collageName: response.data.upsertCollage.name,
                    collagePublishingToken:
                      response.data.upsertCollage.publishingToken,
                    collagePublishedAt: response.data.upsertCollage.publishedAt,
                    collageRepublishedAt:
                      response.data.upsertCollage.republishedAt,
                    collageUnpublishedAt:
                      response.data.upsertCollage.unpublishedAt,
                    collagePublishDisabledAt:
                      response.data.upsertCollage.publishDisabledAt,
                  },
                });
              }

              toaster.positive("Saved successfully", {
                autoHideDuration: 4000,
              });
            } catch (error) {
              toaster.negative("Something went wrong", {
                autoHideDuration: 4000,
              });
            }
          }}
          onPublish={async (state, dispatch) => {
            try {
              const response = await publishCollage({
                variables: {
                  input: {
                    ...(id && {
                      id: parseInt(id),
                    }),
                    body: state.collageBody,
                  },
                },
              });

              if (!id) {
                history.replace(
                  `/projects/design/${response.data.publishCollage.id}`
                );
              }

              dispatch({
                type: CollageDesignerActionType.Change,
                payload: {
                  collagePublishedAt: response.data.publishCollage.publishedAt,
                  collageRepublishedAt:
                    response.data.publishCollage.republishedAt,
                  collageUnpublishedAt:
                    response.data.publishCollage.unpublishedAt,
                  collagePublishDisabledAt:
                    response.data.publishCollage.publishDisabledAt,
                },
              });

              toaster.positive("Published successfully", {
                autoHideDuration: 4000,
              });
            } catch (error) {
              toaster.negative("Something went wrong", {
                autoHideDuration: 4000,
              });
            }
          }}
          onUnpublish={async (state, dispatch) => {
            try {
              if (id) {
                const response = await unpublishCollage({
                  variables: {
                    input: {
                      id: parseInt(id),
                    },
                  },
                });

                dispatch({
                  type: CollageDesignerActionType.Change,
                  payload: {
                    collagePublishedAt:
                      response.data.unpublishCollage.publishedAt,
                    collageRepublishedAt:
                      response.data.unpublishCollage.republishedAt,
                    collageUnpublishedAt:
                      response.data.unpublishCollage.unpublishedAt,
                    collagePublishDisabledAt:
                      response.data.unpublishCollage.publishDisabledAt,
                  },
                });

                toaster.positive("Unpublished successfully", {
                  autoHideDuration: 4000,
                });
              }
            } catch (error) {
              toaster.negative("Something went wrong", {
                autoHideDuration: 4000,
              });
            }
          }}
        />
      )}

      <MultipleInstancesModal
        isOpen={isMultipleInstancesModalOpen}
        onClose={() => setIsMultipleInstancesModalOpen(false)}
      />
    </>
  );
}
