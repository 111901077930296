import { useAuth } from "app/auth/context";
import {
  Download,
  Heading,
  Spacer,
  Text,
  Video,
} from "app/core/components/Icon";
import InfoBox from "app/core/components/InfoBox";
import { styled, useStyletron } from "baseui";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import { LabelXSmall } from "baseui/typography";
import { CollageDesignerContext } from "contexts/CollageDesignerContext";
import React from "react";
import { useContext } from "react";

import { CollageDesignerActionType, CollageNodeType, TextType } from "./types";

const ToolWrapper = styled("div", {
  display: "flex",
  justifyContent: "center",
  fontSize: "0.85rem",
  color: "#535A68",
  alignItems: "center",
  fontWeight: 500,
  border: "1px solid #E6EAF0",
  cursor: "grab",
  backgroundColor: "white",
  borderRadius: "4px",
  gap: "8px",
  boxShadow: "1px 1px 4px rgba(123, 134, 151, 0.05)",
  padding: "16px",
  userSelect: "none",
});

function SpacerToolBarItem() {
  const {
    dispatch,
    state: {
      bindDraggingListeners,
      collageBody: { nodes },
    },
    view: { collageWidth, setCollageTranslate, collageScale },
  } = useContext(CollageDesignerContext);

  return (
    <ToolWrapper
      {...(bindDraggingListeners && bindDraggingListeners("spacer"))}
      onDoubleClick={() => {
        const rootNode = nodes[0];

        dispatch({
          type: CollageDesignerActionType.InsertNode,
          payload: {
            targetId: 0,
            targetParentId: 0,
            index:
              rootNode.type === CollageNodeType.Internal
                ? rootNode.children.length
                : 0,
            node: {
              id: 0,
              type: CollageNodeType.Spacer,
              dimensions: { width: 100, height: 10 },
            },
          },
        });

        const collageHeight = rootNode.dimensions
          ? (rootNode.dimensions.height / rootNode.dimensions.width) *
            collageWidth
          : 0;

        setCollageTranslate(({ x }) => ({
          x,
          y: (-collageHeight + 120) * collageScale,
        }));
      }}
    >
      <Spacer /> Spacer
    </ToolWrapper>
  );
}

function HeadingToolBarItem() {
  const {
    dispatch,
    state: {
      bindDraggingListeners,
      collageBody: { nodes, gap },
    },
    view: { collageWidth, setCollageTranslate, collageScale },
  } = useContext(CollageDesignerContext);

  return (
    <ToolWrapper
      {...(bindDraggingListeners &&
        bindDraggingListeners("heading", CollageNodeType.Text))}
      onDoubleClick={() => {
        const rootNode = nodes[0];

        dispatch({
          type: CollageDesignerActionType.InsertNode,
          payload: {
            targetId: 0,
            targetParentId: 0,
            index:
              rootNode.type === CollageNodeType.Internal
                ? rootNode.children.length
                : 0,
            node: {
              id: 0,
              type: CollageNodeType.Text,
              textType: TextType.Heading,
              textSize: 16,
              textVerticalAlignment: "center",
              dimensions: { width: 1000, height: 41 + gap },
              paddingTop: gap / 2,
              paddingBottom: gap / 2,
            },
          },
        });

        const collageHeight = rootNode.dimensions
          ? (rootNode.dimensions.height / rootNode.dimensions.width) *
            collageWidth
          : 0;

        setCollageTranslate(({ x }) => ({
          x,
          y: (-collageHeight + 120) * collageScale,
        }));
      }}
    >
      <Heading /> Heading
    </ToolWrapper>
  );
}

function TextToolBarItem() {
  const {
    dispatch,
    state: {
      bindDraggingListeners,
      collageBody: { nodes, gap },
    },
    view: { collageWidth, setCollageTranslate, collageScale },
  } = useContext(CollageDesignerContext);

  return (
    <ToolWrapper
      {...(bindDraggingListeners &&
        bindDraggingListeners("text", CollageNodeType.Text))}
      onDoubleClick={() => {
        const rootNode = nodes[0];

        dispatch({
          type: CollageDesignerActionType.InsertNode,
          payload: {
            targetId: 0,
            targetParentId: 0,
            index:
              rootNode.type === CollageNodeType.Internal
                ? rootNode.children.length
                : 0,
            node: {
              id: 0,
              type: CollageNodeType.Text,
              textSize: 16,
              textVerticalAlignment: "center",
              textAlignment: "left",
              dimensions: { width: 1000, height: 35 + gap },
              paddingTop: gap / 2,
              paddingBottom: gap / 2,
            },
          },
        });

        const collageHeight = rootNode.dimensions
          ? (rootNode.dimensions.height / rootNode.dimensions.width) *
            collageWidth
          : 0;

        setCollageTranslate(({ x }) => ({
          x,
          y: (-collageHeight + 120) * collageScale,
        }));
      }}
    >
      <Text />
      Text
    </ToolWrapper>
  );
}

function VideoToolBarItem() {
  const {
    dispatch,
    state: {
      bindDraggingListeners,
      collageBody: { nodes },
    },
    view: { collageWidth, setCollageTranslate, collageScale },
  } = useContext(CollageDesignerContext);
  const {
    verifyFeatureAvailability,
    setIsFeatureNotAvailableModalOpen,
  } = useAuth();
  const isFeatureAvailable = verifyFeatureAvailability("videoComponent");

  return (
    <ToolWrapper
      {...(bindDraggingListeners &&
        isFeatureAvailable &&
        bindDraggingListeners("video", CollageNodeType.Video))}
      onDoubleClick={() => {
        if (isFeatureAvailable) {
          const rootNode = nodes[0];

          dispatch({
            type: CollageDesignerActionType.InsertNode,
            payload: {
              targetId: 0,
              targetParentId: 0,
              index:
                rootNode.type === CollageNodeType.Internal
                  ? rootNode.children.length
                  : 0,
              node: {
                id: 0,
                type: CollageNodeType.Video,
                videoType: "youtube",
                dimensions: { width: 100, height: 100 / (16 / 9) },
              },
            },
          });

          const collageHeight = rootNode.dimensions
            ? (rootNode.dimensions.height / rootNode.dimensions.width) *
              collageWidth
            : 0;

          setCollageTranslate(({ x }) => ({
            x,
            y: (-collageHeight + 120) * collageScale,
          }));
        } else {
          setIsFeatureNotAvailableModalOpen(true);
        }
      }}
    >
      <Video />
      Video
    </ToolWrapper>
  );
}

function DownloadToolBarItem() {
  const {
    dispatch,
    state: {
      bindDraggingListeners,
      collageBody: { nodes },
    },
    view: { collageWidth, setCollageTranslate, collageScale },
  } = useContext(CollageDesignerContext);
  // const {
  //   verifyFeatureAvailability,
  //   setIsFeatureNotAvailableModalOpen,
  // } = useAuth();
  // const isFeatureAvailable = verifyFeatureAvailability("downloadComponent");

  return (
    <ToolWrapper
      {...(bindDraggingListeners &&
        bindDraggingListeners("download", CollageNodeType.Download))}
      onDoubleClick={() => {
        // if (isFeatureAvailable) {
        const rootNode = nodes[0];

        dispatch({
          type: CollageDesignerActionType.InsertNode,
          payload: {
            targetId: 0,
            targetParentId: 0,
            index:
              rootNode.type === CollageNodeType.Internal
                ? rootNode.children.length
                : 0,
            node: {
              id: 0,
              type: CollageNodeType.Download,
              label: "Download photos",
              dimensions: { width: 100, height: 100 / 8 },
            },
          },
        });

        const collageHeight = rootNode.dimensions
          ? (rootNode.dimensions.height / rootNode.dimensions.width) *
            collageWidth
          : 0;

        setCollageTranslate(({ x }) => ({
          x,
          y: (-collageHeight + 120) * collageScale,
        }));
        // } else {
        //   setIsFeatureNotAvailableModalOpen(true);
        // }
      }}
    >
      <Download />
      Download
    </ToolWrapper>
  );
}

export function ToolBar(): React.ReactElement {
  const [css] = useStyletron();

  return (
    <div className={css({ userSelect: "none" })}>
      <LabelXSmall
        color="#8390AE"
        $style={{ textTransform: "uppercase", fontWeight: 400 }}
      >
        Components
      </LabelXSmall>
      <FlexGrid
        flexGridColumnCount={2}
        flexGridColumnGap="8px"
        flexGridRowGap="8px"
        marginTop="scale600"
      >
        <FlexGridItem>
          <HeadingToolBarItem />
        </FlexGridItem>
        <FlexGridItem>
          <TextToolBarItem />
        </FlexGridItem>
        <FlexGridItem>
          <VideoToolBarItem />
        </FlexGridItem>
        <FlexGridItem>
          <SpacerToolBarItem />
        </FlexGridItem>
        <FlexGridItem
          overrides={{
            Block: {
              style: {
                marginRight: 0,
              },
            },
          }}
        >
          <DownloadToolBarItem />
        </FlexGridItem>
      </FlexGrid>
      <InfoBox>
        Grab & drag a component over the canvas to add new component.
      </InfoBox>
    </div>
  );
}
