import { useDrag } from "@use-gesture/react";
import useRect from "app/core/hooks/useRect";
import { styled } from "baseui";
import { useRef } from "react";

import { Rect, XY } from "./types";

type VirtualScrollBarProps = {
  position: number;
  viewportSize: number;
  contentSize: number;
  setTranslate: React.Dispatch<React.SetStateAction<XY>>;
};

const VirtualScrollBarRoot = styled("div", {
  position: "absolute",
  top: "32px",
  right: "360px",
  width: "12px",
  height: "100%",
});

const VirtualScrollBarKnob = styled("div", {
  position: "relative",
  left: 0,
  width: "70%",
  marginLeft: "15%",
  background: "rgba(0, 0, 0, 0.4)",
  borderRadius: "10px",
  userSelect: "none",
  touchAction: "none",
  transition:
    "width 0.2s ease-in-out, margin 0.2s ease-in-out, background 0.2s ease-in-out",
  ":hover": {
    background: "rgba(0, 0, 0, 0.6)",
    width: "100%",
    marginLeft: 0,
  },
});

export default function VirtualScrollBar({
  position,
  contentSize,
  viewportSize,
  setTranslate,
}: VirtualScrollBarProps): React.ReactElement {
  const rootDivElement = useRef<HTMLDivElement>(null);
  const rootDivElementRect = useRect(rootDivElement) as Rect;

  const bind = useDrag(({ active, xy: [, y] }) => {
    if (active) {
      setTranslate(({ x }) => ({
        x,
        y: Math.max(
          Math.min(
            200,
            -((y - rootDivElementRect.y) / viewportSize) * contentSize
          ),
          -contentSize + viewportSize - 200
        ),
      }));
    }
  });

  return (
    <VirtualScrollBarRoot ref={rootDivElement}>
      <VirtualScrollBarKnob
        $style={{
          top: `${(-(position - 200) / (contentSize + 400)) * 97}%`,
          height: `${(viewportSize / (contentSize + 400)) * 97}%`,
          ...(viewportSize / contentSize > 1.2 && { display: "none" }),
        }}
        {...bind()}
      />
    </VirtualScrollBarRoot>
  );
}
