import { gql, useMutation } from "@apollo/client";
import { ControlledInput } from "app/core/components/Input";
import { FormControl } from "baseui/form-control";
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
  ROLE,
  SIZE,
} from "baseui/modal";
import { toaster } from "baseui/toast";
import { LabelLarge } from "baseui/typography";
import { ReactElement, useEffect } from "react";
import { useForm } from "react-hook-form";

const RENAME_COLLAGE = gql`
  mutation RenameCollage($input: RenameCollageInput!) {
    renameCollage(input: $input) {
      id
    }
  }
`;

type RenameCollageModalProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

type FormFields = {
  name: string;
};

export default function RenameCollageModal({
  data,
  isOpen,
  onClose,
  onSuccess,
}: RenameCollageModalProps): ReactElement {
  const [renameCollage] = useMutation(RENAME_COLLAGE);

  const {
    control,
    errors,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm<FormFields>({
    defaultValues: {
      name: "",
    },
  });

  useEffect(() => {
    setTimeout(() => setValue("name", data.name), 0);
  }, [isOpen]);

  async function onSubmit({ name }: FormFields) {
    try {
      await renameCollage({
        variables: {
          input: {
            id: data.id,
            name,
          },
        },
      });

      toaster.positive("Saved successfully", {
        autoHideDuration: 4000,
      });

      onClose();
      onSuccess();
    } catch (error) {
      toaster.negative("Something went wrong", {
        autoHideDuration: 4000,
      });
    }
  }

  return (
    <Modal
      onClose={onClose}
      closeable={!isSubmitting}
      isOpen={isOpen}
      animate
      autoFocus
      size={SIZE.default}
      role={ROLE.dialog}
      overrides={{
        Root: {
          style: {
            zIndex: 10000,
          },
        },
        Dialog: {
          style: {
            borderRadius: "4px",
          },
        },
      }}
    >
      <ModalHeader>
        <LabelLarge color="#535A68" $style={{ fontWeight: 400 }}>
          Rename project
        </LabelLarge>
      </ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl label="Name" error={errors.name && errors.name.message}>
            <ControlledInput
              control={control}
              name="name"
              error={!!errors.name}
              rules={{ required: "This field is required" }}
              disabled={isSubmitting}
            />
          </FormControl>
        </form>
      </ModalBody>
      <ModalFooter>
        <ModalButton
          kind="tertiary"
          disabled={isSubmitting}
          isLoading={isSubmitting}
          onClick={onClose}
        >
          Cancel
        </ModalButton>
        <ModalButton
          type="submit"
          disabled={isSubmitting}
          isLoading={isSubmitting}
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </ModalButton>
      </ModalFooter>
    </Modal>
  );
}
