import { useAuth } from "app/auth/context";
import {
  SORT_DIRECTIONS,
  SORT_OPTIONS,
} from "app/collages/components/CollageDesigner/Gallery";
import reducer, {
  INITIAL_STATE,
} from "app/collages/components/CollageDesigner/store/reducer";
import {
  CollageDesignerAction,
  CollageDesignerActionType,
  CollageDesignerState,
} from "app/collages/components/CollageDesigner/types";
import { XY } from "app/collages/components/CollageDesigner2/types";
import {
  UndoableAction,
  useUndoableReducer,
} from "app/core/hooks/useUndoableReducer";
import deepEqual from "deep-equal";
import React, { useContext, useRef, useState } from "react";
import { useEffect } from "react";
import { useLocalstorageState } from "rooks";
import { useDebouncedCallback } from "use-debounce";

type CollageDesignerContextProps = {
  state: CollageDesignerState;
  dispatch: React.Dispatch<UndoableAction<CollageDesignerAction>>;
  collageRootRef: React.RefObject<HTMLDivElement>;
  canUndo: boolean;
  canRedo: boolean;
  save: () => Promise<void>;
  isSaving: boolean;
  publish: () => Promise<void>;
  isPublishing: boolean;
  unpublish: () => Promise<void>;
  isUnpublishing: boolean;
  view: {
    collageTranslate: XY;
    setCollageTranslate: React.Dispatch<React.SetStateAction<XY>>;
    collageWidth: number;
    setCollageWidth: React.Dispatch<React.SetStateAction<number>>;
    collageScale: number;
    setCollageScale: React.Dispatch<React.SetStateAction<number>>;
  };
  gallery: {
    sortBy: typeof SORT_OPTIONS[number];
    setSortBy: React.Dispatch<
      React.SetStateAction<typeof SORT_OPTIONS[number]>
    >;
    sortDirection: typeof SORT_DIRECTIONS[number];
    setSortDirection: React.Dispatch<
      React.SetStateAction<typeof SORT_DIRECTIONS[number]>
    >;
    isSplitted: boolean;
    setIsSplitted: React.Dispatch<React.SetStateAction<boolean>>;
    minRating: number;
    setMinRating: React.Dispatch<React.SetStateAction<number>>;
  };
};

export const CollageDesignerContext = React.createContext<CollageDesignerContextProps>(
  {} as CollageDesignerContextProps
);

type Props = {
  initialState?: Partial<CollageDesignerState>;
  onSave: (
    state: CollageDesignerState,
    dispatch: React.Dispatch<UndoableAction<CollageDesignerAction>>
  ) => Promise<void>;
  onPublish: (
    state: CollageDesignerState,
    dispatch: React.Dispatch<UndoableAction<CollageDesignerAction>>
  ) => Promise<void>;
  onUnpublish: (
    state: CollageDesignerState,
    dispatch: React.Dispatch<UndoableAction<CollageDesignerAction>>
  ) => Promise<void>;
  children: React.ReactNode;
};

const AUTO_SAVE_TIME = 10000;

export const CollageDesignerProvider = ({
  initialState,
  onSave,
  onPublish,
  onUnpublish,
  children,
}: Props): React.ReactElement => {
  const previousState = useRef<CollageDesignerState>();

  const {
    state,
    dispatch,
    canUndo,
    canRedo,
    actionHistory,
  } = useUndoableReducer<
    CollageDesignerState,
    CollageDesignerAction,
    "collageBody"
  >(
    reducer,
    {
      ...INITIAL_STATE,
      ...initialState,
    },
    "collageBody"
  );

  const collageRootRef = useRef<HTMLDivElement>(null);
  const isInitialStateSet = useRef(false);

  const [collageTranslate, setCollageTranslate] = useState<XY>({
    x: 0,
    y: 0,
  });
  const [collageWidth, setCollageWidth] = useState(0);
  const [collageScale, setCollageScale] = useLocalstorageState(
    `collage:${state.collageId}:scale`,
    1
  );

  const [isSaving, setIsSaving] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [isUnpublishing, setIsUnpublishing] = useState(false);

  const [sortBy, setSortBy] = useState(SORT_OPTIONS[0]);
  const [sortDirection, setSortDirection] = useState(SORT_DIRECTIONS[0]);
  const [isSplitted, setIsSplitted] = useState(true);
  const [minRating, setMinRating] = useState(0);

  const autoSaveDebounced = useDebouncedCallback(save, AUTO_SAVE_TIME);

  const { user, refreshCounts } = useAuth();

  async function save(stateToSave = state) {
    if (!isSaving) {
      setIsSaving(true);
      await onSave(stateToSave, dispatch);
      setIsSaving(false);
    }
  }

  async function publish() {
    if (
      user &&
      user.availableFeatures?.publishedPostsCount !== -1 &&
      (!state.collagePublishedAt || state.collagePublishDisabledAt) &&
      user.publishedCollageCount >=
        ((user.availableFeatures?.publishedPostsCount as number) ?? 0)
    ) {
      throw new Error();
    }

    setIsPublishing(true);
    await onPublish(state, dispatch);
    setIsPublishing(false);
    refreshCounts();
  }

  async function unpublish() {
    setIsUnpublishing(true);
    await onUnpublish(state, dispatch);
    setIsUnpublishing(false);
    refreshCounts();
  }

  useEffect(() => {
    if (!isInitialStateSet.current) {
      isInitialStateSet.current = !!state;
    } else {
      if (
        previousState.current &&
        !deepEqual(previousState.current.collageBody, state.collageBody)
      ) {
        const { [actionHistory.length - 1]: lastAction } = actionHistory;

        if (lastAction.type !== CollageDesignerActionType.Refresh) {
          autoSaveDebounced(state);
        }
      }
    }

    previousState.current = state;
  }, [state]);

  useEffect(() => () => autoSaveDebounced.cancel(), []);

  return (
    <CollageDesignerContext.Provider
      value={{
        state,
        dispatch,
        collageRootRef,
        canUndo,
        canRedo,
        save,
        isSaving,
        publish,
        isPublishing,
        unpublish,
        isUnpublishing,
        view: {
          collageTranslate,
          setCollageTranslate,
          collageWidth,
          setCollageWidth,
          collageScale,
          setCollageScale,
        },
        gallery: {
          sortBy,
          setSortBy,
          sortDirection,
          setSortDirection,
          isSplitted,
          setIsSplitted,
          minRating,
          setMinRating,
        },
      }}
    >
      {children}
    </CollageDesignerContext.Provider>
  );
};

export const useCollageDesigner = (): CollageDesignerContextProps =>
  useContext(CollageDesignerContext);
