import { gql, useMutation, useQuery } from "@apollo/client";
import Button from "app/core/components/Button";
import LoadingSpinner from "app/core/components/LoadingSpinner";
import AppLayout from "app/core/layouts/AppLayout";
import { useStyletron } from "baseui";
import { Cell, Grid } from "baseui/layout-grid";
import { toaster } from "baseui/toast";
import {
  DisplayXSmall,
  LabelMedium,
  LabelSmall,
  ParagraphMedium,
} from "baseui/typography";
import deepEqual from "deep-equal";
import { ReactElement, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import FeaturesTable from "../../components/FeaturesTable";

const FETCH_PLAN = gql`
  query Plan($id: Id!) {
    plan(id: $id) {
      id
      name
      price
      type
      features
    }
  }
`;

interface ShowPlanPageParams {
  id: string;
}

const UPDATE_PLAN = gql`
  mutation UpdatePlan($input: UpdatePlanInput!) {
    updatePlan(input: $input) {
      id
      name
      price
      type
      features
    }
  }
`;

export default function ShowPlanPage(): ReactElement {
  const [css] = useStyletron();
  const { id } = useParams<ShowPlanPageParams>();
  const [updatePlan] = useMutation(UPDATE_PLAN);

  const { loading, data } = useQuery(FETCH_PLAN, {
    variables: { id: parseInt(id) },
    nextFetchPolicy: "no-cache",
  });

  const [features, setFeatures] = useState<Record<string, boolean | number>>(
    {}
  );

  useEffect(() => {
    if (data && data.plan) {
      setFeatures(data.plan.features);
    }
  }, [data]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <AppLayout>
      <Grid>
        <Cell span={12}>
          <div
            className={css({
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "20px",
              paddingBottom: "20px",
            })}
          >
            <DisplayXSmall
              $style={{
                fontSize: "28px",
                fontWeight: 300,
              }}
            >
              {data.plan.name}
            </DisplayXSmall>
          </div>
        </Cell>
        <Cell span={3}>
          <LabelSmall>Name</LabelSmall>
          <ParagraphMedium>{data.plan.name}</ParagraphMedium>
        </Cell>
        <Cell span={3}>
          <LabelSmall>Type</LabelSmall>
          <ParagraphMedium>{data.plan.type}</ParagraphMedium>
        </Cell>
        <Cell span={3}>
          <LabelSmall>Price</LabelSmall>
          <ParagraphMedium>${data.plan.price}</ParagraphMedium>
        </Cell>
        <Cell span={12}>
          <LabelMedium
            marginTop="scale800"
            color="primary"
            marginBottom="scale400"
          >
            Included features
          </LabelMedium>
          <FeaturesTable
            value={features}
            onChange={(value) => setFeatures(value)}
          />

          <Button
            $style={{
              marginTop: "20px",
            }}
            disabled={deepEqual(features, data.plan.features)}
            onClick={async () => {
              await updatePlan({
                variables: {
                  input: {
                    id: parseInt(id),
                    features,
                  },
                },
              });

              toaster.positive("Updated successfully", {
                autoHideDuration: 4000,
              });
            }}
          >
            Save changes
          </Button>
        </Cell>
      </Grid>
    </AppLayout>
  );
}
