import GuestHeader from "app/auth/components/GuestHeader";
import { useAuth } from "app/auth/context";
import Button from "app/core/components/Button";
import { GoogleIcon } from "app/core/components/Icon";
import { ControlledInput } from "app/core/components/Input";
import LoadingSpinner from "app/core/components/LoadingSpinner";
import { useStyletron } from "baseui";
import { FormControl } from "baseui/form-control";
import { Cell, Grid } from "baseui/layout-grid";
import { StyledLink } from "baseui/link";
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
} from "baseui/modal";
import { toaster } from "baseui/toast";
import { Display4, ParagraphSmall } from "baseui/typography";
import React, { ReactElement, useEffect, useState } from "react";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { Link, Redirect, useLocation } from "react-router-dom";

type FormInputs = {
  email: string;
  password: string;
  sms2FaToken?: string;
};

export default function Login(): ReactElement {
  const {
    handleSubmit,
    errors,
    control,
    formState: { isSubmitting },
  } = useForm<FormInputs>({
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const { isAuthenticated, login } = useAuth();
  const location = useLocation();
  const [
    isTokenAuthenticationLoading,
    setIsTokenAuthenticationLoading,
  ] = useState(false);
  const [css] = useStyletron();

  const [is2FaModalOpen, setIs2FaModalOpen] = React.useState(false);

  const { from } = (location.state as LocationState) || {
    from: { pathname: "/projects" },
  };

  const searchParams = new URLSearchParams(location.search);
  const loginToken = searchParams.get("loginToken");

  useEffect(() => {
    async function tryTokenLogin() {
      if (loginToken) {
        setIsTokenAuthenticationLoading(true);
        await loginFunction("token", { loginToken });
        setIsTokenAuthenticationLoading(false);
      }
    }

    tryTokenLogin();
  }, []);

  const loginFunction = useCallback(
    async (
      type: "credentials" | "token",
      input:
        | { email: string; password: string; sms2FaToken?: string }
        | { loginToken: string }
    ) => {
      try {
        await login(type, input);

        toaster.positive("Logged in successfully", {
          autoHideDuration: 4000,
        });
      } catch (error) {
        if (
          error.graphQLErrors &&
          error.graphQLErrors[0] &&
          error.graphQLErrors[0].extensions?.code === "NO_TOKEN_PROVIDED_ERROR"
        ) {
          setIs2FaModalOpen(true);
        } else {
          if (
            error.graphQLErrors &&
            error.graphQLErrors[0] &&
            error.graphQLErrors[0].extensions?.code === "USER_BLOCKED_ERROR"
          ) {
            toaster.negative("The user is blocked", {
              autoHideDuration: 4000,
            });
          } else if (
            error.graphQLErrors &&
            error.graphQLErrors[0] &&
            error.graphQLErrors[0].extensions?.code ===
              "EMAIL_NOT_VERIFIED_ERROR"
          ) {
            toaster.negative("The e-mail address is not verified", {
              autoHideDuration: 4000,
            });
          } else {
            {
              toaster.negative("The e-mail address or password is incorrect", {
                autoHideDuration: 4000,
              });
            }
          }
        }
      }
    },
    [login]
  );

  if (isAuthenticated) {
    return <Redirect to={from} />;
  }

  if (isTokenAuthenticationLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <GuestHeader />
      <Grid>
        <Cell skip={[0, 1, 3]} span={[4, 6, 6]}>
          <div
            className={css({
              marginTop: "40px",
              padding: "20px",
              textAlign: "center",
              maxWidth: "430px",
              marginLeft: "auto",
              marginRight: "auto",
            })}
          >
            <form
              onSubmit={handleSubmit((data) =>
                loginFunction("credentials", data)
              )}
            >
              <Display4 $style={{ fontWeight: 300, marginBottom: "60px" }}>
                Welcome back
              </Display4>
              <FormControl
                label={() => "Email Address"}
                error={errors.email && errors.email.message}
                overrides={{
                  Label: {
                    style: {
                      textTransform: "uppercase",
                      textAlign: "left",
                      fontSize: "12px",
                      color: "#8390AE",
                    },
                  },
                }}
              >
                <ControlledInput
                  control={control}
                  name="email"
                  error={!!errors.email}
                  rules={{ required: "This field is required" }}
                  disabled={isSubmitting}
                />
              </FormControl>
              <FormControl
                label={() => (
                  <>
                    Password
                    <Link to="/forgot-password">
                      <StyledLink
                        $style={{
                          float: "right",
                          color: "#A48D79",
                          textDecoration: "none",
                        }}
                      >
                        Forgot?
                      </StyledLink>
                    </Link>
                  </>
                )}
                error={errors.password && errors.password.message}
                overrides={{
                  Label: {
                    style: {
                      textTransform: "uppercase",
                      textAlign: "left",
                      fontSize: "12px",
                      color: "#8390AE",
                    },
                  },
                }}
              >
                <ControlledInput
                  control={control}
                  name="password"
                  error={!!errors.password}
                  rules={{ required: "This field is required" }}
                  type="password"
                  autoComplete="current-password"
                  disabled={isSubmitting}
                />
              </FormControl>

              <div
                className={css({
                  marginBottom: "10px",
                })}
              >
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  $style={{
                    width: "100%",
                    display: "block",
                  }}
                >
                  Sign in
                </Button>
                <div
                  className={css({
                    margin: "20px 0",
                    textAlign: "center",
                    position: "relative",
                  })}
                >
                  <div
                    className={css({
                      borderTop: "1px solid #dfdfdf",
                      margin: "0 auto",
                      position: "absolute",
                      top: "50%",
                      left: 0,
                      right: 0,
                      bottom: 0,
                      width: "100%",
                      zIndex: -1,
                    })}
                  ></div>
                  <ParagraphSmall
                    color="gray"
                    display={"inline"}
                    backgroundColor={"#F6F8F9"}
                    paddingLeft="scale600"
                    paddingRight="scale600"
                  >
                    or
                  </ParagraphSmall>
                </div>
                <Button
                  kind="secondary"
                  $as="a"
                  href={`${
                    process.env.REACT_APP_API_URL
                  }/auth/google?redirectUrl=${
                    window.location.href.split("?")[0]
                  }`}
                  disabled={isSubmitting}
                  $style={{
                    width: "100%",
                  }}
                  startEnhancer={() => <GoogleIcon />}
                >
                  Sign in with Google
                </Button>
              </div>
              <Modal
                onClose={() => setIs2FaModalOpen(false)}
                closeable
                isOpen={is2FaModalOpen}
                animate
              >
                <ModalHeader>Confirm login</ModalHeader>
                <ModalBody>
                  <FormControl
                    label={() => "Code"}
                    error={errors.sms2FaToken && errors.sms2FaToken.message}
                  >
                    <ControlledInput
                      control={control}
                      name="sms2FaToken"
                      error={!!errors.sms2FaToken}
                      rules={{ required: "This field is required" }}
                      autoComplete="one-time-code"
                      disabled={isSubmitting}
                    />
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <ModalButton
                    kind="tertiary"
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                  >
                    Cancel
                  </ModalButton>
                  <ModalButton
                    type="submit"
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    onClick={handleSubmit((data) =>
                      loginFunction("credentials", data)
                    )}
                  >
                    Confirm
                  </ModalButton>
                </ModalFooter>
              </Modal>
            </form>
          </div>
        </Cell>
      </Grid>
    </>
  );
}
