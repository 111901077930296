import { gql, useMutation } from "@apollo/client";
import { useAuth } from "app/auth/context";
import { FormControl } from "baseui/form-control";
import { toaster } from "baseui/toast";
import { LabelMedium } from "baseui/typography";
import { ReactElement, useEffect } from "react";
import { useForm } from "react-hook-form";

import { ControlledCheckbox } from "./Checkbox";

const UPDATE_REF_LINK = gql`
  mutation UpdateRefLink($input: UpdateRefLinkInput!) {
    updateRefLink(input: $input) {
      id
      email
      firstName
      lastName
      phone
      has2FaEnabled
      isAdmin
      availableFeatures
      collageExportCount
      publishedCollageCount
      hasRefLinkEnabled
    }
  }
`;

type FormInputs = {
  hasRefLinkEnabled: boolean;
};

export default function RefLinkForm(): ReactElement {
  const [updateRefLink] = useMutation(UPDATE_REF_LINK);
  const { user, setUser, verifyFeatureAvailability } = useAuth();

  const {
    handleSubmit,
    errors,
    control,
    watch,
    formState: { isSubmitting },
  } = useForm<FormInputs>({
    defaultValues: {
      ...user,
    },
  });

  async function update(input: FormInputs) {
    try {
      const response = await updateRefLink({
        variables: { input },
      });

      setUser(response.data.updateRefLink);

      toaster.positive("Saved successfully", {
        autoHideDuration: 4000,
      });
    } catch (error) {
      toaster.negative("The data provided is incorrect", {
        autoHideDuration: 4000,
      });
    }
  }

  useEffect(() => {
    if (user && user.hasRefLinkEnabled !== watch("hasRefLinkEnabled")) {
      handleSubmit(update)();
    }
  }, [watch("hasRefLinkEnabled")]);

  return (
    <form onSubmit={handleSubmit(update)}>
      <LabelMedium color="primary" marginBottom="scale600">
        Powered By Picnook
      </LabelMedium>

      <FormControl
        error={errors.hasRefLinkEnabled && errors.hasRefLinkEnabled.message}
      >
        <ControlledCheckbox
          control={control}
          name="hasRefLinkEnabled"
          disabled={
            !verifyFeatureAvailability("disableRefLink") || isSubmitting
          }
          error={!!errors.hasRefLinkEnabled}
        >
          Enabled
        </ControlledCheckbox>
      </FormControl>
    </form>
  );
}
