import Button from "app/core/components/Button";
import Input from "app/core/components/Input";
import { styled, useStyletron } from "baseui";
import { Block } from "baseui/block";
import { StatefulPopover } from "baseui/popover";
import React, { useEffect, useState } from "react";
import { RgbaStringColorPicker } from "react-colorful";
import { Controller, ControllerProps } from "react-hook-form";
import { hexToRgba, rgbaToHex } from "utils/color-format-conversion";

const ColorPickerContainer = styled("div", {
  position: "relative",
});

type ColorPickerProps = {
  initialColor: string;
  onPick?: (color: string) => void;
  children?: React.ReactNode;
};

export default function ColorPicker({
  initialColor,
  onPick,
}: ColorPickerProps): React.ReactElement {
  const [css, theme] = useStyletron();
  const [color, setColor] = useState<string>(initialColor);
  const [hexColor, setHexColor] = useState<string>("");

  useEffect(() => {
    setHexColor(rgbaToHex(color));
  }, [color]);

  useEffect(() => {
    setColor(initialColor);
  }, [initialColor]);

  return (
    <ColorPickerContainer>
      <StatefulPopover
        showArrow
        content={({ close }) => (
          <div
            className={css({
              background: "#fff",
              minWidth: "320px",
              padding: "20px",
            })}
          >
            <style
              dangerouslySetInnerHTML={{
                __html: `.react-colorful {
              position: relative;
              display: flex;
              flex-direction: column;
              height: 200px;
              user-select: none;
              cursor: default;
            }
            
            .react-colorful__saturation {
              position: relative;
              flex-grow: 1;
              border-bottom: 12px solid #000; 
              background-image: linear-gradient(to top, #000, rgba(0, 0, 0, 0)),
                linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
            }
            
            .react-colorful__pointer-fill,
            .react-colorful__alpha-gradient {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              right: 0;
              bottom: 0;
              pointer-events: none;
              border-radius: inherit;
            }
            
            /* Improve elements rendering on light backgrounds */
            .react-colorful__alpha-gradient,
            .react-colorful__saturation {
              box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.05);
            }
            
            .react-colorful__hue,
            .react-colorful__alpha {
              position: relative;
              height: 24px;
            }
            
            .react-colorful__hue {
              background: linear-gradient(
                to right,
                #f00 0%,
                #ff0 17%,
                #0f0 33%,
                #0ff 50%,
                #00f 67%,
                #f0f 83%,
                #f00 100%
              );
            }

            .react-colorful__interactive {
              position: absolute;
              left: 0;
              top: 0;
              right: 0;
              bottom: 0;
              border-radius: inherit;
              outline: none;
              /* Don't trigger the default scrolling behavior when the event is originating from this element */
              touch-action: none;
            }
            
            .react-colorful__pointer {
              position: absolute;
              z-index: 1;
              box-sizing: border-box;
              width: 28px;
              height: 28px;
              transform: translate(-50%, -50%);
              background-color: #fff;
              border: 2px solid #fff;
              border-radius: 50%;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
            }
            
            .react-colorful__interactive:focus .react-colorful__pointer {
              transform: translate(-50%, -50%) scale(1.1);
            }
            
            /* Chessboard-like pattern for alpha related elements */
            .react-colorful__alpha,
            .react-colorful__alpha-pointer {
              background-color: #fff;
              background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill-opacity=".05"><rect x="8" width="8" height="8"/><rect y="8" width="8" height="8"/></svg>');
            }
            
            /* Display the saturation pointer over the hue one */
            .react-colorful__saturation-pointer {
              z-index: 3;
            }
            
            /* Display the hue pointer over the alpha one */
            .react-colorful__hue-pointer {
              z-index: 2;
            }`,
              }}
            />
            <RgbaStringColorPicker color={color} onChange={setColor} />
            <Input
              value={hexColor}
              maxLength={6}
              onChange={(event: React.FormEvent<HTMLInputElement>) => {
                event.stopPropagation();
                setHexColor(event.currentTarget.value);
                if (event.currentTarget.value.length === 6)
                  setColor(hexToRgba(event.currentTarget.value));
              }}
              autoFocus
              startEnhancer="#"
              overrides={{
                Root: {
                  style: {
                    marginTop: "10px",
                    borderTopLeftRadius: theme.borders.radius200,
                    borderTopRightRadius: theme.borders.radius200,
                    borderBottomRightRadius: theme.borders.radius200,
                    borderBottomLeftRadius: theme.borders.radius200,
                    borderTopWidth: "1px",
                    borderRightWidth: "1px",
                    borderBottomWidth: "1px",
                    borderLeftWidth: "1px",
                  },
                },
                Input: {
                  props: {
                    onPaste: (event: React.ClipboardEvent) => {
                      event.preventDefault();
                      let data = event.clipboardData.getData("text/plain");
                      data = data.includes("#") ? data.substring(1) : data;

                      if (
                        typeof data === "string" &&
                        data.length === 6 &&
                        !isNaN(Number("0x" + data))
                      ) {
                        setHexColor(data);
                        setColor(hexToRgba(data));
                      }
                    },
                  },
                  style: {
                    fontSize: "1rem",
                  },
                },
              }}
            />
            <Button
              onClick={() => {
                if (onPick) onPick(color);
                close();
              }}
              $style={{
                width: "100%",
                marginTop: "10px",
              }}
            >
              Confirm
            </Button>
          </div>
        )}
        overrides={{
          Body: {
            style: {
              zIndex: 11000,
            },
          },
        }}
      >
        <Block>
          <Input
            startEnhancer={() => (
              <div
                className={css({
                  backgroundColor: color,
                  height: "17px",
                  width: "17px",
                  borderRadius: "2px",
                  borderWidth: "1px",
                  borderStyle: "solid",
                  borderColor: "rgba(145, 153, 168, 0.2)",
                  cursor: "pointer",
                })}
              ></div>
            )}
            value={hexColor ? "#" + hexColor : color}
          />
        </Block>
      </StatefulPopover>
    </ColorPickerContainer>
  );
}

export function ControlledColorPicker({
  control,
  name,
  rules,
  ...rest
}: Omit<
  ControllerProps<typeof ColorPicker>,
  "render" | "initialColor"
>): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ onChange, value }) => (
        <ColorPicker
          {...rest}
          initialColor={value}
          onPick={(color) => onChange(color)}
        />
      )}
    />
  );
}
