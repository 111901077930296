import { mergeOverrides, useStyletron } from "baseui";
import {
  COUNTRIES,
  Country,
  PhoneInput as BasePhoneInput,
  PhoneInputProps as BasePhoneInputProps,
} from "baseui/phone-input";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { Controller, ControllerProps } from "react-hook-form";

type PhoneInputProps = {
  value?: string;
  onChange?: (value: string) => void;
} & BasePhoneInputProps;

export default function PhoneInput({
  value,
  onChange,
  overrides,
  ...props
}: PhoneInputProps): React.ReactElement {
  const [, theme] = useStyletron();

  const [dialCode, ...splittedText] = value?.split(" ") || ["", ""];

  const [country, setCountry] = useState<Country>(
    Object.values(COUNTRIES).find(
      (country: Country) => country.dialCode === dialCode
    ) || COUNTRIES.US
  );
  const [text, setText] = useState(splittedText.join(" ") || "");

  useEffect(() => {
    if (onChange) {
      onChange(country && text ? `${country.dialCode} ${text}` : "");
    }
  }, [country, text]);

  return (
    <BasePhoneInput
      country={country}
      onCountryChange={({ option }) => setCountry(option as Country)}
      text={text}
      onTextChange={(event: SyntheticEvent<HTMLInputElement, Event>) =>
        setText(event.currentTarget.value)
      }
      overrides={mergeOverrides(
        {
          Root: {
            style: {
              position: "relative",
              borderTopLeftRadius: theme.borders.radius200,
              borderTopRightRadius: theme.borders.radius200,
              borderBottomRightRadius: theme.borders.radius200,
              borderBottomLeftRadius: theme.borders.radius200,
              borderTopWidth: "1px",
              borderRightWidth: "1px",
              borderBottomWidth: "1px",
              borderLeftWidth: "1px",
            },
          },
          PhoneInput: {
            style: {
              fontSize: "1rem",
            },
          },
          StartEnhancer: {
            style: {
              paddingLeft: "0px",
              paddingRight: "0px",
            },
          },
          EndEnhancer: {
            style: {
              paddingLeft: "0px",
              paddingRight: "0px",
            },
          },
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        overrides as any
      )}
      {...props}
    />
  );
}

export function ControlledPhoneInput({
  control,
  name,
  rules,
  ...rest
}: Omit<ControllerProps<typeof PhoneInput>, "render">): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ onChange, onBlur, value }) => (
        <PhoneInput
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          {...rest}
        />
      )}
    />
  );
}
