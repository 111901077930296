import { ReactDOMAttributes } from "@use-gesture/react";
import { Value } from "baseui/select";
import { RawDraftContentState } from "draft-js";
import { ElementRef } from "react";

import { ForwardedPhotoCropper } from "./PhotoCropper";

type ExcludeTypeField<A> = { [K in Exclude<keyof A, "type">]: A[K] };
type DraftTextAlignment = "left" | "center" | "right";

export type ExtractNodeParameters<A, T> = ExcludeTypeField<
  Extract<A, { type: T }>
>;

export type Dimensions = {
  width: number;
  height: number;
  fitContent?: boolean;
};

export type PhotoIdentifier = number;

export type Photo = {
  id: PhotoIdentifier;
  name: string;
  url: string;
  thumbnailUrl: string;
  dimensions: Dimensions;
  rating: number;
  createdAt: string;
  updatedAt: string;
};

export type PhotoTransform = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export enum SeoScoreRuleType {
  MainKeywordInTitle = "main_keyword_in_title",
  MainKeywordInDescription = "main_keyword_in_description",
  TitleLength = "title_length",
  DescriptionLength = "description_length",
  MainKeywordOnTopContent = "main_keyword_on_top_content",
  MainKeywordInContent = "main_keyword_in_content",
  MainKeywordInHeadings = " main_keyword_in_headings",
  MainKeywordInAltTexts = " main_keyword_in_alt_texts",
  KeywordDensity = "keyword_density",
  MainKeywordInTitleStart = "main_keyword_in_title_start",
  NumberInTitle = "number_in_title",
  Passive = "passive",
}

export type SeoScoreRuleset = {
  title: string;
  score: number;
  rules: SeoScoreRule[];
};

export type SeoScoreRule = {
  label: {
    success: string;
    warning?: string;
    error: string;
  };
  type: SeoScoreRuleType;
  value: number;
  min: number;
  success: number;
  description?: string;
};

export type SeoScore = {
  score: number;
  rulesets: SeoScoreRuleset[];
};

export type TextStyle = {
  id: string;
  label: string;
  hasChanged?: boolean;
  textAs?: string;
  textFontFamily?: string;
  textSize?: number;
};

export type CollageNodeIdentifier = number;

export enum CollageNodeType {
  Internal = "internal",
  Photo = "photo",
  Spacer = "spacer",
  Text = "text",
  Video = "video",
  Download = "download",
}
export enum TextType {
  Heading = "heading",
  Text = "text",
}

export type CollageNode = {
  id: CollageNodeIdentifier;
  dimensions?: Dimensions;
  normalizedDimensions?: Dimensions;
  offset?: { top: number; left: number };
  parent?: {
    id: CollageNodeIdentifier;
    direction: "vertical" | "horizontal";
    index: number;
  };
} & (
  | {
      type: CollageNodeType.Internal;
      direction: "vertical" | "horizontal";
      children: CollageNodeIdentifier[];
    }
  | {
      type: CollageNodeType.Photo;
      photoId: PhotoIdentifier;
      photoUrl?: string;
      photoThumbnailUrl?: string;
      photoTransform?: PhotoTransform;
      isMirrored?: boolean;
      photoDimensions: Dimensions;
      metaData?: CollageMetaData;
      ratio: number;
      recycleOptionIndex?: number;
      rescaleOptionIndex?: number;
    }
  | {
      type: CollageNodeType.Spacer;
    }
  | {
      type: CollageNodeType.Text;
      textContentState?: RawDraftContentState;
      textAlignment?: DraftTextAlignment;
      textVerticalAlignment?: string;
      textColor?: string;
      textAs?: string;
      textFontFamily?: string;
      textSize?: number;
      textFontWeight?: string;
      textLineHeight?: number;
      textLetterSpacing?: number;
      textStyle?: TextStyle;
      textType?: TextType;
      textVariants?: string[];
      paddingTop?: number;
      paddingBottom?: number;
      paddingLeft?: number;
      paddingRight?: number;
      paddingUnit?: string;
      inheritUserStylesheets?: boolean;
    }
  | {
      type: CollageNodeType.Video;
      videoType: string;
      videoUrl?: string;
    }
  | {
      type: CollageNodeType.Download;
      label: string;
      password?: string;
      photoWidth?: number;
    }
);

export enum CollageNodeActionType {
  Create,
  Modify,
  InsertChild,
  RemoveChild,
}

export type CollageNodeAction =
  | {
      type: CollageNodeActionType.Create;
      payload: CollageNode;
    }
  | {
      type: CollageNodeActionType.Modify;
      payload: Omit<
        Partial<CollageNode>,
        | "id"
        | "type"
        | "dimensions"
        | "normalizedDimensions"
        | "offset"
        | "parent"
      >;
    }
  | {
      type: CollageNodeActionType.InsertChild;
      payload: {
        childId: CollageNodeIdentifier;
        index?: number;
      };
    }
  | {
      type: CollageNodeActionType.RemoveChild;
      payload: {
        childId: CollageNodeIdentifier;
      };
    };

export type CollageNodes = {
  lastId: CollageNodeIdentifier;
} & Record<CollageNodeIdentifier, CollageNode>;

export type CollageMetaData = {
  title?: string;
  description?: string;
  tags?: Value;
  seoScore?: SeoScore;
};

export interface CollageBody {
  nodes: CollageNodes;
  gap: number;
  backgroundColor: string;
  isPhotoPreviewEnabled: boolean;
  photoPreviewAutoplaySpeed: number | null;
  isPinterestEnabled: boolean;
  nodeEntryAnimation: string | null;
  backgroundMusicType: string | null;
  backgroundMusicUrl: string | null;
  seo: CollageMetaData;
}

export interface CollageDesignerState {
  status: CollageDesignerStatus;
  collageId: number | null;
  collagePublishingToken: string | null;
  collagePublishedAt: string | null;
  collageRepublishedAt: string | null;
  collageUnpublishedAt: string | null;
  collagePublishDisabledAt: string | null;
  activeNodeIds: CollageNodeIdentifier[];
  activeTextNodeId: CollageNodeIdentifier;
  croppingNodeId: number | null;
  croppingNodeHandleRef: React.RefObject<
    ElementRef<typeof ForwardedPhotoCropper>
  > | null;
  collageName: string;
  collageBody: CollageBody;
  textStyles: TextStyle[];
  photos: Photo[];
  selectedDraggablesIds: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  bindDraggingListeners?: (...args: any[]) => ReactDOMAttributes;
}

export enum CollageDesignerActionType {
  InsertNode = "InsertNode",
  MoveNode = "MoveNode",
  ReplaceNode = "ReplaceNode",
  RemoveNode = "RemoveNode",
  ApplyTransformToNode = "ApplyTransformToNode",
  SetNodeDimensions = "SetNodeDimensions",
  ChangeGap = "ChangeGap",
  ChangeBackgroundColor = "ChangeBackgroundColor",
  ChangeMetaData = "ChangeMetaData",
  ChangeActiveNodeIds = "ChangeActiveNodeIds",
  ChangeActiveTextNodeId = "ChangeActiveTextNodeId",
  ChangeNodeMetaData = "ChangeNodeMetaData",
  ModifyNode = "ModifyNode",
  ChangeScale = "ChangeScale",
  ChangeName = "ChangeName",
  Change = "Change",
  ChangeCollageBody = "ChangeCollageBody",
  SetSelectedDraggablesIds = "SetSelectedDraggablesIds",
  AddPhoto = "AddPhoto",
  Refresh = "Refresh",
  RemovePhoto = "RemovePhoto",
  ChangeStatus = "ChangeStatus",
  SetCroppingNode = "SetCroppingNode",
  AutoLayout = "AutoLayout",
}

export type CollageDesignerAction =
  | {
      type: CollageDesignerActionType.InsertNode;
      payload: {
        targetId: CollageNodeIdentifier;
        targetParentId: CollageNodeIdentifier;
        index: number;
        node: CollageNode;
      };
    }
  | {
      type: CollageDesignerActionType.MoveNode;
      payload: {
        targetId: CollageNodeIdentifier;
        targetParentId: CollageNodeIdentifier;
        id: CollageNodeIdentifier;
        parentId: CollageNodeIdentifier;
        index: number;
      };
    }
  | {
      type: CollageDesignerActionType.ReplaceNode;
      payload: {
        targetId: CollageNodeIdentifier;
        photoId: PhotoIdentifier;
        id?: CollageNodeIdentifier;
        parentId?: CollageNodeIdentifier;
      };
    }
  | {
      type: CollageDesignerActionType.RemoveNode;
      payload: {
        targetId: CollageNodeIdentifier;
      };
    }
  | {
      type: CollageDesignerActionType.ModifyNode;
      payload: {
        targetId: CollageNodeIdentifier;
        node: Partial<CollageNode>;
      };
    }
  | {
      type: CollageDesignerActionType.ApplyTransformToNode;
      payload: {
        targetId: CollageNodeIdentifier;
        photoTransform: PhotoTransform;
      };
    }
  | {
      type: CollageDesignerActionType.SetNodeDimensions;
      payload: {
        targetId: CollageNodeIdentifier;
        dimensions: Dimensions;
      };
    }
  | {
      type: CollageDesignerActionType.ChangeNodeMetaData;
      payload: {
        targetId: CollageNodeIdentifier;
        metaData: CollageMetaData;
      };
    }
  | {
      type: CollageDesignerActionType.ChangeGap;
      payload: {
        collageGap: number;
      };
    }
  | {
      type: CollageDesignerActionType.ChangeBackgroundColor;
      payload: {
        collageBackgroundColor: string;
      };
    }
  | {
      type: CollageDesignerActionType.ChangeMetaData;
      payload: {
        collageMetaData: CollageMetaData;
      };
    }
  | {
      type: CollageDesignerActionType.ChangeActiveNodeIds;
      payload: {
        activeNodeIds: CollageNodeIdentifier[];
      };
    }
  | {
      type: CollageDesignerActionType.ChangeActiveTextNodeId;
      payload: {
        activeTextNodeId: CollageNodeIdentifier;
      };
    }
  | {
      type: CollageDesignerActionType.ChangeScale;
      payload: {
        collageScale: number;
      };
    }
  | {
      type: CollageDesignerActionType.ChangeName;
      payload: {
        collageName: string;
      };
    }
  | {
      type: CollageDesignerActionType.SetSelectedDraggablesIds;
      payload: {
        selectedDraggablesIds: string[];
      };
    }
  | {
      type: CollageDesignerActionType.AddPhoto;
      payload: {
        photo: Photo;
      };
    }
  | {
      type: CollageDesignerActionType.RemovePhoto;
      payload: {
        id: PhotoIdentifier;
      };
    }
  | {
      type: CollageDesignerActionType.Change;
      payload: Partial<CollageDesignerState>;
    }
  | {
      type: CollageDesignerActionType.Refresh;
    }
  | {
      type: CollageDesignerActionType.ChangeCollageBody;
      payload: Partial<CollageBody>;
    }
  | {
      type: CollageDesignerActionType.ChangeStatus;
      payload: {
        status: CollageDesignerStatus;
      };
    }
  | {
      type: CollageDesignerActionType.SetCroppingNode;
      payload: {
        croppingNodeId: CollageDesignerState["croppingNodeId"];
        croppingNodeHandleRef?: CollageDesignerState["croppingNodeHandleRef"];
      };
    }
  | {
      type: CollageDesignerActionType.AutoLayout;
    };

export enum CollageDesignerStatus {
  Idle,
  PhotoCropping,
}
