import useResizeObserver from "@react-hook/resize-observer";
import { useLayoutEffect, useState } from "react";

export default function useRect(
  target: React.RefObject<HTMLDivElement>
): DOMRectReadOnly | undefined {
  const [rect, setRect] = useState<DOMRectReadOnly>();

  useLayoutEffect(() => {
    setRect(target.current?.getBoundingClientRect());
  }, [target]);

  useResizeObserver(target, () =>
    setRect(target.current?.getBoundingClientRect())
  );

  return rect;
}
