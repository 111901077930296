import {
  ApolloClient,
  ApolloLink,
  FieldPolicy,
  from,
  InMemoryCache,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { createUploadLink } from "apollo-upload-client";

import { customFetch } from "./fetch";

function skipTakePagination(): FieldPolicy {
  return {
    keyArgs: [],
    merge(existing, incoming, { args }) {
      const skip = args?.skip ?? 0;
      const items = existing && existing.items ? existing.items.slice(0) : [];
      for (let i = 0; i < incoming.items.length; ++i) {
        items[skip + i] = incoming.items[i];
      }

      return { ...incoming, items };
    },
  };
}

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        myCollages: skipTakePagination(),
      },
    },
  },
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_GRAPHQL_WS_URL as string,
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: {
      get token() {
        return localStorage.getItem("token");
      },
    },
  },
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const uploadLink = (createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_API_URL as string,
  fetch: customFetch,
  credentials: "include",
}) as unknown) as ApolloLink;

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);

    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  from([authLink, uploadLink])
);

export default new ApolloClient({
  cache,
  link: splitLink,
});
