import { useAuth } from "app/auth/context";
import ExportModal from "app/collages/components/CollageDesigner/ExportModal";
import PublishModal from "app/collages/components/CollageDesigner/PublishModal";
import { CollageDesignerActionType } from "app/collages/components/CollageDesigner/types";
import { Avatar } from "baseui/avatar";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import {
  ALIGN,
  HeaderNavigation,
  StyledNavigationItem,
  StyledNavigationList,
} from "baseui/header-navigation";
import { StyledLink } from "baseui/link";
import { StatefulMenu } from "baseui/menu";
import { Popover } from "baseui/popover";
import { LabelXSmall } from "baseui/typography";
import { CollageDesignerContext } from "contexts/CollageDesignerContext";
import { ReactElement, useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import Button, { ForwardedButton } from "../Button";
import { Download, Pencil } from "../Icon";
import Input from "../Input";
import Logo from "../Logo";

type HeaderProps = {
  inDesigner?: boolean;
};

export function HeaderCollageControls(): ReactElement {
  const {
    state: { collageName },
    save,
    isSaving,
    dispatch,
  } = useContext(CollageDesignerContext);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  const { user } = useAuth();

  return (
    <>
      <Block marginRight="20px">
        <Input
          startEnhancer={() => <Pencil />}
          size="compact"
          value={collageName}
          onChange={(event: React.FormEvent<HTMLInputElement>) => {
            dispatch({
              type: CollageDesignerActionType.ChangeName,
              payload: {
                collageName: event?.currentTarget?.value,
              },
            });
          }}
        />
      </Block>
      <Button
        size="compact"
        onClick={() => save()}
        disabled={isSaving}
        isLoading={isSaving}
        $style={{ marginRight: "10px" }}
      >
        Save
      </Button>
      <Button
        size="compact"
        onClick={async () => {
          if (user) {
            setIsPublishModalOpen(true);
          }
        }}
        $style={{ marginRight: "10px" }}
      >
        Publish
      </Button>
      <Button
        $style={{ padding: "9px", marginRight: "20px" }}
        onClick={() => setIsExportModalOpen(true)}
      >
        <Download width={16} height={16} strokeWidth={1} />
      </Button>
      <PublishModal
        isOpen={isPublishModalOpen}
        onClose={() => setIsPublishModalOpen(false)}
      />
      <ExportModal
        isOpen={isExportModalOpen}
        onClose={() => setIsExportModalOpen(false)}
      />
    </>
  );
}

export function AutoLayoutButton(): ReactElement {
  const { dispatch } = useContext(CollageDesignerContext);

  return (
    <Button
      size="compact"
      $style={{ marginLeft: "20px" }}
      onClick={() => dispatch({ type: CollageDesignerActionType.AutoLayout })}
    >
      Auto Layout
    </Button>
  );
}

export default function Header({ inDesigner }: HeaderProps): ReactElement {
  const { user, isAuthenticated, logout } = useAuth();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const history = useHistory();

  return (
    <HeaderNavigation
      overrides={{
        Root: {
          style: {
            position: "relative",
            borderBottomColor: "#D8D8D8",
            alignItems: "center",
            paddingTop: "18px",
            paddingBottom: "18px",
            backgroundColor: "white",
            ...(inDesigner && {
              marginTop: "-70px",
              zIndex: 2,
              backgroundColor: "white",
            }),
          },
        },
      }}
    >
      <StyledNavigationList $align={ALIGN.left}>
        <StyledNavigationItem>
          <Block overrides={{ Block: { style: { lineHeight: 0 } } }}>
            <Logo />
          </Block>
        </StyledNavigationItem>
      </StyledNavigationList>
      <StyledNavigationList>
        <Block
          display="flex"
          alignItems="center"
          overrides={{ Block: { style: { lineHeight: 0 } } }}
        >
          <LabelXSmall
            $style={{
              marginTop: "2px",
              fontSize: "12px",
              lineHeight: 1,
              color: "#535A68",
              fontWeight: 500,
              borderLeft: "1px solid #9199A8",
              paddingLeft: "20px",
            }}
          >
            MyBlog
          </LabelXSmall>
          {inDesigner && (
            <>
              <Button
                size="compact"
                $style={{ marginLeft: "20px" }}
                onClick={() => history.goBack()}
              >
                Go to Projects
              </Button>
              <AutoLayoutButton />
            </>
          )}
        </Block>
      </StyledNavigationList>
      <StyledNavigationList $align={ALIGN.center}></StyledNavigationList>
      {!isAuthenticated ? (
        <StyledNavigationList $align={ALIGN.right}>
          {isAuthenticated ? (
            <>
              {/* <StyledNavigationItem>
              <Link to="/projects">
                <StyledLink>Projekty</StyledLink>
              </Link>
            </StyledNavigationItem> */}
            </>
          ) : (
            <>
              <StyledNavigationItem>
                <Link to="/login">
                  <StyledLink>Logowanie</StyledLink>
                </Link>
              </StyledNavigationItem>
              <StyledNavigationItem>
                <Link to="/register">
                  <StyledLink>Rejestracja</StyledLink>
                </Link>
              </StyledNavigationItem>
            </>
          )}
        </StyledNavigationList>
      ) : (
        <StyledNavigationList $align={ALIGN.right}>
          {isAuthenticated && user && (
            <StyledNavigationItem>
              <Block
                display="flex"
                alignItems="center"
                overrides={{ Block: { style: { lineHeight: 0 } } }}
                paddingRight="scale800"
              >
                {inDesigner && <HeaderCollageControls />}
                <Popover
                  isOpen={isMenuOpen}
                  onClickOutside={() => setIsMenuOpen(false)}
                  overrides={{
                    Body: {
                      style: {
                        zIndex: 20,
                        opacity: 1,
                        boxShadow: "unset",
                        borderRadius: "4px",
                        border: "1px solid #E6EAF0",
                        overflow: "hidden",
                      },
                    },
                  }}
                  content={() => (
                    <StatefulMenu
                      onItemSelect={({ item, event }) => {
                        event?.preventDefault();
                        event?.stopPropagation();

                        item.onClick && item.onClick();
                        setIsMenuOpen((isMenuOpen) => !isMenuOpen);
                      }}
                      overrides={{
                        List: {
                          style: {
                            paddingTop: "2px",
                            paddingBottom: "2px",
                            minWidth: "110px",
                          },
                        },
                        ListItem: {
                          style: {
                            borderBottomColor: "#E6E6E6",
                            borderBottomWidth: "1px",
                            borderBottomStyle: "solid",
                            ":last-of-type": {
                              borderBottomWidth: "0px",
                            },
                          },
                        },
                      }}
                      items={[
                        {
                          label: "Projects",
                          onClick: () => history.push("/projects"),
                        },
                        {
                          label: "Account",
                          onClick: () => history.push("/account"),
                        },
                        {
                          label: "Billing",
                          onClick: () => history.push("/billing"),
                        },
                        ...(user.isAdmin
                          ? [
                              {
                                label: "Admin",
                                onClick: () => history.push("/admin"),
                              },
                            ]
                          : []),
                        {
                          label: "Help Center",
                          onClick: () =>
                            window.open("https://help.picnook.io/en/"),
                        },
                        {
                          label: "Sign out",
                          onClick: logout,
                        },
                      ]}
                    />
                  )}
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();

                    setIsMenuOpen((isMenuOpen) => !isMenuOpen);
                  }}
                  placement="bottomRight"
                >
                  <ForwardedButton
                    size={SIZE.compact}
                    kind={KIND.tertiary}
                    overrides={{
                      Root: {
                        style: {
                          padding: 0,
                          ":hover": {
                            backgroundColor: "unset",
                          },
                        },
                      },
                    }}
                  >
                    <Avatar
                      size="scale900"
                      name={
                        user.lastName
                          ? `${user.firstName} ${user.lastName}`
                          : user.firstName
                      }
                    />
                  </ForwardedButton>
                </Popover>
              </Block>
            </StyledNavigationItem>
          )}
        </StyledNavigationList>
      )}
    </HeaderNavigation>
  );
}
