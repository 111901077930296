import { mergeOverrides, useStyletron } from "baseui";
import { Block } from "baseui/block";
import {
  Select as BaseSelect,
  SelectProps as BaseSelectProps,
} from "baseui/select";
import React, { createElement } from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { StyleObject } from "styletron-standard";

import { ChevronDown, Close } from "./Icon";

type SelectProps = {
  minimal?: boolean;
  $style?: StyleObject;
} & BaseSelectProps;

export default function Select({
  overrides,
  minimal,
  $style,
  ...props
}: SelectProps): React.ReactElement {
  const [, theme] = useStyletron();

  return (
    <BaseSelect
      overrides={mergeOverrides(
        {
          Root: {
            style: $style,
          },
          ControlContainer: {
            style: {
              borderTopLeftRadius: theme.borders.radius200,
              borderTopRightRadius: theme.borders.radius200,
              borderBottomRightRadius: theme.borders.radius200,
              borderBottomLeftRadius: theme.borders.radius200,
              borderTopWidth: "1px",
              borderRightWidth: "1px",
              borderBottomWidth: "1px",
              borderLeftWidth: "1px",
              ...(minimal && {
                borderTopWidth: "0px",
                borderRightWidth: "0px",
                borderBottomWidth: "0px",
                borderLeftWidth: "0px",
              }),
            },
          },
          ValueContainer: {
            style: {
              fontSize: "1rem",
              ...(minimal && {
                color: "#8390AE",
              }),
            },
          },
          Input: {
            style: {
              fontSize: "1rem",
              ...(minimal && {
                color: "#8390AE",
              }),
            },
          },
          SelectArrow: {
            props: {
              overrides: {
                // eslint-disable-next-line react/display-name
                Svg: () => (
                  <ChevronDown
                    {...(minimal && { fill: "#8390AE", height: "10" })}
                  />
                ),
              },
            },
          },
          ClearIcon: {
            props: {
              overrides: {
                // eslint-disable-next-line
                Svg: (props: any) => (
                  <Close
                    {...props}
                    viewBox="0 0 16 16"
                    style={{ marginRight: "6px" }}
                    {...(minimal && { fill: "#8390AE", height: "10" })}
                  />
                ),
              },
            },
          },
          Dropdown: {
            style: {
              boxShadow: "none",
              borderTopLeftRadius: theme.borders.radius200,
              borderTopRightRadius: theme.borders.radius200,
              borderBottomRightRadius: theme.borders.radius200,
              borderBottomLeftRadius: theme.borders.radius200,
              backgroundColor: theme.colors.inputFill,
              borderLeftWidth: "1px",
              borderLeftStyle: "solid",
              borderLeftColor: theme.colors.borderSelected,
              borderBottomWidth: "1px",
              borderBottomStyle: "solid",
              borderBottomColor: theme.colors.borderSelected,
              borderRightWidth: "1px",
              borderRightStyle: "solid",
              borderRightColor: theme.colors.borderSelected,
              borderTopWidth: "1px",
              borderTopStyle: "solid",
              borderTopColor: theme.colors.borderSelected,
            },
          },
          Popover: {
            props: {
              overrides: {
                Body: {
                  style: {
                    boxShadow: "none",
                    zIndex: 20,
                    marginTop: "5px",
                    marginBottom: "5px",
                  },
                },
              },
            },
          },
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        overrides as any
      )}
      clearable={false}
      {...props}
    />
  );
}

export function ControlledSelect({
  control,
  name,
  rules,
  ...rest
}: Omit<ControllerProps<typeof Select>, "render">): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ onChange, onBlur, value }) => (
        <Select
          onChange={(params) => params && onChange(params.value)}
          onBlur={onBlur}
          value={value}
          {...rest}
        />
      )}
    />
  );
}

export function IconSelect({
  overrides,
  minimal,
  $style,
  ...props
}: SelectProps): React.ReactElement {
  const [, theme] = useStyletron();

  return (
    <BaseSelect
      overrides={mergeOverrides(
        {
          Root: {
            style: { width: "44px", ...$style },
          },
          ControlContainer: {
            style: {
              borderTopLeftRadius: theme.borders.radius200,
              borderTopRightRadius: theme.borders.radius200,
              borderBottomRightRadius: theme.borders.radius200,
              borderBottomLeftRadius: theme.borders.radius200,
              borderTopWidth: "1px",
              borderRightWidth: "1px",
              borderBottomWidth: "1px",
              borderLeftWidth: "1px",
              ...(minimal && {
                borderTopWidth: "0px",
                borderRightWidth: "0px",
                borderBottomWidth: "0px",
                borderLeftWidth: "0px",
              }),
            },
          },
          ValueContainer: {
            style: {
              paddingLeft: "0px",
              paddingTop: "0px",
              paddingRight: "0px",
              paddingBottom: "0px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "44px",
              width: "44px",
              fontSize: "1rem",
              ...(minimal && {
                color: "#8390AE",
              }),
            },
          },
          SingleValue: {
            style: {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
          },
          IconsContainer: {
            style: {
              display: "none",
            },
          },
          Input: {
            style: {
              fontSize: "1rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              ...(minimal && {
                color: "#8390AE",
              }),
            },
          },
          SelectArrow: {
            props: {
              overrides: {
                // eslint-disable-next-line react/display-name
                Svg: () => null,
              },
            },
          },
          ClearIcon: {
            props: {
              overrides: {
                // eslint-disable-next-line
                Svg: (props: any) => (
                  <Close
                    {...props}
                    viewBox="0 0 16 16"
                    style={{ marginRight: "6px" }}
                    {...(minimal && { fill: "#8390AE", height: "10" })}
                  />
                ),
              },
            },
          },
          DropdownListItem: {
            style: {
              paddingLeft: "0px",
              paddingRight: "0px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
          },
          Dropdown: {
            style: {
              boxShadow: "none",
              borderTopLeftRadius: theme.borders.radius200,
              borderTopRightRadius: theme.borders.radius200,
              borderBottomRightRadius: theme.borders.radius200,
              borderBottomLeftRadius: theme.borders.radius200,
              backgroundColor: theme.colors.inputFill,
              borderLeftWidth: "1px",
              borderLeftStyle: "solid",
              borderLeftColor: theme.colors.borderSelected,
              borderBottomWidth: "1px",
              borderBottomStyle: "solid",
              borderBottomColor: theme.colors.borderSelected,
              borderRightWidth: "1px",
              borderRightStyle: "solid",
              borderRightColor: theme.colors.borderSelected,
              borderTopWidth: "1px",
              borderTopStyle: "solid",
              borderTopColor: theme.colors.borderSelected,
            },
          },
          Popover: {
            props: {
              overrides: {
                Body: {
                  style: {
                    boxShadow: "none",
                    zIndex: 20,
                    marginTop: "5px",
                    marginBottom: "5px",
                  },
                },
              },
            },
          },
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        overrides as any
      )}
      getOptionLabel={({ option }) => (
        <Block display="flex" justifyContent="center" alignItems="center">
          {createElement(option?.icon, {
            width: 28,
            height: 26,
          })}
        </Block>
      )}
      getValueLabel={({ option }) => (
        <Block display="flex" justifyContent="center" alignItems="center">
          {createElement(option?.icon, {
            width: 28,
            height: 26,
          })}
        </Block>
      )}
      clearable={false}
      {...props}
    />
  );
}
