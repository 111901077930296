import { LabelMedium } from "baseui/typography";
import { memo } from "react";

import {
  CollageNode,
  CollageNodeType,
  ExtractNodeParameters,
} from "../../CollageDesigner/types";
import { CommonNodeRendererProps, OptionalExceptFor } from "../types";
import NodeRendererWrapper from "./NodeRendererWrapper";

type VideoNodeRendererProps = OptionalExceptFor<
  ExtractNodeParameters<CollageNode, CollageNodeType.Video>,
  "id"
> &
  CommonNodeRendererProps;

function VideoNodeRenderer({
  rootDivElement,
  collageRootDivElement,
  collageTranslate,
  id,
  offset,
  normalizedDimensions,
  parent,
  collageWidth,
  dispatch,
  collageGap,
  rootNode,
  activeNodeIds,
  collageScale,
  bindDraggingListeners,
  status,
  croppingNodeId,
}: VideoNodeRendererProps): React.ReactElement {
  return (
    <NodeRendererWrapper
      id={id}
      offset={offset}
      normalizedDimensions={normalizedDimensions}
      collageWidth={collageWidth}
      dispatch={dispatch}
      rootDivElement={rootDivElement}
      collageRootDivElement={collageRootDivElement}
      collageTranslate={collageTranslate}
      collageScale={collageScale}
      bindDraggingListeners={bindDraggingListeners.bind(
        null,
        `internal@0/${id}/${parent?.id}`,
        CollageNodeType.Video
      )}
      collageGap={collageGap}
      includeDragButton={true}
      rootNode={rootNode}
      croppingNodeId={croppingNodeId}
      activeNodeIds={activeNodeIds}
      status={status}
      type={CollageNodeType.Video}
    >
      <LabelMedium
        $style={{
          textAlign: "center",
          top: "50%",
          position: "relative",
          marginTop: "-10px",
        }}
      >
        Video
      </LabelMedium>
    </NodeRendererWrapper>
  );
}

const MemoizedVideoNodeRenderer = memo(VideoNodeRenderer);

export default MemoizedVideoNodeRenderer;
