import { MiniCanvas2 } from "app/core/components/Icon";
import { styled } from "baseui";
import { Block } from "baseui/block";
import { LabelSmall } from "baseui/typography";
import { CollageDesignerContext } from "contexts/CollageDesignerContext";
import React, { useContext } from "react";

import ActiveNodePanel, {
  NodeSettingsContainer,
} from "../CollageDesigner/ActiveNodePanel";
import ActiveDraggablesPanel from "./ActiveDraggablesPanel";

const Container = styled("div", {
  backgroundColor: "#FCFCFD",
  filter: "drop-shadow(-2px 2px 5px rgba(0, 0, 0, 0.05))",
  height: "100%",
  width: "360px",
  flexShrink: 0,
  display: "flex",
  flexDirection: "column",
  position: "absolute",
  right: "0px",
  top: "0px",
  zIndex: 1,
  userSelect: "none",
});

export default function RightSidebar(): React.ReactElement {
  const {
    state: { activeNodeIds, selectedDraggablesIds },
  } = useContext(CollageDesignerContext);

  return (
    <>
      <Container>
        {activeNodeIds.length > 0 ? (
          <NodeSettingsContainer>
            <ActiveNodePanel />
          </NodeSettingsContainer>
        ) : selectedDraggablesIds.length > 0 ? (
          <NodeSettingsContainer>
            <ActiveDraggablesPanel />
          </NodeSettingsContainer>
        ) : (
          <Block
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            marginTop="200px"
          >
            <MiniCanvas2 />
            <LabelSmall
              marginTop="42px"
              $style={{ fontWeight: 400 }}
              color="#8390AE"
            >
              Select an element to edit properties.
            </LabelSmall>
          </Block>
        )}
      </Container>
    </>
  );
}
