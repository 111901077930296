import { useStyletron } from "baseui";
import {
  Button as BaseButton,
  ButtonProps as BaseButtonProps,
  KIND,
} from "baseui/button";
import React, { ForwardedRef, forwardRef } from "react";
import { StyleObject } from "styletron-standard";

type ButtonProps = {
  children?: React.ReactNode;
  $style?: StyleObject;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  forwardedRef?: ForwardedRef<any>;
} & BaseButtonProps;

function Button({
  children,
  $style,
  kind,
  size,
  forwardedRef,
  ...props
}: ButtonProps): React.ReactElement {
  const [, theme] = useStyletron();

  return (
    <BaseButton
      overrides={{
        BaseButton: {
          style: {
            fontWeight: 500,
            fontSize: "1rem",
            lineHeight: "1rem",
            ...(!props.shape && {
              borderTopLeftRadius: theme.borders.radius200,
              borderTopRightRadius: theme.borders.radius200,
              borderBottomRightRadius: theme.borders.radius200,
              borderBottomLeftRadius: theme.borders.radius200,
            }),
            ...$style,
            ...(kind === KIND.secondary && {
              borderTopWidth: "1px",
              borderTopColor: "#E6EAF0",
              borderTopStyle: "solid",
              borderRightWidth: "1px",
              borderRightColor: "#E6EAF0",
              borderRightStyle: "solid",
              borderBottomWidth: "1px",
              borderBottomColor: "#E6EAF0",
              borderBottomStyle: "solid",
              borderLeftWidth: "1px",
              borderLeftColor: "#E6EAF0",
              borderLeftStyle: "solid",
            }),
            ...(kind === KIND.tertiary && {
              backgroundColor: "unset",
              color: "#AF9883",
            }),
            transition: theme.animation.timing200,
            ":hover": {
              opacity: 0.8,
            },
          },
        },
        LoadingSpinnerContainer: {
          style: {
            maxHeight: size === "compact" ? "10px" : "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        },
      }}
      kind={kind}
      size={size}
      ref={forwardedRef}
      {...props}
    >
      {children}
    </BaseButton>
  );
}

export default Button;

// eslint-disable-next-line react/display-name
export const ForwardedButton = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (props: ButtonProps, ref: ForwardedRef<any>) => (
    <Button forwardedRef={ref} {...props} />
  )
);
