import { mergeOverrides, useStyletron } from "baseui";
import { SharedProps } from "baseui/input";
import {
  Textarea as BaseTextarea,
  TextareaProps as BaseTextareaProps,
} from "baseui/textarea";
import React from "react";
import { ChangeEvent } from "react";
import { Controller, ControllerProps } from "react-hook-form";

type TextareaProps = {
  endEnhancer?: ((args: SharedProps) => React.ReactNode) | React.ReactNode;
} & BaseTextareaProps;

export default function Textarea({
  overrides,
  endEnhancer,
  ...props
}: TextareaProps): React.ReactElement {
  const [, theme] = useStyletron();

  return (
    <BaseTextarea
      overrides={mergeOverrides(
        {
          Root: {
            style: {
              position: "relative",
              borderTopLeftRadius: theme.borders.radius200,
              borderTopRightRadius: theme.borders.radius200,
              borderBottomRightRadius: theme.borders.radius200,
              borderBottomLeftRadius: theme.borders.radius200,
              borderTopWidth: "1px",
              borderRightWidth: "1px",
              borderBottomWidth: "1px",
              borderLeftWidth: "1px",
            },
          },
          Input: {
            style: {
              fontSize: "1rem",
            },
          },
          After: {
            // eslint-disable-next-line react/display-name
            component: () => <>{endEnhancer}</>,
          },
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        overrides as any
      )}
      {...props}
    />
  );
}

export function ControlledTextarea({
  control,
  name,
  rules,
  ...rest
}: Omit<ControllerProps<typeof Textarea>, "render">): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ onChange, onBlur, value }) => (
        <Textarea
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
            onChange(e.target.value)
          }
          onBlur={onBlur}
          value={value}
          {...rest}
        />
      )}
    />
  );
}
