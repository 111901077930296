import { gql, useMutation } from "@apollo/client";
import GuestHeader from "app/auth/components/GuestHeader";
import { useAuth } from "app/auth/context";
import Button from "app/core/components/Button";
import { ControlledInput } from "app/core/components/Input";
import { useStyletron } from "baseui";
import { FormControl } from "baseui/form-control";
import { Cell, Grid } from "baseui/layout-grid";
import { toaster } from "baseui/toast";
import { Display4 } from "baseui/typography";
import { ReactElement } from "react";
import { useForm } from "react-hook-form";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";

type FormInputs = {
  password: string;
};

const RESET_PASSWORD = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input)
  }
`;

interface ResetPasswordPageParams {
  token: string;
}

export default function ResetPasswordPage(): ReactElement {
  const { token } = useParams<ResetPasswordPageParams>();

  const {
    handleSubmit,
    errors,
    reset,
    control,
    formState: { isSubmitting },
  } = useForm<FormInputs>({
    defaultValues: {
      password: "",
    },
  });
  const { isAuthenticated } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const [css] = useStyletron();
  const [resetPassword] = useMutation(RESET_PASSWORD);

  const { from } = (location.state as LocationState) || {
    from: { pathname: "/projects" },
  };

  if (isAuthenticated) {
    return <Redirect to={from} />;
  }

  return (
    <>
      <GuestHeader />
      <Grid>
        <Cell skip={[0, 1, 4]} span={[4, 6, 4]}>
          <div
            className={css({
              marginTop: "40px",
              padding: "20px",
              textAlign: "center",
            })}
          >
            <form
              onSubmit={handleSubmit(async ({ password }) => {
                try {
                  await resetPassword({
                    variables: {
                      input: {
                        token: decodeURIComponent(token),
                        password,
                      },
                    },
                  });
                  reset();

                  toaster.positive(
                    "Password has been reset. You can now log in using the new one.",
                    {
                      autoHideDuration: 4000,
                    }
                  );
                  history.push("/login");
                } catch (error) {
                  toaster.negative("Something went wrong.", {
                    autoHideDuration: 4000,
                  });
                }
              })}
            >
              <Display4 $style={{ fontWeight: 300, marginBottom: "60px" }}>
                Password reset
              </Display4>
              <FormControl
                label={() => "New password"}
                error={errors.password && errors.password.message}
                overrides={{
                  Label: {
                    style: {
                      textTransform: "uppercase",
                      textAlign: "left",
                      fontSize: "12px",
                      color: "#8390AE",
                    },
                  },
                }}
              >
                <ControlledInput
                  control={control}
                  name="password"
                  error={!!errors.password}
                  rules={{
                    required: "This field is required",
                    minLength: {
                      value: 8,
                      message: "Hasło musi mieć co najmniej 8 znaków",
                    },
                  }}
                  type="password"
                  disabled={isSubmitting}
                />
              </FormControl>

              <div
                className={css({
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "10px",
                  alignItems: "center",
                })}
              >
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  $style={{
                    width: "100%",
                    display: "block",
                  }}
                >
                  Reset
                </Button>
              </div>
            </form>
          </div>
        </Cell>
      </Grid>
    </>
  );
}
