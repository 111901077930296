import { useMutation } from "@apollo/client";
import composeRefs from "@seznam/compose-react-refs";
import Button, { ForwardedButton } from "app/core/components/Button";
import Checkbox from "app/core/components/Checkbox";
import Divider from "app/core/components/Divider";
import { CheckThick, List, Star } from "app/core/components/Icon";
import useKeyPress from "app/core/hooks/useKeyPress";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import { StatefulPopover } from "baseui/popover";
import { StarRating } from "baseui/rating";
import { toaster } from "baseui/toast";
import { LabelXSmall } from "baseui/typography";
import { CollageDesignerContext } from "contexts/CollageDesignerContext";
import { gql } from "graphql-tag";
import React, {
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHotkeys } from "react-hotkeys-hook";

import {
  CollageDesignerActionType,
  CollageNode,
  CollageNodeType,
  Dimensions,
  Photo,
} from "./types";
import UploadPhotosModal from "./UploadPhotosModal";

type GalleryItemProps = {
  photo: Photo;
  index: number;
  photos: Photo[];
  isRangeSelectionKeyPressed: boolean;
  rangeSelectionStartIndex: number | null;
  setRangeSelectionStartIndex: React.Dispatch<
    React.SetStateAction<number | null>
  >;
};

const DELETE_PHOTO = gql`
  mutation DeleteOrUnlinkPhoto($input: DeleteOrUnlinkPhotoInput!) {
    deleteOrUnlinkPhoto(input: $input)
  }
`;

function GalleryItem({
  photo,
  index,
  photos,
  isRangeSelectionKeyPressed,
  rangeSelectionStartIndex,
  setRangeSelectionStartIndex,
}: GalleryItemProps): React.ReactElement {
  const [css, theme] = useStyletron();
  const {
    state: {
      collageBody: { nodes },
      selectedDraggablesIds,
      bindDraggingListeners,
    },
    dispatch,
    view: { collageWidth, setCollageTranslate, collageScale },
  } = useContext(CollageDesignerContext);
  const id = `photo@${photo.id}`;
  const isSelected = useMemo(() => selectedDraggablesIds.includes(id), [
    selectedDraggablesIds,
  ]);

  const inUse = useMemo(
    () =>
      Object.values(nodes)
        .filter((value) => typeof value !== "number")
        .some((collageNode) => {
          collageNode = collageNode as CollageNode;

          return (
            collageNode.type === CollageNodeType.Photo &&
            collageNode.photoId === photo.id
          );
        }),
    [nodes]
  );

  const isMultipleSelectionKeyPressed =
    +useKeyPress("Meta") + +useKeyPress("Control") > 0;

  return (
    <div
      className={css({
        height: "100px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      })}
    >
      <div
        {...(bindDraggingListeners &&
          bindDraggingListeners(
            isSelected ? selectedDraggablesIds.join("|") : id,
            CollageNodeType.Photo
          ))}
        onClick={(event) => {
          event.stopPropagation();
          if (isMultipleSelectionKeyPressed) {
            dispatch({
              type: CollageDesignerActionType.SetSelectedDraggablesIds,
              payload: {
                selectedDraggablesIds: isSelected
                  ? [...selectedDraggablesIds.filter((x) => x !== id)]
                  : [...selectedDraggablesIds, id],
              },
            });
          } else {
            if (selectedDraggablesIds.length === 1) {
              const [selectedDraggableId] = selectedDraggablesIds;
              const selectedDraggableIndex = photos.findIndex(
                (photo) => `photo@${photo.id}` === selectedDraggableId
              );
              if (selectedDraggableIndex !== rangeSelectionStartIndex) {
                rangeSelectionStartIndex = selectedDraggableIndex;
                setRangeSelectionStartIndex(selectedDraggableIndex);
              }
            }

            if (
              !isRangeSelectionKeyPressed ||
              (isRangeSelectionKeyPressed && rangeSelectionStartIndex === null)
            ) {
              setRangeSelectionStartIndex(null);
              dispatch({
                type: CollageDesignerActionType.SetSelectedDraggablesIds,
                payload: {
                  selectedDraggablesIds: [id],
                },
              });
            }

            if (isRangeSelectionKeyPressed) {
              if (rangeSelectionStartIndex === null) {
                setRangeSelectionStartIndex(index);
              } else {
                const startIndex = Math.min(rangeSelectionStartIndex, index);
                const endIndex = Math.max(rangeSelectionStartIndex, index);

                dispatch({
                  type: CollageDesignerActionType.SetSelectedDraggablesIds,
                  payload: {
                    selectedDraggablesIds: photos
                      .slice(startIndex, endIndex + 1)
                      .map((photo) => `photo@${photo.id}`),
                  },
                });
              }
            }
          }
        }}
        onDoubleClick={() => {
          const rootNode = nodes[0];

          dispatch({
            type: CollageDesignerActionType.InsertNode,
            payload: {
              targetId: 0,
              targetParentId: 0,
              index:
                rootNode.type === CollageNodeType.Internal
                  ? rootNode.children.length
                  : 0,
              node: {
                id: 0,
                type: CollageNodeType.Photo,
                photoId: photo.id,
                dimensions: { ...(photo as Photo).dimensions },
                photoDimensions: {
                  ...(photo as Photo).dimensions,
                },
                ratio: 1.5,
              },
            },
          });

          const collageHeight = rootNode.dimensions
            ? (rootNode.dimensions.height / rootNode.dimensions.width) *
              collageWidth
            : 0;

          setCollageTranslate(({ x }) => ({
            x,
            y: (-collageHeight + 120) * collageScale,
          }));
        }}
        className={css({
          boxSizing: "border-box",
          borderWidth: "2px",
          borderStyle: "solid",
          borderColor: isSelected ? theme.colors.accent : "white",
          width: `${
            contain(photo.dimensions, { width: 142, height: 100 }).width - 4
          }px`,
          height: `${
            contain(photo.dimensions, { width: 142, height: 100 }).height - 4
          }px`,
        })}
      >
        <div
          className={css({
            backgroundImage: `url(${photo.url}?width=150&version=${photo.updatedAt}&format=webp)`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "100% 100%",
            width: "100%",
            height: "100%",
            ...(inUse && { opacity: 0.5 }),
          })}
        ></div>
      </div>
    </div>
  );
}

export const SORT_OPTIONS = [
  {
    label: "Title",
    id: "FILENAME",
    function: (a: Photo, b: Photo): number =>
      a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: "base",
      }),
  },
  {
    label: "Created At",
    id: "CREATED_AT",
    function: (a: Photo, b: Photo): number =>
      new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
  },
];

enum SortDirection {
  ASC,
  DESC,
}

export const SORT_DIRECTIONS = [
  {
    label: "Ascending",
    id: SortDirection.ASC,
  },
  {
    label: "Descending",
    id: SortDirection.DESC,
  },
];

export default function Gallery(): React.ReactElement {
  const {
    state: {
      collageId,
      photos,
      selectedDraggablesIds,
      collageBody: { nodes },
    },
    gallery: {
      sortBy,
      setSortBy,
      sortDirection,
      setSortDirection,
      isSplitted,
      setIsSplitted,
      minRating,
      setMinRating,
    },
    dispatch,
  } = useContext(CollageDesignerContext);
  const [css, theme] = useStyletron();
  const [isUploadPhotosModalOpen, setIsUploadPhotosModalOpen] = useState(false);
  const [rangeSelectionStartIndex, setRangeSelectionStartIndex] = useState<
    number | null
  >(null);

  const [deletePhoto] = useMutation(DELETE_PHOTO);

  const sortedPhotos = useMemo(
    () =>
      photos && sortDirection.id === SortDirection.ASC
        ? photos
            .slice()
            .filter(
              (photo) => !minRating || (minRating && photo.rating >= minRating)
            )
            .sort(sortBy.function)
        : photos
            .slice()
            .filter(
              (photo) => !minRating || (minRating && photo.rating >= minRating)
            )
            .sort(sortBy.function)
            .reverse(),
    [photos, sortBy, sortDirection, minRating]
  );

  const sortedUnusedPhotos = useMemo(
    () =>
      sortedPhotos.filter(
        (photo) =>
          !Object.values(nodes)
            .filter((value) => typeof value !== "number")
            .some((collageNode) => {
              collageNode = collageNode as CollageNode;

              return (
                collageNode.type === CollageNodeType.Photo &&
                collageNode.photoId === photo.id
              );
            })
      ),
    [sortedPhotos, nodes]
  );

  const sortedUsedPhotos = useMemo(
    () =>
      sortedPhotos.filter((photo) =>
        Object.values(nodes)
          .filter((value) => typeof value !== "number")
          .some((collageNode) => {
            collageNode = collageNode as CollageNode;

            return (
              collageNode.type === CollageNodeType.Photo &&
              collageNode.photoId === photo.id
            );
          })
      ),
    [sortedPhotos, nodes]
  );

  const isRangeSelectionKeyPressed = useKeyPress("Shift");

  const deleteSelected = useCallback(async () => {
    try {
      for (const id of selectedDraggablesIds) {
        const [, photoId] = id.split("@");

        await deletePhoto({
          variables: {
            input: {
              id: +photoId,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              collageId: +collageId!,
            },
          },
        });

        for (const node of Object.values(nodes).filter(
          (node) =>
            typeof node !== "number" &&
            node.type === CollageNodeType.Photo &&
            node.photoId === +photoId
        )) {
          if (typeof node !== "number") {
            dispatch({
              type: CollageDesignerActionType.RemoveNode,
              payload: {
                targetId: node.id,
              },
            });
          }
        }

        dispatch({
          type: CollageDesignerActionType.RemovePhoto,
          payload: {
            id: +photoId,
          },
        });
      }

      if (selectedDraggablesIds.length > 0) {
        toaster.positive("Deleted successfully", {
          autoHideDuration: 4000,
        });
      }

      dispatch({
        type: CollageDesignerActionType.SetSelectedDraggablesIds,
        payload: {
          selectedDraggablesIds: [],
        },
      });
    } catch (error) {
      toaster.negative("Something went wrong.", {
        autoHideDuration: 4000,
      });
    }
  }, [selectedDraggablesIds, nodes, dispatch]);

  const deleteHotkeyRef = useHotkeys(
    "delete, backspace",
    (event) => {
      event.preventDefault();
      deleteSelected();
    },
    [deleteSelected]
  );

  const unusedDeleteHotkeyRef = useHotkeys(
    "delete, backspace",
    (event) => {
      event.preventDefault();
      deleteSelected();
    },
    { enabled: isSplitted },
    [deleteSelected]
  );

  const selectAllHotkeyRef = useHotkeys(
    "ctrl+a, cmd+a",
    (event) => {
      event.preventDefault();
      const targetPhotos = sortedPhotos;

      if (targetPhotos.length > 0) {
        dispatch({
          type: CollageDesignerActionType.SetSelectedDraggablesIds,
          payload: {
            selectedDraggablesIds: targetPhotos.map(
              (photo) => `photo@${photo.id}`
            ),
          },
        });
      }
    },
    { enabled: !isSplitted },
    [nodes, isSplitted]
  );

  const selectAllUsedHotkeyRef = useHotkeys(
    "ctrl+a, cmd+a",
    (event) => {
      event.preventDefault();
      const targetPhotos = sortedUsedPhotos;

      if (targetPhotos.length > 0) {
        dispatch({
          type: CollageDesignerActionType.SetSelectedDraggablesIds,
          payload: {
            selectedDraggablesIds: targetPhotos.map(
              (photo) => `photo@${photo.id}`
            ),
          },
        });
      }
    },
    { enabled: isSplitted },
    [nodes, isSplitted]
  );

  const selectAllUnusedHotkeyRef = useHotkeys(
    "ctrl+a, cmd+a",
    (event) => {
      event.preventDefault();
      const targetPhotos = sortedUnusedPhotos;

      if (targetPhotos.length > 0) {
        dispatch({
          type: CollageDesignerActionType.SetSelectedDraggablesIds,
          payload: {
            selectedDraggablesIds: targetPhotos.map(
              (photo) => `photo@${photo.id}`
            ),
          },
        });
      }
    },
    { enabled: isSplitted },
    [nodes, isSplitted]
  );

  const scrollPosition = localStorage.getItem(`sP${collageId}`);

  const scrollBodyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function saveScrollPosition(event: Event) {
      localStorage.setItem(
        `sP${collageId}`,
        (event.target as HTMLDivElement).scrollTop.toString()
      );
    }

    if (scrollBodyRef.current) {
      scrollBodyRef.current.addEventListener("scroll", saveScrollPosition);

      return () =>
        scrollBodyRef.current?.removeEventListener(
          "scroll",
          saveScrollPosition
        );
    }
  }, [scrollBodyRef, isSplitted]);

  useEffect(() => {
    localStorage.setItem(`sP${collageId}`, "0");
  }, [isSplitted]);

  useEffect(() => {
    if (scrollBodyRef.current && scrollPosition) {
      scrollBodyRef.current.scrollTo({ top: parseInt(scrollPosition) });
    }
  }, [scrollBodyRef]);

  return (
    <div
      onClick={() => {
        dispatch({
          type: CollageDesignerActionType.SetSelectedDraggablesIds,
          payload: {
            selectedDraggablesIds: [],
          },
        });
      }}
      className={css({
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      })}
    >
      <Block display="flex" marginLeft="26px" marginRight="26px">
        <Button
          $style={{ flexGrow: 1 }}
          onClick={() => setIsUploadPhotosModalOpen(true)}
        >
          Upload images
        </Button>
        <StatefulPopover
          overrides={{
            Body: {
              style: {
                zIndex: 20,
                opacity: 1,
                boxShadow: "unset",
                borderRadius: "4px",
                border: "1px solid #E6EAF0",
                overflow: "hidden",
              },
            },
          }}
          content={() => (
            <Block
              display="flex"
              flexDirection="column"
              backgroundColor="white"
              position="relative"
              paddingTop="10px"
              paddingBottom="10px"
              minWidth="170px"
            >
              {SORT_OPTIONS.map((option, index) => (
                <button
                  key={`sortOption${index}`}
                  className={css({
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "10px",
                    height: "20px",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    backgroundColor: "unset",
                    border: "unset",
                    color: "#3C3C3C",
                    fontSize: "14px",
                    ":hover": {
                      backgroundColor: "#fafafa",
                      cursor: "pointer",
                    },
                  })}
                  onClick={() => setSortBy(option as typeof SORT_OPTIONS[0])}
                >
                  {sortBy === option && <CheckThick />}
                  <span
                    className={css({
                      marginLeft: sortBy === option ? "10px" : "21px",
                    })}
                  >
                    {option.label}
                  </span>
                </button>
              ))}
              <Divider />
              {SORT_DIRECTIONS.map((option, index) => (
                <button
                  key={`sortDirection${index}`}
                  className={css({
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "10px",
                    height: "20px",
                    paddingTop: "16px",
                    paddingBottom: "16px",
                    backgroundColor: "unset",
                    border: "unset",
                    color: "#3C3C3C",
                    fontSize: "14px",
                    ":hover": {
                      backgroundColor: "#fafafa",
                      cursor: "pointer",
                    },
                  })}
                  onClick={() => setSortDirection(option)}
                >
                  {sortDirection === option && <CheckThick />}
                  <span
                    className={css({
                      marginLeft: sortDirection === option ? "10px" : "21px",
                    })}
                  >
                    {option.label}
                  </span>
                </button>
              ))}
              <Divider />
              <div
                className={css({
                  marginTop: "4px",
                  marginBottom: "4px",
                })}
              >
                <span
                  className={css({
                    fontSize: "18px",
                    lineHeight: "15px",
                    marginLeft: "32px",
                    marginRight: "6px",
                    verticalAlign: "top",
                    fontWeight: 300,
                  })}
                >
                  ≥
                </span>
                <StarRating
                  numItems={5}
                  size={22}
                  value={minRating}
                  overrides={{
                    Root: {
                      style: {
                        height: "auto",
                      },
                    },
                    Item: {
                      // eslint-disable-next-line react/display-name
                      component: ({
                        $isActive,
                        $isSelected,
                        $isFocusVisible,
                      }: {
                        $isActive: boolean;
                        $isSelected: boolean;
                        $isFocusVisible: boolean;
                      }) => {
                        return (
                          <Star
                            className={css({
                              cursor: "pointer",
                              marginLeft: "1px",
                              marginRight: "1px",
                              ":hover": {
                                fill: "#A48D79",
                                opacity: 0.8,
                              },
                            })}
                            fill={
                              $isActive || $isSelected || $isFocusVisible
                                ? "#A48D79"
                                : "#CDCDCD"
                            }
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            onClick={(e: any) => {
                              const rating =
                                Array.from(
                                  e.currentTarget.parentNode.children
                                ).indexOf(e.currentTarget) + 1;

                              setMinRating(rating === minRating ? 0 : rating);
                            }}
                          />
                        );
                      },
                    },
                  }}
                />
              </div>
              <button
                className={css({
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "10px",
                  height: "20px",
                  paddingTop: "16px",
                  paddingBottom: "16px",
                  backgroundColor: "unset",
                  border: "unset",
                  color: "#3C3C3C",
                  fontSize: "14px",
                  ":hover": {
                    backgroundColor: "#fafafa",
                    cursor: "pointer",
                  },
                })}
                disabled={minRating === 0}
                onClick={() => setMinRating(0)}
              >
                <span
                  className={css({
                    marginLeft: "21px",
                  })}
                >
                  Clear filters
                </span>
              </button>
              <Divider />
              <div>
                <Checkbox
                  checked={isSplitted}
                  onChange={(e) => setIsSplitted(e.currentTarget.checked)}
                  $checkmarkStyle={{
                    width: "17px",
                    height: "17px",
                    backgroundSize: "8px 8px",
                    marginLeft: "6px",
                    marginRight: "0px",
                  }}
                  $labelStyle={{
                    fontSize: "14px",
                    color: "#3C3C3C",
                  }}
                >
                  Split Used Photos
                </Checkbox>
              </div>
            </Block>
          )}
          returnFocus
          autoFocus
        >
          <ForwardedButton
            size="compact"
            kind="secondary"
            $style={{ marginLeft: "12px" }}
          >
            <List />
          </ForwardedButton>
        </StatefulPopover>
      </Block>

      {!isSplitted ? (
        <>
          <div
            ref={
              composeRefs(
                deleteHotkeyRef,
                selectAllHotkeyRef,
                scrollBodyRef
              ) as RefObject<HTMLDivElement>
            }
            tabIndex={-1}
            className={css({
              overflow: "auto",
              paddingLeft: "26px",
              paddingRight: "26px",
              paddingBottom: "26px",
              marginTop: theme.sizing.scale1000,
              ":focus": {
                outline: "none",
              },
            })}
          >
            <Block>
              <LabelXSmall
                color="#8390AE"
                $style={{
                  textTransform: "uppercase",
                  fontWeight: 400,
                }}
              >
                Photos
                <span className={css({ color: "#AF9883", marginLeft: "8px" })}>
                  {sortedPhotos?.length}
                </span>
              </LabelXSmall>
            </Block>
            <FlexGrid
              flexGridColumnCount={2}
              marginTop="scale600"
              flexGridColumnGap="scale800"
              flexGridRowGap="scale800"
            >
              {sortedPhotos &&
                sortedPhotos.map((photo, index) => (
                  <FlexGridItem key={photo.id}>
                    <GalleryItem
                      photo={photo}
                      index={index}
                      photos={sortedPhotos}
                      isRangeSelectionKeyPressed={isRangeSelectionKeyPressed}
                      rangeSelectionStartIndex={rangeSelectionStartIndex}
                      setRangeSelectionStartIndex={setRangeSelectionStartIndex}
                    />
                  </FlexGridItem>
                ))}
            </FlexGrid>
          </div>
        </>
      ) : (
        <Block
          ref={scrollBodyRef}
          maxHeight="90vh"
          marginTop="scale1000"
          overflow="auto"
        >
          <div
            className={css({
              paddingLeft: "26px",
              paddingRight: "26px",
              marginBottom: "26px",
              ":focus": {
                outline: "none",
              },
            })}
          >
            <div
              ref={
                composeRefs(
                  unusedDeleteHotkeyRef,
                  selectAllUnusedHotkeyRef
                ) as RefObject<HTMLDivElement>
              }
              tabIndex={-1}
              className={css({
                ":focus": {
                  outline: "none",
                },
              })}
            >
              <LabelXSmall
                color="#8390AE"
                $style={{ textTransform: "uppercase", fontWeight: 400 }}
              >
                Not used
                <span className={css({ color: "#AF9883", marginLeft: "8px" })}>
                  {sortedUnusedPhotos.length}
                </span>
              </LabelXSmall>
              <FlexGrid
                marginTop="scale600"
                flexGridColumnCount={2}
                flexGridColumnGap="scale800"
                flexGridRowGap="scale800"
              >
                {sortedUnusedPhotos &&
                  sortedUnusedPhotos.map((photo, index) => (
                    <FlexGridItem key={photo.id}>
                      <GalleryItem
                        photo={photo}
                        index={index}
                        photos={sortedUnusedPhotos}
                        isRangeSelectionKeyPressed={isRangeSelectionKeyPressed}
                        rangeSelectionStartIndex={rangeSelectionStartIndex}
                        setRangeSelectionStartIndex={
                          setRangeSelectionStartIndex
                        }
                      />
                    </FlexGridItem>
                  ))}
              </FlexGrid>
            </div>
            <div
              className={css({
                marginTop: theme.sizing.scale1000,
                marginBottom: theme.sizing.scale400,
                ":focus": {
                  outline: "none",
                },
              })}
              ref={
                composeRefs(
                  deleteHotkeyRef,
                  selectAllUsedHotkeyRef
                ) as RefObject<HTMLDivElement>
              }
              tabIndex={-1}
            >
              <LabelXSmall
                color="#8390AE"
                $style={{ textTransform: "uppercase", fontWeight: 400 }}
              >
                Used
                <span className={css({ color: "#AF9883", marginLeft: "8px" })}>
                  {sortedUsedPhotos.length}
                </span>
              </LabelXSmall>
              <FlexGrid
                flexGridColumnCount={2}
                marginTop="scale600"
                flexGridColumnGap="scale800"
                flexGridRowGap="scale800"
              >
                {sortedUsedPhotos &&
                  sortedUsedPhotos.map((photo, index) => (
                    <FlexGridItem key={photo.id}>
                      <GalleryItem
                        photo={photo}
                        index={index}
                        photos={sortedUsedPhotos}
                        isRangeSelectionKeyPressed={isRangeSelectionKeyPressed}
                        rangeSelectionStartIndex={rangeSelectionStartIndex}
                        setRangeSelectionStartIndex={
                          setRangeSelectionStartIndex
                        }
                      />
                    </FlexGridItem>
                  ))}
              </FlexGrid>
            </div>
          </div>
        </Block>
      )}
      <UploadPhotosModal
        isOpen={isUploadPhotosModalOpen}
        onClose={() => setIsUploadPhotosModalOpen(false)}
      />
    </div>
  );
}

function contain(imageDimensions: Dimensions, bounds: Dimensions): Dimensions {
  let width;
  let height;

  if (imageDimensions.width > imageDimensions.height) {
    width = bounds.width;
    height = (imageDimensions.height / imageDimensions.width) * width;
  } else {
    height = bounds.height;
    width = (imageDimensions.width / imageDimensions.height) * height;
  }

  return {
    width,
    height,
  };
}
