import { Camera, Grid, Settings, TrendingUp } from "app/core/components/Icon";
import { styled, useStyletron } from "baseui";
import { Button as BaseButton, ButtonProps, KIND } from "baseui/button";
import React, { useState } from "react";
import { StyleObject } from "styletron-standard";

import CollageDetailsPanel from "../CollageDesigner/CollageDetailsPanel";
import CollageSeoSettings, {
  CollageSeoSettingsContainer,
} from "../CollageDesigner/CollageSeoSettings";
import Gallery from "../CollageDesigner/Gallery";
import { ToolBar } from "../CollageDesigner/ToolBar";

type ToolButtonProps = {
  children: React.ReactNode | string;
  icon?: React.ReactElement<SVGSVGElement>;
  active?: boolean;
  $style?: StyleObject;
} & ButtonProps;

function ToolButton({
  icon,
  children,
  active,
  $style,
  ...props
}: ToolButtonProps): React.ReactElement {
  const [css, theme] = useStyletron();

  return (
    <BaseButton
      kind={KIND.tertiary}
      startEnhancer={() =>
        icon &&
        React.cloneElement(icon, {
          stroke: active ? "#AF9883" : "white",
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any)
      }
      overrides={{
        Root: { style: $style },
        BaseButton: {
          style: {
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: active ? "white" : "#B9BCD2",
            fontSize: "0.85rem",
            fontWeight: 400,
            ...$style,
            ":hover": {
              backgroundColor: "unset",
              color: "white",
            },
            ":active": {
              backgroundColor: "unset",
              color: "white",
            },
            ":focus": {
              backgroundColor: "unset",
              color: "white",
            },
          },
        },
        StartEnhancer: {
          style: {
            marginRight: "unset",
            marginBottom: theme.sizing.scale200,
          },
        },
      }}
      {...props}
    >
      {active && (
        <span
          className={css({
            backgroundColor: "#AF9883",
            width: "2px",
            left: "0px",
            top: "14px",
            bottom: "14px",
            position: "absolute",
            borderRadius: "0px 3px 3px 0px",
          })}
        ></span>
      )}
      {children}
    </BaseButton>
  );
}

const Container = styled("aside", {
  backgroundColor: "#3F4047",
  width: "85px",
  height: "100%",
  flexShrink: 0,
  display: "flex",
  flexDirection: "column",
  paddingTop: "12px",
  paddingBottom: "12px",
  position: "relative",
});

const LeftSidebar = styled("div", ({ noPadding }: { noPadding?: boolean }) => ({
  backgroundColor: "#FCFCFD",
  filter: "drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.05))",
  height: "100%",
  width: "360px",
  flexShrink: 0,
  display: "flex",
  flexDirection: "column",
  paddingTop: "26px",
  paddingBottom: noPadding ? "0px" : "26px",
  paddingLeft: noPadding ? "0px" : "26px",
  paddingRight: noPadding ? "0px" : "26px",
  position: "absolute",
  left: "100%",
  top: "0px",
  zIndex: 2,
}));

export enum PanelType {
  Images = "images",
  Components = "components",
  SEO = "seo",
  Settings = "settings",
}

export default function ToolBox(): React.ReactElement {
  //const [css] = useStyletron();

  const [activePanel, setActivePanel] = useState<PanelType>(PanelType.Images);

  return (
    <Container>
      <ToolButton
        icon={<Camera />}
        active={activePanel === PanelType.Images}
        onClick={() => setActivePanel(PanelType.Images)}
      >
        Images
      </ToolButton>
      <ToolButton
        icon={<Grid />}
        active={activePanel === PanelType.Components}
        onClick={() => setActivePanel(PanelType.Components)}
      >
        Components
      </ToolButton>
      <ToolButton
        icon={<TrendingUp />}
        active={activePanel === PanelType.SEO}
        onClick={() => setActivePanel(PanelType.SEO)}
      >
        SEO
      </ToolButton>
      <ToolButton
        icon={<Settings />}
        active={activePanel === PanelType.Settings}
        onClick={() => setActivePanel(PanelType.Settings)}
      >
        Settings
      </ToolButton>
      {/* <ToolButton
        $style={{ marginTop: "auto" }}
        onClick={() =>
          dispatch({
            type: CollageDesignerActionType.ChangeActiveNodeIds,
            payload: {
              activeNodeId: 8,
            },
          })
        }
        icon={<MessageCircle />}
      >
        Feedback
      </ToolButton> */}

      {activePanel && (
        <LeftSidebar noPadding={activePanel === PanelType.Images}>
          {activePanel === PanelType.Images && <Gallery />}
          {activePanel === PanelType.Components && <ToolBar />}
          {activePanel === PanelType.SEO && (
            <CollageSeoSettingsContainer>
              <CollageSeoSettings />
            </CollageSeoSettingsContainer>
          )}
          {activePanel === PanelType.Settings && <CollageDetailsPanel />}
        </LeftSidebar>
      )}
    </Container>
  );
}
