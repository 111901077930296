import {
  CollageDesignerAction,
  CollageDesignerActionType,
  CollageDesignerState,
  CollageDesignerStatus,
  CollageNode,
  CollageNodeAction,
  CollageNodeActionType,
  CollageNodeIdentifier,
  CollageNodes,
  CollageNodeType,
  Dimensions,
  Photo,
} from "../types";
import { SeoScoreRuleType } from "./../types";

export const INITIAL_STATE: CollageDesignerState = {
  status: CollageDesignerStatus.Idle,
  collageId: null,
  collagePublishingToken: null,
  collagePublishedAt: null,
  collageRepublishedAt: null,
  collageUnpublishedAt: null,
  collagePublishDisabledAt: null,
  collageName: "",
  selectedDraggablesIds: [],
  activeNodeIds: [],
  activeTextNodeId: -1,
  croppingNodeId: null,
  croppingNodeHandleRef: null,
  collageBody: {
    nodes: {
      lastId: 5,
      0: {
        id: 0,
        type: CollageNodeType.Internal,
        direction: "vertical",
        children: [],
      },
    },
    gap: 10,
    backgroundColor: "rgba(255, 255, 255, 1)",
    seo: {
      title: "",
      description: "",
      tags: [],
      seoScore: {
        score: 60,
        rulesets: [
          {
            title: "Basic",
            score: 0,
            rules: [
              {
                label: {
                  success: "You're using main keyword in the title.",
                  error: "You're not using main keyword in the title.",
                },
                type: SeoScoreRuleType.MainKeywordInTitle,
                value: 0,
                min: 1,
                success: 1,
                description:
                  "Main keyword in the title is considered as good practice.",
              },
              {
                label: {
                  success: "You're using main keyword in the description.",
                  error: "You're not using main keyword in the description.",
                },
                type: SeoScoreRuleType.MainKeywordInDescription,
                value: 1,
                min: 1,
                success: 1,
                description:
                  "Main keyword in the description is considered as good practice.",
              },
              {
                label: {
                  success: "Title's length is correct.",
                  warning: "Title should be minimum 20 characters long.",
                  error: "Title is too short.",
                },
                type: SeoScoreRuleType.TitleLength,
                value: 30,
                min: 20,
                success: 40,
                description:
                  "Best practice is between 50 and 65 characters, with 60 being the sweet spot.",
              },
              {
                label: {
                  success: "Description's length is correct.",
                  warning: "Description should be minimum 60 characters long.",
                  error: "Description is too short.",
                },
                type: SeoScoreRuleType.DescriptionLength,
                value: 30,
                min: 60,
                success: 140,
                description:
                  "Common practice is to keep description between 120-150 characters long.",
              },
              {
                label: {
                  success:
                    "Main keyword was found in first 10% of the content.",
                  error:
                    "Main keyword was not found in first 10% of the content.",
                },
                type: SeoScoreRuleType.MainKeywordOnTopContent,
                value: 0,
                min: 1,
                success: 1,
                description:
                  "Main keyword should be included in the first 10% of the content, at the very beginning for the best results.",
              },
              {
                label: {
                  success: "Main keyword was found in the content.",
                  error: "Main keyword was not found in the content.",
                },
                type: SeoScoreRuleType.MainKeywordInContent,
                value: 0,
                min: 1,
                success: 1,
                description:
                  "It is important to include your main keyword in the post content.",
              },
            ],
          },
          {
            title: "Additional",
            score: 0,
            rules: [
              {
                label: {
                  success: "Main keyword has been found in the headings.",
                  error: "Main keyword has not been found in the headings.",
                },
                type: SeoScoreRuleType.MainKeywordInHeadings,
                value: 0,
                min: 1,
                success: 1,
                description: "One or more heading should include main keyword.",
              },
              {
                label: {
                  success:
                    "Main keyword has been found in your photos' alt text.",
                  error:
                    "Main keyword has not been found in your photos' alt text.",
                },
                type: SeoScoreRuleType.MainKeywordInAltTexts,
                value: 0,
                min: 1,
                success: 1,
                description:
                  "One or more photos should include main keyword in the alt text.",
              },
              {
                label: {
                  success: "Keyword density is on the correct level.",
                  error: "Keyword density is too low.",
                },
                type: SeoScoreRuleType.KeywordDensity,
                value: 0,
                min: 1,
                success: 1,
                description:
                  "There's no perfect keyword density score, but you shouldn't keep it either too low or too high.",
              },
            ],
          },
          {
            title: "Title readibility",
            score: 2,
            rules: [
              {
                label: {
                  success:
                    "Your main keyword is on the beginning of the title.",
                  error:
                    "Your main keyword is not on the beginning of the title.",
                },
                type: SeoScoreRuleType.MainKeywordInTitleStart,
                value: 1,
                min: 1,
                success: 1,
                description:
                  "The best place for main keyword is the beginning of the title.",
              },
              {
                label: {
                  success: "You're using number in the title.",
                  error: "You're not using number in the title.",
                },
                type: SeoScoreRuleType.NumberInTitle,
                value: 1,
                min: 1,
                success: 1,
                description:
                  "According to the data title with number generate 36% more clicks.",
              },
            ],
          },
        ],
      },
    },
    nodeEntryAnimation: null,
    backgroundMusicType: null,
    backgroundMusicUrl: null,
    isPhotoPreviewEnabled: false,
    photoPreviewAutoplaySpeed: null,
    isPinterestEnabled: false,
  },
  photos: [],
  textStyles: [
    {
      id: "heading1",
      label: "Heading 1",
      textAs: "header-one",
      textSize: 48,
    },
    {
      id: "heading2",
      label: "Heading 2",
      textAs: "header-two",
      textSize: 32,
    },
    {
      id: "heading3",
      label: "Heading 3",
      textAs: "header-three",
      textSize: 24,
    },
    {
      id: "heading4",
      label: "Heading 4",
      textAs: "header-four",
      textSize: 20,
    },
    {
      id: "heading5",
      label: "Heading 5",
      textAs: "header-five",
      textSize: 18,
    },
    {
      id: "heading6",
      label: "Heading 6",
      textAs: "header-six",
      textSize: 16,
    },
  ],
};

function collageNodeReducer(
  state: CollageNode,
  action: CollageNodeAction
): CollageNode {
  switch (action.type) {
    case CollageNodeActionType.Create:
      return {
        ...action.payload,
      };

    case CollageNodeActionType.Modify:
      return {
        ...state,
        ...action.payload,
      };

    case CollageNodeActionType.InsertChild: {
      const { childId, index } = action.payload;

      return {
        ...state,
        ...(state.type === CollageNodeType.Internal && {
          children: [
            ...state.children.filter((id) => id !== childId).slice(0, index),
            childId,
            ...state.children.filter((id) => id !== childId).slice(index),
          ],
        }),
      };
    }

    case CollageNodeActionType.RemoveChild:
      return {
        ...state,
        ...(state.type === CollageNodeType.Internal && {
          children: [
            ...state.children.filter((id) => id !== action.payload.childId),
          ],
        }),
      };

    default:
      return {
        ...state,
      };
  }
}

function collageNodesReducer(
  photos: Photo[],
  state: CollageNodes,
  action: CollageDesignerAction
): CollageNodes {
  switch (action.type) {
    case CollageDesignerActionType.InsertNode: {
      const { targetId, node, targetParentId, index } = action.payload;
      const targetParent = state[targetParentId];
      const target = state[targetId];
      const id = ++state.lastId;

      return {
        ...state,
        [id]: collageNodeReducer(state[id], {
          type: CollageNodeActionType.Create,
          payload: {
            ...node,
            id,
          },
        }),
        ...(state[targetId].type === CollageNodeType.Internal
          ? {
              [targetId]: collageNodeReducer(state[targetId], {
                type: CollageNodeActionType.InsertChild,
                payload: {
                  childId: id,
                  index,
                },
              }),
            }
          : {
              lastId: id + 1,
              [id + 1]: collageNodeReducer(state[id + 1], {
                type: CollageNodeActionType.Create,
                payload: {
                  ...target,
                  id: id + 1,
                },
              }),
              [targetId]: collageNodeReducer(state[targetId], {
                type: CollageNodeActionType.Create,
                payload: {
                  id: targetId,
                  type: CollageNodeType.Internal,
                  direction:
                    targetParent.type === CollageNodeType.Internal &&
                    targetParent.direction === "horizontal"
                      ? "vertical"
                      : "horizontal",
                  children: index === 0 ? [id, id + 1] : [id + 1, id],
                },
              }),
            }),
      };
    }

    case CollageDesignerActionType.MoveNode: {
      const { id, targetParentId, parentId } = action.payload;
      let { index, targetId } = action.payload;
      const newState = { ...state };

      const targetParent = newState[targetParentId];
      const target = newState[targetId];
      const currentParent = newState[parentId];
      let partialState = {
        [parentId]: collageNodeReducer(newState[parentId], {
          type: CollageNodeActionType.RemoveChild,
          payload: {
            childId: id,
          },
        }),
      };

      if (
        parentId === targetId &&
        currentParent.type === CollageNodeType.Internal &&
        currentParent.children.indexOf(id) < index
      ) {
        // Take into account the loss of the node that is being moved
        index--;
      }

      if (
        parentId !== targetId &&
        currentParent.type === CollageNodeType.Internal &&
        parentId !== 0
      ) {
        const newParent = partialState[parentId];

        if (
          newParent.type === CollageNodeType.Internal &&
          newParent.children.length === 1
        ) {
          const [childId] = newParent.children;
          partialState = {
            [parentId]: {
              ...newState[childId],
              parent: newParent.parent,
              id: parentId,
            },
          };

          delete newState[childId];

          if (targetId === childId) {
            targetId = parentId;
          }
        }
      }

      partialState = {
        ...partialState,
        ...((partialState[targetId] ?? state[targetId]).type ===
        CollageNodeType.Internal
          ? {
              [targetId]: collageNodeReducer(
                partialState[targetId] ?? state[targetId],
                {
                  type: CollageNodeActionType.InsertChild,
                  payload: {
                    childId: id,
                    index,
                  },
                }
              ),
            }
          : {
              lastId: state.lastId + 1,
              [state.lastId + 1]: collageNodeReducer(state[state.lastId + 1], {
                type: CollageNodeActionType.Create,
                payload: {
                  ...target,
                  id: state.lastId + 1,
                },
              }),
              [targetId]: collageNodeReducer(
                partialState[targetId] ?? state[targetId],
                {
                  type: CollageNodeActionType.Create,
                  payload: {
                    id: targetId,
                    type: CollageNodeType.Internal,
                    direction:
                      targetParent.type === CollageNodeType.Internal &&
                      targetParent.direction === "horizontal"
                        ? "vertical"
                        : "horizontal",
                    parent: state[targetId].parent,
                    children:
                      index === 0
                        ? [id, state.lastId + 1]
                        : [state.lastId + 1, id],
                  },
                }
              ),
            }),
      };

      const root = partialState[0] ?? newState[0];
      for (const id in partialState) {
        const node = partialState[id];

        if (
          node.type === CollageNodeType.Internal &&
          node.direction === "vertical" &&
          node.parent?.id === 0
        ) {
          delete newState[parentId];
          delete partialState[parentId];

          if (
            root.type === CollageNodeType.Internal &&
            root.children.indexOf(parentId) > -1
          ) {
            const parentNodeIndex = root.children.indexOf(parentId);

            root.children.splice(
              parentNodeIndex,
              node.children.length,
              ...node.children
            );
          }
        }
      }

      return {
        ...newState,
        ...partialState,
      };
    }

    case CollageDesignerActionType.ReplaceNode: {
      const { photoId, id, targetId } = action.payload;
      const target = state[targetId];
      const source = id ? state[id] : undefined;

      const photo = photos.find((p) => p.id === photoId);

      let shouldResetDimensions = true;
      if (
        target &&
        target.type === CollageNodeType.Photo &&
        target.dimensions &&
        target.photoDimensions &&
        target.dimensions.width / target.dimensions.height !==
          target.photoDimensions.width / target.photoDimensions.height
      ) {
        shouldResetDimensions = false;
      }

      return {
        ...state,
        [targetId]: collageNodeReducer(state[targetId], {
          type: CollageNodeActionType.Modify,
          payload: {
            photoId,
            ...(shouldResetDimensions && { dimensions: null }),
            photoDimensions: photo?.dimensions,
            ...(source &&
              source.type === CollageNodeType.Photo && {
                dimensions: source.dimensions,
                photoDimensions: source.photoDimensions,
              }),
          },
        }),
        ...(id && {
          [id]: collageNodeReducer(state[id], {
            type: CollageNodeActionType.Modify,
            payload: {
              ...(target.type === CollageNodeType.Photo && {
                photoId: target.photoId,
                photoUrl: target.photoUrl,
                dimensions: target.dimensions,
                photoDimensions: target.photoDimensions,
              }),
            },
          }),
        }),
      };
    }

    case CollageDesignerActionType.RemoveNode: {
      const { targetId } = action.payload;
      const { lastId, ...nodes } = state;

      delete nodes[targetId];

      for (const nodeId in nodes) {
        let node = nodes[nodeId];

        if (
          node.type === CollageNodeType.Internal &&
          node.children.includes(targetId)
        ) {
          node = collageNodeReducer(node, {
            type: CollageNodeActionType.RemoveChild,
            payload: {
              childId: targetId,
            },
          });

          if (
            node.type === CollageNodeType.Internal &&
            node.children.length === 1 &&
            node.id !== 0
          ) {
            const [childId] = node.children;

            node = {
              ...nodes[childId],
              id: parseInt(nodeId),
            };

            delete nodes[childId];
          }

          nodes[nodeId] = node;
        }
      }

      return {
        lastId,
        ...nodes,
      };
    }

    case CollageDesignerActionType.ApplyTransformToNode: {
      const { targetId, photoTransform } = action.payload;

      return {
        ...state,
        [targetId]: collageNodeReducer(state[targetId], {
          type: CollageNodeActionType.Modify,
          payload: {
            photoTransform,
          },
        }),
      };
    }

    case CollageDesignerActionType.SetNodeDimensions: {
      const { targetId, dimensions } = action.payload;

      return {
        ...state,
        [targetId]: collageNodeReducer(state[targetId], {
          type: CollageNodeActionType.Modify,
          payload: {
            dimensions,
          },
        }),
      };
    }

    case CollageDesignerActionType.ChangeNodeMetaData: {
      const { targetId, metaData } = action.payload;

      return {
        ...state,
        [targetId]: collageNodeReducer(state[targetId], {
          type: CollageNodeActionType.Modify,
          payload: {
            metaData,
          },
        }),
      };
    }

    case CollageDesignerActionType.ModifyNode: {
      const { targetId, node } = action.payload;

      return {
        ...state,
        [targetId]: collageNodeReducer(state[targetId], {
          type: CollageNodeActionType.Modify,
          payload: {
            ...node,
          },
        }),
      };
    }

    default:
      return {
        ...state,
      };
  }
}

function setDimensions(
  photos: Photo[],
  { lastId, ...nodes }: CollageNodes
): CollageNodes {
  const newNodes: CollageNodes = {
    lastId,
  };

  // let firstParentId = -1;

  // function findParentId(id: CollageNodeIdentifier) {
  //   const node = nodes[id];

  //   if (node.type === CollageNodeType.Internal) {
  //     if (node.children.includes(targetId)) {
  //       firstParentId = id;
  //     } else {
  //       for (const childId of node.children) {
  //         findParentId(childId);
  //       }
  //     }
  //   }
  // }

  // findParentId(0);

  function calculateDimensions(
    id: CollageNodeIdentifier
    // offset: { top: number; left: number }
  ): Dimensions {
    const node = nodes[id];

    let dimensions: Dimensions = {
      width: 0,
      height: 0,
    };

    if (node) {
      if (node.type === CollageNodeType.Internal) {
        const parameter = node.direction === "horizontal" ? "height" : "width";
        const oppositeParameter =
          node.direction === "horizontal" ? "width" : "height";

        const childrenDimensions: {
          [id: number]: Dimensions;
        } = {};

        for (const childId of node.children) {
          childrenDimensions[childId] = calculateDimensions(childId);
        }

        const max = Math.max(
          ...Object.values(childrenDimensions).map((x) => x[parameter])
        );

        for (const childId in childrenDimensions) {
          childrenDimensions[childId] = {
            ...childrenDimensions[childId],
          } as Dimensions;
          childrenDimensions[childId][oppositeParameter] =
            (max / childrenDimensions[childId][parameter]) *
            childrenDimensions[childId][oppositeParameter];
          childrenDimensions[childId][parameter] = max;
        }

        const sum = Object.values(childrenDimensions).reduce(
          (a, b) => a + b[oppositeParameter],
          0
        );

        for (const childId of node.children) {
          // const offsetParameter =
          //   node.direction === "horizontal" ? "left" : "top";
          // const oppositeOffsetParameter =
          //   node.direction === "horizontal" ? "top" : "left";

          // offset[offsetParameter] += childrenDimensions[childId][parameter] / sum;
          // offset[oppositeOffsetParameter] +=
          //   childrenDimensions[childId][oppositeParameter] / sum;

          newNodes[childId] = {
            ...newNodes[childId],
            // offset: { ...offset },
            normalizedDimensions: {
              width: 0,
              height: 0,
              [oppositeParameter]:
                childrenDimensions[childId][oppositeParameter] / sum,
              [parameter]: childrenDimensions[childId][parameter] / sum,
              ...(node.id === 0 && {
                width: 1,
                height:
                  childrenDimensions[childId].height /
                  childrenDimensions[childId].width,
                fitContent: childrenDimensions[childId].fitContent,
              }),
            },
          };
        }

        dimensions[oppositeParameter] = sum;
        dimensions[parameter] = Math.max(
          ...Object.values(childrenDimensions).map((x) => x[parameter])
        );
      } else {
        if (node.dimensions) {
          dimensions = node.dimensions;
        } else {
          if (node.type === CollageNodeType.Photo) {
            const photo = photos.find((photo) => photo.id === node.photoId);

            if (photo) {
              dimensions = photo.dimensions;
            }
          } else if (node.type === CollageNodeType.Text) {
            dimensions = {
              width: 8,
              height: 1,
            };
          } else {
            dimensions = {
              width: 1000,
              height: 1000,
            };
          }
        }
      }

      newNodes[id] = {
        ...node,
        dimensions,
      };
    }

    return dimensions;
  }

  const t0 = performance.now();
  calculateDimensions(0);
  const t1 = performance.now();
  // eslint-disable-next-line no-console
  console.log(`calculateDimensions: ${t1 - t0} ms.`);

  function calculateOffsets(
    id: CollageNodeIdentifier,
    offset: { top: number; left: number },
    parentId?: CollageNodeIdentifier,
    parentDirection?: "vertical" | "horizontal",
    index?: number,
    parentSize?: number
  ) {
    const originalOffset = { ...offset };
    const node = newNodes[id];

    if (node.type !== CollageNodeType.Internal) {
      newNodes[id] = {
        ...node,
        offset: { ...offset },
        ...(typeof parentId !== "undefined" &&
          parentDirection &&
          typeof index !== "undefined" && {
            parent: {
              id: parentId,
              direction: parentDirection,
              index,
            },
          }),
        ...(node.normalizedDimensions &&
          parentSize && {
            normalizedDimensions: {
              width: node.normalizedDimensions.width * parentSize,
              height: node.normalizedDimensions.height * parentSize,
              fitContent: node.normalizedDimensions.fitContent,
            },
          }),
      };
    } else {
      newNodes[id] = {
        ...node,
        offset: { ...offset },
        ...(typeof parentId !== "undefined" &&
          parentDirection &&
          typeof index !== "undefined" && {
            parent: {
              id: parentId,
              direction: parentDirection,
              index,
            },
          }),
        ...(node.normalizedDimensions &&
          parentSize && {
            normalizedDimensions: {
              width: node.normalizedDimensions.width * parentSize,
              height: node.normalizedDimensions.height * parentSize,
              fitContent: node.normalizedDimensions.fitContent,
            },
          }),
      };
    }

    if (node.type === CollageNodeType.Internal) {
      const oppositeParameter =
        node.direction === "horizontal" ? "width" : "height";

      const offsetParameter = node.direction === "horizontal" ? "left" : "top";

      const newNode = newNodes[id];

      for (const childId of node.children) {
        calculateOffsets(
          childId,
          offset,
          node.id,
          node.direction,
          node.children.indexOf(childId),
          newNode.normalizedDimensions
            ? newNode.normalizedDimensions[oppositeParameter]
            : undefined
        );

        offset[offsetParameter] +=
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          newNodes[childId].normalizedDimensions![oppositeParameter];
      }
      offset[offsetParameter] = originalOffset[offsetParameter];
    }
  }

  const t3 = performance.now();
  calculateOffsets(0, { left: 0, top: 0 });
  const t4 = performance.now();
  // eslint-disable-next-line no-console
  console.log(`calculateOffsets: ${t4 - t3} ms.`);

  return newNodes;
}

export default function reducer(
  state: CollageDesignerState,
  action: CollageDesignerAction
): CollageDesignerState {
  switch (action.type) {
    case CollageDesignerActionType.InsertNode:
    case CollageDesignerActionType.MoveNode:
    case CollageDesignerActionType.ReplaceNode:
    case CollageDesignerActionType.RemoveNode:
    case CollageDesignerActionType.SetNodeDimensions:
    case CollageDesignerActionType.ApplyTransformToNode:
    case CollageDesignerActionType.ChangeNodeMetaData:
    case CollageDesignerActionType.ModifyNode: {
      const nodes = setDimensions(
        state.photos,
        collageNodesReducer(state.photos, state.collageBody.nodes, action)
      );

      for (const key in nodes) {
        if (key !== "lastId") {
          const collageNode = nodes[key] as CollageNode;

          if (collageNode.type === CollageNodeType.Photo) {
            const photo = state.photos.find(
              (photo) => photo.id === collageNode.photoId
            );
            if (photo) {
              (nodes[
                key
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ] as any).photoUrl = `${photo.url}?version=${photo.updatedAt}&format=webp`;
              (nodes[
                key
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ] as any).photoThumbnailUrl = `${photo.url}?width=150&version=${photo.updatedAt}&format=webp`;
            }
          }
        }
      }

      return {
        ...state,
        collageBody: {
          ...state.collageBody,
          nodes,
        },
        activeNodeIds: state.activeNodeIds.filter((id) => id in nodes),
      };
    }

    case CollageDesignerActionType.AutoLayout: {
      const { lastId, ...nodes } = state.collageBody.nodes;

      const orderedNodes = getNodesInOrder(
        nodes[0] as CollageNode & { type: CollageNodeType.Internal },
        state.collageBody.nodes
      );

      const chunks: CollageNode[][] = [];
      let chunkIndex = -1;
      let photoIndex = 0;
      for (const [, node] of orderedNodes.entries()) {
        if (node.type !== CollageNodeType.Photo) {
          chunkIndex++;
          if (photoIndex % 3 === 2) {
            photoIndex--;
          }
        } else {
          if (photoIndex % 3 === 0 || photoIndex % 3 === 1) {
            chunkIndex++;
          }
          photoIndex++;
        }

        const chunk = chunks[chunkIndex];
        chunks[chunkIndex] = chunk ? [...chunk, node] : [node];
      }

      const newRoot = {
        ...nodes[0],
        children: [],
      } as CollageNode & {
        type: CollageNodeType.Internal;
      };

      let newNodes: CollageNodes = {
        lastId,
        0: newRoot,
      };

      for (const chunk of chunks) {
        if (chunk.length === 1) {
          newNodes[chunk[0].id] = { ...chunk[0] };
          newRoot.children.push(chunk[0].id);
          continue;
        }

        if (chunk.length > 1) {
          // if (chunk.some((node) => node.type !== CollageNodeType.Photo)) {
          //   for (const node of chunk) {
          //     newNodes[node.id] = { ...node };
          //     newRoot.children.push(node.id);
          //   }
          //   continue;
          // }

          const internalNode: CollageNode = {
            id: ++newNodes.lastId,
            type: CollageNodeType.Internal,
            children: chunk.map((node) => node.id),
            direction: "horizontal",
          };

          for (const node of chunk) {
            newNodes[node.id] = { ...node };
            newNodes[node.id].parent = {
              id: internalNode.id,
              direction: "horizontal",
              index: chunk.indexOf(node),
            };
          }

          newRoot.children.push(internalNode.id);
          newNodes[internalNode.id] = internalNode;
        }
      }

      newNodes = setDimensions(state.photos, newNodes);

      return {
        ...state,
        collageBody: {
          ...state.collageBody,
          nodes: {
            ...newNodes,
          },
        },
      };
    }

    case CollageDesignerActionType.Refresh: {
      const nodes = setDimensions(state.photos, state.collageBody.nodes);

      for (const key in nodes) {
        if (key !== "lastId") {
          const collageNode = nodes[key] as CollageNode;

          if (collageNode.type === CollageNodeType.Photo) {
            const photo = state.photos.find(
              (photo) => photo.id === collageNode.photoId
            );
            if (photo) {
              (nodes[
                key
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ] as any).photoUrl = `${photo.url}?version=${photo.updatedAt}`;
              (nodes[
                key
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ] as any).photoThumbnailUrl = `${photo.url}?width=150&version=${photo.updatedAt}`;
            }
          }
        }
      }

      return {
        ...state,
        collageBody: {
          ...state.collageBody,
          nodes,
        },
      };
    }

    case CollageDesignerActionType.ChangeGap:
    case CollageDesignerActionType.ChangeBackgroundColor:
    case CollageDesignerActionType.ChangeMetaData:
    case CollageDesignerActionType.ChangeScale:
    case CollageDesignerActionType.ChangeName:
    case CollageDesignerActionType.Change:
    case CollageDesignerActionType.ChangeStatus:
      return {
        ...state,
        ...action.payload,
      };

    case CollageDesignerActionType.SetCroppingNode:
      return {
        ...state,
        ...action.payload,
        status: action.payload.croppingNodeId
          ? CollageDesignerStatus.PhotoCropping
          : CollageDesignerStatus.Idle,
      };

    case CollageDesignerActionType.SetSelectedDraggablesIds:
      return {
        ...state,
        ...action.payload,
        activeNodeIds: [],
      };

    case CollageDesignerActionType.ChangeActiveNodeIds:
      return {
        ...state,
        ...action.payload,
        selectedDraggablesIds: [],
      };

    case CollageDesignerActionType.ChangeActiveTextNodeId:
      return {
        ...state,
        ...action.payload,
        selectedDraggablesIds: [],
      };

    case CollageDesignerActionType.ChangeCollageBody:
      return {
        ...state,
        collageBody: {
          ...state.collageBody,
          ...action.payload,
        },
      };

    case CollageDesignerActionType.AddPhoto:
      return {
        ...state,
        photos: [
          ...state.photos.filter(
            (photo) => photo.id !== action.payload.photo.id
          ),
          action.payload.photo,
        ],
      };

    case CollageDesignerActionType.RemovePhoto:
      return {
        ...state,
        photos: [
          ...state.photos.filter((photo) => photo.id !== action.payload.id),
        ],
      };

    default:
      return {
        ...state,
      };
  }
}

function getNodesInOrder(
  node: CollageNode & { type: CollageNodeType.Internal },
  allNodes: CollageNodes
): CollageNode[] {
  const nodes: CollageNode[] = [];

  for (const childId of node.children) {
    const child = allNodes[childId];

    if (child) {
      if (child.type === CollageNodeType.Internal) {
        nodes.push(...getNodesInOrder(child, allNodes));
      } else {
        nodes.push(child);
      }
    }
  }

  return nodes;
}
