import { animated, useSpring } from "@react-spring/web";
import composeRefs from "@seznam/compose-react-refs";
import { useDrag, useHover } from "@use-gesture/react";
import { Trash } from "app/core/components/Icon";
import IconButton from "app/core/components/IconButton";
import { styled, useStyletron } from "baseui";
import { RefObject, useMemo, useRef, useState } from "react";
import { forwardRef, memo } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { round } from "utils/misc";

import {
  CollageDesignerActionType,
  CollageNode,
  CollageNodeType,
} from "../../CollageDesigner/types";
import { CommonNodeRendererProps } from "../types";

const Resizer = styled(
  "div",
  ({
    axis,
    modifier,
    shift,
  }: {
    axis: "x" | "y";
    modifier: number;
    shift: number;
  }) => ({
    position: "absolute",
    touchAction: "none",
    ...(axis === "x" && {
      width: "7px",
      height: "80px",
      top: "50%",
      [modifier === -1 ? "left" : "right"]: `${10 + shift}px`,
      transform: "translateY(-50%)",
      cursor: "ew-resize",
    }),
    ...(axis === "y" && {
      width: "80px",
      height: "7px",
      left: "50%",
      [modifier === -1 ? "top" : "bottom"]: `${10 + shift}px`,
      transform: "translateX(-50%)",
      cursor: "ns-resize",
    }),
    borderRadius: "10px",
    backgroundColor: "#af9883",
  })
);

const Indicator = styled("div", () => ({
  position: "absolute",
  display: "block",
  left: 0,
  top: 0,
  width: `100%`,
  height: `100%`,
  zIndex: 2,
  borderWidth: "2px",
  borderStyle: "dashed",
  pointerEvents: "none",
}));

const NodeRendererWrapperRoot = styled("div", {
  position: "absolute",
  touchAction: "none",
  outline: "none",
});

const OverflowHidder = styled("div", {
  position: "absolute",
  overflow: "hidden",
});

type NodeRendererWrapperProps = Pick<
  CollageNode,
  "id" | "normalizedDimensions" | "offset" | "type"
> &
  CommonNodeRendererProps & { children?: React.ReactNode } & {
    forwardedRef: React.ForwardedRef<HTMLDivElement>;
    includeDragButton?: boolean;
  };

function NodeRendererWrapper({
  id,
  type,
  offset,
  normalizedDimensions,
  collageWidth,
  collageGap,
  children,
  dispatch,
  forwardedRef,
  rootNode,
  actions,
  showResizers = true,
  showActions = true,
  activeNodeIds,
}: NodeRendererWrapperProps): React.ReactElement {
  if (!offset || !normalizedDimensions) {
    throw new Error("Node without offset and dimensions cannot be rendered.");
  }

  const [css, theme] = useStyletron();
  const [isMouseOver, setIsMouseOver] = useState(false);

  const isActive = useMemo(() => activeNodeIds.includes(id), [activeNodeIds]);

  const rootDivRef = useRef<HTMLDivElement>(null);

  const absoluteDimensions = {
    width: normalizedDimensions.width * collageWidth,
    height: normalizedDimensions.height * collageWidth,
  };

  const bindResizerDraggingListeners = useDrag(
    ({ args: [axis, modifier], delta: [dx, dy] }) => {
      const property = axis === "x" ? "width" : "height";
      const delta = axis === "x" ? dx : dy;
      const value = absoluteDimensions[property] + delta * modifier;

      if (value > 60 && (property === "height" || property === "width")) {
        dispatch({
          type: CollageDesignerActionType.SetNodeDimensions,
          payload: {
            targetId: id as number,
            dimensions: {
              ...absoluteDimensions,
              [property]: value,
            },
          },
        });
      }
    }
  );

  useHover(
    ({ active }) => {
      api.start({
        to: {
          opacity: active ? 1 : 0,
        },
      });
      setIsMouseOver(active);
    },
    {
      target: rootDivRef,
    }
  );

  const removeNodeHotkeyRef = useHotkeys("backspace", (event) => {
    event.preventDefault();
    dispatch({
      type: CollageDesignerActionType.RemoveNode,
      payload: {
        targetId: id as number,
      },
    });
  });

  const [{ opacity }, api] = useSpring(() => ({
    from: {
      opacity: 0,
    },
    config: {
      friction: 0,
      clamp: true,
    },
  }));

  const applyGap = type === CollageNodeType.Text ? false : true;

  const bottom =
    rootNode && rootNode.dimensions
      ? rootNode.dimensions.height / rootNode.dimensions.width
      : 0;

  let shiftLeft = 0;
  let shiftRight = 0;
  let shiftTop = 0;
  let shiftBottom = 0;

  if (offset.left > 0) {
    shiftLeft = collageGap / 2;
  }

  if (round(offset.left + normalizedDimensions.width, 2) < 1) {
    shiftRight = collageGap / 2;
  }

  if (offset.top > 0) {
    shiftTop = collageGap / 2;
  }

  if (Math.abs(offset.top + normalizedDimensions.height - bottom) > 0.01) {
    shiftBottom = collageGap / 2;
  }

  if (!applyGap) {
    shiftLeft = 0;
    shiftRight = 0;
    shiftTop = 0;
    shiftBottom = 0;
  }

  const width = applyGap
    ? normalizedDimensions.width * collageWidth - shiftLeft - shiftRight
    : normalizedDimensions.width * collageWidth;

  const height = applyGap
    ? normalizedDimensions.height * collageWidth - shiftTop - shiftBottom
    : normalizedDimensions.height * collageWidth;

  return (
    <NodeRendererWrapperRoot
      style={{
        transform: `translate(${offset.left * collageWidth}px, ${
          offset.top * collageWidth
        }px)`,
        width: `${normalizedDimensions.width * collageWidth}px`,
        height: `${normalizedDimensions.height * collageWidth}px`,
        ...(isMouseOver && { zIndex: 10 }),
      }}
      onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();

        dispatch({
          type: CollageDesignerActionType.ChangeActiveNodeIds,
          payload: {
            activeNodeIds: [id as number],
          },
        });

        if (type !== CollageNodeType.Text) {
          dispatch({
            type: CollageDesignerActionType.ChangeActiveTextNodeId,
            payload: {
              activeTextNodeId: -1,
            },
          });
        }
      }}
      ref={
        composeRefs(
          rootDivRef,
          removeNodeHotkeyRef
        ) as RefObject<HTMLDivElement>
      }
      tabIndex={-1}
    >
      <OverflowHidder
        style={{
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
          ...(applyGap && {
            ...(offset.top > 0 && { top: `${collageGap / 2}px` }),
            ...(offset.left > 0 && { left: `${collageGap / 2}px` }),
            ...(round(offset.left + normalizedDimensions.width, 2) > 1 && {
              right: `${collageGap / 2}px`,
            }),
            ...(Math.abs(offset.top + normalizedDimensions.height - bottom) <
              0.01 && { bottom: `${collageGap / 2}px` }),
          }),
          width: `${width}px`,
          height: `${height}px`,
        }}
        ref={forwardedRef}
      >
        {children}

        <Indicator
          $style={{
            borderColor: isActive
              ? theme.colors.accent
              : isMouseOver
              ? theme.colors.primary
              : "transparent",
            borderStyle: isMouseOver ? "dashed" : "solid",
          }}
        />
      </OverflowHidder>

      <animated.div
        style={{
          opacity,
        }}
      >
        {showResizers && (
          <>
            {normalizedDimensions.width < 1 && (
              <>
                <Resizer
                  {...bindResizerDraggingListeners("x", -1)}
                  axis="x"
                  modifier={-1}
                  shift={shiftLeft}
                />
                <Resizer
                  {...bindResizerDraggingListeners("x", 1)}
                  axis="x"
                  modifier={1}
                  shift={shiftRight}
                />
              </>
            )}
            {normalizedDimensions.width === 1 && (
              <>
                <Resizer
                  {...bindResizerDraggingListeners("y", -1)}
                  axis="y"
                  modifier={-1}
                  shift={shiftTop}
                />
                <Resizer
                  {...bindResizerDraggingListeners("y", 1)}
                  axis="y"
                  modifier={1}
                  shift={shiftBottom}
                />
              </>
            )}
          </>
        )}

        {showActions && (
          <div
            className={css({
              position: "absolute",
              right: `${shiftRight + 16}px`,
              top: `${shiftTop - 16}px`,
              zIndex: 10,
              display: "flex",
              alignItems: "center",
              gap: "6px",
              flexDirection: "row-reverse",
            })}
          >
            <IconButton
              onClick={() => {
                dispatch({
                  type: CollageDesignerActionType.RemoveNode,
                  payload: {
                    targetId: id as number,
                  },
                });
              }}
            >
              <Trash />
            </IconButton>
            {actions &&
              actions.map(({ icon, onClick }, index) => (
                <IconButton key={index} onClick={onClick}>
                  {icon}
                </IconButton>
              ))}

            {/* {includeDragButton && (
            <IconButton
              {...bindDraggingListeners()}
              $style={{ marginRight: "12px" }}
            >
              <Move />
            </IconButton>
          )} */}
          </div>
        )}
      </animated.div>
    </NodeRendererWrapperRoot>
  );
}

const MemoizedNodeRendererWrapper = memo(
  forwardRef<HTMLDivElement, Omit<NodeRendererWrapperProps, "forwardedRef">>(
    (props, ref) => <NodeRendererWrapper forwardedRef={ref} {...props} />
  )
);

export default MemoizedNodeRendererWrapper;
