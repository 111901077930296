import { useStyletron } from "baseui";

function Divider(): React.ReactElement {
  const [css] = useStyletron();

  return (
    <hr
      className={css({
        height: "1px",
        width: "100%",
        backgroundColor: "#E6E6E6",
        outline: "unset",
        border: "unset",
      })}
    />
  );
}

export default Divider;
