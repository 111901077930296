import { useAuth } from "app/auth/context";
import { ReactElement, useEffect } from "react";
import { Redirect } from "react-router-dom";

export default function Logout(): ReactElement {
  const { logout } = useAuth();

  useEffect(() => logout(), []);

  return (
    <Redirect
      to={{
        pathname: `/login`,
      }}
    />
  );
}
