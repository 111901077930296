import Button from "app/core/components/Button";
import Checkbox from "app/core/components/Checkbox";
import {
  AlignCenter,
  AlignJustify,
  AlignLeft,
  AlignRight,
  Camera,
  Crop,
  Duplicate,
  Info,
  LetterSpacing,
  LineHeight,
  Mirror,
  PaddingBottom,
  PaddingLeft,
  PaddingRight,
  PaddingTop,
  Repeat,
  Rescale,
  Revert,
  Trash,
  VerticalAlignBottom,
  VerticalAlignMiddle,
  VerticalAlignTop,
} from "app/core/components/Icon";
import IconButton from "app/core/components/IconButton";
import InfoBox from "app/core/components/InfoBox";
import Input from "app/core/components/Input";
import Radio from "app/core/components/Radio";
import Select, { IconSelect } from "app/core/components/Select";
import Slider from "app/core/components/Slider";
import { useFormWarnings } from "app/core/hooks/useFormWarnings";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { StatefulPopover } from "baseui/popover";
import { RadioGroup } from "baseui/radio";
import { StatefulTooltip } from "baseui/tooltip";
import { CollageDesignerContext } from "contexts/CollageDesignerContext";
import { convertFromRaw, convertToRaw, EditorState, RichUtils } from "draft-js";
import React, { ChangeEvent, useContext, useMemo, useState } from "react";
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from "react-hook-form";

import {
  CollageDesignerActionType,
  CollageDesignerStatus,
  CollageNodeType,
  PhotoTransform,
  TextStyle,
  TextType,
} from "./types";
import ColorPicker from "./ui/ColorPicker";
import FontPicker from "./ui/FontPicker";
import FormControl from "./ui/FormControl";
import Panel from "./ui/Panel";

type NodeSettingsContainerProps = {
  children?: React.ReactNode;
};

export function NodeSettingsContainer({
  children,
}: NodeSettingsContainerProps): React.ReactElement {
  const methods = useForm({
    mode: "onChange",
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
}

const YOUTUBE_REGEX = /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/i;
const VIMEO_REGEX = /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_-]+)?/i;

const STANDARD_ASPECT_RATIOS = [
  {
    id: (1 / 1).toFixed(2),
    label: "1:1",
  },
  {
    id: (5 / 4).toFixed(2),
    label: "5:4",
  },
  {
    id: (4 / 3).toFixed(2),
    label: "4:3",
  },
  {
    id: (3 / 2).toFixed(2),
    label: "3:2",
  },
  {
    id: (16 / 9).toFixed(2),
    label: "16:9",
  },
  {
    id: (3 / 1).toFixed(2),
    label: "3:1",
  },
  {
    id: (4 / 5).toFixed(2),
    label: "4:5",
  },
  {
    id: (3 / 4).toFixed(2),
    label: "3:4",
  },
  {
    id: (2 / 3).toFixed(2),
    label: "2:3",
  },
  {
    id: (9 / 16).toFixed(2),
    label: "9:16",
  },
  {
    id: (1 / 3).toFixed(2),
    label: "1:3",
  },
];

const CUSTOM_ASPECT_RATIO = {
  id: "custom",
  label: "Custom",
};

const ALIGN_STYLES = [
  {
    id: "left",
    label: "Left",
    icon: AlignLeft,
  },
  {
    id: "center",
    label: "Center",
    icon: AlignCenter,
  },
  {
    id: "right",
    label: "Right",
    icon: AlignRight,
  },
  {
    id: "justify",
    label: "Justify",
    icon: AlignJustify,
  },
];

const VERTICAL_ALIGN_STYLES = [
  {
    id: "flex-start",
    label: "Top",
    icon: VerticalAlignTop,
  },
  {
    id: "center",
    label: "Middle",
    icon: VerticalAlignMiddle,
  },
  {
    id: "flex-end",
    label: "Bottom",
    icon: VerticalAlignBottom,
  },
];

// const SIZE_STYLES = [
//   { id: "10", label: "10" },
//   { id: "12", label: "12" },
//   { id: "14", label: "14" },
//   { id: "16", label: "16" },
//   { id: "18", label: "18" },
//   { id: "20", label: "20" },
//   { id: "24", label: "24" },
//   { id: "28", label: "28" },
//   { id: "36", label: "36" },
//   { id: "48", label: "48" },
//   { id: "72", label: "72" },
// ];

export const VERTICAL_RESCALE_OPTIONS: number[] = [3 / 2, 5 / 4, 1];
export const HORIZONTAL_RESCALE_OPTIONS: number[] = [2 / 3, 4 / 5, 1];

export const RECYCLE_OPTIONS: PhotoTransform[] = [
  {
    height: NaN,
    width: 0.8,
    y: -1,
    x: 0.1,
  },
  {
    height: 0.6,
    width: NaN,
    y: 0.2,
    x: 0.1,
  },
  {
    height: 0.6,
    width: NaN,
    y: 0.2,
    x: 0.9,
  },
];

export function getRecycleOption(index: number): PhotoTransform {
  const randomOption = RECYCLE_OPTIONS[index];

  if (isNaN(randomOption.height) && isNaN(randomOption.width)) {
    return {
      height: -1,
      width: -1,
      y: -1,
      x: -1,
    };
  } else if (isNaN(randomOption.width)) {
    return {
      height: -1,
      width: randomOption.height,
      y: randomOption.y,
      x:
        randomOption.x === 0.5
          ? (1 - randomOption.height) / 2
          : randomOption.x < 0.5
          ? randomOption.x
          : randomOption.x - randomOption.height,
    };
  } else {
    return {
      height: -1,
      width: randomOption.width,
      y:
        randomOption.y === 0.5
          ? (1 - randomOption.width) / 2
          : randomOption.y < 0.5
          ? randomOption.y
          : randomOption.y - randomOption.width,
      x: randomOption.x,
    };
  }
}

export default function ActiveNodePanel(): React.ReactElement {
  const [initialValidated] = useState<boolean>(false);
  const { control, register, handleSubmit, errors } = useFormContext();

  const [css, theme] = useStyletron();

  const onSubmit = () => null;

  const {
    state: {
      activeNodeIds,
      collageBody: { seo, nodes },
      textStyles,
      photos,
      status,
      croppingNodeHandleRef,
    },
    dispatch,
  } = useContext(CollageDesignerContext);

  const activeNodes = useMemo(() => activeNodeIds.map((id) => nodes[id]), [
    activeNodeIds,
    nodes,
  ]);

  const activeNode = useMemo(
    () => (activeNodes.length === 1 ? activeNodes[0] : null),
    [activeNodes]
  );

  const aspectRatio = useMemo(
    () =>
      activeNode &&
      activeNode.dimensions &&
      activeNode.dimensions.width / activeNode.dimensions.height,
    [activeNode]
  );

  const isVideoUrlValid = useMemo(() => {
    if (activeNode?.type === CollageNodeType.Video) {
      if (activeNode.videoType === "youtube") {
        return YOUTUBE_REGEX.test(activeNode.videoUrl || "");
      } else {
        return VIMEO_REGEX.test(activeNode.videoUrl || "");
      }
    } else {
      return false;
    }
  }, [activeNode]);

  const value = useMemo(() => {
    const standardAspectRatio = STANDARD_ASPECT_RATIOS.find(
      (standardAspectRatio) =>
        standardAspectRatio.id === aspectRatio?.toFixed(2)
    );
    return standardAspectRatio ? standardAspectRatio : CUSTOM_ASPECT_RATIO;
  }, [aspectRatio]);

  const photoRatio =
    activeNode &&
    activeNode.type === CollageNodeType.Photo &&
    activeNode.photoDimensions &&
    activeNode.photoDimensions.width / activeNode.photoDimensions.height;

  const { warnings } = useFormWarnings([
    {
      name: "title",
      rules: {
        minLength: {
          value: 20,
          message: "Tytuł powinien być dłuższy niż 25 znaków",
        },
        maxLength: {
          value: 60,
          message: "Tytuł powinien być krótszy niż 60 znaków",
        },
      },
    },
  ]);

  const photo = useMemo(
    () =>
      activeNode?.type === CollageNodeType.Photo &&
      photos &&
      photos.find((photo) => photo.id === activeNode.photoId),
    [activeNode, photos]
  );

  const currentRecycleOptionIndex = useMemo(
    () =>
      activeNode?.type === CollageNodeType.Photo
        ? activeNode.recycleOptionIndex ?? -1
        : -1,
    [activeNode]
  );

  const currentRescaleOptionIndex = useMemo(
    () =>
      activeNode?.type === CollageNodeType.Photo
        ? activeNode.rescaleOptionIndex ?? -1
        : -1,
    [activeNode]
  );

  return (
    <>
      {activeNode?.type === CollageNodeType.Photo && (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Panel
              title="Selected container"
              icon={<Camera height="16px" stroke="#AF9883" />}
            >
              <FormControl label="Selected image" caption={photo && photo.name}>
                <Block
                  display="flex"
                  alignItems="center"
                  width="100%"
                  backgroundColor="#f3f4f6"
                  justifyContent="center"
                >
                  <img src={activeNode.photoThumbnailUrl} width={150} />
                </Block>
              </FormControl>
              <Block
                marginTop="scale400"
                marginBottom="scale800"
                display="flex"
                gridGap="12px"
              >
                <IconButton
                  onClick={() => {
                    if (status === CollageDesignerStatus.Idle) {
                      dispatch({
                        type: CollageDesignerActionType.SetCroppingNode,
                        payload: {
                          croppingNodeId: activeNode.id as number,
                        },
                      });
                    } else {
                      croppingNodeHandleRef?.current?.transform();
                    }
                  }}
                >
                  <Crop />
                </IconButton>

                <IconButton
                  disabled={status !== CollageDesignerStatus.Idle}
                  onClick={() => {
                    dispatch({
                      type: CollageDesignerActionType.ModifyNode,
                      payload: {
                        targetId: activeNode.id as number,
                        node: {
                          isMirrored: !activeNode.isMirrored,
                        },
                      },
                    });
                  }}
                >
                  <Mirror />
                </IconButton>
                <IconButton
                  disabled={status !== CollageDesignerStatus.Idle}
                  onClick={() => {
                    const RESCALE_OPTIONS =
                      photoRatio && photoRatio < 1
                        ? VERTICAL_RESCALE_OPTIONS
                        : HORIZONTAL_RESCALE_OPTIONS;

                    const nextRescaleOptionIndex =
                      currentRescaleOptionIndex < RESCALE_OPTIONS.length
                        ? currentRescaleOptionIndex + 1
                        : 0;
                    dispatch({
                      type: CollageDesignerActionType.ModifyNode,
                      payload: {
                        targetId: activeNode.id as number,
                        node: {
                          rescaleOptionIndex: nextRescaleOptionIndex,
                          ...(nextRescaleOptionIndex === RESCALE_OPTIONS.length
                            ? {
                                ...(photoRatio && {
                                  dimensions: {
                                    width: 1000,
                                    height: 1000 / photoRatio,
                                  },
                                }),
                                photoTransform: undefined,
                              }
                            : {
                                dimensions: {
                                  width: 1000,
                                  height:
                                    1000 /
                                    RESCALE_OPTIONS[nextRescaleOptionIndex],
                                },
                                photoTransform: {
                                  height: -1,
                                  width: -1,
                                  y: -1,
                                  x: -1,
                                },
                              }),
                        },
                      },
                    });
                  }}
                >
                  <Rescale />
                </IconButton>
                <StatefulTooltip
                  content={() => (
                    <Block padding={"20px"}>
                      Hello, there! 👋
                      <Input placeholder="Focusable Element" />
                    </Block>
                  )}
                  showArrow
                  popoverMargin={8}
                  focusLock
                  returnFocus
                  renderAll
                >
                  <IconButton
                    disabled={status !== CollageDesignerStatus.Idle}
                    onClick={() => {
                      const nextRecycleOptionIndex =
                        currentRecycleOptionIndex < RECYCLE_OPTIONS.length
                          ? currentRecycleOptionIndex + 1
                          : 0;
                      dispatch({
                        type: CollageDesignerActionType.ModifyNode,
                        payload: {
                          targetId: activeNode.id as number,
                          node: {
                            recycleOptionIndex: nextRecycleOptionIndex,
                            ...(nextRecycleOptionIndex ===
                            RECYCLE_OPTIONS.length
                              ? { photoTransform: undefined }
                              : {
                                  photoTransform: {
                                    ...getRecycleOption(nextRecycleOptionIndex),
                                  },
                                }),
                          },
                        },
                      });
                    }}
                  >
                    <Repeat />
                  </IconButton>
                </StatefulTooltip>

                <IconButton
                  disabled={status !== CollageDesignerStatus.Idle}
                  onClick={() => {
                    dispatch({
                      type: CollageDesignerActionType.RemoveNode,
                      payload: {
                        targetId: activeNode.id as number,
                      },
                    });
                  }}
                >
                  <Trash />
                </IconButton>
              </Block>
              <FormControl label="Aspect ratio">
                <Controller
                  name="aspect_ratio"
                  defaultValue=""
                  control={control}
                  register={register}
                  render={({ onChange, onBlur }) => (
                    <Block display="flex" gridGap="12px">
                      <Select
                        options={[
                          ...STANDARD_ASPECT_RATIOS,
                          ...(value === CUSTOM_ASPECT_RATIO
                            ? [CUSTOM_ASPECT_RATIO]
                            : []),
                        ]}
                        disabled={status !== CollageDesignerStatus.Idle}
                        value={[value]}
                        clearable={false}
                        searchable={false}
                        onInputChange={onChange}
                        onBlur={onBlur}
                        onChange={(params) =>
                          params.value &&
                          params.value[0] &&
                          params.value[0].id &&
                          params.value[0].id !== "custom" &&
                          dispatch({
                            type: CollageDesignerActionType.SetNodeDimensions,
                            payload: {
                              targetId: activeNode.id as number,
                              dimensions: {
                                width: 1000,
                                height:
                                  1000 /
                                  parseFloat(params.value[0].id as string),
                              },
                            },
                          })
                        }
                      />
                      {photoRatio && aspectRatio && (
                        <Button
                          size="mini"
                          kind="tertiary"
                          onClick={() => {
                            dispatch({
                              type: CollageDesignerActionType.ModifyNode,
                              payload: {
                                targetId: activeNode.id as number,
                                node: {
                                  recycleOptionIndex: undefined,
                                  rescaleOptionIndex: undefined,
                                  dimensions: {
                                    width: 1000,
                                    height: 1000 / photoRatio,
                                  },
                                  photoTransform: undefined,
                                },
                              },
                            });
                          }}
                          $style={{
                            paddingLeft: "12px",
                            paddingRight: "12px",
                          }}
                          disabled={
                            status !== CollageDesignerStatus.Idle ||
                            (photoRatio.toFixed(2) === aspectRatio.toFixed(2) &&
                              !activeNode.photoTransform)
                          }
                        >
                          Reset
                        </Button>
                      )}
                    </Block>
                  )}
                />
              </FormControl>
            </Panel>
            <Panel>
              <FormControl label="ALT Text">
                <Controller
                  name="title"
                  defaultValue=""
                  control={control}
                  register={register}
                  // rules={{
                  //   required: {
                  //     value: true,
                  //     message: "Tytuł nie może być pusty",
                  //   },
                  // }}
                  render={({ onChange, onBlur }) => (
                    <Input
                      value={
                        activeNode?.type === CollageNodeType.Photo
                          ? activeNode?.metaData
                            ? activeNode?.metaData?.title
                            : ""
                          : ""
                      }
                      placeholder={
                        activeNode?.type === CollageNodeType.Photo
                          ? activeNode?.metaData
                            ? activeNode?.metaData?.title
                            : seo?.tags?.map((o) => o.id).join(", ")
                          : seo?.tags?.map((o) => o.id).join(", ")
                      }
                      onBlur={onBlur}
                      onChange={(event: React.FormEvent<HTMLInputElement>) => {
                        onChange(event);
                        dispatch({
                          type: CollageDesignerActionType.ChangeNodeMetaData,
                          payload: {
                            targetId: activeNode.id as number,
                            metaData: {
                              ...(activeNode?.type === CollageNodeType.Photo &&
                                activeNode?.metaData),
                              title: event.currentTarget.value,
                            },
                          },
                        });
                      }}
                      positive={
                        initialValidated && !errors?.title && !warnings?.title
                      }
                    />
                  )}
                />

                <InfoBox>
                  If you leave it blank, we will automatically refill alt text
                  with your keywords.
                </InfoBox>
              </FormControl>
            </Panel>
          </form>
        </>
      )}

      {activeNode?.type === CollageNodeType.Text && (
        <>
          <Panel
            title={
              activeNode.textType === TextType.Heading
                ? "Heading settings"
                : "Text settings"
            }
            icon={<Camera height="16px" stroke="#AF9883" />}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              {activeNode.textType === TextType.Heading && (
                <>
                  <FormControl label="Styles">
                    <Controller
                      name="style"
                      defaultValue=""
                      control={control}
                      register={register}
                      render={({ onChange, onBlur }) => (
                        <Select
                          options={[...textStyles]}
                          value={
                            activeNode.textStyle
                              ? [
                                  {
                                    label: activeNode.textStyle.label,
                                  },
                                ]
                              : undefined
                          }
                          clearable={false}
                          searchable={false}
                          onInputChange={onChange}
                          onBlur={onBlur}
                          placeholder="Style"
                          getOptionLabel={({ option }) => (
                            <span
                              className={css({
                                fontFamily:
                                  option && (option.textFontFamily as string),
                                fontSize:
                                  option &&
                                  `${(option.textSize * 0.7) as number}pt`,
                                lineHeight:
                                  option &&
                                  `${(option.textSize * 0.9) as number}pt`,
                              })}
                            >
                              {option && option.label}
                            </span>
                          )}
                          onChange={(params) =>
                            params.value &&
                            params.value[0] &&
                            params.value[0].id &&
                            dispatch({
                              type: CollageDesignerActionType.ModifyNode,
                              payload: {
                                targetId: activeNode.id as number,
                                node: {
                                  textStyle: params.value[0] as TextStyle,
                                  ...(params.value[0].textAs &&
                                    activeNode.textContentState && {
                                      textContentState: convertToRaw(
                                        RichUtils.toggleBlockType(
                                          EditorState.createWithContent(
                                            convertFromRaw(
                                              activeNode.textContentState
                                            )
                                          ),
                                          params.value[0].textAs
                                        ).getCurrentContent()
                                      ),
                                    }),
                                  ...(params.value[0].textSize && {
                                    textSize: params.value[0].textSize,
                                  }),
                                },
                              },
                            })
                          }
                        />
                      )}
                    />
                  </FormControl>
                </>
              )}
              <FormControl>
                <Controller
                  name="font"
                  defaultValue="a"
                  control={control}
                  register={register}
                  render={() => (
                    <FontPicker
                      apiKey="AIzaSyBQXCeqxw9Vh7a7T5n2SlIX9MaljLxt9U0"
                      activeFontFamily={activeNode.textFontFamily}
                      disabled={!!activeNode.inheritUserStylesheets}
                      getOptionLabel={({ option }) => (
                        <span
                          className={css({
                            fontFamily: option && (option.label as string),
                          })}
                        >
                          {option && option.label}
                        </span>
                      )}
                      getValueLabel={({ option }) => (
                        <span
                          className={css({
                            fontFamily: option && (option.label as string),
                          })}
                        >
                          {option && option.label}
                        </span>
                      )}
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      onChange={(params: any) => {
                        if (
                          params.value &&
                          params.value[0] &&
                          params.value[0].id
                        )
                          dispatch({
                            type: CollageDesignerActionType.ModifyNode,
                            payload: {
                              targetId: activeNode.id as number,
                              node: {
                                textFontFamily: params.value[0]
                                  .family as string,
                                textVariants: params.value[0].variants,
                                textFontWeight: undefined,
                              },
                            },
                          });
                        else
                          dispatch({
                            type: CollageDesignerActionType.ModifyNode,
                            payload: {
                              targetId: activeNode.id as number,
                              node: {
                                textFontFamily: "",
                                textVariants: undefined,
                                textFontWeight: undefined,
                              },
                            },
                          });
                      }}
                    />
                  )}
                />

                <InfoBox>
                  If you choose a specific font, we overwrite your
                  website&apos;s font. If not, the styles will be inherited.
                </InfoBox>
              </FormControl>
              <FormControl>
                <Block display="flex" gridGap="12px">
                  <Controller
                    name="weight"
                    defaultValue=""
                    control={control}
                    register={register}
                    render={({ onBlur }) => (
                      <Select
                        options={activeNode.textVariants
                          ?.filter((weight) => !weight.includes("italic"))
                          ?.map((weight) => ({
                            id: weight === "regular" ? 400 : weight,
                            label: weight === "regular" ? 400 : weight,
                          }))}
                        value={
                          activeNode.textFontWeight
                            ? [{ id: activeNode.textFontWeight }]
                            : [
                                {
                                  id: 400,
                                  label: "400",
                                },
                              ]
                        }
                        onBlur={onBlur}
                        placeholder="Font weight"
                        getOptionLabel={({ option }) => (
                          <>{option && option.label}</>
                        )}
                        getValueLabel={({ option }) => (
                          <>{option && option.label}</>
                        )}
                        disabled={!!activeNode.inheritUserStylesheets}
                        onChange={(params) =>
                          params.value &&
                          params.value[0] &&
                          params.value[0].id &&
                          dispatch({
                            type: CollageDesignerActionType.ModifyNode,
                            payload: {
                              targetId: activeNode.id as number,
                              node: {
                                textFontWeight: params.value[0].id as string,
                              },
                            },
                          })
                        }
                      />
                    )}
                  />
                  <Controller
                    name="size"
                    defaultValue=""
                    control={control}
                    register={register}
                    render={() => {
                      return (
                        <Input
                          value={activeNode.textSize || 16}
                          placeholder="Font size"
                          type="number"
                          onChange={(event) => {
                            if (event.currentTarget.value)
                              dispatch({
                                type: CollageDesignerActionType.ModifyNode,
                                payload: {
                                  targetId: activeNode.id as number,
                                  node: {
                                    textSize: parseInt(
                                      event.currentTarget.value
                                    ),
                                  },
                                },
                              });
                          }}
                          disabled={!!activeNode.inheritUserStylesheets}
                          endEnhancer="pt"
                          $style={{ width: "90px", flexShrink: 0 }}
                        />
                      );
                    }}
                  />
                </Block>
              </FormControl>
              <FormControl>
                <Block display="flex" gridGap="12px">
                  <Controller
                    name="lineHeight"
                    defaultValue=""
                    control={control}
                    register={register}
                    render={({ onChange, onBlur }) => (
                      <Block width="180px">
                        <Input
                          value={
                            activeNode?.textLineHeight
                              ? activeNode.textLineHeight
                              : activeNode.textType === TextType.Heading
                              ? 150
                              : 120
                          }
                          onChange={(
                            event: React.FormEvent<HTMLInputElement>
                          ) => {
                            onChange(event);
                            dispatch({
                              type: CollageDesignerActionType.ModifyNode,
                              payload: {
                                targetId: activeNode.id as number,
                                node: {
                                  textLineHeight: parseInt(
                                    event?.currentTarget?.value
                                  ),
                                },
                              },
                            });
                          }}
                          disabled={!!activeNode.inheritUserStylesheets}
                          onBlur={onBlur}
                          startEnhancer={() => <LineHeight />}
                          endEnhancer="%"
                          placeholder="Line height"
                          type="number"
                        />
                      </Block>
                    )}
                  />
                  <Controller
                    name="letterSpacing"
                    defaultValue=""
                    control={control}
                    register={register}
                    render={({ onChange, onBlur }) => (
                      <Block width="180px">
                        <Input
                          value={activeNode?.textLetterSpacing || 0}
                          onChange={(
                            event: React.FormEvent<HTMLInputElement>
                          ) => {
                            onChange(event);
                            dispatch({
                              type: CollageDesignerActionType.ModifyNode,
                              payload: {
                                targetId: activeNode.id as number,
                                node: {
                                  textLetterSpacing: parseFloat(
                                    event?.currentTarget?.value
                                  ),
                                },
                              },
                            });
                          }}
                          disabled={!!activeNode.inheritUserStylesheets}
                          onBlur={onBlur}
                          type="number"
                          startEnhancer={() => <LetterSpacing />}
                          endEnhancer="pt"
                          placeholder="Letter spacing"
                        />
                      </Block>
                    )}
                  />
                </Block>
              </FormControl>

              <FormControl>
                <ColorPicker
                  initialColor={activeNode.textColor || "black"}
                  onPick={(color) =>
                    dispatch({
                      type: CollageDesignerActionType.ModifyNode,
                      payload: {
                        targetId: (activeNode.id as number) as number,
                        node: {
                          textColor: color,
                        },
                      },
                    })
                  }
                />
              </FormControl>
              <FormControl>
                <Block display="flex" gridGap="12px">
                  <Controller
                    name="alignment"
                    defaultValue=""
                    control={control}
                    register={register}
                    render={({ onChange, onBlur }) => (
                      <IconSelect
                        options={[...ALIGN_STYLES]}
                        value={
                          activeNode.textAlignment
                            ? [{ id: activeNode.textAlignment }]
                            : [{ id: "left" }]
                        }
                        clearable={false}
                        searchable={false}
                        onInputChange={onChange}
                        onBlur={onBlur}
                        placeholder="Align"
                        onChange={(params) =>
                          params.value &&
                          params.value[0] &&
                          params.value[0].id &&
                          dispatch({
                            type: CollageDesignerActionType.ModifyNode,
                            payload: {
                              targetId: activeNode.id as number,
                              node: {
                                textAlignment: (params.value[0]
                                  .id as string) as "left" | "right" | "center",
                              },
                            },
                          })
                        }
                      />
                    )}
                  />
                  <Controller
                    name="vertical-alignment"
                    defaultValue=""
                    control={control}
                    register={register}
                    render={({ onChange, onBlur }) => (
                      <IconSelect
                        options={[...VERTICAL_ALIGN_STYLES]}
                        value={
                          activeNode.textVerticalAlignment
                            ? [{ id: activeNode.textVerticalAlignment }]
                            : [{ id: "flex-start" }]
                        }
                        clearable={false}
                        searchable={false}
                        onInputChange={onChange}
                        onBlur={onBlur}
                        placeholder="Vertical align"
                        onChange={(params) =>
                          params.value &&
                          params.value[0] &&
                          params.value[0].id &&
                          dispatch({
                            type: CollageDesignerActionType.ModifyNode,
                            payload: {
                              targetId: activeNode.id as number,
                              node: {
                                textVerticalAlignment: params.value[0]
                                  .id as string,
                              },
                            },
                          })
                        }
                      />
                    )}
                  />
                </Block>
              </FormControl>

              <FormControl>
                <Checkbox
                  checked={!!activeNode.inheritUserStylesheets}
                  onChange={(event: React.FormEvent<HTMLInputElement>) =>
                    dispatch({
                      type: CollageDesignerActionType.ModifyNode,
                      payload: {
                        targetId: activeNode.id as number,
                        node: {
                          inheritUserStylesheets: event.currentTarget.checked,
                        },
                      },
                    })
                  }
                >
                  Use your website text styles
                  <StatefulPopover
                    overrides={{
                      Body: {
                        style: {
                          zIndex: 20,
                          opacity: 1,
                          borderRadius: "4px",
                          background: "white",
                          border: "1px solid #E6EAF0",
                          overflow: "hidden",
                          fontSize: "12px",
                          maxWidth: "300px",
                          boxShadow: theme.lighting.shadow400,
                        },
                      },
                    }}
                    content={() => (
                      <Block padding={"10px"} backgroundColor={"white"}>
                        Use this option if you want to use your website
                        stylesheets. That include font, size, weight, line
                        height, and letter spacing.
                        <br />
                        <br />
                        <strong className={css({ fontWeight: 500 })}>
                          Please note that this option will overwrite the text
                          styles you set in the editor and you may see different
                          styles in the editor and on your website.
                        </strong>
                      </Block>
                    )}
                    showArrow
                    popoverMargin={8}
                    focusLock
                    returnFocus
                    renderAll
                    triggerType="hover"
                    placement="top"
                  >
                    <span>
                      <Info
                        className={css({
                          flexShrink: 0,
                          position: "relative",
                          marginLeft: "6px",
                          top: "2px",
                        })}
                      />
                    </span>
                  </StatefulPopover>
                </Checkbox>
              </FormControl>

              <FormControl label="Actions">
                <Block
                  marginTop="scale400"
                  marginBottom="scale800"
                  display="flex"
                  gridGap="12px"
                >
                  <IconButton
                    type="button"
                    disabled={status !== CollageDesignerStatus.Idle}
                    onClick={() => {
                      // eslint-disable-next-line @typescript-eslint/no-unused-vars
                      const { id, ...restActiveNode } = activeNode;

                      dispatch({
                        type: CollageDesignerActionType.InsertNode,
                        payload: {
                          targetId: 0,
                          targetParentId: 0,
                          index: (activeNode?.parent?.index || 0) + 1,
                          node: {
                            id: 0,
                            ...restActiveNode,
                          },
                        },
                      });
                    }}
                  >
                    <Duplicate />
                  </IconButton>

                  <IconButton
                    type="button"
                    disabled={status !== CollageDesignerStatus.Idle}
                    onClick={() => {
                      dispatch({
                        type: CollageDesignerActionType.RemoveNode,
                        payload: {
                          targetId: activeNode.id as number,
                        },
                      });
                    }}
                  >
                    <Trash />
                  </IconButton>
                </Block>
              </FormControl>

              <FormControl label="Padding">
                <Select
                  value={
                    activeNode.paddingUnit
                      ? [{ id: activeNode.paddingUnit }]
                      : [{ id: "px" }]
                  }
                  size="mini"
                  options={[
                    {
                      id: "px",
                      label: "px",
                    },
                    { id: "pt", label: "pt" },
                    { id: "%", label: "%" },
                  ]}
                  $style={{
                    width: "70px",
                    marginLeft: "auto",
                    marginTop: "-34px",
                    marginBottom: "8px",
                  }}
                  onChange={(params) => {
                    params.value &&
                      params.value[0] &&
                      params.value[0].id &&
                      dispatch({
                        type: CollageDesignerActionType.ModifyNode,
                        payload: {
                          targetId: activeNode.id as number,
                          node: {
                            paddingUnit: params.value[0].id as string,
                            ...(params.value[0].id === "%" && {
                              ...(activeNode.paddingTop &&
                                activeNode.paddingTop > 100 && {
                                  paddingTop: 100,
                                }),
                              ...(activeNode.paddingRight &&
                                activeNode.paddingRight > 100 && {
                                  paddingRight: 100,
                                }),
                              ...(activeNode.paddingBottom &&
                                activeNode.paddingBottom > 100 && {
                                  paddingBottom: 100,
                                }),
                              ...(activeNode.paddingLeft &&
                                activeNode.paddingLeft > 100 && {
                                  paddingLeft: 100,
                                }),
                            }),
                          },
                        },
                      });
                  }}
                />
                <Block display="flex" flexDirection="column" gridGap="8px">
                  <Block display="flex" gridGap="12px" alignItems="center">
                    <PaddingTop
                      width={20}
                      height={20}
                      className={css({
                        flexShrink: 0,
                      })}
                    />

                    <Slider
                      value={[activeNode.paddingTop || 0]}
                      onChange={(event) => {
                        dispatch({
                          type: CollageDesignerActionType.ModifyNode,
                          payload: {
                            targetId: activeNode.id as number,
                            node: {
                              paddingTop: event.value[0],
                            },
                          },
                        });
                      }}
                      {...(activeNode.paddingUnit === "%" && {
                        min: 0,
                        max: 100,
                      })}
                    />

                    <div className={css({ width: "70px", flexShrink: 0 })}>
                      <Input
                        size="mini"
                        value={activeNode.paddingTop || 0}
                        onChange={(
                          event: React.FormEvent<HTMLInputElement>
                        ) => {
                          dispatch({
                            type: CollageDesignerActionType.ModifyNode,
                            payload: {
                              targetId: activeNode.id as number,
                              node: {
                                paddingTop: parseInt(event.currentTarget.value),
                              },
                            },
                          });
                        }}
                        placeholder="0"
                        type="number"
                        min={0}
                        max={activeNode.paddingUnit === "%" ? 100 : 500}
                      />
                    </div>

                    <IconButton
                      type="button"
                      disabled={status !== CollageDesignerStatus.Idle}
                      onClick={() => {
                        dispatch({
                          type: CollageDesignerActionType.ModifyNode,
                          payload: {
                            targetId: activeNode.id as number,
                            node: {
                              paddingTop: 0,
                            },
                          },
                        });
                      }}
                      $style={{
                        flexShrink: 0,
                        width: "24px",
                        height: "24px",
                      }}
                    >
                      <Revert />
                    </IconButton>
                  </Block>

                  <Block display="flex" gridGap="12px" alignItems="center">
                    <PaddingRight
                      width={20}
                      height={20}
                      className={css({
                        flexShrink: 0,
                      })}
                    />

                    <Slider
                      value={[activeNode.paddingRight || 0]}
                      onChange={(event) => {
                        dispatch({
                          type: CollageDesignerActionType.ModifyNode,
                          payload: {
                            targetId: activeNode.id as number,
                            node: {
                              paddingRight: event.value[0],
                            },
                          },
                        });
                      }}
                      {...(activeNode.paddingUnit === "%" && {
                        min: 0,
                        max: 100,
                      })}
                    />

                    <div className={css({ width: "70px", flexShrink: 0 })}>
                      <Input
                        size="mini"
                        value={activeNode.paddingRight || 0}
                        onChange={(
                          event: React.FormEvent<HTMLInputElement>
                        ) => {
                          dispatch({
                            type: CollageDesignerActionType.ModifyNode,
                            payload: {
                              targetId: activeNode.id as number,
                              node: {
                                paddingRight: parseInt(
                                  event.currentTarget.value
                                ),
                              },
                            },
                          });
                        }}
                        placeholder="0"
                        type="number"
                        min={0}
                        max={activeNode.paddingUnit === "%" ? 100 : 500}
                      />
                    </div>

                    <IconButton
                      type="button"
                      disabled={status !== CollageDesignerStatus.Idle}
                      onClick={() => {
                        dispatch({
                          type: CollageDesignerActionType.ModifyNode,
                          payload: {
                            targetId: activeNode.id as number,
                            node: {
                              paddingRight: 0,
                            },
                          },
                        });
                      }}
                      $style={{
                        flexShrink: 0,
                        width: "24px",
                        height: "24px",
                      }}
                    >
                      <Revert />
                    </IconButton>
                  </Block>

                  <Block display="flex" gridGap="12px" alignItems="center">
                    <PaddingBottom
                      width={20}
                      height={20}
                      className={css({
                        flexShrink: 0,
                      })}
                    />

                    <Slider
                      value={[activeNode.paddingBottom || 0]}
                      onChange={(event) => {
                        dispatch({
                          type: CollageDesignerActionType.ModifyNode,
                          payload: {
                            targetId: activeNode.id as number,
                            node: {
                              paddingBottom: event.value[0],
                            },
                          },
                        });
                      }}
                      {...(activeNode.paddingUnit === "%" && {
                        min: 0,
                        max: 100,
                      })}
                    />

                    <div className={css({ width: "70px", flexShrink: 0 })}>
                      <Input
                        size="mini"
                        value={activeNode.paddingBottom || 0}
                        onChange={(
                          event: React.FormEvent<HTMLInputElement>
                        ) => {
                          dispatch({
                            type: CollageDesignerActionType.ModifyNode,
                            payload: {
                              targetId: activeNode.id as number,
                              node: {
                                paddingBottom: parseInt(
                                  event.currentTarget.value
                                ),
                              },
                            },
                          });
                        }}
                        placeholder="0"
                        type="number"
                        min={0}
                        max={activeNode.paddingUnit === "%" ? 100 : 500}
                      />
                    </div>

                    <IconButton
                      type="button"
                      disabled={status !== CollageDesignerStatus.Idle}
                      onClick={() => {
                        dispatch({
                          type: CollageDesignerActionType.ModifyNode,
                          payload: {
                            targetId: activeNode.id as number,
                            node: {
                              paddingBottom: 0,
                            },
                          },
                        });
                      }}
                      $style={{
                        flexShrink: 0,
                        width: "24px",
                        height: "24px",
                      }}
                    >
                      <Revert />
                    </IconButton>
                  </Block>

                  <Block display="flex" gridGap="12px" alignItems="center">
                    <PaddingLeft
                      width={20}
                      height={20}
                      className={css({
                        flexShrink: 0,
                      })}
                    />

                    <Slider
                      value={[activeNode.paddingLeft || 0]}
                      onChange={(event) => {
                        dispatch({
                          type: CollageDesignerActionType.ModifyNode,
                          payload: {
                            targetId: activeNode.id as number,
                            node: {
                              paddingLeft: event.value[0],
                            },
                          },
                        });
                      }}
                      {...(activeNode.paddingUnit === "%" && {
                        min: 0,
                        max: 100,
                      })}
                    />

                    <div className={css({ width: "70px", flexShrink: 0 })}>
                      <Input
                        size="mini"
                        value={activeNode.paddingLeft || 0}
                        onChange={(
                          event: React.FormEvent<HTMLInputElement>
                        ) => {
                          dispatch({
                            type: CollageDesignerActionType.ModifyNode,
                            payload: {
                              targetId: activeNode.id as number,
                              node: {
                                paddingLeft: parseInt(
                                  event.currentTarget.value
                                ),
                              },
                            },
                          });
                        }}
                        placeholder="0"
                        type="number"
                        min={0}
                        max={activeNode.paddingUnit === "%" ? 100 : 500}
                      />
                    </div>

                    <IconButton
                      type="button"
                      disabled={status !== CollageDesignerStatus.Idle}
                      onClick={() => {
                        dispatch({
                          type: CollageDesignerActionType.ModifyNode,
                          payload: {
                            targetId: activeNode.id as number,
                            node: {
                              paddingLeft: 0,
                            },
                          },
                        });
                      }}
                      $style={{
                        flexShrink: 0,
                        width: "24px",
                        height: "24px",
                      }}
                    >
                      <Revert />
                    </IconButton>
                  </Block>
                </Block>
              </FormControl>
            </form>
          </Panel>
        </>
      )}

      {activeNode?.type === CollageNodeType.Video && (
        <>
          <Panel
            title="Video settings"
            icon={<Camera height="16px" stroke="#AF9883" />}
          >
            <FormControl label="video type">
              <RadioGroup
                value={activeNode.videoType}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  e.target.value &&
                    dispatch({
                      type: CollageDesignerActionType.ModifyNode,
                      payload: {
                        targetId: activeNode.id as number,
                        node: {
                          videoType: e.target.value,
                        },
                      },
                    });
                }}
              >
                <Radio value="youtube">YouTube</Radio>
                <Radio value="vimeo">Vimeo</Radio>
              </RadioGroup>
            </FormControl>
            <FormControl
              label="Video url"
              error={
                !isVideoUrlValid &&
                "This video url is incorrect and the component won't work properly"
              }
            >
              <Input
                value={activeNode.videoUrl || ""}
                error={!isVideoUrlValid}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  e.target.value &&
                    dispatch({
                      type: CollageDesignerActionType.ModifyNode,
                      payload: {
                        targetId: activeNode.id as number,
                        node: {
                          videoUrl: e.target.value,
                        },
                      },
                    });
                }}
              />
            </FormControl>
            <FormControl label="Aspect ratio">
              <Controller
                name="aspect_ratio"
                defaultValue=""
                control={control}
                register={register}
                render={({ onChange, onBlur }) => (
                  <Block display="flex" gridGap="12px">
                    <Select
                      options={[
                        ...STANDARD_ASPECT_RATIOS,
                        ...(value === CUSTOM_ASPECT_RATIO
                          ? [CUSTOM_ASPECT_RATIO]
                          : []),
                      ]}
                      disabled={status !== CollageDesignerStatus.Idle}
                      value={[value]}
                      clearable={false}
                      searchable={false}
                      onInputChange={onChange}
                      onBlur={onBlur}
                      onChange={(params) =>
                        params.value &&
                        params.value[0] &&
                        params.value[0].id &&
                        dispatch({
                          type: CollageDesignerActionType.SetNodeDimensions,
                          payload: {
                            targetId: activeNode.id as number,
                            dimensions: {
                              width: 1000,
                              height:
                                1000 / parseFloat(params.value[0].id as string),
                            },
                          },
                        })
                      }
                    />
                    {aspectRatio && (
                      <Button
                        size="mini"
                        kind="tertiary"
                        onClick={() => {
                          dispatch({
                            type: CollageDesignerActionType.ModifyNode,
                            payload: {
                              targetId: activeNode.id as number,
                              node: {
                                dimensions: {
                                  width: 1000,
                                  height: 1000 / (16 / 9),
                                },
                              },
                            },
                          });
                        }}
                        $style={{
                          paddingLeft: "12px",
                          paddingRight: "12px",
                        }}
                        disabled={
                          status !== CollageDesignerStatus.Idle ||
                          aspectRatio === 16 / 9
                        }
                      >
                        Reset
                      </Button>
                    )}
                  </Block>
                )}
              />
            </FormControl>
          </Panel>
        </>
      )}

      {activeNode?.type === CollageNodeType.Download && (
        <>
          <Panel
            title="Download setting"
            icon={<Camera height="16px" stroke="#AF9883" />}
          >
            <FormControl label="Label">
              <Input
                value={activeNode.label}
                error={!activeNode.label}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  e.target.value &&
                    dispatch({
                      type: CollageDesignerActionType.ModifyNode,
                      payload: {
                        targetId: activeNode.id as number,
                        node: {
                          label: e.target.value,
                        },
                      },
                    });
                }}
              />
            </FormControl>
            <FormControl
              label="Password"
              caption="Leave blank if you don't want to require a password"
            >
              <Input
                value={activeNode.password}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  dispatch({
                    type: CollageDesignerActionType.ModifyNode,
                    payload: {
                      targetId: activeNode.id as number,
                      node: {
                        password: e.target.value,
                      },
                    },
                  });
                }}
                autoComplete="off"
                type="password"
              />
            </FormControl>
            <FormControl label="Exported photo width">
              <RadioGroup
                value={activeNode.photoWidth?.toString()}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  dispatch({
                    type: CollageDesignerActionType.ModifyNode,
                    payload: {
                      targetId: activeNode.id as number,
                      node: {
                        photoWidth: e.target.value
                          ? parseInt(e.target.value)
                          : undefined,
                      },
                    },
                  });
                }}
              >
                <Radio value="">Original</Radio>
                <Radio value="1000">1000 px</Radio>
                <Radio value="1500">1500 px</Radio>
                <Radio value="2000">2000 px</Radio>
                <Radio value="2500">2500 px</Radio>
              </RadioGroup>
            </FormControl>
          </Panel>
        </>
      )}

      {activeNode?.type === CollageNodeType.Spacer && (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Panel
              title="Spacer settings"
              icon={<Camera height="16px" stroke="#AF9883" />}
            >
              <Block
                marginTop="scale400"
                marginBottom="scale800"
                display="flex"
                gridGap="12px"
              >
                <IconButton
                  disabled={status !== CollageDesignerStatus.Idle}
                  onClick={() => {
                    dispatch({
                      type: CollageDesignerActionType.RemoveNode,
                      payload: {
                        targetId: activeNode.id as number,
                      },
                    });
                  }}
                >
                  <Trash />
                </IconButton>
              </Block>
              <FormControl label="Aspect ratio">
                <Controller
                  name="aspect_ratio"
                  defaultValue=""
                  control={control}
                  register={register}
                  render={({ onChange, onBlur }) => (
                    <Block display="flex" gridGap="12px">
                      <Select
                        options={[
                          ...STANDARD_ASPECT_RATIOS,
                          ...(value === CUSTOM_ASPECT_RATIO
                            ? [CUSTOM_ASPECT_RATIO]
                            : []),
                        ]}
                        value={[value]}
                        clearable={false}
                        searchable={false}
                        onInputChange={onChange}
                        onBlur={onBlur}
                        onChange={(params) =>
                          params.value &&
                          params.value[0] &&
                          params.value[0].id &&
                          dispatch({
                            type: CollageDesignerActionType.SetNodeDimensions,
                            payload: {
                              targetId: activeNode.id as number,
                              dimensions: {
                                width: 1000,
                                height:
                                  1000 /
                                  parseFloat(params.value[0].id as string),
                              },
                            },
                          })
                        }
                      />
                    </Block>
                  )}
                />
              </FormControl>
            </Panel>
          </form>
        </>
      )}

      {activeNodes.length > 1 && (
        <Panel title={`Selected containers: ${activeNodes.length}`}>
          <Block
            marginTop="scale400"
            marginBottom="scale800"
            display="flex"
            gridGap="12px"
          >
            <IconButton
              disabled={status !== CollageDesignerStatus.Idle}
              onClick={() => {
                for (const id of activeNodeIds) {
                  dispatch({
                    type: CollageDesignerActionType.RemoveNode,
                    payload: {
                      targetId: id,
                    },
                  });
                }
              }}
            >
              <Trash />
            </IconButton>
          </Block>
        </Panel>
      )}

      {/* <Button
        size="compact"
        onClick={() => {
          dispatch({
            type: CollageDesignerActionType.RemoveNode,
            payload: {
              targetId: activeNode.id as number,
            },
          });
        }}
        $style={{
          width: "100%",
        }}
      >
        Usuń
      </Button> */}
    </>
  );
}
