import {
  FormControl as BaseFormControl,
  FormControlOverrides,
  FormControlProps as BaseFormControlProps,
} from "baseui/form-control";
import { Alert, Check, DeleteAlt } from "baseui/icon";
import { KIND, Notification } from "baseui/notification";
import React from "react";

type FormControlProps = {
  validated?: boolean;
  success?: string;
  error?: boolean | unknown;
  warning?: string;
  children?: React.ReactNode;
  overrides?: FormControlOverrides;
} & BaseFormControlProps;

export default function FormControl({
  validated,
  success,
  error,
  warning,
  children,
  ...props
}: FormControlProps): React.ReactElement {
  return (
    <BaseFormControl
      overrides={{
        Label: {
          style: {
            color: "#8390AE",
            fontWeight: 400,
            textTransform: "uppercase",
            fontSize: "0.85rem",
            letterSpacing: "0.05rem",
          },
        },
        Caption: {
          style: {
            color: "#9199A8",
            fontWeight: 400,
            fontSize: "0.85rem",
            lineHeight: "1.10rem",
            letterSpacing: "0.02rem",
          },
        },
        ControlContainer: {
          style: {
            position: "relative",
          },
        },
      }}
      {...props}
    >
      <>
        {children}
        {error && (
          <Notification
            overrides={{
              Body: { style: { width: "auto" } },
            }}
            kind={KIND.negative}
          >
            <DeleteAlt
              size={20}
              overrides={{
                Svg: {
                  style: {
                    verticalAlign: "middle",
                    marginRight: "10px",
                  },
                },
              }}
            />{" "}
            {error}
          </Notification>
        )}
        {warning && (
          <Notification
            overrides={{
              Body: { style: { width: "auto" } },
            }}
            kind={KIND.warning}
          >
            <Alert
              size={20}
              overrides={{
                Svg: {
                  style: {
                    verticalAlign: "middle",
                    marginRight: "10px",
                  },
                },
              }}
            />{" "}
            {warning}
          </Notification>
        )}
        {validated && !error && !warning && (
          <Notification
            overrides={{
              Body: { style: { width: "auto" } },
            }}
            kind={KIND.positive}
          >
            <Check
              size={20}
              overrides={{
                Svg: {
                  style: {
                    verticalAlign: "middle",
                    marginRight: "10px",
                  },
                },
              }}
            />{" "}
            {success || "Zawartość spełnia wytyczne SEO"}
          </Notification>
        )}
      </>
    </BaseFormControl>
  );
}
