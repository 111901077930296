import { gql, useMutation } from "@apollo/client";
import { useAuth } from "app/auth/context";
import FormControl from "app/collages/components/CollageDesigner/ui/FormControl";
import { useStyletron } from "baseui";
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
} from "baseui/modal";
import { toaster } from "baseui/toast";
import { LabelSmall, ParagraphSmall } from "baseui/typography";
import { useState } from "react";
import { ReactElement, useCallback } from "react";
import { useForm } from "react-hook-form";

import Button from "./Button";
import { ControlledInput } from "./Input";

const TOGGLE_2_FA = gql`
  mutation UpdateAccountData($input: Toggle2FaInput!) {
    toggle2Fa(input: $input) {
      id
      has2FaEnabled
    }
  }
`;

type FormInputs = {
  sms2FaToken?: string;
};

export default function TwoFactorAuthenticationForm(): ReactElement {
  const [toggle2Fa] = useMutation(TOGGLE_2_FA);
  const { user, setUser } = useAuth();

  const {
    handleSubmit,
    errors,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm<FormInputs>({
    defaultValues: {
      sms2FaToken: undefined,
    },
  });

  const [is2FaModalOpen, setIs2FaModalOpen] = useState(false);

  const [css, theme] = useStyletron();

  const handle2FaForm = useCallback(
    async (input: FormInputs) => {
      try {
        const response = await toggle2Fa({
          variables: { input },
        });

        setUser({ user, ...response.data.toggle2Fa });

        reset();
        setIs2FaModalOpen(false);

        toaster.positive("Saved successfully", {
          autoHideDuration: 4000,
        });
      } catch (error) {
        if (
          error.graphQLErrors &&
          error.graphQLErrors[0] &&
          error.graphQLErrors[0].extensions?.code === "NO_TOKEN_PROVIDED_ERROR"
        ) {
          setIs2FaModalOpen(true);
        } else {
          toaster.negative("Something went wrong", {
            autoHideDuration: 4000,
          });
        }
      }
    },
    [toggle2Fa]
  );

  return (
    <form onSubmit={handleSubmit(handle2FaForm)}>
      <LabelSmall
        color="primary"
        marginBottom="scale400"
        $style={{
          textTransform: "uppercase",
          textAlign: "left",
          fontSize: "12px",
          color: "#8390AE",
        }}
      >
        Two-factor authentication{" "}
        <span className={css({ color: theme.colors.primary })}>
          {user?.has2FaEnabled ? "Now is active" : "Now is not active"}
        </span>
      </LabelSmall>

      <ParagraphSmall>
        Securing your account by two-factor authentication when logging in.
        Requires an active phone number.
      </ParagraphSmall>

      <div
        className={css({
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "10px",
        })}
      >
        <Button type="submit" disabled={isSubmitting} isLoading={isSubmitting}>
          {user?.has2FaEnabled ? "Deactivate" : "Activate"}
        </Button>
      </div>
      <Modal
        onClose={() => setIs2FaModalOpen(false)}
        closeable
        isOpen={is2FaModalOpen}
        animate
      >
        <ModalHeader>Confirm action</ModalHeader>
        <ModalBody>
          <FormControl
            label={() => "Code"}
            error={errors.sms2FaToken && errors.sms2FaToken.message}
          >
            <ControlledInput
              control={control}
              name="sms2FaToken"
              error={!!errors.sms2FaToken}
              rules={{ required: "This field is required" }}
              autoComplete="one-time-code"
              disabled={isSubmitting}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <ModalButton
            kind="tertiary"
            disabled={isSubmitting}
            isLoading={isSubmitting}
            onClick={() => setIs2FaModalOpen(false)}
          >
            Cancel
          </ModalButton>
          <ModalButton
            type="submit"
            disabled={isSubmitting}
            isLoading={isSubmitting}
            onClick={handleSubmit(handle2FaForm)}
          >
            Confirm
          </ModalButton>
        </ModalFooter>
      </Modal>
    </form>
  );
}
