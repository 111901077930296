import { gql, useMutation } from "@apollo/client";
import { useStyletron } from "baseui";
import { FormControl } from "baseui/form-control";
import { toaster } from "baseui/toast";
import { LabelMedium } from "baseui/typography";
import { ReactElement } from "react";
import { useForm } from "react-hook-form";

import Button from "./Button";
import { ControlledInput } from "./Input";

const UPDATE_PASSWORD = gql`
  mutation ChangePassword($input: ChangePasswordInput!) {
    changePassword(input: $input) {
      id
      email
      firstName
      lastName
    }
  }
`;

type FormInputs = {
  currentPassword: string;
  newPassword: string;
};

export default function PasswordChangeForm(): ReactElement {
  const [updatePassword] = useMutation(UPDATE_PASSWORD);

  const {
    handleSubmit,
    errors,
    control,
    formState: { isSubmitting },
  } = useForm<FormInputs>({
    defaultValues: {
      currentPassword: "",
      newPassword: "",
    },
  });
  const [css] = useStyletron();

  return (
    <form
      onSubmit={handleSubmit(async (input) => {
        try {
          await updatePassword({
            variables: { input },
          });

          toaster.positive("Saved successfully", {
            autoHideDuration: 4000,
          });
        } catch (error) {
          toaster.negative("The data provided is incorrect", {
            autoHideDuration: 4000,
          });
        }
      })}
    >
      <LabelMedium color="primary" marginBottom="scale600">
        Password change
      </LabelMedium>

      <FormControl
        label={() => "Current password"}
        error={errors.currentPassword && errors.currentPassword.message}
        overrides={{
          Caption: { style: { textAlign: "left" } },
          Label: {
            style: {
              textTransform: "uppercase",
              textAlign: "left",
              fontSize: "12px",
              color: "#8390AE",
            },
          },
        }}
      >
        <ControlledInput
          control={control}
          name="currentPassword"
          type="password"
          error={!!errors.currentPassword}
          rules={{ required: "This field is required" }}
          disabled={isSubmitting}
        />
      </FormControl>
      <FormControl
        label={() => "New password"}
        error={errors.newPassword && errors.newPassword.message}
        overrides={{
          Caption: { style: { textAlign: "left" } },
          Label: {
            style: {
              textTransform: "uppercase",
              textAlign: "left",
              fontSize: "12px",
              color: "#8390AE",
            },
          },
        }}
      >
        <ControlledInput
          control={control}
          name="newPassword"
          type="password"
          error={!!errors.newPassword}
          rules={{ required: "This field is required" }}
          disabled={isSubmitting}
        />
      </FormControl>

      <div
        className={css({
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "10px",
        })}
      >
        <Button type="submit" disabled={isSubmitting} isLoading={isSubmitting}>
          Update password
        </Button>
      </div>
    </form>
  );
}
