import DashboardPage from "app/admin/pages/DashboardPage";
import ListPlansPage from "app/admin/plans/pages/ListPlansPage";
import ShowPlanPage from "app/admin/plans/pages/ShowPlanPage";
import ListUsersPage from "app/admin/users/pages/ListUsersPage";
import ShowUserPage from "app/admin/users/pages/ShowUserPage";
import { Route, Switch } from "react-router";

export default (
  <Switch>
    <Route path="/admin" exact component={DashboardPage} />
    <Route path="/admin/users/:id" component={ShowUserPage} />
    <Route path="/admin/users" component={ListUsersPage} />
    <Route path="/admin/plans/:id" component={ShowPlanPage} />
    <Route path="/admin/plans" component={ListPlansPage} />
  </Switch>
);
