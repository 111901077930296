import { gql, useMutation, useQuery } from "@apollo/client";
import FeaturesTable from "app/admin/plans/components/FeaturesTable";
import Button from "app/core/components/Button";
import LoadingSpinner from "app/core/components/LoadingSpinner";
import AppLayout from "app/core/layouts/AppLayout";
import { useStyletron } from "baseui";
import { Cell, Grid } from "baseui/layout-grid";
import { toaster } from "baseui/toast";
import {
  DisplayXSmall,
  LabelMedium,
  LabelSmall,
  ParagraphMedium,
} from "baseui/typography";
import { ReactElement, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const FETCH_USER = gql`
  query User($id: Id!) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      phone
      createdAt
      overridenPlanFeatures
      isBlocked
      deletedAt
      emailVerifiedAt
      subscription {
        plan {
          name
        }
      }
    }
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
    }
  }
`;

const BLOCK_USER = gql`
  mutation BlockUser($input: BlockUserInput!) {
    blockUser(input: $input) {
      id
    }
  }
`;

const UNBLOCK_USER = gql`
  mutation UnblockUser($input: UnblockUserInput!) {
    unblockUser(input: $input) {
      id
    }
  }
`;

const DELETE_USER = gql`
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      id
    }
  }
`;

const RESEND_VERIFICATION_EMAIL_USER = gql`
  mutation ResendVerificationEmail($input: ResendVerificationEmailInput!) {
    resendVerificationEmail(input: $input) {
      id
    }
  }
`;

interface ShowUserPageParams {
  id: string;
}

export default function ShowUserPage(): ReactElement {
  const [css] = useStyletron();
  const { id } = useParams<ShowUserPageParams>();
  const [updateUser] = useMutation(UPDATE_USER);
  const [blockUser] = useMutation(BLOCK_USER);
  const [unblockUser] = useMutation(UNBLOCK_USER);
  const [deleteUser] = useMutation(DELETE_USER);
  const [resendVerificationEmail] = useMutation(RESEND_VERIFICATION_EMAIL_USER);

  const { loading, data, refetch } = useQuery(FETCH_USER, {
    variables: { id: parseInt(id) },
    nextFetchPolicy: "no-cache",
  });

  const [overridenPlanFeatures, setOverridenPlanFeatures] = useState<
    Record<string, boolean | number>
  >({});

  useEffect(() => {
    if (data && data.user) {
      setOverridenPlanFeatures(data.user.overridenPlanFeatures || {});
    }
  }, [data]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <AppLayout>
      <Grid>
        <Cell span={12}>
          <div
            className={css({
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "20px",
              paddingBottom: "20px",
            })}
          >
            <DisplayXSmall
              $style={{
                fontSize: "28px",
                fontWeight: 300,
              }}
            >
              {data.user.firstName} {data.user.lastName}
            </DisplayXSmall>
          </div>
        </Cell>
        <Cell span={3}>
          <LabelSmall>First name</LabelSmall>
          <ParagraphMedium>{data.user.firstName}</ParagraphMedium>
        </Cell>
        <Cell span={3}>
          <LabelSmall>Last name</LabelSmall>
          <ParagraphMedium>{data.user.lastName}</ParagraphMedium>
        </Cell>
        <Cell span={3}>
          <LabelSmall>E-mail</LabelSmall>
          <ParagraphMedium>{data.user.email}</ParagraphMedium>
        </Cell>
        <Cell span={3}>
          <LabelSmall>Phone</LabelSmall>
          <ParagraphMedium>{data.user.phone}</ParagraphMedium>
        </Cell>
        <Cell span={3}>
          <LabelSmall>Plan</LabelSmall>
          <ParagraphMedium>
            {data.user.subscription?.plan?.name || "-"}
          </ParagraphMedium>
        </Cell>
        <Cell span={3}>
          <LabelSmall>Is blocked?</LabelSmall>
          <ParagraphMedium>
            {data.user.isBlocked ? "Yes" : "No"}
          </ParagraphMedium>
        </Cell>
        <Cell span={3}>
          <LabelSmall>Created at</LabelSmall>
          <ParagraphMedium>
            {new Date(data.user.createdAt).toLocaleString()}
          </ParagraphMedium>
        </Cell>
        <Cell span={3}>
          <LabelSmall>Deleted at</LabelSmall>
          <ParagraphMedium>
            {data.user.deletedAt
              ? new Date(data.user.deletedAt).toLocaleString()
              : "-"}
          </ParagraphMedium>
        </Cell>
        <Cell span={12}>
          <Button
            disabled={!!data.user.deletedAt}
            onClick={async () => {
              if (!data.user.isBlocked) {
                await blockUser({
                  variables: {
                    input: {
                      id: parseInt(id),
                    },
                  },
                });
              } else {
                await unblockUser({
                  variables: {
                    input: {
                      id: parseInt(id),
                    },
                  },
                });
              }

              refetch();
              toaster.positive(
                `${data.user.isBlocked ? "Unblocked" : "Blocked"} successfully`,
                {
                  autoHideDuration: 4000,
                }
              );
            }}
          >
            {data.user.isBlocked ? "Unblock" : "Block"} user
          </Button>
          <Button
            disabled={!!data.user.deletedAt}
            $style={{
              marginLeft: "10px",
            }}
            kind="secondary"
            onClick={async () => {
              await deleteUser({
                variables: {
                  input: {
                    id: parseInt(id),
                  },
                },
              });

              refetch();
              toaster.positive("Deleted successfully", {
                autoHideDuration: 4000,
              });
            }}
          >
            Delete user
          </Button>
          <Button
            $style={{
              marginLeft: "10px",
            }}
            kind="tertiary"
            disabled={!!data.user.emailVerifiedAt}
            onClick={async () => {
              await resendVerificationEmail({
                variables: {
                  input: {
                    id: parseInt(id),
                  },
                },
              });

              refetch();
              toaster.positive(`Resent verification email successfully`, {
                autoHideDuration: 4000,
              });
            }}
          >
            Resend verification email
          </Button>
        </Cell>
        <Cell span={12}>
          <LabelMedium
            marginTop="scale800"
            color="primary"
            marginBottom="scale400"
          >
            Overridden features
          </LabelMedium>
          <FeaturesTable
            value={overridenPlanFeatures}
            onChange={(value) => setOverridenPlanFeatures(value)}
            showOverwriteColumn={true}
          />

          <Button
            $style={{
              marginTop: "20px",
            }}
            onClick={async () => {
              await updateUser({
                variables: {
                  input: {
                    id: parseInt(id),
                    overridenPlanFeatures,
                  },
                },
              });

              toaster.positive("Updated successfully", {
                autoHideDuration: 4000,
              });
            }}
            disabled={!!data.user.deletedAt}
          >
            Save changes
          </Button>
        </Cell>
      </Grid>
    </AppLayout>
  );
}
