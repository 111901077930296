import { animated, useSpring } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";
import { MiniCanvas } from "app/core/components/Icon";
import useKeyPress from "app/core/hooks/useKeyPress";
import { styled, useStyletron } from "baseui";
import { Block } from "baseui/block";
import { LabelSmall } from "baseui/typography";
import { CollageDesignerContext } from "contexts/CollageDesignerContext";
import { convertToRaw, EditorState } from "draft-js";
import { memo, useContext, useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useRaf } from "rooks";

import DraggablePreview from "../CollageDesigner/DraggablePreview";
import {
  CollageDesignerActionType,
  CollageDesignerStatus,
  CollageNode,
  CollageNodeIdentifier,
  CollageNodeType,
  Photo,
  TextType,
} from "../CollageDesigner/types";
import NodeRenderer from "./NodeRenderer/index";
import { ExtendedRect, Rect, XY } from "./types";

function distanceBetween(p1: XY, p2: XY) {
  return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2));
}

const SCROLLING_BY_DRAGGING_OFFSET = 100;
const TOP_BAR_OFFSET = 103;

function calculateScrollingByDraggingSpeed(y: number, height: number): number {
  if (y < SCROLLING_BY_DRAGGING_OFFSET) {
    return Math.min(
      (SCROLLING_BY_DRAGGING_OFFSET - y) / SCROLLING_BY_DRAGGING_OFFSET,
      1
    );
  } else if (y > height - SCROLLING_BY_DRAGGING_OFFSET) {
    return Math.max(
      (y - (height - SCROLLING_BY_DRAGGING_OFFSET)) /
        -SCROLLING_BY_DRAGGING_OFFSET,
      -1
    );
  } else {
    return 0;
  }
}

type DropZone = {
  id: string;
  offset: {
    top: number;
    left: number;
  };
  direction: string;
  width: number;
  height: number;
};

function getCenterOfDropZone(
  dropZone: DropZone,
  width: number,
  scale: number,
  translate: XY
): XY {
  if (dropZone.direction === "horizontal") {
    return {
      x:
        (dropZone.offset.left + dropZone.width / 2) * width * scale +
        translate.x +
        85,
      y: (dropZone.offset.top * width + 10 / 2) * scale + translate.y + 71,
    };
  } else if (dropZone.direction === "vertical") {
    return {
      x: (dropZone.offset.left * width + 10 / 2) * scale + translate.x + 81,
      y:
        (dropZone.offset.top + dropZone.height / 2) * width * scale +
        translate.y +
        71,
    };
  } else {
    return {
      x:
        (dropZone.offset.left + dropZone.width / 2) * width * scale +
        translate.x +
        85,
      y:
        (dropZone.offset.top + dropZone.height / 2) * width * scale +
        translate.y +
        71,
    };
  }
}

const CollageRendererRoot = styled("div", {
  position: "relative",
  transformOrigin: "0 0",
  boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.1)",
});

type CollageRendererProps = {
  rootDivElement: React.RefObject<HTMLDivElement>;
  width: number;
  height: number;
  viewport: ExtendedRect;
  translate: XY;
  setTranslate: React.Dispatch<React.SetStateAction<XY>>;
  scale: number;
};

function CollageRenderer({
  rootDivElement,
  width,
  height,
  viewport,
  translate,
  setTranslate,
  scale,
}: CollageRendererProps): React.ReactElement {
  const collageRootDivElement = useRef<HTMLDivElement>(null);

  const [css, theme] = useStyletron();
  const {
    dispatch,
    state: {
      status,
      collageBody: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        nodes: { lastId, ...nodes },
        backgroundColor,
        gap,
      },
      photos,
      activeNodeIds,
      activeTextNodeId,
      croppingNodeId,
    },
  } = useContext(CollageDesignerContext);
  const [currentlyDraggedNodeId, setCurrentlyDraggedNodeId] = useState<
    string | null
  >(null);
  const [closestDropZone, setClosestDropZone] = useState<DropZone | null>(null);

  const normalizedViewport = useMemo<Rect>(
    () => ({
      x: viewport.x / (width * scale),
      y: viewport.y / (width * scale),
      width: viewport.width / (width * scale),
      height: viewport.height / (width * scale),
    }),
    [viewport]
  );

  const [scrollingByDraggingSpeed, setScrollingByDraggingSpeed] = useState(0);

  useRaf((timeElapsed) => {
    setTranslate(({ x, y }) => ({
      x,
      y: Math.max(
        Math.min(200, y + scrollingByDraggingSpeed * timeElapsed),
        -(height * scale) + viewport.absoluteHeight - 200
      ),
    }));
  }, scrollingByDraggingSpeed !== 0);

  // TODO: Add virtualization on x-axis
  const visibleNodes = useMemo(
    () =>
      Object.entries(
        nodes as Record<CollageNodeIdentifier, CollageNode>
      ).filter(
        ([, node]) =>
          (node.offset &&
            node.normalizedDimensions &&
            (node.offset.top >= normalizedViewport.y ||
              node.offset.top + node.normalizedDimensions.height >=
                normalizedViewport.y) &&
            node.offset.top <=
              normalizedViewport.y + normalizedViewport.height) ||
          node.id ===
            parseInt(currentlyDraggedNodeId?.split("@")[1]?.split("/")[1] ?? "")
      ),
    [normalizedViewport, nodes, currentlyDraggedNodeId]
  );

  const dropZones = useMemo<DropZone[]>(
    () => [
      ...(Object.keys(nodes).length === 1
        ? [
            {
              id: `insert@0/0/0`,
              offset: { top: 0, left: 0 },
              direction: "horizontal",
              width: 1,
              height: 1,
            },
          ]
        : visibleNodes
            .map(([, node]) =>
              node.offset && node.normalizedDimensions && node.parent
                ? [
                    {
                      id:
                        node.parent.direction === "vertical"
                          ? `insert@${node.parent.id}/0/${node.parent.index}/${node.id}`
                          : `insert@${node.id}/${node.parent.id}/0`,
                      offset: { ...node.offset },
                      direction: "horizontal",
                      width: node.normalizedDimensions.width,
                      height: node.normalizedDimensions.height,
                    },
                    {
                      id:
                        node.parent.direction === "vertical"
                          ? `insert@${node.parent.id}/0/${
                              node.parent.index + 1
                            }/${node.id}`
                          : `insert@${node.id}/${node.parent.id}/1`,
                      offset: {
                        ...node.offset,
                        top: node.offset.top + node.normalizedDimensions.height,
                      },
                      direction: "horizontal",
                      width: node.normalizedDimensions.width,
                      height: node.normalizedDimensions.height,
                    },
                    ...(node.type !== "text"
                      ? [
                          {
                            id:
                              node.parent.direction === "horizontal"
                                ? `insert@${node.parent.id}/0/${
                                    node.parent.index + 1
                                  }/${node.id}`
                                : `insert@${node.id}/${node.parent.id}/1`,
                            offset: {
                              ...node.offset,
                              left:
                                node.offset.left +
                                node.normalizedDimensions.width,
                            },
                            direction: "vertical",
                            width: node.normalizedDimensions.width,
                            height: node.normalizedDimensions.height,
                          },
                          {
                            id:
                              node.parent.direction === "horizontal"
                                ? `insert@${node.parent.id}/0/${node.parent.index}/${node.id}`
                                : `insert@${node.id}/${node.parent.id}/0`,
                            offset: { ...node.offset },
                            direction: "vertical",
                            width: node.normalizedDimensions.width,
                            height: node.normalizedDimensions.height,
                          },
                        ]
                      : []),
                    ...(node.type === "photo"
                      ? [
                          {
                            id: `replace@${node.id}`,
                            offset: { ...node.offset },
                            direction: "both",
                            width: node.normalizedDimensions.width,
                            height: node.normalizedDimensions.height,
                          },
                        ]
                      : []),
                  ]
                : []
            )
            .flat()),
    ],
    [visibleNodes, currentlyDraggedNodeId]
  );

  // TODO: Find better way to calculate dimensions of nodes on init
  useEffect(() => {
    dispatch({ type: CollageDesignerActionType.Refresh });
  }, []);

  const [{ x, y }, api] = useSpring(() => ({ x: 0, y: 0 }));

  const isMoveActivationKeyPressed = useKeyPress(" ");
  const isCancelationKeyPressed = useKeyPress("Escape");

  const bindDraggingListeners = useDrag(
    ({
      args: [nodeId, nodeType],
      active,
      xy: [x, y],
      cancel,
      canceled,
      last,
    }) => {
      if (isCancelationKeyPressed) {
        cancel();
      }

      setCurrentlyDraggedNodeId(active ? nodeId : null);
      document.body.style.userSelect = document.body.style.webkitUserSelect = active
        ? "none"
        : "";

      api.start({
        x: active ? x : 0,
        y: active ? y : 0,
        immediate: active,
      });

      const speed = calculateScrollingByDraggingSpeed(
        y - TOP_BAR_OFFSET,
        viewport.height
      );

      if (active && speed !== 0) {
        setScrollingByDraggingSpeed(speed);
      } else {
        setScrollingByDraggingSpeed(0);
      }

      if (currentlyDraggedNodeId) {
        const [sourceType, sourceArgs] = currentlyDraggedNodeId.split("@");

        if (x < 320) {
          setClosestDropZone(null);
        } else {
          const [, collageNodeId] = (sourceArgs ?? "")
            .split("/")
            .map((x) => parseInt(x));

          const nextClosestDropZone = dropZones
            .filter(
              (dropZone) =>
                (!dropZone.id.includes("replace") ||
                  nodeType === CollageNodeType.Photo) &&
                !dropZone.id.includes(`insert@${collageNodeId}/`) &&
                (!dropZone.id.includes("replace") ||
                  !currentlyDraggedNodeId.includes("|")) &&
                ([CollageNodeType.Download, CollageNodeType.Text].includes(
                  nodeType
                )
                  ? dropZone.id.startsWith("insert@0")
                  : true)
            )
            .sort((a, b) => {
              const distanceA = distanceBetween(
                { x, y },
                getCenterOfDropZone(a, width, scale, translate)
              );
              const distanceB = distanceBetween(
                { x, y },
                getCenterOfDropZone(b, width, scale, translate)
              );

              if (distanceA === distanceB) {
                return distanceA < 100
                  ? a.width < b.width
                    ? -1
                    : 1
                  : a.width < b.width
                  ? 1
                  : -1;
              }

              return distanceA - distanceB;
            })[0];

          setClosestDropZone(nextClosestDropZone);

          if (nextClosestDropZone && last && !canceled) {
            const [action, args] = nextClosestDropZone.id.split("@");

            switch (action) {
              case "insert": {
                const [targetId, targetParentId, index] = args
                  .split("/")
                  .map((x) => parseInt(x));
                const [, id, parentId] = (sourceArgs ?? "")
                  .split("/")
                  .map((x) => parseInt(x));

                if (sourceType === "internal") {
                  dispatch({
                    type: CollageDesignerActionType.MoveNode,
                    payload: {
                      id,
                      targetId,
                      targetParentId,
                      parentId,
                      index,
                    },
                  });
                } else {
                  switch (sourceType) {
                    case "photo": {
                      const sourcePhotos = currentlyDraggedNodeId
                        .split("|")
                        .reverse();
                      for (const source of sourcePhotos) {
                        const [, photoId] = source
                          .split("@")
                          .map((x) => parseInt(x));

                        const photo = photos.find(
                          (photo) => photo.id === photoId
                        );

                        dispatch({
                          type: CollageDesignerActionType.InsertNode,
                          payload: {
                            targetId,
                            targetParentId,
                            index,
                            node: {
                              id: 0,
                              type: CollageNodeType.Photo,
                              photoId,
                              photoUrl: (photo as Photo).url,
                              dimensions: {
                                ...(photo as Photo).dimensions,
                              },
                              photoDimensions: {
                                ...(photo as Photo).dimensions,
                              },
                              ratio: 1.5,
                            },
                          },
                        });
                      }
                      break;
                    }

                    case "spacer":
                      dispatch({
                        type: CollageDesignerActionType.InsertNode,
                        payload: {
                          targetId,
                          targetParentId,
                          index,
                          node: {
                            id: 0,
                            type: CollageNodeType.Spacer,
                            ...(targetId === 0 && {
                              dimensions: { width: 100, height: 10 },
                            }),
                          },
                        },
                      });
                      break;

                    case "video":
                      dispatch({
                        type: CollageDesignerActionType.InsertNode,
                        payload: {
                          targetId,
                          targetParentId,
                          index,
                          node: {
                            id: 0,
                            type: CollageNodeType.Video,
                            ...(targetId === 0 && {
                              dimensions: {
                                width: 100,
                                height: 100 / (16 / 9),
                              },
                            }),
                            videoType: "youtube",
                          },
                        },
                      });
                      break;

                    case "heading":
                      dispatch({
                        type: CollageDesignerActionType.InsertNode,
                        payload: {
                          targetId,
                          targetParentId,
                          index,
                          node: {
                            id: 0,
                            type: CollageNodeType.Text,
                            textContentState: convertToRaw(
                              EditorState.createEmpty().getCurrentContent()
                            ),
                            textType: TextType.Heading,
                            textVerticalAlignment: "center",
                            dimensions: { width: 1000, height: 41 + gap },
                            paddingTop: gap / 2,
                            paddingBottom: gap / 2,
                          },
                        },
                      });
                      break;

                    case "text":
                      dispatch({
                        type: CollageDesignerActionType.InsertNode,
                        payload: {
                          targetId,
                          targetParentId,
                          index,
                          node: {
                            id: 0,
                            type: CollageNodeType.Text,
                            textContentState: convertToRaw(
                              EditorState.createEmpty().getCurrentContent()
                            ),
                            textType: TextType.Text,
                            textVerticalAlignment: "center",
                            textAlignment: "left",
                            dimensions: { width: 1000, height: 35 + gap },
                            paddingTop: gap / 2,
                            paddingBottom: gap / 2,
                          },
                        },
                      });
                      break;

                    case "download":
                      dispatch({
                        type: CollageDesignerActionType.InsertNode,
                        payload: {
                          targetId,
                          targetParentId,
                          index,
                          node: {
                            id: 0,
                            type: CollageNodeType.Download,
                            ...(targetId === 0 && {
                              dimensions: {
                                width: 100,
                                height: 100 / 8,
                              },
                            }),
                            label: "Download photos",
                          },
                        },
                      });
                      break;

                    default:
                      break;
                  }
                }
                break;
              }

              case "replace": {
                const [targetId] = args.split("/").map((x) => parseInt(x));
                const [photoId, id, parentId] = (sourceArgs ?? "")
                  .split("/")
                  .map((x) => parseInt(x));

                dispatch({
                  type: CollageDesignerActionType.ReplaceNode,
                  payload: {
                    targetId,
                    photoId,
                    id,
                    parentId,
                  },
                });
                break;
              }

              default:
                break;
            }
          }
        }
      }
    },
    {
      enabled:
        !isMoveActivationKeyPressed && status === CollageDesignerStatus.Idle,
      threshold: 10,
    }
  );

  useEffect(() => {
    dispatch({
      type: CollageDesignerActionType.Change,
      payload: {
        bindDraggingListeners,
      },
    });
  }, []);

  return (
    <CollageRendererRoot
      style={{
        width: `${width}px`,
        transform: `translate(${translate.x}px, ${translate.y}px) scale(${scale})`,
        backgroundColor,
      }}
      ref={collageRootDivElement}
    >
      {visibleNodes
        .filter(([, node]) => node.type !== "internal")
        .map(([id, node]) => (
          <NodeRenderer
            key={id}
            node={node}
            rootNode={nodes[0]}
            rootDivElement={rootDivElement}
            collageRootDivElement={collageRootDivElement}
            collageWidth={width}
            collageScale={scale}
            collageGap={gap}
            collageTranslate={translate}
            dispatch={dispatch}
            bindDraggingListeners={bindDraggingListeners}
            activeNodeIds={activeNodeIds}
            activeTextNodeId={activeTextNodeId}
            status={status}
            croppingNodeId={croppingNodeId}
          />
        ))}
      {Object.keys(nodes).length === 1 && (
        <Block
          display="flex"
          paddingLeft="100px"
          paddingRight="100px"
          paddingTop="66%"
          position="relative"
        >
          <div
            className={css({
              display: "flex",
              flexDirection: "column",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%,-50%)",
              alignItems: "center",
              justifyContent: "center",
            })}
          >
            <MiniCanvas />
            <LabelSmall
              marginTop="42px"
              $style={{ fontWeight: 400 }}
              color="#8390AE"
            >
              Drag and drop photos or other components here.
            </LabelSmall>
          </div>
        </Block>
      )}
      {!!currentlyDraggedNodeId &&
        dropZones.map((dropZone) => (
          <div
            key={JSON.stringify(dropZone)}
            className={css({
              position: "absolute",
              zIndex: 1,
            })}
            style={{
              transform: `translate(${dropZone.offset.left * width}px, ${
                dropZone.offset.top * width
              }px)`,
              width:
                dropZone.direction === "horizontal" ||
                dropZone.direction === "both"
                  ? `${dropZone.width * width}px`
                  : "10px",
              height:
                dropZone.direction === "vertical" ||
                dropZone.direction === "both"
                  ? `${dropZone.height * width}px`
                  : "10px",
              ...(dropZone.direction === "vertical" && { left: "-5px" }),
              ...(dropZone.direction === "horizontal" && { top: "-5px" }),
              backgroundColor:
                JSON.stringify(dropZone) === JSON.stringify(closestDropZone)
                  ? dropZone.direction !== "both"
                    ? theme.colors.accent
                    : "rgba(255, 255, 255, 0.4)"
                  : "transparent",
              transition: "background-color 0.2s ease-in-out",
              borderRadius:
                JSON.stringify(dropZone) === JSON.stringify(closestDropZone) &&
                dropZone.direction !== "both"
                  ? "10px"
                  : 0,
              ...(JSON.stringify(dropZone) ===
                JSON.stringify(closestDropZone) &&
                dropZone.direction === "both" && {
                  border: `4px solid ${theme.colors.accent}`,
                }),
            }}
          ></div>
        ))}
      {!!currentlyDraggedNodeId &&
        createPortal(
          <animated.div
            className={css({
              position: "absolute",
              width: "100px",
              height: "80px",
              left: "-50px",
              top: "-40px",
              touchAction: "none",
              zIndex: 10,
            })}
            style={{
              x,
              y,
              scale,
            }}
          >
            <DraggablePreview id={currentlyDraggedNodeId} />
          </animated.div>,
          document.body
        )}

      {/* Use the root node to give correct height for div */}
      {nodes[0] && nodes[0].dimensions && (
        <div
          style={{
            height: `${
              (nodes[0].dimensions.height / nodes[0].dimensions.width) * width
            }px`,
          }}
        ></div>
      )}
    </CollageRendererRoot>
  );
}

const MemoizedCollageRenderer = memo(CollageRenderer);

export default MemoizedCollageRenderer;
