import DesignCollagePage from "app/collages/pages/DesignCollagePage";
import ShowCollagesPage from "app/collages/pages/ShowCollagesPage";
import { Route, Switch } from "react-router";

export default (
  <Switch>
    <Route path="/projects" exact component={ShowCollagesPage} />
    <Route path="/projects/design/:id?" component={DesignCollagePage} />
  </Switch>
);
