import { gql, useMutation } from "@apollo/client";
import { Camera, Trash } from "app/core/components/Icon";
import IconButton from "app/core/components/IconButton";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { toaster } from "baseui/toast";
import { CollageDesignerContext } from "contexts/CollageDesignerContext";
import React, { useCallback, useContext, useMemo } from "react";

import {
  CollageDesignerActionType,
  CollageNodeType,
} from "../CollageDesigner/types";
import FormControl from "../CollageDesigner/ui/FormControl";
import Panel from "../CollageDesigner/ui/Panel";

const DELETE_PHOTO = gql`
  mutation DeleteOrUnlinkPhoto($input: DeleteOrUnlinkPhotoInput!) {
    deleteOrUnlinkPhoto(input: $input)
  }
`;

export default function ActiveDraggablesPanel(): React.ReactElement {
  const [css, theme] = useStyletron();

  const {
    dispatch,
    state: {
      collageBody: { nodes },
      selectedDraggablesIds,
      photos,
      collageId,
    },
  } = useContext(CollageDesignerContext);

  const activePhotos = useMemo(
    () =>
      selectedDraggablesIds.map((id) =>
        photos.find((photo) => photo.id === Number(id.split("@")[1]))
      ),
    [selectedDraggablesIds, photos]
  );
  const [deletePhoto] = useMutation(DELETE_PHOTO);

  const deleteSelected = useCallback(async () => {
    try {
      for (const id of selectedDraggablesIds) {
        const [, photoId] = id.split("@");

        await deletePhoto({
          variables: {
            input: {
              id: +photoId,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              collageId: +collageId!,
            },
          },
        });

        for (const node of Object.values(nodes).filter(
          (node) =>
            typeof node !== "number" &&
            node.type === CollageNodeType.Photo &&
            node.photoId === +photoId
        )) {
          if (typeof node !== "number") {
            dispatch({
              type: CollageDesignerActionType.RemoveNode,
              payload: {
                targetId: node.id,
              },
            });
          }
        }

        dispatch({
          type: CollageDesignerActionType.RemovePhoto,
          payload: {
            id: +photoId,
          },
        });
      }

      if (selectedDraggablesIds.length > 0) {
        toaster.positive("Deleted successfully", {
          autoHideDuration: 4000,
        });
      }

      dispatch({
        type: CollageDesignerActionType.SetSelectedDraggablesIds,
        payload: {
          selectedDraggablesIds: [],
        },
      });
    } catch (error) {
      toaster.negative("Something went wrong.", {
        autoHideDuration: 4000,
      });
    }
  }, [selectedDraggablesIds, nodes, dispatch]);

  return (
    <Panel
      title="Selected in library"
      icon={<Camera height="16px" stroke="#AF9883" />}
    >
      <FormControl
        label="Selected image(s)"
        caption={
          activePhotos.length === 1 &&
          activePhotos.map((photo) => photo?.name).join(", ")
        }
      >
        <Block
          display="flex"
          alignItems="center"
          width="100%"
          minHeight={activePhotos.length > 1 ? "250px" : undefined}
          backgroundColor="#f3f4f6"
          justifyContent="center"
          position="relative"
        >
          {activePhotos.length > 1 ? (
            <div
              className={css({
                left: `${(activePhotos.slice(0, 3).length - 1) * 5}px`,
                top: `${(activePhotos.slice(0, 3).length - 1) * 5}px`,
                position: "relative",
                height: "100%",
                width: "100%",
              })}
            >
              {activePhotos
                .slice(0, 3)
                .reverse()
                .map((photo, index) => (
                  <div
                    key={photo?.id}
                    className={css({
                      position: "absolute",
                      transform: "translate(-50%, -50%)",
                      left: "50%",
                      top: "50%",
                      boxShadow: theme.lighting.shadow500,
                      marginLeft: `${index * -10}px`,
                      marginTop: `${index * -10}px`,
                      backgroundColor: "#f6f8f9",
                      pointerEvents: "none",
                      opacity: `${
                        1 - (activePhotos.slice(0, 3).length - index - 1) * 0.4
                      }`,
                      lineHeight: 0,
                      ...(index !== activePhotos.slice(0, 3).length - 1 && {
                        "::after": {
                          content: "' '",
                          position: "absolute",
                          left: "0px",
                          top: "0px",
                          right: "0px",
                          bottom: "0px",
                          backgroundColor: "rgba(0, 0, 0, 0.6)",
                        },
                      }),
                    })}
                  >
                    {index === activePhotos.slice(0, 3).length - 1 && (
                      <div
                        className={css({
                          position: "absolute",
                          left: "-10px",
                          top: "-10px",
                          background: theme.colors.primary,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontWeight: 500,
                          color: "white",
                          borderRadius: "50%",
                          width: "25px",
                          height: "25px",
                          boxShadow: theme.lighting.shadow500,
                        })}
                      >
                        {activePhotos.length}
                      </div>
                    )}
                    <img
                      src={`${photo?.url}?width=1500&version=${photo?.updatedAt}&format=webp`}
                      width={220}
                      height={124}
                      className={css({
                        objectFit: "contain",
                        objectPosition: "center",
                      })}
                    />
                  </div>
                ))}
            </div>
          ) : (
            <img
              src={`${activePhotos[0]?.url}?width=1500&version=${activePhotos[0]?.updatedAt}&format=webp`}
              width={"100%"}
              className={css({
                pointerEvents: "none",
              })}
            />
          )}
        </Block>
      </FormControl>
      <Block
        marginTop="scale400"
        marginBottom="scale800"
        display="flex"
        gridGap="12px"
      >
        <IconButton onClick={() => deleteSelected()}>
          <Trash />
        </IconButton>
      </Block>
    </Panel>
  );
}
