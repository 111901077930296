import * as React from "react";

export function ChevronDown(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width={12}
      height={7}
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.47.47a.75.75 0 011.06 0L6 4.94 10.47.47a.75.75 0 111.06 1.06l-5 5a.75.75 0 01-1.06 0l-5-5a.75.75 0 010-1.06z"
        fill={props.fill || "#AF9883"}
      />
    </svg>
  );
}

export function ChevronUp(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width={12}
      height={7}
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform="scale(1,-1)"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.47.47a.75.75 0 011.06 0L6 4.94 10.47.47a.75.75 0 111.06 1.06l-5 5a.75.75 0 01-1.06 0l-5-5a.75.75 0 010-1.06z"
        fill="#AF9883"
      />
    </svg>
  );
}

export function Camera(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23 19C23 19.5304 22.7893 20.0391 22.4142 20.4142C22.0391 20.7893 21.5304 21 21 21H3C2.46957 21 1.96086 20.7893 1.58579 20.4142C1.21071 20.0391 1 19.5304 1 19V8C1 7.46957 1.21071 6.96086 1.58579 6.58579C1.96086 6.21071 2.46957 6 3 6H7L9 3H15L17 6H21C21.5304 6 22.0391 6.21071 22.4142 6.58579C22.7893 6.96086 23 7.46957 23 8V19Z"
        stroke={props.stroke || "white"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 17C14.2091 17 16 15.2091 16 13C16 10.7909 14.2091 9 12 9C9.79086 9 8 10.7909 8 13C8 15.2091 9.79086 17 12 17Z"
        stroke={props.stroke || "white"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Grid(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 2H4V9H11V2Z"
        stroke={props.stroke || "white"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 2H15V9H22V2Z"
        stroke={props.stroke || "white"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 13H15V20H22V13Z"
        stroke={props.stroke || "white"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 13H4V20H11V13Z"
        stroke={props.stroke || "white"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function TrendingUp(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23 6L13.5 15.5L8.5 10.5L1 18"
        stroke={props.stroke || "white"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 6H23V12"
        stroke={props.stroke || "white"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Settings(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 5C14 3.89543 13.1046 3 12 3C10.8954 3 10 3.89543 10 5M14 5C14 6.10457 13.1046 7 12 7C10.8954 7 10 6.10457 10 5M14 5H20M10 5L4 5M16 12C16 13.1046 16.8954 14 18 14C19.1046 14 20 13.1046 20 12C20 10.8954 19.1046 10 18 10C16.8954 10 16 10.8954 16 12ZM16 12H4M8 19C8 17.8954 7.10457 17 6 17C4.89543 17 4 17.8954 4 19C4 20.1046 4.89543 21 6 21C7.10457 21 8 20.1046 8 19ZM8 19H20"
        stroke={props.stroke || "white"}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}

export function MessageCircle(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.875 11.9792C21.8786 13.3541 21.5574 14.7103 20.9375 15.9375C20.2025 17.4081 19.0727 18.645 17.6744 19.5097C16.2762 20.3744 14.6648 20.8327 13.0208 20.8334C11.646 20.8369 10.2897 20.5157 9.0625 19.8959L3.125 21.875L5.10417 15.9375C4.4843 14.7103 4.16308 13.3541 4.16667 11.9792C4.1673 10.3352 4.62564 8.72383 5.49033 7.3256C6.35503 5.92737 7.59193 4.7975 9.0625 4.06253C10.2897 3.44267 11.646 3.12145 13.0208 3.12503H13.5417C15.7129 3.24481 17.7636 4.16124 19.3012 5.69884C20.8388 7.23644 21.7552 9.28717 21.875 11.4584V11.9792Z"
        stroke={props.stroke || "white"}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function List(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 6C7 5.44772 7.44772 5 8 5H21C21.5523 5 22 5.44772 22 6C22 6.55228 21.5523 7 21 7H8C7.44772 7 7 6.55228 7 6Z"
        fill="#9199A8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 12C7 11.4477 7.44772 11 8 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H8C7.44772 13 7 12.5523 7 12Z"
        fill="#9199A8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 18C7 17.4477 7.44772 17 8 17H21C21.5523 17 22 17.4477 22 18C22 18.5523 21.5523 19 21 19H8C7.44772 19 7 18.5523 7 18Z"
        fill="#9199A8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 6C2 5.44772 2.44772 5 3 5H3.01C3.56228 5 4.01 5.44772 4.01 6C4.01 6.55228 3.56228 7 3.01 7H3C2.44772 7 2 6.55228 2 6Z"
        fill="#9199A8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12C2 11.4477 2.44772 11 3 11H3.01C3.56228 11 4.01 11.4477 4.01 12C4.01 12.5523 3.56228 13 3.01 13H3C2.44772 13 2 12.5523 2 12Z"
        fill="#9199A8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 18C2 17.4477 2.44772 17 3 17H3.01C3.56228 17 4.01 17.4477 4.01 18C4.01 18.5523 3.56228 19 3.01 19H3C2.44772 19 2 18.5523 2 18Z"
        fill="#9199A8"
      />
    </svg>
  );
}

export function Placeholder(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="21" height="21" rx="7" fill="#E4E8EB" />
      <path
        d="M4.41328 12.7002H4.07695V8.71895H4.41328V12.7002ZM8.87523 11.4588C8.82966 11.8781 8.67927 12.1989 8.42406 12.4213C8.17068 12.6437 7.83253 12.7549 7.40961 12.7549C7.1143 12.7549 6.85271 12.6811 6.62484 12.5334C6.3988 12.3857 6.2238 12.177 6.09984 11.9072C5.97589 11.6356 5.91299 11.3257 5.91117 10.9775V10.4607C5.91117 10.1071 5.97315 9.79355 6.09711 9.52012C6.22107 9.24668 6.3988 9.03613 6.63031 8.88848C6.86365 8.739 7.13161 8.66426 7.43422 8.66426C7.86078 8.66426 8.19711 8.78001 8.4432 9.01152C8.69112 9.24121 8.83513 9.55931 8.87523 9.96582H8.53617C8.45232 9.28952 8.085 8.95137 7.43422 8.95137C7.07328 8.95137 6.78526 9.08626 6.57016 9.35605C6.35687 9.62585 6.25023 9.99863 6.25023 10.4744V10.9611C6.25023 11.4205 6.35414 11.7869 6.56195 12.0604C6.77159 12.3338 7.05414 12.4705 7.40961 12.4705C7.76143 12.4705 8.02667 12.3867 8.20531 12.2189C8.38396 12.0494 8.49424 11.796 8.53617 11.4588H8.87523ZM13.195 10.9256C13.195 11.292 13.1312 11.6137 13.0036 11.8908C12.8778 12.1679 12.6973 12.3812 12.4622 12.5307C12.227 12.6801 11.9554 12.7549 11.6473 12.7549C11.1825 12.7549 10.807 12.589 10.5208 12.2572C10.2346 11.9236 10.0915 11.4743 10.0915 10.9092V10.499C10.0915 10.1363 10.1553 9.81543 10.2829 9.53652C10.4123 9.25579 10.5946 9.04069 10.8298 8.89121C11.0649 8.73991 11.3356 8.66426 11.6419 8.66426C11.9481 8.66426 12.2179 8.73809 12.4512 8.88574C12.6864 9.0334 12.8678 9.24212 12.9954 9.51191C13.123 9.78171 13.1895 10.0943 13.195 10.4498V10.9256ZM12.8587 10.4936C12.8587 10.0141 12.7502 9.63861 12.5333 9.36699C12.3164 9.09538 12.0192 8.95957 11.6419 8.95957C11.2718 8.95957 10.9765 9.09629 10.7559 9.36973C10.5372 9.64134 10.4278 10.0214 10.4278 10.51V10.9256C10.4278 11.3977 10.5372 11.7723 10.7559 12.0494C10.9747 12.3247 11.2718 12.4623 11.6473 12.4623C12.0283 12.4623 12.3255 12.3265 12.5387 12.0549C12.752 11.7814 12.8587 11.4014 12.8587 10.9146V10.4936ZM17.5667 12.7002H17.2304L14.9417 9.29316V12.7002H14.6027V8.71895H14.9417L17.2331 12.1287V8.71895H17.5667V12.7002Z"
        fill="#192637"
      />
    </svg>
  );
}

export function Info(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 1.875C3.72183 1.875 1.875 3.72183 1.875 6C1.875 8.27817 3.72183 10.125 6 10.125C8.27817 10.125 10.125 8.27817 10.125 6C10.125 3.72183 8.27817 1.875 6 1.875ZM1.125 6C1.125 3.30761 3.30761 1.125 6 1.125C8.69239 1.125 10.875 3.30761 10.875 6C10.875 8.69239 8.69239 10.875 6 10.875C3.30761 10.875 1.125 8.69239 1.125 6ZM6 3.12498C6.20711 3.12498 6.375 3.29288 6.375 3.49998V3.50498C6.375 3.71209 6.20711 3.87998 6 3.87998C5.79289 3.87998 5.625 3.71209 5.625 3.50498V3.49998C5.625 3.29288 5.79289 3.12498 6 3.12498ZM6 4.625C6.20711 4.625 6.375 4.79289 6.375 5L6.375 8.5C6.375 8.70711 6.20711 8.875 6 8.875C5.79289 8.875 5.625 8.70711 5.625 8.5L5.625 5C5.625 4.79289 5.79289 4.625 6 4.625Z"
        fill="#8390AE"
      />
    </svg>
  );
}

export function Trash(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path
          d="M2.59155 3.64258H3.69491H12.5218"
          stroke="white"
          strokeWidth="1.32403"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.35011 3.64275V2.53939C5.35011 2.24677 5.46635 1.96612 5.67327 1.7592C5.88019 1.55228 6.16084 1.43604 6.45347 1.43604H8.66018C8.95281 1.43604 9.23346 1.55228 9.44038 1.7592C9.6473 1.96612 9.76354 2.24677 9.76354 2.53939V3.64275M11.4186 3.64275V11.3663C11.4186 11.6589 11.3023 11.9395 11.0954 12.1465C10.8885 12.3534 10.6079 12.4696 10.3152 12.4696H4.79843C4.5058 12.4696 4.22515 12.3534 4.01823 12.1465C3.81131 11.9395 3.69507 11.6589 3.69507 11.3663V3.64275H11.4186Z"
          stroke="white"
          strokeWidth="1.32403"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export function Repeat(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path
          d="M9.91455 0.888672L12.1213 3.09539L9.91455 5.30211"
          stroke="white"
          strokeWidth="1.32403"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.19092 6.40529V5.30193C2.19092 4.71667 2.42341 4.15539 2.83725 3.74155C3.25109 3.32771 3.81238 3.09521 4.39764 3.09521H12.1211"
          stroke="white"
          strokeWidth="1.32403"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.39764 13.0257L2.19092 10.819L4.39764 8.6123"
          stroke="white"
          strokeWidth="1.32403"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.1211 7.50879V8.61215C12.1211 9.19741 11.8887 9.75869 11.4748 10.1725C11.061 10.5864 10.4997 10.8189 9.91443 10.8189H2.19092"
          stroke="white"
          strokeWidth="1.32403"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export function ChevronLeft(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.18188 13L3.18188 9L7.18188 5"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ChevronRight(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="10"
      height="14"
      viewBox="0 0 10 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform="scale(-1,1)"
      {...props}
    >
      <path
        d="M7.18188 13L3.18188 9L7.18188 5"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Undo(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3 9V15M3 15H9M3 15L5.64028 12.6307C7.02129 11.2521 8.81296 10.3596 10.7453 10.0878C12.6777 9.81593 14.6461 10.1794 16.3539 11.1234C18.0617 12.0675 19.4164 13.5409 20.2139 15.3218"
        stroke="#8390AE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Redo(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21 9V15M21 15H15M21 15L18.3597 12.6307C16.9787 11.2521 15.1871 10.3596 13.2547 10.0878C11.3223 9.81593 9.35395 10.1794 7.64613 11.1234C5.93832 12.0675 4.58359 13.5409 3.78607 15.3218"
        stroke="#8390AE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function LineHeight(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.6514 14.4023H10.4814L9.54492 17H8.19141L11.9922 7.04688H13.1406L16.9482 17H15.6016L14.6514 14.4023ZM10.8779 13.3223H14.2617L12.5664 8.66699L10.8779 13.3223Z"
        fill="#9199A8"
      />
      <rect
        x="21.333"
        y="3.99951"
        width="18.6667"
        height="1.33333"
        transform="rotate(-180 21.333 3.99951)"
        fill="#9199A8"
      />
      <rect
        x="21.333"
        y="21.333"
        width="18.6667"
        height="1.33333"
        transform="rotate(-180 21.333 21.333)"
        fill="#9199A8"
      />
    </svg>
  );
}

export function LetterSpacing(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.6514 14.4023H10.4814L9.54492 17H8.19141L11.9922 7.04688H13.1406L16.9482 17H15.6016L14.6514 14.4023ZM10.8779 13.3223H14.2617L12.5664 8.66699L10.8779 13.3223Z"
        fill="#9199A8"
      />
      <rect
        x="4"
        y="2.66602"
        width="18.6667"
        height="1.33333"
        transform="rotate(90 4 2.66602)"
        fill="#9199A8"
      />
      <rect
        x="21.334"
        y="2.66602"
        width="18.6667"
        height="1.33333"
        transform="rotate(90 21.334 2.66602)"
        fill="#9199A8"
      />
    </svg>
  );
}

export function Pencil(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4676 1.83038C14.2806 1.83038 14.0955 1.86937 13.9227 1.94515C13.7499 2.02092 13.593 2.13198 13.4607 2.27198L3.09318 13.2494L2.338 16.1813L5.10699 15.3817L15.4745 4.40425C15.6068 4.26425 15.7117 4.09803 15.7832 3.91511C15.8548 3.73218 15.8916 3.53612 15.8916 3.33812C15.8916 3.14012 15.8548 2.94406 15.7832 2.76113C15.7117 2.5782 15.6068 2.41199 15.4745 2.27198C15.3423 2.13198 15.1853 2.02092 15.0126 1.94515C14.8398 1.86937 14.6546 1.83038 14.4676 1.83038ZM13.3266 0.421462C13.6884 0.262809 14.0761 0.181152 14.4676 0.181152C14.8592 0.181152 15.2469 0.26281 15.6086 0.421462C15.9704 0.580114 16.2991 0.812654 16.5759 1.10581C16.8528 1.39896 17.0724 1.74698 17.2223 2.13C17.3721 2.51302 17.4492 2.92354 17.4492 3.33812C17.4492 3.75269 17.3721 4.16321 17.2223 4.54624C17.0724 4.92926 16.8528 5.27728 16.5759 5.57043L6.06213 16.7027C5.9663 16.8042 5.84711 16.8774 5.71635 16.9152L1.43295 18.1521C1.16332 18.2299 0.874963 18.1488 0.677342 17.9396C0.479721 17.7304 0.403143 17.425 0.476679 17.1395L1.64488 12.6042C1.68054 12.4657 1.74971 12.3395 1.84554 12.2381L12.3593 1.10581C12.6362 0.812654 12.9649 0.580114 13.3266 0.421462Z"
        fill="#AF9883"
      />
    </svg>
  );
}

export function Heading(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="1" y="7" width="14" height="1" fill="#535A68" />
      <rect x="1" y="10" width="9" height="1" fill="#535A68" />
      <rect x="1" y="13" width="9" height="1" fill="#535A68" />
      <rect x="1.5" y="1.5" width="13" height="3" stroke="#535A68" />
    </svg>
  );
}

export function Text(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.671 6.95221H12.3117C12.5382 6.95221 12.7218 6.76857 12.7218 6.54206V3.59766C12.7218 3.37114 12.5382 3.1875 12.3117 3.1875H3.68848C3.46196 3.1875 3.27832 3.37114 3.27832 3.59766V6.54206C3.27832 6.76857 3.46196 6.95221 3.68848 6.95221H5.3291C5.55562 6.95221 5.73926 6.76857 5.73926 6.54206V5.64844H6.7696V11.4453H6.11772C5.89121 11.4453 5.70757 11.629 5.70757 11.8555V13.4961C5.70757 13.7226 5.89121 13.9062 6.11772 13.9062H9.88241C10.1089 13.9062 10.2926 13.7226 10.2926 13.4961V11.8555C10.2926 11.629 10.1089 11.4453 9.88241 11.4453H9.23054V5.64844H10.2609V6.54206C10.2609 6.76857 10.4445 6.95221 10.671 6.95221ZM8.82038 4.82812C8.59386 4.82812 8.41022 5.01177 8.41022 5.23828V11.8555C8.41022 12.082 8.59386 12.2656 8.82038 12.2656H9.47226V13.0859H6.52788V12.2656H7.17975C7.40627 12.2656 7.58991 12.082 7.58991 11.8555V5.23828C7.58991 5.01177 7.40627 4.82812 7.17975 4.82812H5.3291C5.10259 4.82812 4.91895 5.01177 4.91895 5.23828V6.1319H4.09863V4.00781H11.9015V6.1319H11.0812V5.23828C11.0812 5.01177 10.8975 4.82812 10.671 4.82812H8.82038Z"
        fill="#535A68"
      />
      <path
        d="M1.41016 4.12361C1.63667 4.12361 1.82031 3.93997 1.82031 3.71346V1.82031H3.71346C3.93997 1.82031 4.12361 1.63667 4.12361 1.41016C4.12361 1.18364 3.93997 1 3.71346 1H1.41016C1.18364 1 1 1.18364 1 1.41016V3.71346C1 3.94 1.18364 4.12361 1.41016 4.12361Z"
        fill="#535A68"
      />
      <path
        d="M14.5899 1H12.2866C12.0601 1 11.8765 1.18364 11.8765 1.41016C11.8765 1.63667 12.0601 1.82031 12.2866 1.82031H14.1798V3.71346C14.1798 3.93997 14.3634 4.12361 14.5899 4.12361C14.8164 4.12361 15.0001 3.93997 15.0001 3.71346V1.41016C15.0001 1.18364 14.8164 1 14.5899 1Z"
        fill="#535A68"
      />
      <path
        d="M14.5899 11.8765C14.3634 11.8765 14.1798 12.0601 14.1798 12.2866V14.1798H12.2866C12.0601 14.1798 11.8765 14.3634 11.8765 14.5899C11.8765 14.8164 12.0601 15.0001 12.2866 15.0001H14.5899C14.8164 15.0001 15.0001 14.8164 15.0001 14.5899V12.2866C15.0001 12.0601 14.8164 11.8765 14.5899 11.8765Z"
        fill="#535A68"
      />
      <path
        d="M1.41016 15.0001H3.71346C3.93997 15.0001 4.12361 14.8164 4.12361 14.5899C4.12361 14.3634 3.93997 14.1798 3.71346 14.1798H1.82031V12.2866C1.82031 12.0601 1.63667 11.8765 1.41016 11.8765C1.18364 11.8765 1 12.0601 1 12.2866V14.5899C1 14.8164 1.18364 15.0001 1.41016 15.0001Z"
        fill="#535A68"
      />
    </svg>
  );
}

export function Video(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.692 2H3.308C2.58561 2 2 2.58561 2 3.308V12.692C2 13.4144 2.58561 14 3.308 14H12.692C13.4144 14 14 13.4144 14 12.692V3.308C14 2.58561 13.4144 2 12.692 2Z"
        stroke="#535A68"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 2V14"
        stroke="#535A68"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 2V14"
        stroke="#535A68"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 8H14"
        stroke="#535A68"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 5H5"
        stroke="#535A68"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 11H5"
        stroke="#535A68"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 11H14"
        stroke="#535A68"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 5H14"
        stroke="#535A68"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Spacer(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.89645 6.85355C10.0917 7.04882 10.4083 7.04882 10.6036 6.85355C10.7988 6.65829 10.7988 6.34171 10.6036 6.14645L9.89645 6.85355ZM8 4.25L8.35355 3.89645C8.15829 3.70118 7.84171 3.70118 7.64645 3.89645L8 4.25ZM5.39645 6.14645C5.20118 6.34171 5.20118 6.65829 5.39645 6.85355C5.59171 7.04882 5.90829 7.04882 6.10355 6.85355L5.39645 6.14645ZM6.10355 9.14645C5.90829 8.95118 5.59171 8.95118 5.39645 9.14645C5.20118 9.34171 5.20118 9.65829 5.39645 9.85355L6.10355 9.14645ZM8 11.75L7.64645 12.1036C7.84171 12.2988 8.15829 12.2988 8.35355 12.1036L8 11.75ZM10.6036 9.85355C10.7988 9.65829 10.7988 9.34171 10.6036 9.14645C10.4083 8.95118 10.0917 8.95118 9.89645 9.14645L10.6036 9.85355ZM2 2V1.5C1.72386 1.5 1.5 1.72386 1.5 2H2ZM14 2H14.5C14.5 1.72386 14.2761 1.5 14 1.5V2ZM14 14V14.5C14.2761 14.5 14.5 14.2761 14.5 14H14ZM2 14H1.5C1.5 14.2761 1.72386 14.5 2 14.5V14ZM10.6036 6.14645L8.35355 3.89645L7.64645 4.60355L9.89645 6.85355L10.6036 6.14645ZM7.64645 3.89645L5.39645 6.14645L6.10355 6.85355L8.35355 4.60355L7.64645 3.89645ZM5.39645 9.85355L7.64645 12.1036L8.35355 11.3964L6.10355 9.14645L5.39645 9.85355ZM8.35355 12.1036L10.6036 9.85355L9.89645 9.14645L7.64645 11.3964L8.35355 12.1036ZM2 2.5H14V1.5H2V2.5ZM13.5 2V14H14.5V2H13.5ZM14 13.5H2V14.5H14V13.5ZM2.5 14V2H1.5V14H2.5Z"
        fill="#535A68"
      />
    </svg>
  );
}

export function Mirror(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.66 1.33617C0.66 1.10921 0.878118 0.945969 1.09586 1.00996L4.24475 1.93535C4.38949 1.97788 4.48889 2.1107 4.48889 2.26155V10.9784C4.48889 11.1293 4.38949 11.2621 4.24475 11.3047L1.09586 12.23C0.878118 12.294 0.66 12.1308 0.66 11.9038V1.33617Z"
        stroke="white"
        strokeWidth="1.32"
      />
      <path
        d="M14.0511 1.33617C14.0511 1.10921 13.8329 0.945969 13.6152 1.00996L10.4663 1.93535C10.3216 1.97788 10.2222 2.1107 10.2222 2.26155V10.9784C10.2222 11.1293 10.3216 11.2621 10.4663 11.3047L13.6152 12.23C13.8329 12.294 14.0511 12.1308 14.0511 11.9038V1.33617Z"
        stroke="white"
        strokeWidth="1.32"
      />
      <path
        d="M7.35559 0.735352V2.94202"
        stroke="white"
        strokeWidth="1.32"
        strokeLinecap="round"
      />
      <path
        d="M7.35559 5.88428V8.09094"
        stroke="white"
        strokeWidth="1.32"
        strokeLinecap="round"
      />
      <path
        d="M7.35559 11.0332V13.2399"
        stroke="white"
        strokeWidth="1.32"
        strokeLinecap="round"
      />
    </svg>
  );
}

export function MiniCanvas(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="107"
      height="61"
      viewBox="0 0 107 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect opacity="0.4" y="5" width="14" height="16" rx="2" fill="#9199A8" />
      <rect opacity="0.4" y="23" width="31" height="16" rx="2" fill="#9199A8" />
      <rect
        opacity="0.4"
        x="17.5"
        y="5.5"
        width="13"
        height="15"
        rx="1.5"
        stroke="#AF9883"
        strokeDasharray="3 3"
      />
      <rect opacity="0.4" y="42" width="9" height="15" rx="2" fill="#9199A8" />
      <rect
        opacity="0.4"
        x="11"
        y="42"
        width="20"
        height="15"
        rx="2"
        fill="#9199A8"
      />
      <rect
        opacity="0.4"
        x="53.5"
        y="0.5"
        width="53"
        height="60"
        rx="4.5"
        stroke="#9199A8"
      />
      <rect x="59" y="5" width="25" height="29" rx="2" fill="#CABBAE" />
      <path
        d="M82 33L86.1242 42.8992L87.5883 38.5883L91.8992 37.1242L82 33Z"
        stroke="#AF9883"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M87.833 38.8335L91.333 42.3335"
        stroke="#AF9883"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function MiniCanvas2(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="54"
      height="61"
      viewBox="0 0 54 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        opacity="0.4"
        x="0.5"
        y="0.5"
        width="53"
        height="60"
        rx="4.5"
        stroke="#9199A8"
      />
      <rect x="6" y="5" width="25" height="29" rx="2" fill="#CABBAE" />
      <path
        d="M29 33L33.1242 42.8992L34.5883 38.5883L38.8992 37.1242L29 33Z"
        stroke="#AF9883"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.8333 38.8335L38.3333 42.3335"
        stroke="#AF9883"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Close(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      cursor="pointer"
      {...props}
    >
      <rect
        x="2.34326"
        y="0.928955"
        width="18"
        height="1"
        transform="rotate(45 2.34326 0.928955)"
        fill={props.fill || "#AF9883"}
        stroke={props.fill || "#AF9883"}
      />
      <rect
        x="15.0713"
        y="2.34326"
        width="18"
        height="1"
        transform="rotate(135 15.0713 2.34326)"
        fill={props.fill || "#AF9883"}
        stroke={props.fill || "#AF9883"}
      />
    </svg>
  );
}

export function Move(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.27537 7.1123L2.14746 9.24021L4.27537 11.3681"
        stroke="white"
        strokeWidth="1.32403"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.11255 4.27488L9.24046 2.14697L11.3684 4.27488"
        stroke="white"
        strokeWidth="1.32403"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3684 14.2051L9.24046 16.333L7.11255 14.2051"
        stroke="white"
        strokeWidth="1.32403"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2054 7.1123L16.3334 9.24021L14.2054 11.3681"
        stroke="white"
        strokeWidth="1.32403"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.14746 9.24023H16.3335"
        stroke="white"
        strokeWidth="1.32403"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.24036 2.14697V16.333"
        stroke="white"
        strokeWidth="1.32403"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Picture(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#A48D79"
      {...props}
    >
      <path
        d="M2.75 14.75H13.25C14.0784 14.75 14.75 14.0784 14.75 13.25V2.75C14.75 1.92157 14.0784 1.25 13.25 1.25H2.75C1.92157 1.25 1.25 1.92157 1.25 2.75V13.25C1.25 14.0784 1.92157 14.75 2.75 14.75ZM2.75 14.75L11 6.5L14.75 10.25M6.5 5.375C6.5 5.99632 5.99632 6.5 5.375 6.5C4.75368 6.5 4.25 5.99632 4.25 5.375C4.25 4.75368 4.75368 4.25 5.375 4.25C5.99632 4.25 6.5 4.75368 6.5 5.375Z"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function User(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#9199A8"
      {...props}
    >
      <path
        d="M15 15.75V14.25C15 13.4544 14.6839 12.6913 14.1213 12.1287C13.5587 11.5661 12.7956 11.25 12 11.25H6C5.20435 11.25 4.44129 11.5661 3.87868 12.1287C3.31607 12.6913 3 13.4544 3 14.25V15.75"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 8.25C10.6569 8.25 12 6.90685 12 5.25C12 3.59315 10.6569 2.25 9 2.25C7.34315 2.25 6 3.59315 6 5.25C6 6.90685 7.34315 8.25 9 8.25Z"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function DollarSign(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 2 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#9199A8"
      {...props}
    >
      <path
        d="M9 2.75V19.25"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.75 5.75H7.125C6.42881 5.75 5.76113 6.02656 5.26884 6.51884C4.77656 7.01113 4.5 7.67881 4.5 8.375C4.5 9.07119 4.77656 9.73887 5.26884 10.2312C5.76113 10.7234 6.42881 11 7.125 11H10.875C11.5712 11 12.2389 11.2766 12.7312 11.7688C13.2234 12.2611 13.5 12.9288 13.5 13.625C13.5 14.3212 13.2234 14.9889 12.7312 15.4812C12.2389 15.9734 11.5712 16.25 10.875 16.25H4.5"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Help(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#9199A8"
      {...props}
    >
      <path
        d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.81738 6.74994C6.99371 6.24869 7.34175 5.82602 7.79985 5.55679C8.25795 5.28756 8.79655 5.18914 9.32026 5.27897C9.84397 5.3688 10.319 5.64108 10.6612 6.04758C11.0034 6.45409 11.1907 6.96858 11.1899 7.49994C11.1899 8.99994 8.93988 9.74994 8.93988 9.74994"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 12.75H9.0075"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Logout(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#9199A8"
      {...props}
    >
      <path
        d="M6.75 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V3.75C2.25 3.35218 2.40804 2.97064 2.68934 2.68934C2.97064 2.40804 3.35218 2.25 3.75 2.25H6.75"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 12.75L15.75 9L12 5.25"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 9H6.75"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function PlusCircle(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      strokeWidth="2"
      {...props}
    >
      <path
        d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85787 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85787 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9 6V12" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6 9H12" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export function Check(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      strokeWidth="1.6"
      stroke="currentColor"
      {...props}
    >
      <path
        d="M10 3L4.5 8.5L2 6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function X(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      strokeWidth={1.6}
      stroke="currentColor"
      {...props}
    >
      <path d="M9 3L3 9" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3 3L9 9" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export function Menu(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      strokeWidth="2"
      stroke="currentColor"
      {...props}
    >
      <path d="M3 12H21" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3 6H21" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3 18H21" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export function Search(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      strokeWidth="2"
      stroke="currentColor"
      {...props}
    >
      <path
        d="M8.25 14.25C11.5637 14.25 14.25 11.5637 14.25 8.25C14.25 4.93629 11.5637 2.25 8.25 2.25C4.93629 2.25 2.25 4.93629 2.25 8.25C2.25 11.5637 4.93629 14.25 8.25 14.25Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 15.75L12.4875 12.4875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ArrowDown(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      strokeWidth="2"
      stroke="currentColor"
      {...props}
    >
      <path d="M12 5V19" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M19 12L12 19L5 12"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ArrowUp(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      strokeWidth="2"
      stroke="currentColor"
      style={{ transform: "rotate(180deg)" }}
      {...props}
    >
      <path d="M12 5V19" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M19 12L12 19L5 12"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Plus(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7 2.9165V11.0832"
        stroke="#8390AE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.91669 7H11.0834"
        stroke="#8390AE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Minus(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="10"
      height="2"
      viewBox="0 0 10 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.916687 1H9.08336"
        stroke="#8390AE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Download(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.4"
      {...props}
    >
      <path
        d="M10.5 7.5V9.5C10.5 9.76522 10.3946 10.0196 10.2071 10.2071C10.0196 10.3946 9.76522 10.5 9.5 10.5H2.5C2.23478 10.5 1.98043 10.3946 1.79289 10.2071C1.60536 10.0196 1.5 9.76522 1.5 9.5V7.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 5L6 7.5L8.5 5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M6 7.5V1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export function CheckThick(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="11"
      height="9"
      viewBox="0 0 11 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.5 0.75L3.625 7.625L0.5 4.5"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Star(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="#A48D79"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7.5 0L9.8175 4.695L15 5.4525L11.25 9.105L12.135 14.265L7.5 11.8275L2.865 14.265L3.75 9.105L0 5.4525L5.1825 4.695L7.5 0Z" />
    </svg>
  );
}

export function Briefcase(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="23"
      height="20"
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.4734 5H3.47339C2.36882 5 1.47339 5.89543 1.47339 7V17C1.47339 18.1046 2.36882 19 3.47339 19H19.4734C20.578 19 21.4734 18.1046 21.4734 17V7C21.4734 5.89543 20.578 5 19.4734 5Z"
        stroke="#A48D79"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4734 19V3C15.4734 2.46957 15.2627 1.96086 14.8876 1.58579C14.5125 1.21071 14.0038 1 13.4734 1H9.47339C8.94296 1 8.43425 1.21071 8.05918 1.58579C7.6841 1.96086 7.47339 2.46957 7.47339 3V19"
        stroke="#A48D79"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function AlignCenter(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="3.5" y="7" width="21.7778" height="1.55556" fill="#3C3C3C" />
      <rect
        x="6.61133"
        y="19.4443"
        width="15.5556"
        height="1.55556"
        fill="#3C3C3C"
      />
      <rect
        x="8.16699"
        y="13.2222"
        width="12.4444"
        height="1.55556"
        fill="#3C3C3C"
      />
    </svg>
  );
}

export function AlignLeft(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="3.5" y="7" width="21.7778" height="1.55556" fill="#3C3C3C" />
      <rect
        x="3.5"
        y="19.4443"
        width="15.5556"
        height="1.55556"
        fill="#3C3C3C"
      />
      <rect
        x="3.5"
        y="13.2222"
        width="12.4444"
        height="1.55556"
        fill="#3C3C3C"
      />
    </svg>
  );
}

export function AlignRight(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="3.5" y="7" width="21.7778" height="1.55556" fill="#3C3C3C" />
      <rect
        x="9.72168"
        y="19.4443"
        width="15.5556"
        height="1.55556"
        fill="#3C3C3C"
      />
      <rect
        x="12.833"
        y="13.2222"
        width="12.4444"
        height="1.55556"
        fill="#3C3C3C"
      />
    </svg>
  );
}

export function AlignJustify(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="3.5" y="7" width="21.7778" height="1.55556" fill="#3C3C3C" />
      <rect
        x="3.5"
        y="19.4443"
        width="21.7778"
        height="1.55556"
        fill="#3C3C3C"
      />
      <rect
        x="3.5"
        y="13.2222"
        width="21.7778"
        height="1.55556"
        fill="#3C3C3C"
      />
    </svg>
  );
}

export function VerticalAlignTop(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 22.4893L12 8.48926"
        stroke="#3C3C3C"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 3L3 3"
        stroke="#3C3C3C"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M5 15.4897L12 8.48975L19 15.4897"
        stroke="#3C3C3C"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function VerticalAlignMiddle(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 2.4895V8.4895"
        stroke="#3C3C3C"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 22.4895L12 16.4895"
        stroke="#3C3C3C"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 12.4895H21"
        stroke="#3C3C3C"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M15 5.4895L12 8.4895L9 5.4895"
        stroke="#3C3C3C"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 19.4895L12 16.4895L15 19.4895"
        stroke="#3C3C3C"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function VerticalAlignBottom(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform="rotate(180)"
      {...props}
    >
      <path
        d="M12 22.4893L12 8.48926"
        stroke="#3C3C3C"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 3L3 3"
        stroke="#3C3C3C"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
      <path
        d="M5 15.4897L12 8.48975L19 15.4897"
        stroke="#3C3C3C"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function GoogleIcon(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="16"
      height="16"
      viewBox="14 14 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <rect id="path-2" x="0" y="0" width="40" height="40" rx="2"></rect>
      </defs>
      <g
        id="Google-Button"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="9-PATCH" transform="translate(-608.000000, -160.000000)"></g>
        <g
          id="btn_google_light_normal"
          transform="translate(-1.000000, -1.000000)"
        >
          <g
            id="button"
            transform="translate(4.000000, 4.000000)"
            filter="url(#filter-1)"
          >
            <g id="button-bg">
              <use fill="#FFFFFF" fillRule="evenodd" xlinkHref="#path-2"></use>
              <use fill="none" xlinkHref="#path-2"></use>
              <use fill="none" xlinkHref="#path-2"></use>
              <use fill="none" xlinkHref="#path-2"></use>
            </g>
          </g>
          <g id="logo_googleg_48dp" transform="translate(15.000000, 15.000000)">
            <path
              d="M17.64,9.20454545 C17.64,8.56636364 17.5827273,7.95272727 17.4763636,7.36363636 L9,7.36363636 L9,10.845 L13.8436364,10.845 C13.635,11.97 13.0009091,12.9231818 12.0477273,13.5613636 L12.0477273,15.8195455 L14.9563636,15.8195455 C16.6581818,14.2527273 17.64,11.9454545 17.64,9.20454545 L17.64,9.20454545 Z"
              id="Shape"
              fill="#4285F4"
            ></path>
            <path
              d="M9,18 C11.43,18 13.4672727,17.1940909 14.9563636,15.8195455 L12.0477273,13.5613636 C11.2418182,14.1013636 10.2109091,14.4204545 9,14.4204545 C6.65590909,14.4204545 4.67181818,12.8372727 3.96409091,10.71 L0.957272727,10.71 L0.957272727,13.0418182 C2.43818182,15.9831818 5.48181818,18 9,18 L9,18 Z"
              id="Shape"
              fill="#34A853"
            ></path>
            <path
              d="M3.96409091,10.71 C3.78409091,10.17 3.68181818,9.59318182 3.68181818,9 C3.68181818,8.40681818 3.78409091,7.83 3.96409091,7.29 L3.96409091,4.95818182 L0.957272727,4.95818182 C0.347727273,6.17318182 0,7.54772727 0,9 C0,10.4522727 0.347727273,11.8268182 0.957272727,13.0418182 L3.96409091,10.71 L3.96409091,10.71 Z"
              id="Shape"
              fill="#FBBC05"
            ></path>
            <path
              d="M9,3.57954545 C10.3213636,3.57954545 11.5077273,4.03363636 12.4404545,4.92545455 L15.0218182,2.34409091 C13.4631818,0.891818182 11.4259091,0 9,0 C5.48181818,0 2.43818182,2.01681818 0.957272727,4.95818182 L3.96409091,7.29 C4.67181818,5.16272727 6.65590909,3.57954545 9,3.57954545 L9,3.57954545 Z"
              id="Shape"
              fill="#EA4335"
            ></path>
            <path d="M0,0 L18,0 L18,18 L0,18 L0,0 Z" id="Shape"></path>
          </g>
          <g id="handles_square"></g>
        </g>
      </g>
    </svg>
  );
}

export function Bold(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 18V6H12.2033C13.6593 6 14.7637 6.28022 15.5165 6.84066C16.2692 7.3956 16.6456 8.21154 16.6456 9.28846C16.6456 9.87637 16.4945 10.3956 16.1923 10.8462C15.8901 11.2912 15.4698 11.6181 14.9313 11.8269C15.5467 11.9808 16.0302 12.2912 16.3819 12.7582C16.739 13.2253 16.9176 13.7967 16.9176 14.4725C16.9176 15.6264 16.5495 16.5 15.8132 17.0934C15.0769 17.6868 14.0275 17.989 12.6648 18H8ZM10.4725 12.7747V16.0137H12.5907C13.1731 16.0137 13.6264 15.8764 13.9505 15.6016C14.2802 15.3214 14.4451 14.9368 14.4451 14.4478C14.4451 13.3489 13.8764 12.7912 12.739 12.7747H10.4725ZM10.4725 11.0275H12.3022C13.5495 11.0055 14.1731 10.5082 14.1731 9.53571C14.1731 8.99176 14.0137 8.60165 13.6951 8.36538C13.3819 8.12363 12.8846 8.00275 12.2033 8.00275H10.4725V11.0275Z"
        fill="black"
      />
    </svg>
  );
}

export function Italic(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.6632 18.95H10L12.2502 6H13.9135L11.6632 18.95Z"
        fill="black"
      />
    </svg>
  );
}

export function Underline(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.1993 4V12.4567C16.1936 13.6298 15.8235 14.5894 15.0888 15.3354C14.3599 16.0814 13.369 16.5 12.1162 16.5911L11.6805 16.6082C10.3195 16.6082 9.23462 16.2409 8.42597 15.5063C7.61731 14.7716 7.20729 13.7608 7.1959 12.4738V4H8.81891V12.4226C8.81891 13.3223 9.06663 14.0228 9.56207 14.5239C10.0575 15.0194 10.7637 15.2671 11.6805 15.2671C12.6088 15.2671 13.3178 15.0194 13.8075 14.5239C14.303 14.0285 14.5507 13.3309 14.5507 12.4311V4H16.1993Z"
        fill="black"
      />
      <path d="M6 18.1458H17.344V19H6V18.1458Z" fill="black" />
    </svg>
  );
}

export function UnorderedList(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 6C7 5.44772 7.44772 5 8 5H21C21.5523 5 22 5.44772 22 6C22 6.55228 21.5523 7 21 7H8C7.44772 7 7 6.55228 7 6Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 12C7 11.4477 7.44772 11 8 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H8C7.44772 13 7 12.5523 7 12Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 18C7 17.4477 7.44772 17 8 17H21C21.5523 17 22 17.4477 22 18C22 18.5523 21.5523 19 21 19H8C7.44772 19 7 18.5523 7 18Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 6C2 5.44772 2.44772 5 3 5H3.01C3.56228 5 4.01 5.44772 4.01 6C4.01 6.55228 3.56228 7 3.01 7H3C2.44772 7 2 6.55228 2 6Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12C2 11.4477 2.44772 11 3 11H3.01C3.56228 11 4.01 11.4477 4.01 12C4.01 12.5523 3.56228 13 3.01 13H3C2.44772 13 2 12.5523 2 12Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 18C2 17.4477 2.44772 17 3 17H3.01C3.56228 17 4.01 17.4477 4.01 18C4.01 18.5523 3.56228 19 3.01 19H3C2.44772 19 2 18.5523 2 18Z"
        fill="black"
      />
    </svg>
  );
}

export function OrderedList(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.63281 7H2.97266V4.89844L2.32422 5.08789V4.58789L3.57227 4.15625H3.63281V7Z"
        fill="black"
      />
      <path
        d="M4.1582 13H2.17383V12.5703L3.08789 11.6094C3.31315 11.3529 3.42578 11.1491 3.42578 10.998C3.42578 10.8757 3.39909 10.7826 3.3457 10.7188C3.29232 10.6549 3.21484 10.623 3.11328 10.623C3.01302 10.623 2.93164 10.666 2.86914 10.752C2.80664 10.8366 2.77539 10.9427 2.77539 11.0703H2.11523C2.11523 10.8958 2.15885 10.735 2.24609 10.5879C2.33333 10.4395 2.45443 10.3236 2.60938 10.2402C2.76432 10.1569 2.9375 10.1152 3.12891 10.1152C3.4362 10.1152 3.67253 10.1862 3.83789 10.3281C4.00456 10.4701 4.08789 10.6738 4.08789 10.9395C4.08789 11.0514 4.06706 11.1608 4.02539 11.2676C3.98372 11.373 3.91862 11.4844 3.83008 11.6016C3.74284 11.7174 3.60156 11.873 3.40625 12.0684L3.03906 12.4922H4.1582V13Z"
        fill="black"
      />
      <path
        d="M2.77539 17.3027H3.08594C3.33203 17.3027 3.45508 17.1823 3.45508 16.9414C3.45508 16.8477 3.42578 16.7715 3.36719 16.7129C3.30859 16.653 3.22591 16.623 3.11914 16.623C3.0319 16.623 2.95573 16.6484 2.89062 16.6992C2.82682 16.75 2.79492 16.8132 2.79492 16.8887H2.13672C2.13672 16.7389 2.17839 16.6055 2.26172 16.4883C2.34505 16.3711 2.46029 16.2799 2.60742 16.2148C2.75586 16.1484 2.91862 16.1152 3.0957 16.1152C3.41211 16.1152 3.66081 16.1875 3.8418 16.332C4.02279 16.4766 4.11328 16.6751 4.11328 16.9277C4.11328 17.0501 4.07552 17.166 4 17.2754C3.92578 17.3835 3.81706 17.4714 3.67383 17.5391C3.82487 17.5938 3.94336 17.6771 4.0293 17.7891C4.11523 17.8997 4.1582 18.0371 4.1582 18.2012C4.1582 18.4551 4.06055 18.6582 3.86523 18.8105C3.66992 18.9629 3.41341 19.0391 3.0957 19.0391C2.90951 19.0391 2.73633 19.0039 2.57617 18.9336C2.41732 18.862 2.29688 18.7637 2.21484 18.6387C2.13281 18.5124 2.0918 18.3691 2.0918 18.209H2.75391C2.75391 18.2962 2.78906 18.3717 2.85938 18.4355C2.92969 18.4993 3.01628 18.5312 3.11914 18.5312C3.23503 18.5312 3.32747 18.4993 3.39648 18.4355C3.46549 18.3704 3.5 18.2878 3.5 18.1875C3.5 18.0443 3.46419 17.9427 3.39258 17.8828C3.32096 17.8229 3.22201 17.793 3.0957 17.793H2.77539V17.3027Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 6C7 5.44772 7.44772 5 8 5H21C21.5523 5 22 5.44772 22 6C22 6.55228 21.5523 7 21 7H8C7.44772 7 7 6.55228 7 6Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 12C7 11.4477 7.44772 11 8 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H8C7.44772 13 7 12.5523 7 12Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 18C7 17.4477 7.44772 17 8 17H21C21.5523 17 22 17.4477 22 18C22 18.5523 21.5523 19 21 19H8C7.44772 19 7 18.5523 7 18Z"
        fill="black"
      />
    </svg>
  );
}

export function Link(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="20"
      height="10"
      viewBox="0 0 20 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.25 1C12.25 0.585786 12.5858 0.25 13 0.25L15 0.25C17.6234 0.25 19.75 2.37665 19.75 5C19.75 7.62335 17.6234 9.75 15 9.75H13C12.5858 9.75 12.25 9.41421 12.25 9C12.25 8.58579 12.5858 8.25 13 8.25H15C16.7949 8.25 18.25 6.79493 18.25 5C18.25 3.20508 16.7949 1.75 15 1.75L13 1.75C12.5858 1.75 12.25 1.41421 12.25 1ZM5 1.75C3.20507 1.75 1.75 3.20508 1.75 5C1.75 6.79493 3.20507 8.25 5 8.25H7C7.41421 8.25 7.75 8.58579 7.75 9C7.75 9.41421 7.41421 9.75 7 9.75H5C2.37665 9.75 0.25 7.62335 0.25 5C0.25 2.37665 2.37665 0.250001 5 0.250001H7C7.41421 0.250001 7.75 0.585787 7.75 1C7.75 1.41421 7.41421 1.75 7 1.75H5ZM5.25 5C5.25 4.58579 5.58579 4.25 6 4.25H14C14.4142 4.25 14.75 4.58579 14.75 5C14.75 5.41421 14.4142 5.75 14 5.75H6C5.58579 5.75 5.25 5.41421 5.25 5Z"
        fill="#3C3C3C"
      />
    </svg>
  );
}

export function Crop(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3902_1640)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.09236 2.55673e-05C4.46054 0.00321644 4.75642 0.304269 4.75323 0.672445L4.66659 10.6691C4.66722 10.845 4.73739 11.0136 4.86185 11.1381C4.98687 11.2631 5.15644 11.3333 5.33325 11.3333H15.3333C15.7014 11.3333 15.9999 11.6318 15.9999 12C15.9999 12.3682 15.7014 12.6667 15.3333 12.6667H5.33325C4.80282 12.6667 4.29411 12.456 3.91904 12.0809C3.54397 11.7058 3.33325 11.1971 3.33325 10.6667V10.6609L3.33328 10.6609L3.41994 0.66089C3.42313 0.292714 3.72419 -0.00316527 4.09236 2.55673e-05Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.6667 3.3335C11.1971 3.3335 11.7058 3.54421 12.0809 3.91928C12.456 4.29436 12.6667 4.80306 12.6667 5.3335V15.3335C12.6667 15.7017 12.3682 16.0002 12 16.0002C11.6318 16.0002 11.3333 15.7017 11.3333 15.3335V5.3335C11.3333 5.15669 11.2631 4.98712 11.1381 4.86209C11.0136 4.73763 10.845 4.66746 10.6691 4.66683L0.672445 4.75347C0.304269 4.75666 0.00321644 4.46078 2.55673e-05 4.09261C-0.00316527 3.72443 0.292714 3.42338 0.66089 3.42019L10.6667 3.3335Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3902_1640">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function Rescale(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 622 626"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M45 555V72C46.6742 58.9742 50.2479 53.7448 65 51H262C265.586 31.7001 269.576 21.2549 284 4H65C23.3383 11.3518 8.49819 23.7008 0 63V561C0 591.5 22.5 622 65 626H548C594.5 624.5 619 597 619 561V343C603.538 355.431 594.039 359.517 576 363V544C575.042 572.151 568.799 580.051 548 580H65C50.6965 578.042 45.6417 573.381 45 555Z"
        fill="white"
      />
      <path
        d="M503 67H351C324.5 67 303.5 7.00024 355.5 0H589.5C612.454 5.52206 619.001 13.7787 621.5 36V265.5C616.5 302 565.5 315.5 554 265.5V115.5L308.5 360.5H453C495 363 505.5 418 455.5 428.5H227.5C203.936 423.205 195.413 416.755 192 396.5V163C197.5 119 255.5 120 259.5 163V312L503 67Z"
        fill="white"
      />
    </svg>
  );
}

export function Duplicate(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      width="16"
      height="16"
      {...props}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75"
      />
    </svg>
  );
}

export function PaddingTop(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 1C1.44772 1 1 1.44772 1 2V14C1 14.5523 1.44772 15 2 15H14C14.5523 15 15 14.5523 15 14V2C15 1.44772 14.5523 1 14 1H2ZM13.5 3.5C13.5 2.94772 13.0523 2.5 12.5 2.5H3.5C2.94772 2.5 2.5 2.94771 2.5 3.5V12.5C2.5 13.0523 2.94771 13.5 3.5 13.5H12.5C13.0523 13.5 13.5 13.0523 13.5 12.5V3.5Z"
        fill="#9199A8"
      />
      <path
        d="M5.5 5L2.5 2.5H13.5L10.5 5H8H5.5Z"
        fill="#525761"
        stroke="#525761"
      />
    </svg>
  );
}

export function PaddingRight(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 2C15 1.44772 14.5523 1 14 1L2 0.999999C1.44772 0.999999 1 1.44772 1 2L0.999999 14C0.999999 14.5523 1.44772 15 2 15L14 15C14.5523 15 15 14.5523 15 14L15 2ZM12.5 13.5C13.0523 13.5 13.5 13.0523 13.5 12.5L13.5 3.5C13.5 2.94772 13.0523 2.5 12.5 2.5L3.5 2.5C2.94772 2.5 2.5 2.94771 2.5 3.5L2.5 12.5C2.5 13.0523 2.94771 13.5 3.5 13.5L12.5 13.5Z"
        fill="#9199A8"
      />
      <path
        d="M11 5.5L13.5 2.5L13.5 13.5L11 10.5L11 8L11 5.5Z"
        fill="#525761"
        stroke="#525761"
      />
    </svg>
  );
}

export function PaddingBottom(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 2C15 1.44772 14.5523 1 14 1L2 0.999999C1.44772 0.999999 1 1.44772 1 2L0.999999 14C0.999999 14.5523 1.44772 15 2 15L14 15C14.5523 15 15 14.5523 15 14L15 2ZM12.5 13.5C13.0523 13.5 13.5 13.0523 13.5 12.5L13.5 3.5C13.5 2.94772 13.0523 2.5 12.5 2.5L3.5 2.5C2.94772 2.5 2.5 2.94771 2.5 3.5L2.5 12.5C2.5 13.0523 2.94771 13.5 3.5 13.5L12.5 13.5Z"
        fill="#9199A8"
      />
      <path
        d="M10.5 11L13.5 13.5L2.5 13.5L5.5 11L8 11L10.5 11Z"
        fill="#525761"
        stroke="#525761"
      />
    </svg>
  );
}

export function PaddingLeft(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 15C14.5523 15 15 14.5523 15 14L15 2C15 1.44772 14.5523 1 14 1L2 1C1.44771 1 0.999999 1.44772 0.999999 2L1 14C1 14.5523 1.44772 15 2 15L14 15ZM2.5 12.5C2.5 13.0523 2.94771 13.5 3.5 13.5L12.5 13.5C13.0523 13.5 13.5 13.0523 13.5 12.5L13.5 3.5C13.5 2.94772 13.0523 2.5 12.5 2.5L3.5 2.5C2.94771 2.5 2.5 2.94772 2.5 3.5L2.5 12.5Z"
        fill="#9199A8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 2C15 1.44772 14.5523 1 14 1L2 1C1.44772 1 1 1.44772 1 2L1 14C1 14.5523 1.44772 15 2 15L14 15C14.5523 15 15 14.5523 15 14L15 2ZM12.5 13.5C13.0523 13.5 13.5 13.0523 13.5 12.5L13.5 3.5C13.5 2.94772 13.0523 2.5 12.5 2.5L3.5 2.5C2.94772 2.5 2.5 2.94772 2.5 3.5L2.5 12.5C2.5 13.0523 2.94772 13.5 3.5 13.5L12.5 13.5Z"
        fill="#9199A8"
      />
      <path
        d="M5 10.5L2.5 13.5L2.5 2.50002L5 5.50001L5 8.00001L5 10.5Z"
        fill="#525761"
        stroke="#525761"
      />
    </svg>
  );
}

export function Padding(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 1.99998C15 1.4477 14.5523 0.999985 14 0.999985L2 0.999984C1.44772 0.999984 1 1.4477 1 1.99998L0.999999 14C0.999999 14.5523 1.44772 15 2 15L14 15C14.5523 15 15 14.5523 15 14L15 1.99998ZM12.5 13.5C13.0523 13.5 13.5 13.0523 13.5 12.5L13.5 3.49998C13.5 2.9477 13.0523 2.49998 12.5 2.49998L3.5 2.49998C2.94772 2.49998 2.5 2.9477 2.5 3.49998L2.5 12.5C2.5 13.0523 2.94771 13.5 3.5 13.5L12.5 13.5Z"
        fill="#9199A8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0.999985C1.44772 0.999985 1 1.4477 1 1.99999V14C1 14.5523 1.44772 15 2 15H14C14.5523 15 15 14.5523 15 14V1.99998C15 1.4477 14.5523 0.999985 14 0.999985H2ZM13.5 3.49998C13.5 2.9477 13.0523 2.49998 12.5 2.49998H3.5C2.94772 2.49998 2.5 2.9477 2.5 3.49998V12.5C2.5 13.0523 2.94771 13.5 3.5 13.5H12.5C13.0523 13.5 13.5 13.0523 13.5 12.5V3.49998Z"
        fill="#9199A8"
      />
      <path
        d="M11 5.49998L13.5 2.49998L13.5 13.5L11 10.5L11 7.99998L11 5.49998Z"
        fill="#9199A8"
        stroke="#9199A8"
      />
      <path
        d="M5.5 4.99998L2.5 2.49998H13.5L10.5 4.99998H8H5.5Z"
        fill="#9199A8"
        stroke="#9199A8"
      />
      <path
        d="M5 10.5L2.5 13.5L2.5 2.49998L5 5.49998L5 7.99998L5 10.5Z"
        fill="#9199A8"
        stroke="#9199A8"
      />
      <path
        d="M10.5 11L13.5 13.5L2.5 13.5L5.5 11L8 11L10.5 11Z"
        fill="#9199A8"
        stroke="#9199A8"
      />
    </svg>
  );
}

export function Revert(
  props: React.SVGProps<SVGSVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.2929 9.87868L12.7071 9.87868L12.7071 6.63604L13.4023 6.63604C14.503 6.63604 15.5586 7.07328 16.3368 7.85157C18.6192 10.1339 18.5901 13.8952 16.2902 16.1951C13.9382 18.5471 10.0917 18.577 7.75736 16.2426C6.67048 15.1558 6 13.6571 6 12H4C4 14.2089 4.89653 16.2102 6.34315 17.6569C9.4761 20.7898 14.589 20.7247 17.7044 17.6093C20.7678 14.546 20.832 9.51828 17.7511 6.43736C16.5977 5.28399 15.0334 4.63604 13.4023 4.63604L12.7071 4.63604L12.7071 1.3934L11.2929 1.3934L7.05025 5.63604L11.2929 9.87868Z"
        fill="currentColor"
      />
    </svg>
  );
}
