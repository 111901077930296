import { gql, useMutation } from "@apollo/client";
import GuestHeader from "app/auth/components/GuestHeader";
import { useAuth } from "app/auth/context";
import Button from "app/core/components/Button";
import { ControlledInput } from "app/core/components/Input";
import { useStyletron } from "baseui";
import { FormControl } from "baseui/form-control";
import { Cell, Grid } from "baseui/layout-grid";
import { toaster } from "baseui/toast";
import { Display4 } from "baseui/typography";
import { ReactElement } from "react";
import { useForm } from "react-hook-form";
import { Redirect, useLocation } from "react-router-dom";

type FormInputs = {
  email: string;
};

const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(input: $input)
  }
`;

export default function ForgotPasswordPage(): ReactElement {
  const {
    handleSubmit,
    errors,
    reset,
    control,
    formState: { isSubmitting },
  } = useForm<FormInputs>({
    defaultValues: {
      email: "",
    },
  });
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const [css] = useStyletron();
  const [forgotPassword] = useMutation(FORGOT_PASSWORD);

  const { from } = (location.state as LocationState) || {
    from: { pathname: "/projects" },
  };

  if (isAuthenticated) {
    return <Redirect to={from} />;
  }

  return (
    <>
      <GuestHeader />
      <Grid>
        <Cell skip={[0, 1, 4]} span={[4, 6, 4]}>
          <div
            className={css({
              marginTop: "40px",
              padding: "20px",
              textAlign: "center",
            })}
          >
            <form
              onSubmit={handleSubmit(async (input) => {
                try {
                  await forgotPassword({ variables: { input } });
                  reset();

                  toaster.positive(
                    "Instructions have been sent to your e-mail address",
                    {
                      autoHideDuration: 4000,
                    }
                  );
                } catch (error) {
                  toaster.negative("Something went wrong.", {
                    autoHideDuration: 4000,
                  });
                }
              })}
            >
              <Display4 $style={{ fontWeight: 300, marginBottom: "60px" }}>
                Forgot password
              </Display4>
              <FormControl
                label={() => "E-mail address"}
                error={errors.email && errors.email.message}
                overrides={{
                  Caption: {
                    style: {
                      textAlign: "left",
                    },
                  },
                  Label: {
                    style: {
                      textTransform: "uppercase",
                      textAlign: "left",
                      fontSize: "12px",
                      color: "#8390AE",
                    },
                  },
                }}
              >
                <ControlledInput
                  control={control}
                  name="email"
                  error={!!errors.email}
                  rules={{ required: "This field is required" }}
                  disabled={isSubmitting}
                />
              </FormControl>

              <div>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  $style={{
                    width: "100%",
                    display: "block",
                  }}
                >
                  Send e-mail
                </Button>
              </div>
            </form>
          </div>
        </Cell>
      </Grid>
    </>
  );
}
