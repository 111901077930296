import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import LoadingSpinner from "app/core/components/LoadingSpinner";
import { toaster } from "baseui/toast";
import gql from "graphql-tag";
import { decode } from "jsonwebtoken";
import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useInterval } from "rooks";

import { FeatureNotAvailableModal } from "./components/FeatureNotAvailableModal";
import { TokenExpiredModal } from "./components/TokenExpiredModal";

type User = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  has2FaEnabled: boolean;
  isAdmin: boolean;
  availableFeatures: Record<string, boolean | number>;
  collageExportCount: number;
  publishedCollageCount: number;
  hasRefLinkEnabled: boolean;
  subscription?: {
    id: number;
    state: string;
    updateUrl?: number;
  };
};

type AuthContextProps = {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  login: (
    type: "credentials" | "token",
    input:
      | { email: string; password: string; sms2FaToken?: string }
      | { loginToken: string }
  ) => Promise<void>;
  logout: () => void;
  isAuthenticated: boolean;
  verifyFeatureAvailability: (feature: string) => boolean;
  setIsFeatureNotAvailableModalOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  refreshCounts: () => void;
  refetch: () => void;
};

export const AuthContext = createContext<AuthContextProps>(
  {} as AuthContextProps
);

const LOGIN = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      token
      user {
        firstName
        lastName
        email
        phone
        has2FaEnabled
        isAdmin
        availableFeatures
        collageExportCount
        publishedCollageCount
        hasRefLinkEnabled
        subscription {
          id
          state
          updateUrl
        }
      }
    }
  }
`;

const TOKEN_LOGIN = gql`
  mutation TokenLogin($input: TokenLoginInput!) {
    tokenLogin(input: $input) {
      token
      user {
        firstName
        lastName
        email
        phone
        has2FaEnabled
        isAdmin
        availableFeatures
        collageExportCount
        publishedCollageCount
        hasRefLinkEnabled
        subscription {
          id
          state
          updateUrl
        }
      }
    }
  }
`;

const FETCH_ME = gql`
  query Me {
    me {
      firstName
      lastName
      email
      phone
      has2FaEnabled
      isAdmin
      availableFeatures
      collageExportCount
      publishedCollageCount
      hasRefLinkEnabled
      subscription {
        id
        state
        updateUrl
      }
    }
  }
`;

const REFRESH_COUNTS = gql`
  query Me {
    me {
      collageExportCount
      publishedCollageCount
    }
  }
`;

export function AuthProvider({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  const [_login] = useMutation(LOGIN);
  const [tokenLogin] = useMutation(TOKEN_LOGIN);
  const [
    isFetchingAuthenticatedUser,
    setIsFetchingAuthenticatedUser,
  ] = useState(true);
  const [user, setUser] = useState<User | null>(null);
  const location = useLocation();
  const history = useHistory();

  const [refreshCounts] = useLazyQuery(REFRESH_COUNTS, {
    fetchPolicy: "no-cache",
    onCompleted: (data: {
      me: Pick<User, "publishedCollageCount" | "collageExportCount">;
    }) => {
      if (data && data.me) {
        setUser((user) => {
          if (user) {
            return {
              ...user,
              publishedCollageCount: data.me.publishedCollageCount,
              collageExportCount: data.me.collageExportCount,
            };
          } else {
            return null;
          }
        });
      }
    },
  });
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [
    isFeatureNotAvailableModalOpen,
    setIsFeatureNotAvailableModalOpen,
  ] = useState(false);
  const [isTokenExpiredModalOpen, setIsTokenExpiredModalOpen] = useState(false);

  useInterval(
    () => {
      const decodedToken = decode(localStorage.getItem("token") || "");

      if (isAuthenticated && !localStorage.getItem("token")) {
        setIsTokenExpiredModalOpen(true);
      } else if (
        decodedToken &&
        typeof decodedToken !== "string" &&
        "exp" in decodedToken
      ) {
        if (Date.now() / 1000 > (decodedToken.exp ?? 0)) {
          setIsTokenExpiredModalOpen(true);
        }
      }
    },
    10000,
    true
  );

  const searchParams = new URLSearchParams(location.search);
  const loginToken = searchParams.get("loginToken");

  // Try to fetch an user using the token stored in the local storage
  const { refetch } = useQuery(FETCH_ME, {
    onCompleted({ me }) {
      setUser(me);
      setIsAuthenticated(true);

      setIsFetchingAuthenticatedUser(false);
    },
    onError() {
      // If the query returns nothing then the token has expired so we can delete it from the local storage
      localStorage.removeItem("token");

      setIsFetchingAuthenticatedUser(false);
    },
    skip: !!loginToken,
  });

  useEffect(() => {
    const paddlePlanIdToBuy = window.localStorage.getItem("paddlePlanIdToBuy");
    if (isAuthenticated && paddlePlanIdToBuy) {
      if (user?.subscription && user?.subscription?.updateUrl) {
        history.push("/billing");
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).Paddle.Checkout.open({
        product: +paddlePlanIdToBuy,
        email: user?.email,
        coupon: window.localStorage.getItem("coupon"),
        successCallback: () => {
          toaster.positive(
            "You've successfully bought the MyBlog's subscription. It's currently being processed and your subscription will be active shortly.",
            {
              autoHideDuration: 10000,
            }
          );
        },
      });

      window.localStorage.setItem("paddlePlanIdToBuy", "");
      window.localStorage.setItem("coupon", "");
    }
  }, [isAuthenticated]);

  if (isFetchingAuthenticatedUser && !loginToken) {
    return <LoadingSpinner />;
  }

  async function login(
    type: "credentials" | "token",
    input:
      | { email: string; password: string; sms2FaToken?: string }
      | { loginToken: string }
  ) {
    const mutationFunction = type === "credentials" ? _login : tokenLogin;

    const { data } = await mutationFunction({
      variables: {
        input,
      },
    });

    // Set the token to the browser's local storage
    localStorage.setItem(
      "token",
      data[type === "credentials" ? "login" : "tokenLogin"].token
    );

    // Set the authenticated user to context's value
    setUser(data[type === "credentials" ? "login" : "tokenLogin"].user);
    setIsAuthenticated(true);
  }

  function logout() {
    localStorage.removeItem("token");

    setUser(null);
    setIsAuthenticated(false);
    window.location.href = "/";
  }

  function verifyFeatureAvailability(feature: string): boolean {
    const isFeatureAvailable = !!user?.availableFeatures[feature];

    return isFeatureAvailable;
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        login,
        logout,
        isAuthenticated,
        verifyFeatureAvailability,
        setIsFeatureNotAvailableModalOpen,
        refreshCounts,
        refetch,
      }}
    >
      {children}
      <FeatureNotAvailableModal
        isOpen={isFeatureNotAvailableModalOpen}
        onClose={() => setIsFeatureNotAvailableModalOpen(false)}
      />
      <TokenExpiredModal
        isOpen={isTokenExpiredModalOpen}
        onClose={() => {
          setIsTokenExpiredModalOpen(false);
          logout();
        }}
      />
    </AuthContext.Provider>
  );
}

export const useAuth = (): AuthContextProps => useContext(AuthContext);
