import Checkbox from "app/core/components/Checkbox";
import Input from "app/core/components/Input";
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic";
import { ReactElement } from "react";

type FeatureDefinition = {
  name: string;
  type: "boolean" | "number";
};

export const FEATURES: Record<string, FeatureDefinition> = {
  publishedPostsCount: {
    name: "Published posts",
    type: "number",
  },
  blogExport: {
    name: "Export posts as JPEG",
    type: "boolean",
  },
  blogForever: {
    name: "Keep your published blogs forever",
    type: "boolean",
  },
  allowedCollageExportsCount: {
    name: "Export image collages per month",
    type: "number",
  },
  googleTrends: {
    name: "Google Trends",
    type: "boolean",
  },
  advancedImageSeo: {
    name: "Image SEO",
    type: "boolean",
  },
  advancedContentSeo: {
    name: "Advanced Content SEO",
    type: "boolean",
  },
  videoComponent: {
    name: "Youtube, Vimeo",
    type: "boolean",
  },
  gif: {
    name: "GIF",
    type: "boolean",
  },
  spotify: {
    name: "Spotify",
    type: "boolean",
  },
  pinterest: {
    name: "Pinterest",
    type: "boolean",
  },
  disableRefLink: {
    name: "Remove Picnook branding",
    type: "boolean",
  },
};

type FeaturesTableProps = {
  value: Record<string, boolean | number>;
  onChange: (value: Record<string, boolean | number>) => void;
  showOverwriteColumn?: boolean;
};

export default function FeaturesTable({
  value,
  onChange,
  showOverwriteColumn = false,
}: FeaturesTableProps): ReactElement {
  return (
    <TableBuilder data={Object.entries(FEATURES)}>
      <TableBuilderColumn id="name" header="Feature">
        {(row) => row[1].name}
      </TableBuilderColumn>
      {showOverwriteColumn && (
        <TableBuilderColumn id="name" header="Overwrite">
          {(row) => (
            <Checkbox
              checked={row[0] in value}
              onChange={(event) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { [row[0]]: _featureValue, ...restValue } = value;

                onChange({
                  ...restValue,
                  ...(!!event.currentTarget.checked && {
                    [row[0]]:
                      row[1].type === "boolean"
                        ? false
                        : row[1].type === "number"
                        ? 0
                        : undefined,
                  }),
                });
              }}
            />
          )}
        </TableBuilderColumn>
      )}
      <TableBuilderColumn id="value" header="Value">
        {(row) => (
          <>
            {row[1].type === "boolean" ? (
              <Checkbox
                checked={!!value[row[0]]}
                disabled={showOverwriteColumn && !(row[0] in value)}
                onChange={(event) =>
                  onChange({
                    ...value,
                    [row[0]]: !!event.currentTarget.checked,
                  })
                }
              />
            ) : (
              <Input
                value={value[row[0]] ? value[row[0]].toString() : 0}
                disabled={!(row[0] in value)}
                onChange={(event) =>
                  onChange({
                    ...value,
                    [row[0]]: +event.currentTarget.value,
                  })
                }
                min={-1}
                size="compact"
                type="number"
              />
            )}
          </>
        )}
      </TableBuilderColumn>
    </TableBuilder>
  );
}
