import Button from "app/core/components/Button";
import Checkbox from "app/core/components/Checkbox";
import {
  Bold,
  Check,
  Duplicate,
  Italic,
  Link,
  OrderedList,
  Underline,
  UnorderedList,
  X,
} from "app/core/components/Icon";
import IconButton from "app/core/components/IconButton";
import Input from "app/core/components/Input";
import Select from "app/core/components/Select";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { SIZE } from "baseui/input";
import { Popover } from "baseui/popover";
import { Option } from "baseui/select";
import {
  convertFromRaw,
  convertToRaw,
  Editor,
  EditorState,
  Modifier,
  RichUtils,
} from "draft-js";
import {
  ChangeEvent,
  memo,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useLocalStorageState from "use-local-storage-state";

import {
  CollageDesignerActionType,
  CollageNode,
  CollageNodeType,
  ExtractNodeParameters,
  TextType,
} from "../../CollageDesigner/types";
import { CommonNodeRendererProps, OptionalExceptFor } from "../types";
import NodeRendererWrapper from "./NodeRendererWrapper";

const DESKTOP_REF_WIDTH = 1920 * 0.46;

const REL_TYPES = [
  { id: "nofollow", label: "nofollow" },
  { id: "dofollow", label: "dofollow" },
  { id: "noreferrer", label: "noreferrer" },
  { id: "noopener", label: "noopener" },
];

type LinkState = {
  nodeId?: number;
  id?: number;
  url?: string;
  title?: string;
  rel?: ReadonlyArray<Option>;
  target?: ReadonlyArray<Option>;
};

type TextNodeRendererProps = OptionalExceptFor<
  ExtractNodeParameters<CollageNode, CollageNodeType.Text>,
  "id"
> &
  CommonNodeRendererProps;

function TextNodeRenderer({
  rootDivElement,
  collageRootDivElement,
  collageTranslate,
  id,
  offset,
  normalizedDimensions,
  textContentState,
  textVerticalAlignment,
  textAlignment,
  textColor,
  textFontFamily,
  textSize = 16,
  textLineHeight,
  textLetterSpacing = 0,
  textFontWeight,
  textType,
  parent,
  collageWidth,
  dispatch,
  rootNode,
  collageScale,
  collageGap,
  bindDraggingListeners,
  activeNodeIds,
  activeTextNodeId,
  status,
  dimensions,
  croppingNodeId,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft,
  paddingUnit = "px",
  inheritUserStylesheets,
}: TextNodeRendererProps): React.ReactElement {
  const [css] = useStyletron();

  const [showRichUtils, setShowRichUtils] = useState(false);
  const [showLinkForm, setShowLinkForm] = useState(false);

  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );
  const [mountNode, setMountNode] = useState<HTMLElement | null>();

  const editingActive = activeTextNodeId === id;

  const editorWrapperRef = useRef<HTMLDivElement>(null);
  const editorRef = useRef<Editor>(null);
  const popoverRef = useRef<Popover>(null);

  // const parameter =
  //   p === "horizontal" || parentId === 0 ? "width" : "height";

  useEffect(() => {
    if (!editingActive) {
      setShowLinkForm(false);
      setShowRichUtils(false);
    }
  }, [editingActive]);

  useEffect(() => {
    if (textContentState?.blocks)
      setEditorState(
        EditorState.createWithContent(convertFromRaw(textContentState))
      );
  }, []);

  const observer = new MutationObserver(function () {
    setTimeout(() => {
      if (editorRef?.current) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const currentEditor = editorRef.current as any;

        const innerHeight =
          currentEditor.editor.parentElement.parentElement.parentElement
            .clientHeight;
        const contentHeight =
          currentEditor.editor.parentElement.parentElement.scrollHeight /
            (collageWidth / 1000) +
          12;

        let paddingTop = 0;
        let paddingBottom = 0;

        if (editorWrapperRef.current) {
          paddingTop = parseInt(
            window
              .getComputedStyle(editorWrapperRef.current, null)
              .getPropertyValue("padding-top")
          );

          paddingBottom = parseInt(
            window
              .getComputedStyle(editorWrapperRef.current, null)
              .getPropertyValue("padding-bottom")
          );
        }

        if (
          currentEditor.editor.parentElement.parentElement.scrollHeight !=
          innerHeight
        ) {
          dispatch({
            type: CollageDesignerActionType.SetNodeDimensions,
            payload: {
              targetId: id as number,
              dimensions: {
                width: 1000,
                height: contentHeight + paddingTop + paddingBottom,
                fitContent: true,
              },
            },
          });
        }
      }
    }, 10);
  });

  useEffect(() => {
    if (editorWrapperRef.current) {
      observer.observe(editorWrapperRef.current, {
        attributes: true,
        attributeFilter: ["class"],
      });
    }
  }, [editorWrapperRef]);

  useEffect(() => {
    function handleSelection() {
      if (
        window?.getSelection()?.toString().replace(/\s/g, "").length ||
        0 > 0
      ) {
        const mountNode = window?.getSelection()?.focusNode?.parentElement;

        setMountNode(mountNode);
        setShowRichUtils(true);
      } else {
        setMountNode(undefined);
        setShowRichUtils(false);
        setShowLinkForm(false);
      }
    }

    editorWrapperRef?.current?.addEventListener(
      "mouseup",
      handleSelection,
      false
    );

    return () => {
      editorWrapperRef?.current?.removeEventListener(
        "mouseup",
        handleSelection,
        false
      );
    };
  }, [editorState]);

  // useEffect(() => {
  //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //   // @ts-ignore
  //   const editorBoundingRect = editorRef.current.editor.getBoundingClientRect();
  //   const editorWrapperBoundingRect = editorWrapperRef.current?.getBoundingClientRect();

  //   let paddingTop: number | undefined = undefined;
  //   let paddingBottom: number | undefined = undefined;
  //   if (editorBoundingRect && editorWrapperBoundingRect) {
  //     paddingTop = editorBoundingRect.top - editorWrapperBoundingRect.top;
  //     paddingBottom =
  //       editorWrapperBoundingRect.bottom - editorBoundingRect.bottom;
  //   }

  //   dispatch({
  //     type: CollageDesignerActionType.ModifyNode,
  //     payload: {
  //       targetId: id as number,
  //       node: {
  //         ...(typeof paddingTop !== "undefined" &&
  //           typeof paddingBottom !== "undefined" && {
  //             paddingTop,
  //             paddingBottom,
  //           }),
  //       },
  //     },
  //   });
  // }, [dimensions]);

  const INLINE_STYLES = [
    { label: <Bold />, style: "BOLD" },
    { label: <Italic />, style: "ITALIC" },
    { label: <Underline />, style: "UNDERLINE" },
  ];

  const BLOCK_TYPES = [
    { label: <UnorderedList />, style: "unordered-list-item" },
    { label: <OrderedList />, style: "ordered-list-item" },
  ];

  function LinkForm({ isOpen }: { isOpen: boolean }): ReactElement {
    const [hasLink, setHasLink] = useState(false);

    const currentSelectionState = editorState.getSelection();
    const startKey = currentSelectionState.getStartKey();
    const startOffset = currentSelectionState.getStartOffset();
    const currentContent = editorState.getCurrentContent();
    const currentContentBlock = currentContent.getBlockForKey(startKey);
    const inlineStyle = currentContentBlock.getInlineStyleAt(startOffset);
    const entityWithCursor = currentContentBlock.getEntityAt(startOffset);
    const selection = editorState.getSelection();

    const [linkFormState, setLinkFormState] = useLocalStorageState<
      LinkState | undefined
    >("linkform" + id, {
      ssr: true,
      defaultValue: {
        nodeId: id,
        id: startOffset,
        rel: [{ id: "nofollow", label: "nofollow" }],
      },
    });

    useEffect(() => {
      if (isOpen) {
        if (
          entityWithCursor &&
          currentContent.getEntity(entityWithCursor).getType() === "LINK"
        ) {
          setHasLink(true);

          if (
            linkFormState?.id !== startOffset ||
            linkFormState?.nodeId !== id
          ) {
            setLinkFormState({
              nodeId: id,
              id: startOffset,
              ...(currentContent
                .getEntity(entityWithCursor)
                .getData() as LinkState),
            });
          }
        } else {
          if (inlineStyle.has("LINK")) {
            setHasLink(true);
          }

          if (linkFormState?.id !== startOffset || linkFormState?.nodeId !== id)
            setLinkFormState({
              nodeId: id,
              id: startOffset,
              rel: [{ id: "nofollow", label: "nofollow" }],
            });
        }
      }
    }, [isOpen, startOffset]);

    function removeLink(): void {
      const newContentState = Modifier.applyEntity(
        currentContent,
        selection,
        null
      );

      let newEditorState = EditorState.push(
        editorState,
        newContentState,
        "apply-entity"
      );

      if (selection) {
        newEditorState = EditorState.forceSelection(newEditorState, selection);
        newEditorState = RichUtils.toggleInlineStyle(newEditorState, "LINK");
      }

      setEditorState(newEditorState);
      dispatch({
        type: CollageDesignerActionType.ModifyNode,
        payload: {
          targetId: id as number,
          node: {
            textContentState: convertToRaw(newEditorState.getCurrentContent()),
          },
        },
      });

      setLinkFormState({
        nodeId: id,
        id: startOffset,
        rel: [{ id: "nofollow", label: "nofollow" }],
      });
    }

    function addLink(): void {
      currentContent.createEntity("LINK", "MUTABLE", linkFormState);
      const entityKey = currentContent.getLastCreatedEntityKey();

      const newContentState = Modifier.applyEntity(
        currentContent,
        selection,
        entityKey
      );

      let newEditorState = EditorState.push(
        editorState,
        newContentState,
        "apply-entity"
      );

      if (selection) {
        newEditorState = EditorState.forceSelection(newEditorState, selection);
        newEditorState = RichUtils.toggleInlineStyle(newEditorState, "LINK");

        if (hasLink)
          newEditorState = RichUtils.toggleInlineStyle(newEditorState, "LINK");
      }

      setEditorState(newEditorState);
      dispatch({
        type: CollageDesignerActionType.ModifyNode,
        payload: {
          targetId: id as number,
          node: {
            textContentState: convertToRaw(newEditorState.getCurrentContent()),
          },
        },
      });
    }

    return (
      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          gap: "3px",
          width: "250px",
        })}
      >
        <Input
          size="mini"
          placeholder="URL"
          value={linkFormState?.url}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            e.stopPropagation();
            setLinkFormState({ ...linkFormState, url: e.target.value });
          }}
          onKeyPress={(e) => {
            e.stopPropagation();
          }}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
          onKeyUp={(e) => {
            e.stopPropagation();
          }}
        />

        <Input
          size="mini"
          placeholder="Title"
          value={linkFormState?.title}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setLinkFormState({ ...linkFormState, title: e.target.value })
          }
          onKeyPress={(e) => {
            e.stopPropagation();
          }}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
          onKeyUp={(e) => {
            e.stopPropagation();
          }}
        />

        <Select
          size="mini"
          placeholder="Rel"
          multi
          clearable
          options={REL_TYPES}
          value={linkFormState?.rel}
          onChange={(params) =>
            setLinkFormState({ ...linkFormState, rel: params.value })
          }
        />

        <Checkbox
          checked={linkFormState?.target?.[0]?.id === "_blank"}
          {...{
            onMouseDown: (event) => {
              event.preventDefault();
              event.stopPropagation();
            },
          }}
          onChange={(event) => {
            event.stopPropagation();
            event.preventDefault();
            setLinkFormState({
              ...linkFormState,
              target: event.currentTarget.checked
                ? [{ id: "_blank", label: "_blank" }]
                : [{ id: "_self", label: "_self" }],
            });
          }}
        >
          Open in a new tab
        </Checkbox>

        <Button
          size="mini"
          startEnhancer={<Check />}
          $style={{
            marginTop: "6px",
          }}
          {...{
            onMouseDown: (event: Event) => {
              event.preventDefault();
            },
          }}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();

            addLink();

            setShowLinkForm(false);
            setShowRichUtils(false);
          }}
        >
          Apply
        </Button>

        <Button
          size="mini"
          kind="minimal"
          disabled={!hasLink}
          startEnhancer={<X />}
          {...{
            onMouseDown: (event: Event) => {
              event.preventDefault();
            },
          }}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();

            removeLink();

            setShowLinkForm(false);
            setShowRichUtils(false);
          }}
        >
          Remove
        </Button>
      </div>
    );
  }

  const InlineStyleControls = () => {
    const currentStyle = editorState.getCurrentInlineStyle();

    const selection = editorState.getSelection();
    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();

    return (
      <div
        className={css({ display: "flex", flexDirection: "row", gap: "3px" })}
      >
        {INLINE_STYLES.map((type) => (
          <IconButton
            minimal
            size={SIZE.mini}
            key={type.style}
            isSelected={currentStyle.has(type.style)}
            $style={{
              borderTopLeftRadius: "6px",
              borderTopRightRadius: "6px",
              borderBottomRightRadius: "6px",
              borderBottomLeftRadius: "6px",
            }}
            {...{
              onMouseDown: (event: Event) => {
                event.preventDefault();
                event.stopPropagation();
              },
            }}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              setEditorState(
                RichUtils.toggleInlineStyle(editorState, type.style)
              );
              dispatch({
                type: CollageDesignerActionType.ModifyNode,
                payload: {
                  targetId: id as number,
                  node: {
                    textContentState: convertToRaw(
                      RichUtils.toggleInlineStyle(
                        editorState,
                        type.style
                      ).getCurrentContent()
                    ),
                  },
                },
              });
            }}
          >
            {type.label}
          </IconButton>
        ))}

        <IconButton
          minimal
          size={SIZE.mini}
          isSelected={currentStyle.has("LINK")}
          $style={{
            borderTopLeftRadius: "6px",
            borderTopRightRadius: "6px",
            borderBottomRightRadius: "6px",
            borderBottomLeftRadius: "6px",
          }}
          {...{
            onMouseDown: (event: Event) => {
              event.preventDefault();
              event.stopPropagation();
            },
          }}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();

            setShowLinkForm(true);
          }}
        >
          <Link />
        </IconButton>

        {textType === TextType.Text && (
          <Block
            width="1px"
            height="22px"
            backgroundColor="#ededed"
            alignSelf="center"
            marginLeft="5px"
            marginRight="5px"
          />
        )}

        {textType === TextType.Text &&
          BLOCK_TYPES.map((type) => (
            <IconButton
              minimal
              size={SIZE.mini}
              key={type.style}
              $style={{
                borderTopLeftRadius: "6px",
                borderTopRightRadius: "6px",
                borderBottomRightRadius: "6px",
                borderBottomLeftRadius: "6px",
              }}
              isSelected={blockType === type.style}
              {...{
                onMouseDown: (event: Event) => {
                  event.preventDefault();
                },
              }}
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                setEditorState(
                  RichUtils.toggleBlockType(editorState, type.style)
                );
                dispatch({
                  type: CollageDesignerActionType.ModifyNode,
                  payload: {
                    targetId: id as number,
                    node: {
                      textContentState: convertToRaw(
                        RichUtils.toggleBlockType(
                          editorState,
                          type.style
                        ).getCurrentContent()
                      ),
                    },
                  },
                });
              }}
            >
              {type.label}
            </IconButton>
          ))}
      </div>
    );
  };

  const fontSize = useMemo(() => {
    let fontSize = textSize;

    if (parent?.id !== 0) {
      fontSize *= collageWidth / DESKTOP_REF_WIDTH;
    }

    return Math.floor(fontSize);
  }, [textSize, parent]);

  return (
    <>
      <Popover
        autoFocus
        ref={popoverRef}
        isOpen={showRichUtils && editingActive}
        renderAll
        overrides={{
          Inner: {
            style: ({ $theme }) => ({
              backgroundColor: "#fcfcfd",
              boxShadow: $theme.lighting.shadow400,
              borderRadius: $theme.borders.radius200,
              padding: "5px",
            }),
          },
          Body: {
            style: {
              background: "none",
              boxShadow: "none",
              position: "absolute",
              zIndex: 2,
              transform: "none",
              ...(mountNode && {
                left: `${
                  mountNode.getBoundingClientRect().left +
                  mountNode.getBoundingClientRect().width / 2
                }px`,
                top: `${
                  mountNode.nodeName === "DIV"
                    ? mountNode.getBoundingClientRect().top - 85
                    : mountNode.getBoundingClientRect().top - 40
                }px`,
                transform: "translate(-50%,-50%)",
              }),
            },
          },
        }}
        content={<InlineStyleControls />}
      >
        {" "}
      </Popover>

      <Popover
        autoFocus
        focusLock
        isOpen={showLinkForm && showRichUtils && editingActive}
        renderAll
        overrides={{
          Inner: {
            style: ({ $theme }) => ({
              backgroundColor: "#fcfcfd",
              boxShadow: $theme.lighting.shadow400,
              borderRadius: $theme.borders.radius200,
              padding: "5px",
            }),
          },
          Body: {
            style: {
              background: "none",
              boxShadow: "none",
              position: "absolute",
              zIndex: 2,
              transform: "none",
              ...(mountNode && {
                left: `${
                  mountNode.getBoundingClientRect().left +
                  mountNode.getBoundingClientRect().width / 2
                }px`,
                top: `${
                  mountNode.nodeName === "DIV"
                    ? mountNode.getBoundingClientRect().top + 35
                    : mountNode.getBoundingClientRect().top - 10
                }px`,
                transform: "translate(-50%,0%)",
              }),
            },
          },
        }}
        content={
          <LinkForm isOpen={showLinkForm && showRichUtils && editingActive} />
        }
      >
        {" "}
      </Popover>

      <style
        dangerouslySetInnerHTML={{
          __html: `
            .DraftEditor-editorContainer, .DraftEditor-root, .public-DraftEditor-content { height: auto; }
            .public-DraftEditor-content div > * { font-size: inherit; font-weight: inherit; margin-block: 0; }  
            .public-DraftEditor-content div > * > * { text-decoration-thickness: from-font; }
            .public-DraftEditorPlaceholder-root { position: relative; height: 0; opacity: 0.4; user-select: none; }
          `,
        }}
      />

      <NodeRendererWrapper
        id={id}
        offset={offset}
        normalizedDimensions={normalizedDimensions}
        collageWidth={collageWidth}
        dispatch={dispatch}
        rootDivElement={rootDivElement}
        collageRootDivElement={collageRootDivElement}
        collageTranslate={collageTranslate}
        collageScale={collageScale}
        bindDraggingListeners={bindDraggingListeners.bind(
          null,
          `internal@0/${id}/${parent?.id}`,
          CollageNodeType.Text
        )}
        collageGap={collageGap}
        rootNode={rootNode}
        includeDragButton={true}
        activeNodeIds={activeNodeIds}
        showResizers={false}
        croppingNodeId={croppingNodeId}
        showActions={!editingActive}
        status={status}
        type={CollageNodeType.Text}
        actions={[
          {
            icon: <Duplicate />,
            onClick: () => {
              dispatch({
                type: CollageDesignerActionType.InsertNode,
                payload: {
                  targetId: 0,
                  targetParentId: 0,
                  index: (parent?.index || 0) + 1,
                  node: {
                    id: 0,
                    type: CollageNodeType.Text,
                    textAlignment,
                    textColor,
                    textContentState,
                    textFontFamily,
                    textFontWeight,
                    textLetterSpacing,
                    textLineHeight,
                    textSize,
                    textType,
                    textVerticalAlignment,
                    dimensions,
                    paddingTop,
                    paddingRight,
                    paddingBottom,
                    paddingLeft,
                    paddingUnit,
                    inheritUserStylesheets,
                  },
                },
              });
            },
          },
        ]}
      >
        {/* <StyledTextarea
              value={textValue}
              onChange={(e: React.FormEvent<HTMLTextAreaElement>) => {
                dispatch({
                  type: CollageDesignerActionType.ModifyNode,
                  payload: {
                    targetId: id as number,
                    node: {
                      textValue: e.currentTarget.value,
                    },
                  },
                });
              }}
              className={css({
                height: "100%",
                ...(textComponent?.displayName &&
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  (theme.typography as any)[textComponent.displayName]),
                textAlign,
              })}
            /> */}
        <Block
          {...(!editingActive &&
            bindDraggingListeners(
              `internal@0/${id}/${parent?.id}`,
              CollageNodeType.Text
            ))}
          ref={editorWrapperRef}
          className={css({
            height: "100%",
            color: textColor,
            ...(!inheritUserStylesheets && {
              fontFamily: textFontFamily || "sans-serif",
              fontSize: `${fontSize}pt`,
              lineHeight: `${
                textLineHeight
                  ? textLineHeight
                  : textType === TextType.Heading
                  ? 150
                  : 120
              }%`,
              letterSpacing: `${textLetterSpacing}pt`,
            }),
            fontWeight: parseInt(textFontWeight || "400"),
            display: "flex",
            flexDirection: "column",
            justifyContent: textVerticalAlignment,
            textAlign: textAlignment,
            paddingTop: `${paddingTop}${paddingUnit}`,
            paddingRight: `${paddingRight}${paddingUnit}`,
            paddingBottom: `${paddingBottom}${paddingUnit}`,
            paddingLeft: `${paddingLeft}${paddingUnit}`,
          })}
        >
          <div
            className={css({
              position: "absolute",
              left: "0px",
              top: "0px",
              right: "0px",
              bottom: "0px",
              pointerEvents: editingActive ? "none" : "all",
              ...(!editingActive &&
                !editorState.getCurrentContent().hasText() && {
                  "::after": {
                    position: "absolute",
                    content: "'Double click to add text'",
                    left: "0px",
                    top: "0px",
                    right: "0px",
                    bottom: "0px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    opacity: 0.5,
                    fontSize: "14px",
                    color: "#9199A8",
                    letterSpacing: "0px !important",
                    fontFamily: "Roboto, sans-serif",
                  },
                }),
            })}
            onDoubleClick={() => {
              dispatch({
                type: CollageDesignerActionType.ChangeActiveTextNodeId,
                payload: {
                  activeTextNodeId: id as number,
                },
              });

              editorRef.current?.focus();
            }}
          ></div>

          <Editor
            customStyleMap={{
              LINK: {
                textDecorationLine: "underline",
                textUnderlineOffset: "2px",
                textDecorationThickness: "from-font",
                color: "rgb(175, 152, 131)",
              },
            }}
            ref={editorRef}
            editorState={editorState}
            textAlignment={textAlignment}
            placeholder={editingActive ? "Type..." : ""}
            handlePastedText={() => {
              setTimeout(() => {
                if (editorRef?.current) {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  const currentEditor = editorRef.current as any;

                  const innerHeight =
                    currentEditor.editor.parentElement.parentElement
                      .parentElement.clientHeight;
                  const contentHeight =
                    currentEditor.editor.parentElement.parentElement
                      .scrollHeight /
                      (collageWidth / 1000) +
                    12;

                  let paddingTop = 0;
                  let paddingBottom = 0;

                  if (editorWrapperRef.current) {
                    paddingTop = parseInt(
                      window
                        .getComputedStyle(editorWrapperRef.current, null)
                        .getPropertyValue("padding-top")
                    );

                    paddingBottom = parseInt(
                      window
                        .getComputedStyle(editorWrapperRef.current, null)
                        .getPropertyValue("padding-bottom")
                    );
                  }

                  if (
                    currentEditor.editor.parentElement.parentElement
                      .scrollHeight !== innerHeight
                  ) {
                    dispatch({
                      type: CollageDesignerActionType.SetNodeDimensions,
                      payload: {
                        targetId: id as number,
                        dimensions: {
                          width: 1000,
                          height: contentHeight + paddingTop + paddingBottom,
                          fitContent: true,
                        },
                      },
                    });
                  }
                }
              }, 10);

              return "not-handled";
            }}
            onChange={(editorState) => {
              setEditorState(editorState);

              let paddingTop = 0;
              let paddingBottom = 0;

              if (editorWrapperRef.current) {
                paddingTop = parseInt(
                  window
                    .getComputedStyle(editorWrapperRef.current, null)
                    .getPropertyValue("padding-top")
                );

                paddingBottom = parseInt(
                  window
                    .getComputedStyle(editorWrapperRef.current, null)
                    .getPropertyValue("padding-bottom")
                );
              }

              dispatch({
                type: CollageDesignerActionType.ModifyNode,
                payload: {
                  targetId: id as number,
                  node: {
                    textContentState: convertToRaw(
                      editorState.getCurrentContent()
                    ),
                  },
                },
              });

              setTimeout(() => {
                if (editorRef?.current) {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  const currentEditor = editorRef.current as any;

                  const innerHeight =
                    currentEditor.editor.parentElement.parentElement
                      .parentElement.clientHeight;
                  const contentHeight =
                    currentEditor.editor.parentElement.parentElement
                      .scrollHeight /
                      (collageWidth / 1000) +
                    12;

                  if (
                    currentEditor.editor.parentElement.parentElement
                      .scrollHeight != innerHeight
                  ) {
                    dispatch({
                      type: CollageDesignerActionType.SetNodeDimensions,
                      payload: {
                        targetId: id as number,
                        dimensions: {
                          width: 1000,
                          height: contentHeight + paddingTop + paddingBottom,
                          fitContent: true,
                        },
                      },
                    });
                  }
                }
              }, 10);
            }}
          />
        </Block>

        {textFontFamily !== "Arial" && (
          <style
            dangerouslySetInnerHTML={{
              __html: `
                  @import
                    url('https://fonts.googleapis.com/css2?family=${textFontFamily}:wght@${
                textFontWeight || 400
              }&display=swap');
                `,
            }}
          />
        )}
      </NodeRendererWrapper>
    </>
  );
}

const MemoizedTextNodeRenderer = memo(TextNodeRenderer);

export default MemoizedTextNodeRenderer;
