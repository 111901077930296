import AccountForm from "app/core/components/AccountForm";
import PasswordChangeForm from "app/core/components/PasswordChangeForm";
import RefLinkForm from "app/core/components/RefLinkForm";
import TwoFactorAuthenticationForm from "app/core/components/TwoFactorAuthenticationForm";
import AppLayout from "app/core/layouts/AppLayout";
import { Block } from "baseui/block";
import { Cell, Grid } from "baseui/layout-grid";
import { DisplayXSmall } from "baseui/typography";
import { ReactElement } from "react";

export default function Account(): ReactElement {
  return (
    <AppLayout>
      <Grid>
        <Cell span={12}>
          <DisplayXSmall
            $style={{
              fontSize: "28px",
              fontWeight: 300,
            }}
          >
            My Account
          </DisplayXSmall>
        </Cell>
        <Cell span={8}>
          <Block marginTop="scale800">
            <AccountForm />
          </Block>
          <TwoFactorAuthenticationForm />
          <Block marginTop="scale800">
            <RefLinkForm />
          </Block>
          <Block marginTop="scale800">
            <PasswordChangeForm />
          </Block>
        </Cell>
      </Grid>
    </AppLayout>
  );
}
