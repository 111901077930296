import Header from "app/core/components/Header";
import { UndoableAction } from "app/core/hooks/useUndoableReducer";
import { styled } from "baseui";
import { CollageDesignerProvider } from "contexts/CollageDesignerContext";

import {
  CollageDesignerAction,
  CollageDesignerState,
} from "../CollageDesigner/types";
import CanvasView from "./CanvasView";
import RightSidebar from "./RightSidebar";
import ToolBox from "./ToolBox";

type CollageDesignerProps = {
  initialState?: Partial<CollageDesignerState>;
  onSave: (
    state: CollageDesignerState,
    dispatch: React.Dispatch<UndoableAction<CollageDesignerAction>>
  ) => Promise<void>;
  onPublish: (
    state: CollageDesignerState,
    dispatch: React.Dispatch<UndoableAction<CollageDesignerAction>>
  ) => Promise<void>;
  onUnpublish: (
    state: CollageDesignerState,
    dispatch: React.Dispatch<UndoableAction<CollageDesignerAction>>
  ) => Promise<void>;
};

const CollageDesignerRoot = styled("div", {
  display: "flex",
  height: "calc(100vh - 70px)",
  alignItems: "stretch",
  position: "relative",
});

export default function CollageDesigner({
  initialState,
  onSave,
  onPublish,
  onUnpublish,
}: CollageDesignerProps): React.ReactElement {
  return (
    <CollageDesignerProvider
      initialState={initialState}
      onSave={onSave}
      onPublish={onPublish}
      onUnpublish={onUnpublish}
    >
      <Header inDesigner />
      <CollageDesignerRoot>
        <ToolBox />
        <CanvasView />
        <RightSidebar />
      </CollageDesignerRoot>
    </CollageDesignerProvider>
  );
}
