import composeRefs from "@seznam/compose-react-refs";
import { useDrag, useWheel } from "@use-gesture/react";
import { Minus, Plus, Redo, Undo } from "app/core/components/Icon";
import IconButton from "app/core/components/IconButton";
import useKeyPress from "app/core/hooks/useKeyPress";
import useRect from "app/core/hooks/useRect";
import { UndoableActionType } from "app/core/hooks/useUndoableReducer";
import { styled, useStyletron } from "baseui";
import { Block } from "baseui/block";
import { LabelSmall } from "baseui/typography";
import { CollageDesignerContext } from "contexts/CollageDesignerContext";
import {
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHotkeys } from "react-hotkeys-hook";

import { CollageDesignerActionType } from "../CollageDesigner/types";
import CollageRenderer from "./CollageRenderer";
import { ExtendedRect, Rect } from "./types";
import VirtualScrollBar from "./VirtualScrollBar";

const CanvasViewRoot = styled("div", {
  position: "relative",
  width: "100%",
  height: "100%",
  backgroundColor: "#f6f8f9",
  overflow: "hidden",
  touchAction: "none",
});

const Topbar = styled("div", {
  backgroundColor: "#fff",
  boxShadow: "2px -2px 5px rgba(0, 0, 0, 0.05)",
  width: "100%",
  flexShrink: 0,
  display: "flex",
  position: "absolute",
  justifyContent: "center",
  alignItems: "center",
  left: "0px",
  right: "0px",
  top: "0px",
  zIndex: 1,
});

const INITIAL_SCALE = 0.5;
const MOVE_ACTIVATION_KEY_CODE = " ";

export default function CanvasView(): React.ReactElement {
  const [css] = useStyletron();

  const rootDivElement = useRef<Element>(null);
  const rootDivElementRect = useRect(
    rootDivElement as RefObject<HTMLDivElement>
  ) as Rect;

  const [isDraggingViewPort, setIsDraggingViewPort] = useState(false);

  const {
    dispatch,
    canRedo,
    canUndo,
    state: {
      collageBody: { nodes },
      activeNodeIds,
      activeTextNodeId,
    },
    view: {
      collageTranslate,
      setCollageTranslate,
      collageWidth,
      setCollageWidth,
      collageScale,
      setCollageScale,
    },
  } = useContext(CollageDesignerContext);

  const viewport = useMemo<ExtendedRect>(
    () =>
      rootDivElementRect && collageWidth && collageTranslate
        ? {
            x: Math.abs(Math.min(collageTranslate.x, 0)),
            y: Math.abs(Math.min(collageTranslate.y, 0)),
            width: Math.max(
              0,
              Math.min(
                rootDivElementRect.width - collageTranslate.x,
                collageWidth
              )
            ),
            height: Math.max(
              0,
              Math.min(
                rootDivElementRect.height - collageTranslate.y,
                rootDivElementRect.height
              )
            ),
            absoluteWidth: rootDivElementRect.width,
            absoluteHeight: rootDivElementRect.height,
          }
        : {
            x: 0,
            y: 0,
            width: 0,
            height: 0,
            absoluteWidth: 0,
            absoluteHeight: 0,
          },
    [collageTranslate, collageWidth, rootDivElementRect, collageScale]
  );

  const collageHeight = useMemo(() => {
    const rootNode = nodes[0];
    return rootDivElementRect
      ? rootNode.type === "internal" &&
        rootNode.children.length > 0 &&
        rootNode.dimensions
        ? (rootNode.dimensions.height / rootNode.dimensions.width) *
          rootDivElementRect.width *
          INITIAL_SCALE
        : (rootDivElementRect.width * INITIAL_SCALE) / 1.5
      : 0;
  }, [rootDivElementRect, nodes[0]]);

  const isMoveActivationKeyPressed = useKeyPress(MOVE_ACTIVATION_KEY_CODE);

  const bindDraggingListeners = useDrag(
    ({ active, delta: [deltaX, deltaY] }) => {
      setCollageTranslate(({ x, y }) => ({ x: x + deltaX, y: y + deltaY }));
      setIsDraggingViewPort(active);
    },
    { enabled: isMoveActivationKeyPressed && collageScale > 1.4 }
  );

  useEffect(() => {
    if (rootDivElementRect) {
      setCollageWidth(rootDivElementRect.width * INITIAL_SCALE);
      setCollageTranslate({
        x: ((1 - collageScale * INITIAL_SCALE) / 2) * rootDivElementRect.width,
        y: (rootDivElementRect.height - collageHeight) / 2,
      });
    }
  }, [rootDivElementRect]);

  const changeCollageScale = useCallback(
    (newCollageScaleFunction: (collageScale: number) => number) => {
      setCollageScale((collageScale) => {
        let newCollageScale = newCollageScaleFunction(collageScale);
        newCollageScale = Math.max(0.5, newCollageScale);

        if (rootDivElementRect) {
          setCollageTranslate(({ y }) => ({
            x:
              ((1 - newCollageScale * INITIAL_SCALE) / 2) *
              rootDivElementRect.width,
            y: Math.max(
              Math.min(200, y),
              -(collageHeight * newCollageScale) + viewport.absoluteHeight - 200
            ),
          }));
        }
        return newCollageScale;
      });
    },
    [rootDivElementRect]
  );

  useHotkeys(
    "alt+*",
    (event) => {
      switch (event.key) {
        case "0":
        case "ľ":
          changeCollageScale(() => 1);
          break;
        case "+":
        case "=":
        case "≠":
          changeCollageScale((collageScale) => collageScale + 0.1);
          break;
        case "-":
        case "–":
          changeCollageScale((collageScale) => collageScale - 0.1);
          break;

        default:
          break;
      }
    },
    {},
    [rootDivElementRect]
  );

  useHotkeys(
    "ctrl+z, cmd+z",
    () => canUndo && dispatch({ type: UndoableActionType.Undo }),
    [canUndo]
  );

  useHotkeys(
    "ctrl+y, cmd+shift+z",
    () => canRedo && dispatch({ type: UndoableActionType.Redo }),
    [canRedo]
  );

  const selectAllHotkeyRef = useHotkeys(
    "ctrl+a, cmd+a",
    (event) => {
      event.preventDefault();
      dispatch({
        type: CollageDesignerActionType.ChangeActiveNodeIds,
        payload: {
          activeNodeIds: [
            ...Object.keys(nodes)
              .filter((key) => key !== "0" && key !== "lastId")
              .map((key) => +key),
          ],
        },
      });
    },
    [nodes]
  );

  useWheel(
    ({ delta: [, deltaY], direction: [, directionY], event, active }) => {
      if (active) {
        if (event.altKey) {
          changeCollageScale((collageScale) => collageScale - directionY / 10);
        } else {
          if (collageHeight > rootDivElementRect.height - 200) {
            setCollageTranslate(({ x, y }) => ({
              x,
              y: Math.max(
                Math.min(200, y - deltaY),
                -(collageHeight * collageScale) + viewport.absoluteHeight - 200
              ),
            }));
          } else {
            setCollageTranslate(({ x }) => ({
              x,
              y: (rootDivElementRect.height - collageHeight) / 2,
            }));
          }
        }
      }
    },
    {
      target: rootDivElement,
    }
  );

  useEffect(() => {
    function disableWindowBlurOnAlt(event: Event) {
      event.preventDefault();
    }

    window.addEventListener("keyup", disableWindowBlurOnAlt, {
      passive: false,
    });

    return () => window.removeEventListener("keyup", disableWindowBlurOnAlt);
  }, []);

  return (
    <CanvasViewRoot
      ref={
        composeRefs(
          rootDivElement,
          selectAllHotkeyRef
        ) as RefObject<HTMLDivElement>
      }
      tabIndex={-1}
      style={{
        cursor:
          isMoveActivationKeyPressed && collageScale > 1.4
            ? isDraggingViewPort
              ? "grabbing"
              : "grab"
            : "default",
        ...(isDraggingViewPort && { userSelect: "none" }),
      }}
      onClick={(event) => {
        if (event.target === event.currentTarget) {
          activeNodeIds.length > 0 &&
            dispatch({
              type: CollageDesignerActionType.ChangeActiveNodeIds,
              payload: {
                activeNodeIds: [],
              },
            });

          activeTextNodeId &&
            dispatch({
              type: CollageDesignerActionType.ChangeActiveTextNodeId,
              payload: {
                activeTextNodeId: -1,
              },
            });
        }
      }}
      {...bindDraggingListeners()}
    >
      <Topbar>
        <Block alignItems="center" display="flex">
          <IconButton
            size="mini"
            onClick={() => changeCollageScale(() => collageScale - 0.1)}
            minimal
            disabled={collageScale < 0.6}
          >
            <Minus />
          </IconButton>
          <LabelSmall color="#8390AE" $style={{ fontWeight: 500 }}>
            {Math.round(collageScale * 100) + "%"}
          </LabelSmall>
          <IconButton
            size="mini"
            onClick={() => changeCollageScale(() => collageScale + 0.1)}
            minimal
            disabled={collageScale > 1.5}
          >
            <Plus />
          </IconButton>
        </Block>
        <span
          className={css({
            width: "1px",
            height: "16px",
            backgroundColor: "#8390AE",
            opacity: "0.2",
            marginLeft: "16px",
            marginRight: "20px",
            display: "block",
          })}
        ></span>
        <IconButton
          size="mini"
          onClick={() => dispatch({ type: UndoableActionType.Undo })}
          minimal
          disabled={!canUndo}
        >
          <Undo />
        </IconButton>
        <IconButton
          size="mini"
          onClick={() => dispatch({ type: UndoableActionType.Redo })}
          minimal
          disabled={!canRedo}
        >
          <Redo />
        </IconButton>
      </Topbar>
      {viewport && (
        <>
          <CollageRenderer
            rootDivElement={rootDivElement as RefObject<HTMLDivElement>}
            width={collageWidth}
            height={collageHeight}
            translate={collageTranslate}
            setTranslate={setCollageTranslate}
            scale={collageScale}
            viewport={viewport}
          />
          <VirtualScrollBar
            position={collageTranslate.y}
            contentSize={collageHeight * collageScale}
            viewportSize={viewport.absoluteHeight}
            setTranslate={setCollageTranslate}
          />
        </>
      )}
    </CanvasViewRoot>
  );
}
