import { createTheme } from "baseui";

const primitives = {
  primaryFontFamily: "'Roboto', sans-serif",
};

const overrides = {
  colors: {
    primary: "#AF9883",
    contentPrimary: "#3C3C3C",
    accent: "#F5BA47",
    buttonPrimaryFill: "#AF9883",
    buttonPrimaryHover: "#AC9580",
    buttonPrimaryActive: "#AC9580",
    buttonSecondaryFill: "#FFFFFF",
    buttonSecondaryHover: "#FCFCFC",
    buttonSecondaryActive: "#FCFCFC",
    buttonSecondaryText: "#3C3C3C",
    inputBorder: "#E6EAF0",
    inputFill: "#FFFFFF",
    inputFocus: "#E6EAF0",
    borderFocus: "#E6EAF0",
    borderSelected: "#E6EAF0",
    tagPrimarySolidBackground: "#AF9883",
    backgroundSecondary: "#F8F8F8",
  },
};

export default createTheme(primitives, overrides);
