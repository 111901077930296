import { gql, useQuery } from "@apollo/client";
import Table from "app/core/components/Table";
import AppLayout from "app/core/layouts/AppLayout";
import { useStyletron } from "baseui";
import { Cell, Grid } from "baseui/layout-grid";
import { StyledLink } from "baseui/link";
import { Pagination } from "baseui/pagination";
import { TableBuilderColumn } from "baseui/table-semantic";
import { DisplayXSmall } from "baseui/typography";
import { ReactElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";

const FETCH_USERS = gql`
  query Users($skip: Int, $take: Int) {
    users(skip: $skip, take: $take) {
      totalCount
      items {
        id
        firstName
        lastName
        email
        phone
      }
    }
  }
`;

const PAGE_SIZE = 10;

export default function ListUsersPage(): ReactElement {
  const [css] = useStyletron();
  const [currentPage, setCurrentPage] = useState(1);
  const { loading, data, refetch } = useQuery(FETCH_USERS, {
    variables: { skip: 0, take: PAGE_SIZE },
  });

  const numPages =
    data && data.users ? Math.ceil(data.users.totalCount / PAGE_SIZE) : 0;

  useEffect(() => {
    refetch();
  }, []);

  return (
    <AppLayout>
      <Grid>
        <Cell span={12}>
          <div
            className={css({
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "20px",
              paddingBottom: "20px",
            })}
          >
            <DisplayXSmall
              $style={{
                fontSize: "28px",
                fontWeight: 300,
              }}
            >
              Users
            </DisplayXSmall>
          </div>

          <Table
            isLoading={loading}
            currentPage={currentPage}
            pageSize={PAGE_SIZE}
            data={(data && data.users && data.users.items) || []}
          >
            <TableBuilderColumn
              id="id"
              header="Id."
              numeric
              overrides={{
                TableBodyCell: {
                  style: {
                    width: "10px",
                  },
                },
              }}
            >
              {(row) => row.id}
            </TableBuilderColumn>
            <TableBuilderColumn id="email" header="E-mail">
              {(row) => (
                <StyledLink
                  $as={Link}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  to={`/admin/users/${row.id}`}
                >
                  {row.email}
                </StyledLink>
              )}
            </TableBuilderColumn>
            <TableBuilderColumn id="firstName" header="First name">
              {(row) => row.firstName}
            </TableBuilderColumn>
            <TableBuilderColumn id="lastName" header="Last name">
              {(row) => row.lastName}
            </TableBuilderColumn>
            <TableBuilderColumn id="phone" header="Phone">
              {(row) => row.phone}
            </TableBuilderColumn>
          </Table>

          <Pagination
            size="compact"
            numPages={numPages}
            currentPage={currentPage}
            onPageChange={async ({ nextPage }) => {
              await refetch({
                skip: (nextPage - 1) * PAGE_SIZE,
                take: PAGE_SIZE,
              });

              setCurrentPage(Math.min(Math.max(nextPage, 1), numPages));
            }}
            overrides={{ Root: { style: { marginTop: "20px" } } }}
          />
        </Cell>
      </Grid>
    </AppLayout>
  );
}
