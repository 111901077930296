import { gql, useMutation } from "@apollo/client";
import LoadingSpinner from "app/core/components/LoadingSpinner";
import { toaster } from "baseui/toast";
import { ReactElement, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

const VERIFY_EMAIL = gql`
  mutation VerifyEmail($input: VerifyEmailInput!) {
    verifyEmail(input: $input)
  }
`;

interface VerifyEmailPageParams {
  token: string;
  email: string;
}

export default function VerifyEmailPage(): ReactElement {
  const history = useHistory();

  const { token, email } = useParams<VerifyEmailPageParams>();
  const [verifyEmail] = useMutation(VERIFY_EMAIL, {
    variables: {
      input: {
        token: decodeURIComponent(token),
        email: decodeURIComponent(email),
      },
    },
  });

  useEffect(() => {
    (async () => {
      try {
        await verifyEmail();

        toaster.positive(
          "Your e-mail address has been verified. You can now login",
          {
            autoHideDuration: 4000,
          }
        );

        history.push("/login");
      } catch (error) {
        toaster.negative("Something went wrong.", {
          autoHideDuration: 4000,
        });

        history.push("/login");
      }
    })();
  }, []);

  return <LoadingSpinner />;
}
