/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAuth } from "app/auth/context";
import Button from "app/core/components/Button";
import {
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
} from "app/core/components/Icon";
import InfoBox from "app/core/components/InfoBox";
import Input from "app/core/components/Input";
import Select from "app/core/components/Select";
import Textarea from "app/core/components/Textarea";
import { styled, useStyletron } from "baseui";
import { Accordion, Panel as BasePanel } from "baseui/accordion";
import { Block } from "baseui/block";
import { ListItem } from "baseui/list";
import { ProgressBar } from "baseui/progress-bar";
import { LabelMedium, LabelSmall, ParagraphMedium } from "baseui/typography";
import { CollageDesignerContext } from "contexts/CollageDesignerContext";
import React, { useContext, useEffect, useState } from "react";
import { useRef } from "react";
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from "react-hook-form";
import { useHistory } from "react-router";

import {
  CollageDesignerActionType,
  CollageNodeType,
  SeoScoreRuleType,
  TextType,
} from "./types";
import FormControl from "./ui/FormControl";
import Panel from "./ui/Panel";

const Overlay = styled("div", {
  position: "absolute",
  top: 0,
  left: 0,
  width: "720px",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 2,
});

const FlexSpaceBetween = styled("div", {
  display: "flex",
  justifyContent: "space-between",
});

const ExpandedSidebar = styled(
  "div",
  ({ $isExpanded }: { $isExpanded?: boolean }) => ({
    backgroundColor: "#FCFCFD",
    //filter: "drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.05))",
    height: "100%",
    minWidth: "360px",
    flexShrink: 0,
    display: "flex",
    flexDirection: "column",
    // paddingTop: "26px",
    // paddingBottom: "26px",
    // paddingLeft: "26px",
    // paddingRight: "26px",
    position: "absolute",
    left: $isExpanded ? "100%" : "0%",
    top: "0px",
    zIndex: 1,
    borderLeft: "1px solid #E9ECF2",
    overflow: "auto",
  })
);

type CollageSeoSettingsContainerProps = {
  children?: React.ReactNode;
};

export function CollageSeoSettingsContainer({
  children,
}: CollageSeoSettingsContainerProps): React.ReactElement {
  const methods = useForm({
    mode: "onChange",
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
}

export function ExpandButton(): React.ReactElement {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  return isExpanded ? (
    <span onClick={() => setIsExpanded(!isExpanded)}>
      <ChevronUp />
    </span>
  ) : (
    <span onClick={() => setIsExpanded(!isExpanded)}>
      <ChevronDown />
    </span>
  );
}

export default function CollageSeoSettings(): React.ReactElement {
  const [initialValidated] = useState<boolean>(false);
  const { control, register, handleSubmit, errors } = useFormContext();

  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const {
    verifyFeatureAvailability,
    setIsFeatureNotAvailableModalOpen,
  } = useAuth();

  const [css] = useStyletron();
  const history = useHistory();

  const onSubmit = () => null;

  const {
    state: {
      collageBody: { seo, nodes },
    },
    dispatch,
  } = useContext(CollageDesignerContext);

  const isSeoAvailable = verifyFeatureAvailability("advancedContentSeo");

  return (
    <>
      {!isSeoAvailable && (
        <Overlay $style={{ width: isExpanded ? "720px" : "360px" }}>
          <ParagraphMedium>
            This feature is not available in your plan.
          </ParagraphMedium>
          <Button
            onClick={() => {
              history.push("/billing");
            }}
          >
            Upgrade plan
          </Button>
        </Overlay>
      )}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={css({ ...(!isSeoAvailable && { filter: "blur(2px)" }) })}
      >
        <FormControl label="Google preview">
          <div
            className={css({
              backgroundColor: "#fff",
              borderRadius: "4px",
              border: "1px solid #E6EAF0",
              padding: "20px",
            })}
          >
            <LabelMedium color="#1419A4" $style={{ fontWeight: 400 }}>
              {seo?.title?.slice(0, 50) || "Enter title below"}
              {seo?.title && seo?.title?.length > 50 && "..."}
            </LabelMedium>
            <span
              className={css({
                color: "#2A632A",
                display: "block",
                marginTop: "10px",
                marginBottom: "10px",
              })}
            >
              https://your-domain.com/
            </span>
            <span
              className={css({
                color: "#929292",
                display: "block",
                wordBreak: "break-word",
              })}
            >
              {seo?.description?.slice(0, 155) || "Enter description below"}
              {seo?.description && seo?.description?.length > 155 && "..."}
            </span>
          </div>

          <InfoBox>
            Remember that our feature is a preview. In the next step, you have
            to paste these texts into your SEO plugin.
          </InfoBox>
        </FormControl>
        <FormControl
          label={() => (
            <FlexSpaceBetween>
              <span>SEO Title</span>
              <span
                className={css({
                  fontSize: "0.85rem",
                  textTransform: "none",
                  letterSpacing: "unset",
                })}
              >
                &bull; Include your focus keywords.
              </span>
            </FlexSpaceBetween>
          )}
          caption={() => (
            <FlexSpaceBetween>
              <span className={css({ marginLeft: "auto" })}>
                {seo?.title?.length}/50-65 characters
                <span
                  className={css({
                    width: "6px",
                    height: "6px",
                    borderRadius: "50%",
                    backgroundColor:
                      seo?.title && seo?.title?.length > 50
                        ? "#A5DD8A"
                        : "#F0DEB1",
                    display: "inline-block",
                    marginLeft: "6px",
                    top: "-1px",
                    position: "relative",
                  })}
                ></span>
              </span>
            </FlexSpaceBetween>
          )}
          validated={initialValidated}
        >
          <Controller
            name="title"
            defaultValue=""
            control={control}
            register={register}
            rules={{
              required: {
                value: true,
                message: "Tytuł nie może być pusty",
              },
            }}
            render={({ onChange, onBlur }) => (
              <Input
                value={seo?.title}
                onBlur={onBlur}
                onChange={(event: React.FormEvent<HTMLInputElement>) => {
                  onChange(event);
                  dispatch({
                    type: CollageDesignerActionType.ChangeCollageBody,
                    payload: {
                      seo: {
                        ...seo,
                        title: event.currentTarget.value,
                      },
                    },
                  });
                }}
                overrides={{
                  Input: {
                    props: {
                      name: "title",
                    },
                  },
                }}
                endEnhancer={() => (
                  <ProgressBar
                    value={seo?.title?.length || 0}
                    successValue={50}
                    overrides={{
                      Root: {
                        style: {
                          position: "absolute",
                          bottom: "0px",
                          right: "0px",
                          left: "0px",
                          height: "2px",
                        },
                      },
                      BarContainer: {
                        style: {
                          marginTop: "0px",
                          marginBottom: "0px",
                          marginRight: "0px",
                          marginLeft: "0px",
                        },
                      },
                    }}
                  />
                )}
                error={errors?.title}
              />
            )}
          />
        </FormControl>
        <FormControl
          label={() => (
            <FlexSpaceBetween>
              <span>Description</span>
              <span
                className={css({
                  fontSize: "0.85rem",
                  textTransform: "none",
                  letterSpacing: "unset",
                })}
              >
                &bull; Include your focus keywords.
              </span>
            </FlexSpaceBetween>
          )}
          caption={() => (
            <FlexSpaceBetween>
              <span className={css({ marginLeft: "auto" })}>
                {seo?.description?.length}/135-300 characters
                <span
                  className={css({
                    width: "6px",
                    height: "6px",
                    borderRadius: "50%",
                    backgroundColor:
                      seo?.description && seo?.description?.length > 135
                        ? "#A5DD8A"
                        : "#F0DEB1",
                    display: "inline-block",
                    marginLeft: "6px",
                    top: "-1px",
                    position: "relative",
                  })}
                ></span>
              </span>
            </FlexSpaceBetween>
          )}
          validated={initialValidated}
        >
          <Controller
            name="description"
            control={control}
            register={register}
            rules={{
              required: {
                value: true,
                message: "Opis nie może być pusty",
              },
            }}
            defaultValue=""
            render={({ onChange, onBlur }) => (
              <Textarea
                onBlur={onBlur}
                value={seo?.description}
                onChange={(event: React.FormEvent<HTMLTextAreaElement>) => {
                  onChange(event);
                  dispatch({
                    type: CollageDesignerActionType.ChangeCollageBody,
                    payload: {
                      seo: {
                        ...seo,
                        description: event.currentTarget.value,
                      },
                    },
                  });
                }}
                endEnhancer={
                  <ProgressBar
                    value={seo?.description?.length || 0}
                    successValue={135}
                    overrides={{
                      Root: {
                        style: {
                          position: "absolute",
                          bottom: "0px",
                          right: "0px",
                          left: "0px",
                          height: "2px",
                        },
                      },
                      BarContainer: {
                        style: {
                          marginTop: "0px",
                          marginBottom: "0px",
                          marginRight: "0px",
                          marginLeft: "0px",
                        },
                      },
                    }}
                  />
                }
                overrides={{
                  Input: {
                    props: {
                      name: "description",
                    },
                  },
                }}
              />
            )}
          />
        </FormControl>
        <FormControl
          label={
            <Block
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              Keywords
              <Button
                disabled={!seo?.tags?.length}
                $style={{}}
                size="mini"
                type="button"
                onClick={() => {
                  if (verifyFeatureAvailability("googleTrends")) {
                    window.open(
                      `https://trends.google.pl/trends/explore?geo=US&q=` +
                        seo?.tags?.map(({ label }) => label).join(","),
                      "_blank"
                    );
                  } else {
                    setIsFeatureNotAvailableModalOpen(true);
                  }
                }}
              >
                Compare in Google Trends
              </Button>
            </Block>
          }
          caption="Choose a single word, phrase or part of a sentence that people will likely search for. You can also use multiple keywords."
        >
          <Controller
            name="tags"
            control={control}
            register={register}
            defaultValue=""
            render={({ onChange, onBlur }) => (
              <Select
                creatable
                multi
                onBlur={onBlur}
                onInputChange={onChange}
                noResultsMsg="Enter a keyword"
                placeholder="Enter a keyword"
                onChange={({ value }) =>
                  dispatch({
                    type: CollageDesignerActionType.ChangeCollageBody,
                    payload: {
                      seo: {
                        ...seo,
                        tags: value,
                      },
                    },
                  })
                }
                value={seo?.tags}
                overrides={{
                  Input: {
                    props: {
                      name: "tags",
                    },
                  },
                  ValueContainer: {
                    style: {
                      maxHeight: "250px",
                      overflowY: "auto",
                    },
                  },
                  Tag: {
                    props: {
                      overrides: {
                        Text: {
                          style: {
                            whiteSpace: "normal",
                            maxWidth: "unset",
                            wordBreak: "break-word",
                          },
                        },
                        Root: {
                          style: {
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            height: "auto",
                            borderRadius: "16px",
                          },
                        },
                      },
                    },
                  },
                  SelectArrow: {
                    props: {
                      overrides: {
                        // eslint-disable-next-line react/display-name
                        Svg: () => null,
                      },
                    },
                  },
                }}
                error={errors?.tags}
              />
            )}
          />
        </FormControl>
      </form>
      <div
        className={css({
          cursor: "pointer",
          position: "absolute",
          left: isExpanded ? "200%" : "100%",
          top: "20px",
          backgroundColor: "#AF9883",
          width: "16px",
          height: "80px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "0px 10px 10px 0px",
        })}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {isExpanded ? <ChevronLeft /> : <ChevronRight />}
      </div>
      <ExpandedSidebar
        $isExpanded={isExpanded}
        $style={{ ...(!isSeoAvailable && { filter: "blur(2px)" }) }}
      >
        <Panel title="SEO Analysis">
          <Accordion
            accordion={false}
            overrides={{
              Root: {
                style: {},
              },
              Header: {
                style: {
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  backgroundColor: "unset",
                  color: "#535A68",
                  fontSize: "1rem",
                  fontWeight: 500,
                  borderBottomWidth: "0px",
                },
              },
              Content: {
                style: {
                  backgroundColor: "unset",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                  borderBottomColor: "#E9ECF2",
                },
              },
              // eslint-disable-next-line react/display-name
              ToggleIcon: () => <ExpandButton />,
            }}
          >
            {seo.seoScore?.rulesets.map((ruleset, index) => {
              const panelRef = useRef<BasePanel>(null);
              const [score, setScore] = useState<number>(0);

              useEffect(
                () =>
                  setScore(
                    (panelRef?.current?.props?.children as any)?.filter(
                      (c: any) =>
                        c?.props?.overrides?.Root?.props["data-seo"] === "green"
                    ).length
                  ),
                [seo]
              );

              return (
                <BasePanel
                  ref={panelRef}
                  key={`ruleset` + index}
                  title={
                    <>
                      <span
                        className={css({
                          marginRight: "15px",
                          width: "10px",
                          height: "10px",
                          backgroundColor:
                            score === ruleset?.rules?.length
                              ? "#A5DD8A"
                              : score > 0
                              ? "#F0DEB1"
                              : "red",
                          borderRadius: "50%",
                        })}
                      ></span>
                      <span className={css({ marginRight: "auto" })}>
                        {ruleset.title}
                      </span>
                    </>
                  }
                >
                  {ruleset?.rules.map((item, index) => {
                    let value;

                    switch (item.type) {
                      case SeoScoreRuleType.MainKeywordInTitle:
                        value =
                          (seo?.tags &&
                            seo?.title
                              ?.toLowerCase()
                              .includes(
                                String(seo?.tags[0]?.id).toLowerCase()
                              )) ||
                          0;
                        break;

                      case SeoScoreRuleType.MainKeywordInTitleStart:
                        value =
                          (seo?.tags &&
                            seo?.title
                              ?.toLowerCase()
                              .startsWith(
                                String(seo?.tags[0]?.id).toLowerCase()
                              )) ||
                          0;
                        break;

                      case SeoScoreRuleType.NumberInTitle:
                        value = seo?.title?.match(/([1-9][0-9]*)/)?.length || 0;
                        break;

                      case SeoScoreRuleType.MainKeywordInDescription:
                        value =
                          (seo?.tags &&
                            seo?.description
                              ?.toLowerCase()
                              ?.includes(
                                String(seo?.tags[0]?.id).toLowerCase()
                              )) ||
                          0;
                        break;

                      case SeoScoreRuleType.TitleLength:
                        value = seo?.title?.length || 0;
                        break;

                      case SeoScoreRuleType.DescriptionLength:
                        value = seo?.description?.length || 0;
                        break;

                      case SeoScoreRuleType.MainKeywordOnTopContent:
                        value =
                          (seo?.tags &&
                            JSON.stringify(
                              Object.values(nodes).filter(
                                (o: any) => o.type === CollageNodeType.Text
                              )
                            )
                              .slice(
                                0,
                                Math.max(
                                  JSON.stringify(
                                    Object.values(nodes).filter(
                                      (o: any) =>
                                        o.type === CollageNodeType.Text
                                    )
                                  ).length / 10,
                                  1000
                                )
                              )
                              ?.toLowerCase()
                              ?.includes(
                                String(seo?.tags[0]?.id).toLowerCase()
                              )) ||
                          0;
                        break;

                      case SeoScoreRuleType.MainKeywordInContent:
                        value =
                          (seo?.tags &&
                            JSON.stringify(nodes)
                              ?.toLowerCase()
                              ?.includes(
                                String(seo?.tags[0]?.id).toLowerCase()
                              )) ||
                          0;
                        break;

                      case SeoScoreRuleType.MainKeywordInHeadings:
                        value =
                          (seo?.tags &&
                            JSON.stringify(
                              Object.values(nodes).filter(
                                (o: any) =>
                                  o.type === CollageNodeType.Text &&
                                  o.textType === TextType.Heading
                              )
                            )
                              ?.toLowerCase()
                              ?.includes(
                                String(seo?.tags[0]?.id).toLowerCase()
                              )) ||
                          0;
                        break;

                      case SeoScoreRuleType.MainKeywordInAltTexts:
                        value =
                          (seo?.tags &&
                            JSON.stringify(
                              Object.values(nodes)
                                .filter(
                                  (o: any) => o.type === CollageNodeType.Photo
                                )
                                .map(
                                  (photo: any) =>
                                    photo?.metaData?.title ||
                                    String(seo?.tags?.[0]?.id)
                                )
                            )
                              ?.toLowerCase()
                              ?.includes(
                                String(seo?.tags[0]?.id).toLowerCase()
                              )) ||
                          0;
                        break;

                      case SeoScoreRuleType.KeywordDensity:
                        value =
                          (seo?.tags &&
                            Object.values(nodes)
                              .filter(
                                (o: any) => o.type === CollageNodeType.Text
                              )
                              .map((text: any) =>
                                text?.textContentState?.blocks
                                  ?.map((block: any) =>
                                    block?.text.toLowerCase()
                                  )
                                  .flat()
                              )
                              .join(" ")
                              .match(
                                new RegExp(
                                  String(seo?.tags[0]?.id).toLowerCase(),
                                  "gm"
                                )
                              )?.length) ||
                          0;
                        break;

                      default:
                        value = 0;
                    }

                    return (
                      <ListItem
                        key={index}
                        overrides={{
                          ArtworkContainer: {
                            style: {
                              width: "10px",
                              flexShrink: 0,
                              marginRight: "15px",
                            },
                          },
                          Content: {
                            style: {
                              height: "auto",
                              borderBottomStyle: "none",
                              color: "#535A68",
                              fontSize: "1rem",
                              fontWeight: 400,
                              marginLeft: "0px",
                              display: "block",
                            },
                          },
                          Root: {
                            style: {
                              marginBottom: "8px",
                              backgroundColor: "unset",
                              flexWrap: "wrap",
                            },
                            props: {
                              "data-seo":
                                value >= item.success
                                  ? "green"
                                  : value < item.min
                                  ? "red"
                                  : "yellow",
                            },
                          },
                        }}
                      >
                        <div
                          className={css({
                            display: "flex",
                            alignItems: "center",
                          })}
                        >
                          <span
                            className={css({
                              flexShrink: 0,
                              width: "10px",
                              height: "10px",
                              backgroundColor:
                                value >= item.success
                                  ? "#A5DD8A"
                                  : value < item.min
                                  ? "red"
                                  : "#F0DEB1",
                              borderRadius: "50%",
                              marginRight: "15px",
                            })}
                          ></span>
                          {value >= item.success
                            ? item.label.success
                            : value < item.min
                            ? item.label.error
                            : item.label.warning || item.label.success}
                        </div>
                        <div
                          className={css({
                            backgroundColor: "#f7f7f9",
                            borderRadius: "4px",
                            padding: "25px",
                            width: "100%",
                            flexGrow: 1,
                            marginTop: "15px",
                            marginBottom: "15px",
                          })}
                        >
                          <LabelSmall
                            marginBottom="scale300"
                            color="#AF9883"
                            $style={{ fontWeight: 500 }}
                          >
                            Recommendation
                          </LabelSmall>
                          {item.description}
                        </div>
                      </ListItem>
                    );
                  })}
                </BasePanel>
              );
            })}
          </Accordion>
        </Panel>
      </ExpandedSidebar>
    </>
  );
}
