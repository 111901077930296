import { withStyle } from "baseui";
import { Block } from "baseui/block";
import { StyledSpinnerNext } from "baseui/spinner";

const Spinner = withStyle(StyledSpinnerNext, {
  borderTopColor: "#AF9883",
});

export default function LoadingSpinner({
  noWrapper,
  ...props
}: {
  noWrapper?: boolean;
  $size?: string;
}): React.ReactElement {
  if (noWrapper) return <Spinner {...props} />;

  return (
    <Block
      width="100%"
      height="calc(100vh - 69px)"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Spinner {...props} />
    </Block>
  );
}
