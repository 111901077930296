import {
  Category,
  Font,
  FontManager,
  Options,
  OPTIONS_DEFAULTS,
  Script,
  SortOption,
  Variant,
} from "@samuelmeuli/font-manager";
import Select from "app/core/components/Select";
import { SelectProps } from "baseui/select";
import { PureComponent, ReactElement } from "react";

type LoadingStatus = "loading" | "finished" | "error";

type Props = {
  // Required props
  apiKey: string;

  // Optional props
  activeFontFamily: string;
  onChange: (font: Font) => void;
  pickerId: string;
  families: string[];
  categories: Category[];
  scripts: Script[];
  variants: Variant[];
  filter: (font: Font) => boolean;
  limit: number;
  sort: SortOption;
} & SelectProps;

interface State {
  expanded: boolean;
  loadingStatus: LoadingStatus;
}

export default class FontPicker extends PureComponent<Props, State> {
  // Instance of the FontManager class used for managing, downloading and applying fonts
  fontManager: FontManager;

  static defaultProps = {
    activeFontFamily: undefined,
    onChange: (): void => {}, // eslint-disable-line @typescript-eslint/no-empty-function
    pickerId: OPTIONS_DEFAULTS.pickerId,
    families: OPTIONS_DEFAULTS.families,
    categories: OPTIONS_DEFAULTS.categories,
    scripts: OPTIONS_DEFAULTS.scripts,
    variants: OPTIONS_DEFAULTS.variants,
    filter: OPTIONS_DEFAULTS.filter,
    limit: OPTIONS_DEFAULTS.limit,
    sort: OPTIONS_DEFAULTS.sort,
  };

  state: Readonly<State> = {
    expanded: false,
    loadingStatus: "loading",
  };

  constructor(props: Props) {
    super(props);

    const {
      apiKey,
      activeFontFamily,
      pickerId,
      families,
      categories,
      scripts,
      variants,
      filter,
      limit,
      sort,
      onChange,
    } = this.props;

    const options: Options = {
      pickerId,
      families,
      categories,
      scripts,
      variants,
      filter,
      limit,
      sort,
    };

    // Initialize FontManager object
    this.fontManager = new FontManager(
      apiKey,
      activeFontFamily,
      options,
      onChange
    );
  }

  componentDidMount = (): void => {
    // Generate font list
    this.fontManager
      .init()
      .then((): void => {
        this.setState({
          loadingStatus: "finished",
        });
      })
      .catch((): void => {
        // On error: Log error message
        this.setState({
          loadingStatus: "error",
        });
      });
  };

  render = (): ReactElement => {
    const { activeFontFamily, sort, ...props } = this.props;
    const { loadingStatus } = this.state;

    // Extract and sort font list
    const fonts = Array.from(this.fontManager.getFonts().values()).filter(
      (font) => !font.family.includes("Material Icons")
    );

    if (sort === "alphabet") {
      fonts.sort((font1: Font, font2: Font): number =>
        font1.family.localeCompare(font2.family)
      );
    }

    // Render font picker button and attach font list to it
    return (
      <Select
        options={fonts.map((font) => ({
          label: font.family,
          ...font,
        }))}
        value={activeFontFamily ? [{ label: activeFontFamily }] : undefined}
        clearable={true}
        searchable={true}
        isLoading={loadingStatus === "loading" ? true : false}
        placeholder="default font"
        {...props}
      />
    );
  };
}
