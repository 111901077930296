import { useAuth } from "app/auth/context";
import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

import Header from "./Header";

type PrivateRouteProps = RouteProps;

export default function PrivateRoute({
  children,
  ...props
}: PrivateRouteProps): React.ReactElement {
  const { isAuthenticated } = useAuth();

  return (
    <Route
      {...props}
      render={({ location }) =>
        isAuthenticated ? (
          <>
            <Header />
            {children}
          </>
        ) : (
          <Redirect
            to={{
              pathname: `/login`,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
