import { styled } from "baseui";
import { ReactNode } from "react";
import { ReactElement } from "react";

import SideNav from "../components/SideNav";

const AppLayoutRoot = styled("div", {
  display: "flex",
  alignItems: "stretch",
  height: "calc(100vh - 69px)",
});

const Content = styled("div", {
  flexGrow: 1,
  overflow: "auto",
  padding: "20px",
});

type AppLayoutProps = {
  children: ReactNode;
};

export default function AppLayout({ children }: AppLayoutProps): ReactElement {
  return (
    <AppLayoutRoot>
      <SideNav />
      <Content>{children}</Content>
    </AppLayoutRoot>
  );
}
