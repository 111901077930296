import { useStyletron } from "baseui";
import { CollageDesignerContext } from "contexts/CollageDesignerContext";
import React, { useContext, useMemo } from "react";

type DraggablePreviewProps = {
  id: string;
};

export default function DraggablePreview({
  id,
}: DraggablePreviewProps): React.ReactElement {
  const [css, theme] = useStyletron();
  const {
    state: { photos },
  } = useContext(CollageDesignerContext);

  const photo = useMemo(
    () => photos.find((photo) => photo.id === parseInt(id.split("@")[1])),
    [id]
  );
  const count = useMemo(() => id.split("|").length, [id]);

  return (
    <div
      className={css({
        height: "100px",
        width: "100px",
        ...(photo && {
          backgroundImage: `url(${photo.url}?width=150&version=${photo.updatedAt}&format=webp)`,
        }),
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "contain",
        cursor: "grabbing",
        position: "relative",
      })}
    >
      {count > 1 && (
        <div
          className={css({
            width: "32px",
            height: "32px",
            textAlign: "center",
            backgroundColor: theme.colors.accent200,
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "-16px",
            position: "absolute",
          })}
        >
          {count}
        </div>
      )}
    </div>
  );
}
