import { gql, useMutation, useQuery, useSubscription } from "@apollo/client";
import { useAuth } from "app/auth/context";
import Button from "app/core/components/Button";
import { ControlledCheckbox } from "app/core/components/Checkbox";
import { HorizontalLine } from "app/core/components/HorizontalLine";
import { ControlledInput } from "app/core/components/Input";
import LoadingSpinner from "app/core/components/LoadingSpinner";
import Radio, { ControlledRadioGroup } from "app/core/components/Radio";
import { useStyletron } from "baseui";
import { Accordion, Panel } from "baseui/accordion";
import { Block } from "baseui/block";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import { FormControl } from "baseui/form-control";
import { Modal, ModalBody, ModalHeader, ROLE, SIZE } from "baseui/modal";
import { toaster } from "baseui/toast";
import { LabelLarge, LabelSmall, LabelXSmall } from "baseui/typography";
import { CollageDesignerContext } from "contexts/CollageDesignerContext";
import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { ReactElement, useContext } from "react";
import { useForm } from "react-hook-form";

import { ControlledColorPicker } from "./ui/ColorPicker";

const MAX_COLLAGE_HEIGHT = 10000;

const EXPORT_COLLAGE = gql`
  mutation ExportCollage($input: ExportCollageInput!) {
    exportCollage(input: $input) {
      id
    }
  }
`;

const COLLAGE_LAST_EXPORTS = gql`
  query CollageLastExports($collageId: Id!) {
    collage(id: $collageId) {
      lastExports {
        id
        type
        url
        progress
        state
        createdAt
      }
    }
  }
`;

const COLLAGE_EXPORT_COMPLETED = gql`
  subscription OnCollageExportCompleted($collageId: Id!) {
    collageExportCompleted(collageId: $collageId) {
      id
    }
  }
`;

const COLLAGE_EXPORT_PROGRESS = gql`
  subscription OnCollageExportProgress($collageId: Id!) {
    collageExportProgress(collageId: $collageId) {
      id
    }
  }
`;

const COLLAGE_EXPORT_FAILED = gql`
  subscription OnCollageExportFailed($collageId: Id!) {
    collageExportFailed(collageId: $collageId) {
      id
    }
  }
`;

type ExportModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

enum ExportType {
  Blog = "Blog",
  Collage = "Collage",
}

type FormFields = {
  type: ExportType;
  width: number;
  backgroundColor: string;
  includePrefix: boolean;
  prefix?: string;
  includeSeoKeywords: boolean;
  sendEmailAfterCompletion: boolean;
};

export default function ExportModal({
  isOpen,
  onClose,
}: ExportModalProps): ReactElement {
  const {
    state: {
      collageId,
      collageBody: { nodes, backgroundColor },
    },
  } = useContext(CollageDesignerContext);
  const [exportCollage] = useMutation(EXPORT_COLLAGE);
  const { data, refetch, loading } = useQuery(COLLAGE_LAST_EXPORTS, {
    variables: { collageId },
  });

  useSubscription(COLLAGE_EXPORT_COMPLETED, {
    variables: { collageId },
    onSubscriptionData() {
      if (!loading) {
        refetch();
      }
    },
  });

  useSubscription(COLLAGE_EXPORT_PROGRESS, {
    variables: { collageId },
    onSubscriptionData() {
      if (!loading) {
        refetch();
      }
    },
  });

  useSubscription(COLLAGE_EXPORT_FAILED, {
    variables: { collageId },
    onSubscriptionData() {
      if (!loading) {
        refetch();
      }
    },
  });

  const {
    user,
    setUser,
    verifyFeatureAvailability,
    setIsFeatureNotAvailableModalOpen,
  } = useAuth();

  const [css] = useStyletron();
  const [
    isLastExportsAccordionExpanded,
    setIsLastExportsAccordionExpanded,
  ] = useState(false);

  const {
    control,
    errors,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting },
  } = useForm<FormFields>({
    defaultValues: {
      type: ExportType.Blog,
      width: 1500,
      backgroundColor: backgroundColor || "#ffffff",
      includePrefix: false,
      includeSeoKeywords: false,
    },
  });

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setValue("backgroundColor", backgroundColor, {
          shouldDirty: true,
        });
      }, 0);
    }
  }, [isOpen]);

  const onSubmit = async (data: FormFields) => {
    let shouldExport = true;
    if (data.type === ExportType.Blog) {
      if (!verifyFeatureAvailability("blogExport")) {
        setIsFeatureNotAvailableModalOpen(true);
        return false;
      }
    } else {
      if (user && user.availableFeatures) {
        if (user.availableFeatures["allowedCollageExportsCount"] !== -1) {
          if (
            user.collageExportCount >=
              user.availableFeatures["allowedCollageExportsCount"] ||
            0
          ) {
            toaster.negative(
              "You have used your monthly collages export limit",
              {
                autoHideDuration: 4000,
              }
            );
            return false;
          }
        }
      }

      const rootNode = nodes[0];
      if (rootNode && rootNode.dimensions) {
        const adjustedCollageHeight =
          rootNode.dimensions.height / (rootNode.dimensions.width / data.width);

        if (adjustedCollageHeight > MAX_COLLAGE_HEIGHT) {
          shouldExport = false;
          toaster.negative(
            `Your collage is too high (${adjustedCollageHeight.toLocaleString()}px). Max. height of the collage is ${MAX_COLLAGE_HEIGHT.toLocaleString()}px`,
            {
              autoHideDuration: 4000,
            }
          );
        }
      }
    }

    if (shouldExport) {
      try {
        await exportCollage({
          variables: {
            input: {
              id: collageId,
              ...data,
            },
          },
        });

        toaster.positive("Please wait, your export is being processed.", {
          autoHideDuration: 4000,
        });

        await refetch();

        setIsLastExportsAccordionExpanded(true);

        if (user && data.type === ExportType.Collage) {
          setUser({
            ...user,
            collageExportCount: user.collageExportCount + 1,
          });
        }
      } catch (error) {
        toaster.negative("Something went wrong", {
          autoHideDuration: 4000,
        });
      }
    }
  };

  return (
    <Modal
      onClose={onClose}
      closeable
      isOpen={isOpen}
      animate
      autoFocus
      size={SIZE.default}
      role={ROLE.dialog}
      overrides={{
        Root: {
          style: {
            zIndex: 10000,
          },
        },
        Dialog: {
          style: {
            borderRadius: "4px",
          },
        },
      }}
    >
      <ModalHeader>
        <LabelLarge color="#535A68" $style={{ fontWeight: 400 }}>
          Export project
        </LabelLarge>
      </ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ControlledRadioGroup
            control={control}
            name="type"
            error={!!errors.type}
            disabled={isSubmitting}
            align="horizontal"
          >
            <Radio value={ExportType.Blog}>Export Entire Blog</Radio>
            <Radio value={ExportType.Collage}>Export Collage</Radio>
          </ControlledRadioGroup>
          <HorizontalLine />
          <FlexGrid flexGridColumnCount={2} flexGridColumnGap="scale400">
            <FlexGridItem>
              <FormControl
                label={() => "Export width"}
                error={errors.width && errors.width.message}
                overrides={{
                  Caption: { style: { textAlign: "left" } },
                  Label: {
                    style: {
                      textTransform: "uppercase",
                      textAlign: "left",
                      fontSize: "12px",
                      color: "#8390AE",
                    },
                  },
                }}
              >
                <ControlledInput
                  control={control}
                  name="width"
                  error={!!errors.width}
                  rules={{
                    required: "This field is required",
                    validate: (val) =>
                      (val >= 500 && val <= 2500) ||
                      "Minimum width is 500px and maximum is 2500px",
                    valueAsNumber: true,
                  }}
                  disabled={isSubmitting}
                  type="number"
                />
              </FormControl>
            </FlexGridItem>
            <FlexGridItem>
              <FormControl
                label={() => "BG Color"}
                error={errors.backgroundColor && errors.backgroundColor.message}
                overrides={{
                  Caption: { style: { textAlign: "left" } },
                  Label: {
                    style: {
                      textTransform: "uppercase",
                      textAlign: "left",
                      fontSize: "12px",
                      color: "#8390AE",
                    },
                  },
                }}
              >
                <ControlledColorPicker
                  control={control}
                  name="backgroundColor"
                  rules={{ required: "This field is required" }}
                />
              </FormControl>
            </FlexGridItem>
          </FlexGrid>
          {watch("type") === ExportType.Blog && (
            <>
              <HorizontalLine />
              <FormControl>
                <ControlledCheckbox
                  control={control}
                  name="includePrefix"
                  error={!!errors.includePrefix}
                >
                  Include Prefix
                </ControlledCheckbox>
              </FormControl>
              {watch("includePrefix") && (
                <FormControl
                  error={errors.prefix && errors.prefix.message}
                  overrides={{
                    Caption: { style: { textAlign: "left" } },
                    Label: {
                      style: {
                        textTransform: "uppercase",
                        textAlign: "left",
                        fontSize: "12px",
                        color: "#8390AE",
                      },
                    },
                  }}
                >
                  <ControlledInput
                    control={control}
                    name="prefix"
                    error={!!errors.prefix}
                    rules={{
                      required:
                        watch("includePrefix") && "This field is required",
                    }}
                    disabled={isSubmitting}
                    placeholder="Enter prefix"
                  />
                </FormControl>
              )}
              <FormControl>
                <ControlledCheckbox
                  control={control}
                  name="includeSeoKeywords"
                  error={!!errors.includeSeoKeywords}
                >
                  Include SEO Keywords
                </ControlledCheckbox>
              </FormControl>
            </>
          )}
          <FormControl>
            <ControlledCheckbox
              control={control}
              name="sendEmailAfterCompletion"
              error={!!errors.sendEmailAfterCompletion}
            >
              Email me when the export will be ready
            </ControlledCheckbox>
          </FormControl>
          <Button
            $style={{
              width: "100%",
            }}
            isLoading={isSubmitting}
            disabled={isSubmitting}
            type="submit"
          >
            Export layout
          </Button>
        </form>
        <Accordion
          accordion
          overrides={{
            Root: { style: { marginTop: "20px" } },
            Content: { style: { paddingBottom: "14px" } },
          }}
        >
          <Panel
            expanded={isLastExportsAccordionExpanded}
            onChange={({ expanded }) =>
              setIsLastExportsAccordionExpanded(expanded)
            }
            title="Last exports"
          >
            {loading && <LoadingSpinner />}
            {!loading &&
              data &&
              data.collage?.lastExports
                ?.slice(0, 5)
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .map((collageExport: any) => (
                  <div
                    key={collageExport.id}
                    className={css({
                      marginBottom: "20px",
                    })}
                  >
                    <div
                      className={css({
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      })}
                    >
                      <LabelSmall color="#535A68" $style={{ fontWeight: 400 }}>
                        {collageExport.type} <br />
                        <small>
                          {format(
                            parseISO(collageExport.createdAt),
                            "LLLL d, y, HH:mm"
                          )}
                        </small>
                      </LabelSmall>
                      {collageExport.state === "IN_PROGRESS" ||
                      collageExport.state === "NEW" ? (
                        <Block display="flex" alignItems="center">
                          <LabelXSmall
                            marginRight="scale200"
                            $style={{ fontWeight: 400 }}
                          >
                            {collageExport.state === "NEW"
                              ? "Preparing"
                              : `Generating (${collageExport.progress.toLocaleString(
                                  undefined,
                                  {
                                    style: "percent",
                                    minimumFractionDigits: 0,
                                  }
                                )})`}
                          </LabelXSmall>
                          <LoadingSpinner noWrapper $size={"small"} />
                        </Block>
                      ) : collageExport.state === "COMPLETED" ? (
                        <Button
                          $as="a"
                          size="mini"
                          href={collageExport.url}
                          target="_blank"
                        >
                          Download
                        </Button>
                      ) : (
                        <LabelXSmall
                          color="negative"
                          marginTop="scale100"
                          $style={{ fontWeight: 400 }}
                        >
                          Something went wrong. Please try again
                        </LabelXSmall>
                      )}
                    </div>
                  </div>
                ))}
            {!loading && data && data.collage?.lastExports?.length === 0 && (
              <LabelSmall color="#535A68" $style={{ fontWeight: 400 }}>
                No exports yet
              </LabelSmall>
            )}
          </Panel>
        </Accordion>
      </ModalBody>
    </Modal>
  );
}
