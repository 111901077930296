import { mergeOverrides, useStyletron } from "baseui";
import { Input as BaseInput, InputProps as BaseInputProps } from "baseui/input";
import React from "react";
import { ChangeEvent } from "react";
import { Controller, ControllerProps } from "react-hook-form";
import { StyleObject } from "styletron-standard";

type InputProps = {
  $style?: StyleObject;
} & BaseInputProps;

export default function Input({
  overrides,
  $style,
  ...props
}: InputProps): React.ReactElement {
  const [, theme] = useStyletron();

  return (
    <BaseInput
      overrides={mergeOverrides(
        {
          Root: {
            style: {
              position: "relative",
              borderTopLeftRadius: theme.borders.radius200,
              borderTopRightRadius: theme.borders.radius200,
              borderBottomRightRadius: theme.borders.radius200,
              borderBottomLeftRadius: theme.borders.radius200,
              borderTopWidth: "1px",
              borderRightWidth: "1px",
              borderBottomWidth: "1px",
              borderLeftWidth: "1px",
              ...$style,
            },
          },
          Input: {
            style: {
              fontSize: "1rem",
              paddingLeft: "12px",
              paddingRight: "12px",
            },
          },
          StartEnhancer: {
            style: {
              paddingLeft: "0px",
              paddingRight: "0px",
            },
          },
          EndEnhancer: {
            style: {
              paddingLeft: "0px",
              paddingRight: "0px",
              color: "#9199A8",
              fontSize: "1rem",
            },
          },
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        overrides as any
      )}
      {...props}
    />
  );
}

export function ControlledInput({
  control,
  name,
  rules,
  ...rest
}: Omit<ControllerProps<typeof Input>, "render">): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ onChange, onBlur, value }) => (
        <Input
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChange(e.target.value)
          }
          onBlur={onBlur}
          value={value}
          {...rest}
        />
      )}
    />
  );
}
