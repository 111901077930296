import { gql, useMutation } from "@apollo/client";
import Button from "app/core/components/Button";
import { Check } from "app/core/components/Icon";
import LoadingSpinner from "app/core/components/LoadingSpinner";
import { styled, useStyletron } from "baseui";
import { ButtonProps } from "baseui/button";
import { FileUploader } from "baseui/file-uploader";
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
  ROLE,
  SIZE,
} from "baseui/modal";
import { ProgressBar } from "baseui/progress-bar";
import { LabelLarge, LabelSmall, LabelXSmall } from "baseui/typography";
import { CollageDesignerContext } from "contexts/CollageDesignerContext";
import exifr from "exifr";
import Queue from "promise-queue";
import { useEffect } from "react";
import { ReactElement, useContext, useRef, useState } from "react";
import { uploadFetch } from "utils/fetch";

import { CollageDesignerActionType, Photo } from "./types";

const UPLOAD_PHOTO = gql`
  mutation UploadPhoto($input: UploadPhotoInput!) {
    uploadPhoto(input: $input) {
      uploadUrl
      photo {
        id
        name
        url
        dimensions
        rating
        createdAt
        updatedAt
      }
    }
  }
`;

type UploadFile = {
  name: string;
  progress?: number;
  error?: string;
  isConflicting?: boolean;
  onOverride?: () => void;
  onCancel?: () => void;
};

type UploadPhotosModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const ButtonGroup = styled("div", {
  display: "flex",
});

export default function UploadPhotosModal({
  isOpen,
  onClose,
}: UploadPhotosModalProps): ReactElement {
  const [css, theme] = useStyletron();
  const {
    dispatch,
    save,
    state: { collageId, photos },
  } = useContext(CollageDesignerContext);
  const [uploadPhoto] = useMutation(UPLOAD_PHOTO);
  const [files, setFiles] = useState<UploadFile[]>([]);
  const queue = useRef(new Queue(2));

  const [hasUploadFinished, setHasUploadFinished] = useState(false);

  useEffect(() => {
    if (isOpen && !collageId) {
      save();
    }
  }, [isOpen, collageId]);

  useEffect(() => {
    setHasUploadFinished(false);
    setFiles([]);
  }, [isOpen]);

  useEffect(() => {
    if (
      !!files.length &&
      files.length === files.filter(({ progress }) => progress === 1).length
    ) {
      setHasUploadFinished(true);
    }
  }, [files]);

  return (
    <Modal
      onClose={onClose}
      closeable={false}
      isOpen={isOpen}
      animate
      autoFocus
      size={SIZE.default}
      role={ROLE.dialog}
      overrides={{
        Root: {
          style: {
            zIndex: 10000,
          },
        },
        Dialog: {
          style: {
            borderRadius: "4px",
          },
        },
      }}
    >
      <ModalHeader>
        <LabelLarge color="#535A68" $style={{ fontWeight: 400 }}>
          Upload
        </LabelLarge>
      </ModalHeader>
      <ModalBody>
        {!collageId ? (
          <LoadingSpinner />
        ) : (
          <FileUploader
            multiple
            accept=".jpg,.jpeg,.png,.gif,image/*"
            maxSize={50 * 1000000} // 50 MB
            onDrop={(acceptedFiles, rejectedFiles) => {
              for (const file of acceptedFiles) {
                const image = new Image();
                image.src = window.URL.createObjectURL(file);
                image.onload = () => {
                  if (
                    file.type !== "image/gif" &&
                    (image.width < 1 || image.height < 1)
                  ) {
                    setFiles((files) => [
                      ...files,
                      {
                        name: file.name,
                        error: "Photo has too small quality",
                      },
                    ]);
                  } else if (file.type === "image/gif" && file.size > 4e6) {
                    setFiles((files) => [
                      ...files,
                      {
                        name: file.name,
                        error: "GIF is too large. Max size for GIFs is 4MB",
                      },
                    ]);
                  } else {
                    const isConflicting = photos.some(
                      (photo) => photo.name === file.name
                    );

                    const upload = async () => {
                      try {
                        setFiles((files) =>
                          files.map((mappedFile) => {
                            if (mappedFile.name === file.name) {
                              return {
                                ...mappedFile,
                                progress: 0.01,
                              };
                            }
                            return mappedFile;
                          })
                        );

                        let exifParams;
                        try {
                          exifParams = await exifr.parse(file, {
                            xmp: true,
                            exif: true,
                          });
                        } catch (error) {
                          // Ignore
                        }

                        const response = await uploadPhoto({
                          variables: {
                            input: {
                              collageId,
                              filename: file.name,
                              width: image.width,
                              height: image.height,
                              ...(exifParams &&
                                exifParams.Rating && {
                                  rating: exifParams.Rating,
                                }),
                            },
                          },
                        });

                        await uploadFetch(response.data.uploadPhoto.uploadUrl, {
                          method: "PUT",
                          body: file,
                          onProgress: (event: ProgressEvent) => {
                            setFiles((files) =>
                              files.map((mappedFile) => {
                                if (mappedFile.name === file.name) {
                                  return {
                                    ...mappedFile,
                                    progress: event.loaded / event.total,
                                  };
                                }
                                return mappedFile;
                              })
                            );
                          },
                        });

                        dispatch({
                          type: CollageDesignerActionType.AddPhoto,
                          payload: {
                            photo: {
                              ...(response.data.uploadPhoto.photo as Photo),
                            },
                          },
                        });
                        dispatch({
                          type: CollageDesignerActionType.Refresh,
                        });
                      } catch (error) {
                        const timeout = setTimeout(() => {
                          setFiles((files) => {
                            const index = files.findIndex(
                              (_file) => _file.name === file.name
                            );

                            return [
                              ...files.slice(0, index),
                              {
                                name: file.name,
                                isConflicting,
                                onOverride: addToQueue,
                                onCancel: () =>
                                  setFiles((files) =>
                                    files.filter(
                                      (_file) => _file.name !== file.name
                                    )
                                  ),
                              },
                              ...files.slice(index + 1),
                            ];
                          });
                          addToQueue();
                        }, 3000);

                        setFiles((files) => {
                          const index = files.findIndex(
                            (_file) => _file.name === file.name
                          );

                          return [
                            ...files.slice(0, index),
                            {
                              name: file.name,
                              error: "Network error. Retrying in 3 seconds...",
                              onCancel: () => {
                                clearTimeout(timeout);
                                setFiles((files) =>
                                  files.filter(
                                    (_file) => _file.name !== file.name
                                  )
                                );
                              },
                            },
                            ...files.slice(index + 1),
                          ];
                        });
                      }
                    };

                    const addToQueue = () => {
                      queue.current.add(upload);
                    };

                    setFiles((files) => [
                      ...files,
                      {
                        name: file.name,
                        isConflicting,
                        onOverride: addToQueue,
                        onCancel: () =>
                          setFiles((files) =>
                            files.filter((_file) => _file.name !== file.name)
                          ),
                      },
                    ]);

                    if (!isConflicting) {
                      addToQueue();
                    }
                  }
                };
                image.remove();
              }

              for (const file of rejectedFiles) {
                setFiles((files) => [
                  ...files,
                  {
                    name: file.name,
                    error: "File is too big or has invalid format",
                  },
                ]);
              }
            }}
            overrides={{
              FileDragAndDrop: {
                style: {
                  backgroundColor: "#faf9f8",
                  borderColor: "#AF9883",
                },
              },
              ButtonComponent: {
                // eslint-disable-next-line react/display-name
                component: ({ onClick }: ButtonProps) => (
                  <Button
                    onClick={onClick}
                    $style={{ marginTop: "12px", minWidth: "150px" }}
                  >
                    Browse files
                  </Button>
                ),
              },
              ContentMessage: {
                style: {
                  fontWeight: 400,
                  fontSize: "1rem",
                  color: "#535A68",
                },
              },
            }}
          />
        )}

        <LabelLarge
          marginTop="scale800"
          marginBottom="scale800"
          color="#535A68"
          $style={{ fontWeight: 400 }}
        >
          Queue
        </LabelLarge>

        <div
          className={css({
            maxHeight: "50vh",
            overflowY: "auto",
            overflowX: "hidden",
          })}
        >
          {files.map((file: UploadFile) => (
            <div
              key={file.name}
              className={css({
                marginBottom: "20px",
              })}
            >
              <div
                className={css({
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                })}
              >
                <LabelSmall color="#535A68" $style={{ fontWeight: 400 }}>
                  {file.name}
                </LabelSmall>
                {file.progress && file.progress < 1 && (
                  <LoadingSpinner noWrapper $size={"small"} />
                )}
              </div>
              {file.isConflicting && !file.progress && (
                <LabelXSmall
                  color="negative"
                  marginTop="scale100"
                  $style={{ fontWeight: 400 }}
                >
                  There is a photo named {file.name} in your library.
                  <ButtonGroup>
                    <Button size="mini" onClick={file.onOverride}>
                      Overwrite
                    </Button>
                    <Button
                      size="mini"
                      kind="secondary"
                      onClick={file.onCancel}
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                </LabelXSmall>
              )}
              {file.error && (
                <>
                  <LabelXSmall
                    color="negative"
                    marginTop="scale100"
                    $style={{ fontWeight: 400 }}
                  >
                    {file.error}
                  </LabelXSmall>{" "}
                  <ProgressBar
                    size="small"
                    value={100}
                    overrides={{
                      BarContainer: {
                        style: {
                          marginLeft: 0,
                          marginRight: 0,
                          marginTop: "6px",
                        },
                      },
                      BarProgress: {
                        style: { backgroundColor: theme.colors.negative },
                      },
                    }}
                  />
                  {file.onCancel && (
                    <Button
                      size="mini"
                      kind="secondary"
                      onClick={file.onCancel}
                    >
                      Cancel
                    </Button>
                  )}
                </>
              )}
              {file.progress ? (
                <ProgressBar
                  size="small"
                  value={file.progress}
                  successValue={1}
                  overrides={{
                    BarContainer: {
                      style: {
                        marginLeft: 0,
                        marginRight: 0,
                        marginTop: "6px",
                      },
                    },
                    ...(file.progress === 1 && {
                      BarProgress: {
                        style: { backgroundColor: theme.colors.positive },
                      },
                    }),
                  }}
                />
              ) : (
                !file.error && (
                  <LabelXSmall
                    color="secondary"
                    marginTop="scale100"
                    $style={{ fontWeight: 400 }}
                  >
                    In queue
                  </LabelXSmall>
                )
              )}
            </div>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        {hasUploadFinished && (
          <LabelSmall
            $style={{
              textAlign: "center",
              fontWeight: 400,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "15px",
            }}
          >
            <Check className={css({ marginRight: "5px", stroke: "#af9883" })} />{" "}
            All files has been uploaded successfully.
          </LabelSmall>
        )}

        <ModalButton kind="tertiary" onClick={onClose}>
          Close
        </ModalButton>
      </ModalFooter>
    </Modal>
  );
}
