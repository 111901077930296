import { useEffect } from "react";
import {
  DeepMap,
  FieldError,
  RegisterOptions,
  useForm,
  useFormContext,
} from "react-hook-form";

type RegisteredInput = {
  name: string;
  rules: RegisterOptions;
};

export function useFormWarnings(
  registered: RegisteredInput[]
): {
  warnings: DeepMap<Record<string, unknown>, FieldError>;
} {
  const { register, errors, setValue } = useForm();
  const { watch } = useFormContext();

  if (registered) {
    registered.forEach(({ name, rules }) => {
      register(name, rules);

      const value = watch(name);

      useEffect(() => {
        setValue(name, value, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }, [value]);
    });
  }

  return { warnings: errors };
}
