import { Block } from "baseui/block";
import { memo } from "react";

import {
  CollageNode,
  CollageNodeType,
  ExtractNodeParameters,
} from "../../CollageDesigner/types";
import { CommonNodeRendererProps, OptionalExceptFor } from "../types";
import NodeRendererWrapper from "./NodeRendererWrapper";

type SpacerNodeRendererProps = OptionalExceptFor<
  ExtractNodeParameters<CollageNode, CollageNodeType.Spacer>,
  "id"
> &
  CommonNodeRendererProps;

function SpacerNodeRenderer({
  rootDivElement,
  collageRootDivElement,
  collageTranslate,
  id,
  offset,
  normalizedDimensions,
  parent,
  collageWidth,
  dispatch,
  rootNode,
  collageGap,
  collageScale,
  activeNodeIds,
  bindDraggingListeners,
  status,
  croppingNodeId,
}: SpacerNodeRendererProps): React.ReactElement {
  return (
    <NodeRendererWrapper
      id={id}
      offset={offset}
      normalizedDimensions={normalizedDimensions}
      collageWidth={collageWidth}
      dispatch={dispatch}
      rootDivElement={rootDivElement}
      collageRootDivElement={collageRootDivElement}
      collageGap={collageGap}
      collageTranslate={collageTranslate}
      collageScale={collageScale}
      bindDraggingListeners={bindDraggingListeners.bind(
        null,
        `internal@0/${id}/${parent?.id}`
      )}
      includeDragButton={true}
      rootNode={rootNode}
      activeNodeIds={activeNodeIds}
      croppingNodeId={croppingNodeId}
      status={status}
      type={CollageNodeType.Spacer}
    >
      <Block
        width="100%"
        height="100%"
        {...bindDraggingListeners(
          `internal@0/${id}/${parent?.id}`,
          CollageNodeType.Spacer
        )}
      ></Block>
    </NodeRendererWrapper>
  );
}

const MemoizedSpacerNodeRenderer = memo(SpacerNodeRenderer);

export default MemoizedSpacerNodeRenderer;
