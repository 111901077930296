import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
} from "baseui/modal";
import {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";

type OnConfirmCallback = () => void;
type ConfirmFunction = (onConfirm: OnConfirmCallback) => void;

type ConfirmatorContextProps = {
  confirm: ConfirmFunction;
};

export const ConfirmatorContext = createContext<ConfirmatorContextProps>(
  {} as ConfirmatorContextProps
);

type ConfirmatorProviderProps = {
  children: ReactNode;
};

export default function ConfirmatorProvider({
  children,
}: ConfirmatorProviderProps): ReactElement {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const onConfirmRef = useRef<OnConfirmCallback>();

  const confirm = useCallback((onConfirm: OnConfirmCallback) => {
    setIsConfirmModalOpen(true);
    onConfirmRef.current = onConfirm;
  }, []);

  return (
    <ConfirmatorContext.Provider value={{ confirm }}>
      {children}

      <Modal
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
      >
        <ModalHeader>Confirm</ModalHeader>
        <ModalBody>Are you sure?</ModalBody>
        <ModalFooter>
          <ModalButton
            kind="tertiary"
            onClick={() => setIsConfirmModalOpen(false)}
          >
            Cancel
          </ModalButton>
          <ModalButton
            onClick={() => {
              onConfirmRef.current && onConfirmRef.current();
              onConfirmRef.current = undefined;
              setIsConfirmModalOpen(false);
            }}
          >
            Yes
          </ModalButton>
        </ModalFooter>
      </Modal>
    </ConfirmatorContext.Provider>
  );
}

export const useConfirm = (): ConfirmFunction =>
  useContext(ConfirmatorContext).confirm;
