import { gql, useQuery } from "@apollo/client";
import { useAuth } from "app/auth/context";
import Button from "app/core/components/Button";
import { HorizontalLine } from "app/core/components/HorizontalLine";
import Radio from "app/core/components/Radio";
import { useStyletron } from "baseui";
import { Modal, ModalBody, ModalHeader, ROLE, SIZE } from "baseui/modal";
import { RadioGroup } from "baseui/radio";
import { Textarea } from "baseui/textarea";
import { toaster } from "baseui/toast";
import { LabelLarge, ParagraphSmall, ParagraphXSmall } from "baseui/typography";
import { CollageDesignerContext } from "contexts/CollageDesignerContext";
import copyToClipboard from "copy-to-clipboard";
import { format, parseISO } from "date-fns";
import { ReactElement, useContext, useMemo } from "react";
import { Link } from "react-router-dom";

type PublishModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const FETCH_MY_SUBSCRIPTION = gql`
  query MySubscription {
    mySubscription {
      id
      plan {
        name
        price
        type
        features
      }
      state
      updateUrl
      cancelUrl
      paymentMethod
      paymentCardType
      paymentCardNumberLastFourDigits
      paymentCardExpiresOn
      nextPaymentOn
      nextPaymentAmount
      payments {
        id
        receiptUrl
        currency
        amount
        createdAt
      }
    }
  }
`;

export default function PublishModal({
  isOpen,
  onClose,
}: PublishModalProps): ReactElement {
  const {
    isPublishing,
    publish,
    isUnpublishing,
    unpublish,
    state: {
      collagePublishedAt,
      collageRepublishedAt,
      collageUnpublishedAt,
      collagePublishingToken,
    },
  } = useContext(CollageDesignerContext);
  const { user } = useAuth();
  const { data } = useQuery(FETCH_MY_SUBSCRIPTION);

  const [css, theme] = useStyletron();

  const htmlCode = useMemo(
    () => `<div id="picnook-collage-root" data-collage-id="${collagePublishingToken}">
  <script type="text/javascript" src="https://${
    process.env.REACT_APP_EMBED_BUCKET_NAME
  }.s3.eu-central-1.amazonaws.com/main.js"></script>
</div>${
      user?.hasRefLinkEnabled
        ? `<div style="text-align: center; display: block; width: 100%;"><a href="https://picnook.io" target="_blank" title="Powered by Picnook" style="margin: 14px 0; display: inline-block; height: 36px; width: 36px;"><img src="https://picnook.io/icon.png" height="100%" alt="Picnook - online blogging tool" style="background: none;" /></a></div>`
        : ""
    }`,
    [collagePublishingToken, user?.hasRefLinkEnabled]
  );

  return (
    <Modal
      onClose={onClose}
      closeable
      isOpen={isOpen}
      animate
      autoFocus
      size={SIZE.default}
      role={ROLE.dialog}
      overrides={{
        Root: {
          style: {
            zIndex: 10000,
          },
        },
        Dialog: {
          style: {
            borderRadius: "4px",
          },
        },
      }}
    >
      <ModalHeader>
        <LabelLarge color="#535A68" $style={{ fontWeight: 400 }}>
          Publish project
        </LabelLarge>
      </ModalHeader>
      <ModalBody>
        <RadioGroup value="code" align="horizontal">
          <Radio value="code">Publish via Code</Radio>
        </RadioGroup>
        <HorizontalLine marginBottom={22} marginTop={22} />
        <Button
          size="large"
          disabled={isPublishing || isUnpublishing}
          isLoading={isPublishing || isUnpublishing}
          onClick={async () => {
            if (user) {
              try {
                await publish();
              } catch (error) {
                toaster.negative(
                  "You have reached your published posts limit",
                  {
                    autoHideDuration: 4000,
                  }
                );
              }
            }
          }}
          $style={{
            marginRight: "10px",
            minWidth: "120px",
          }}
        >
          {collagePublishedAt ? "Republish" : "Publish"}
        </Button>
        <Button
          size="large"
          kind="secondary"
          disabled={!collagePublishedAt || isPublishing || isUnpublishing}
          isLoading={isPublishing || isUnpublishing}
          onClick={async () => {
            if (user) {
              await unpublish();
            }
          }}
          $style={{
            minWidth: "120px",
          }}
        >
          {collagePublishedAt ? "Unpublish" : "Unpublished"}
        </Button>
        <ParagraphXSmall color="#8390AE">
          Published at:{" "}
          {collagePublishedAt
            ? format(parseISO(collagePublishedAt), "LLLL d, y, HH:mm")
            : "-"}
          <br />
          Republished at:{" "}
          {collageRepublishedAt
            ? format(parseISO(collageRepublishedAt), "LLLL d, y, HH:mm")
            : "-"}
          <br />
          Unpublished at:{" "}
          {collageUnpublishedAt
            ? format(parseISO(collageUnpublishedAt), "LLLL d, y, HH:mm")
            : "-"}
        </ParagraphXSmall>
        <HorizontalLine marginBottom={10} marginTop={22} />
        <div className={css({ display: "flex", alignItems: "baseline" })}>
          <div className={css({ flexGrow: 1 })}>
            <ParagraphSmall marginTop={0} display="inline-block">
              Active plan:{" "}
              <strong>{data?.mySubscription?.plan.name || "FREE"}</strong>
            </ParagraphSmall>
            <ParagraphSmall
              marginTop={0}
              marginLeft="10px"
              display="inline-block"
              color="#8390AE"
            >
              {user?.publishedCollageCount}/
              {(data?.mySubscription?.plan.features["publishedPostsCount"] ===
              -1
                ? "unlimited"
                : data?.mySubscription?.plan.features["publishedPostsCount"]) ||
                0}{" "}
              published posts
            </ParagraphSmall>
          </div>
          <div className={css({ textAlign: "right" })}>
            <Button
              kind="minimal"
              $style={{
                color: theme.colors.primary,
                fontWeight: 400,
                paddingLeft: 0,
                paddingRight: 0,
              }}
              $as={Link}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              to="/billing"
            >
              Change plan
            </Button>
          </div>
        </div>
        {collagePublishedAt && (
          <>
            <HorizontalLine marginTop={10} marginBottom={22} />
            <Textarea
              size="compact"
              rows={4}
              overrides={{
                Input: {
                  style: {
                    fontSize: "12px",
                    color: "#8390AE",
                  },
                },
              }}
              value={htmlCode}
            />

            <Button
              size="large"
              onClick={() => {
                try {
                  copyToClipboard(htmlCode);
                  toaster.positive("Copied to clipboard", {
                    autoHideDuration: 4000,
                  });
                } catch (error) {
                  toaster.negative("Failed to copy to clipboard", {
                    autoHideDuration: 4000,
                  });
                }
              }}
              $style={{ marginTop: "20px", width: "100%" }}
            >
              Copy code
            </Button>
            <ParagraphXSmall color="#8390AE" $style={{ textAlign: "center" }}>
              Copy & paste the code in your website to embed your post.
            </ParagraphXSmall>
          </>
        )}
      </ModalBody>
    </Modal>
  );
}
