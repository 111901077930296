import { ReactDOMAttributes, useDrag } from "@use-gesture/react";
import useKeyPress from "app/core/hooks/useKeyPress";
import { useOutsideClickAlerter } from "app/core/hooks/useOutsideClickAlerter";
import { styled, useStyletron } from "baseui";
import { LabelXSmall } from "baseui/typography";
import React, {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { ResizableBox } from "react-resizable";
import { StyleObject } from "styletron-react";

import { Rect, XY } from "../CollageDesigner2/types";
import { PhotoTransform } from "./types";

const ResizeHandle = styled<{ direction: string }, "div">(
  "div",
  ({ direction }) => ({
    position: "absolute",
    width: "10px",
    height: "10px",
    zIndex: 100,
    backgroundColor: "#fff",
    border: "2px solid #000",
    boxSizing: "border-box",
    borderRadius: "2px",
    ...(["sw", "se", "s"].includes(direction) && {
      bottom: "-5px",
    }),
    ...(["nw", "ne", "n"].includes(direction) && {
      top: "-5px",
    }),
    ...(["nw", "w", "sw"].includes(direction) && {
      left: "-5px",
    }),
    ...(["ne", "e", "se"].includes(direction) && {
      right: "-5px",
    }),
    ...(["w", "e"].includes(direction) && {
      top: "50%",
      marginTop: "-5px",
    }),
    ...(["n", "s"].includes(direction) && {
      left: "50%",
      marginLeft: "-5px",
    }),
    cursor: `${direction}-resize`,
  })
);

const GuideLineIndicator = styled("div", ({ $theme }) => ({
  position: "absolute",
  transform: "translate(-50%, -50%)",
  backgroundColor: $theme.colors.accent,
}));

const Ruler = styled("div", ({ $theme }) => ({
  position: "absolute",
  backgroundColor: $theme.colors.positive,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 100,
}));

type DraggableResizablePhotoProps = {
  url?: string;
  isMirrored: boolean;
  x: number;
  y: number;
  width: number;
  height: number;
  bounds: {
    width: number;
    height: number;
  };
  collageTranslate: XY;
  onTransform?: (transform: Partial<PhotoTransform>) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  bindDraggingListeners: (...args: any[]) => ReactDOMAttributes;
  isDragging: boolean;
  intermediateTranslate: Omit<PhotoTransform, "width" | "height">;
};

const backgroundStyles: StyleObject = {
  position: "absolute",
  pointerEvents: "none",
  left: 0,
  top: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
};

function DraggableResizablePhoto({
  url,
  isMirrored,
  x,
  y,
  width,
  height,
  bounds,
  onTransform,
  bindDraggingListeners,
  intermediateTranslate,
  isDragging,
}: DraggableResizablePhotoProps) {
  const [css] = useStyletron();

  const isAltPressed = useKeyPress("Alt");

  x = !isDragging ? x : intermediateTranslate.x;
  y = !isDragging ? y : intermediateTranslate.y;

  return (
    <ResizableBox
      width={width}
      height={height}
      lockAspectRatio={true}
      onResize={(_, { handle, size }) => {
        if (onTransform) {
          const deltaWidth = size.width - width;
          const deltaHeight = size.height - height;

          if (handle[0] === "n") {
            y -= deltaHeight;
          } else if (["e", "w"].includes(handle)) {
            y -= deltaHeight / 2;
          }

          if (handle[handle.length - 1] === "w") {
            x -= deltaWidth;
          } else if (["n", "s"].includes(handle)) {
            x -= deltaWidth / 2;
          }

          if (isAltPressed) {
            if (handle[0] === "n") {
              y += deltaHeight / 2;
            } else {
              y -= deltaHeight / 2;
            }

            if (handle[handle.length - 1] === "w") {
              x += deltaWidth / 2;
            } else {
              x -= deltaWidth / 2;
            }
          }

          onTransform({
            x,
            y,
            width: size.width,
            height: size.height,
          });
        }
      }}
      handle={(direction: string) => <ResizeHandle direction={direction} />}
      className={css({
        position: "relative",
        touchAction: "none",
        userSelect: "none",
        left: `${x}px`,
        top: `${y}px`,
      })}
      resizeHandles={["e", "n", "ne", "nw", "s", "se", "sw", "w"]}
    >
      {x < 0 && (
        <div
          className={css({
            ...backgroundStyles,
            width: `${Math.min(Math.abs(x), width)}px`,
            height: `${height}px`,
          })}
        ></div>
      )}
      {y < 0 && (
        <div
          className={css({
            ...backgroundStyles,
            left: `${Math.max(-x, 0)}px`,
            width: `${
              width - Math.max(-x, 0) - Math.max(x + width - bounds.width, 0)
            }px`,
            height: `${Math.min(Math.abs(y), height)}px`,
          })}
        ></div>
      )}
      {x + width > bounds.width && (
        <div
          className={css({
            ...backgroundStyles,
            left: "unset",
            right: 0,
            width: `${Math.min(x + width - bounds.width, width)}px`,
            height: `${height}px`,
          })}
        ></div>
      )}
      {y + height > bounds.height && (
        <div
          className={css({
            ...backgroundStyles,
            top: "unset",
            bottom: 0,
            left: `${Math.max(-x, 0)}px`,
            width: `${
              width - Math.max(-x, 0) - Math.max(x + width - bounds.width, 0)
            }px`,
            height: `${Math.min(y + height - bounds.height, height)}px`,
          })}
        ></div>
      )}
      <div
        className={css({
          width: "100%",
          height: "100%",
          userSelect: "none",
          touchAction: "none",
          backgroundImage: `url(${url})`,
          backgroundSize: "100% 100%",
          ...(isDragging && {
            cursor: "grabbing",
          }),
          ...(isMirrored && { transform: "scaleX(-1)" }),
        })}
        {...bindDraggingListeners()}
      />
    </ResizableBox>
  );
}

const SNAP_THRESHOLD = 6;

type GuideLine = {
  anchor: "left" | "center" | "right" | "top" | "bottom";
  axis: "x" | "y";
  relativePosition: number;
  absolutePosition?: number;
  distance?: number;
  isVisible?: boolean;
};

const GUIDE_LINES: GuideLine[] = [
  {
    anchor: "left",
    axis: "x",
    relativePosition: 0,
  },
  {
    anchor: "right",
    axis: "x",
    relativePosition: 1,
  },
  {
    anchor: "top",
    axis: "y",
    relativePosition: 0,
  },
  {
    anchor: "bottom",
    axis: "y",
    relativePosition: 1,
  },
  {
    anchor: "center",
    axis: "x",
    relativePosition: 0.5,
  },
  {
    anchor: "center",
    axis: "y",
    relativePosition: 0.5,
  },
];

function calculateShift(
  { axis, anchor }: GuideLine,
  { width, height }: PhotoTransform
): number {
  if (axis === "x") {
    return anchor === "right" ? width : anchor === "center" ? width / 2 : 0;
  } else {
    return anchor === "bottom" ? height : anchor === "center" ? height / 2 : 0;
  }
}

function getSnappedCoordinates(
  guideLines: GuideLine[],
  x: number,
  y: number,
  previousTransform: PhotoTransform
) {
  const nearestHorizontalGuideLine = guideLines
    .filter((guideLine) => guideLine.axis === "x")
    .map((guideLine) => {
      const shift = calculateShift(guideLine, previousTransform);

      return {
        ...guideLine,
        shift,
        distance: Math.abs(x + shift - (guideLine.absolutePosition as number)),
      };
    })
    .sort(
      (guideLineA, guideLineB) => guideLineA.distance - guideLineB.distance
    )[0];
  const nearestVerticalGuideLine = guideLines
    .filter((guideLine) => guideLine.axis === "y")
    .map((guideLine) => {
      const shift = calculateShift(guideLine, previousTransform);

      return {
        ...guideLine,
        shift,
        distance: Math.abs(y + shift - (guideLine.absolutePosition as number)),
      };
    })
    .sort(
      (guideLineA, guideLineB) => guideLineA.distance - guideLineB.distance
    )[0];

  return {
    x:
      nearestHorizontalGuideLine.distance < SNAP_THRESHOLD
        ? (nearestHorizontalGuideLine.absolutePosition as number) -
          nearestHorizontalGuideLine.shift
        : x,
    y:
      nearestVerticalGuideLine.distance < SNAP_THRESHOLD
        ? (nearestVerticalGuideLine.absolutePosition as number) -
          nearestVerticalGuideLine.shift
        : y,
  };
}

function createSnapModifier(
  guideLines: GuideLine[],
  previousTransform: PhotoTransform
): (transform: XY) => XY {
  return (transform) => {
    return {
      ...transform,
      ...getSnappedCoordinates(
        guideLines,
        transform.x,
        transform.y,
        previousTransform
      ),
    };
  };
}

function computeAbsolutePositions(
  guideLines: GuideLine[],
  rect: Rect
): GuideLine[] {
  return guideLines.map((guideLine) => ({
    ...guideLine,
    absolutePosition:
      guideLine.relativePosition *
      rect[guideLine.axis === "x" ? "width" : "height"],
  }));
}

type PhotoCropperHandle = {
  transform(): void;
};

type PhotoCropperProps = {
  url?: string;
  rect: Rect;
  isMirrored: boolean;
  initialTransform?: PhotoTransform;
  collageTranslate: XY;
  collageScale: number;
  onTransform: (transform?: PhotoTransform) => void;
  forwardedRef: ForwardedRef<PhotoCropperHandle>;
};

export default function PhotoCropper({
  url,
  rect,
  onTransform,
  isMirrored,
  initialTransform,
  collageTranslate,
  forwardedRef,
}: PhotoCropperProps): React.ReactElement {
  const [css, theme] = useStyletron();
  const [transform, setTransform] = useState<PhotoTransform>(
    initialTransform ?? {
      x: 0,
      y: 0,
      width: rect.width,
      height: rect.height,
    }
  );

  const [isDragging, setIsDragging] = useState(false);
  const [intermediateTranslate, setIntermediateTranslate] = useState<XY>(
    initialTransform ?? {
      x: 0,
      y: 0,
    }
  );
  const [
    snappedIntermediateTranslate,
    setSnappedIntermediateTranslate,
  ] = useState<XY>(intermediateTranslate);
  const backgroundRef = useRef<HTMLDivElement>(null);
  const workspaceRef = useRef<HTMLDivElement>(null);

  const guideLines = useMemo(
    () => computeAbsolutePositions(GUIDE_LINES, rect),
    [rect]
  );
  const visibleGuideLines = useMemo(
    () =>
      guideLines.filter((guideLine) => {
        const shift = calculateShift(guideLine, transform);

        return (
          Math.abs(
            intermediateTranslate[guideLine.axis] +
              shift -
              (guideLine.absolutePosition as number)
          ) < SNAP_THRESHOLD
        );
      }),
    [intermediateTranslate]
  );

  const snapModifier = useMemo(
    () => createSnapModifier(guideLines, transform),
    [transform]
  );

  useOutsideClickAlerter(backgroundRef.current, workspaceRef, () => {
    onTransform(transform);
  });

  const bindDraggingListeners = useDrag(({ active, last, delta: [dx, dy] }) => {
    if (active) {
      setIntermediateTranslate(({ x, y }) => {
        const intermediateTranslate = {
          x: Math.round(x + dx),
          y: Math.round(y + dy),
        };

        setSnappedIntermediateTranslate(snapModifier(intermediateTranslate));

        return intermediateTranslate;
      });
    }

    if (last) {
      setTransform(({ ...rest }) => ({
        ...rest,
        x: snappedIntermediateTranslate.x,
        y: snappedIntermediateTranslate.y,
      }));
    }

    setIsDragging(active);
  });

  useHotkeys("enter", () => onTransform(transform), [transform]);

  useImperativeHandle(
    forwardedRef,
    () => ({
      transform() {
        onTransform(transform);
      },
    }),
    [transform]
  );

  return (
    <div
      className={css({
        position: "absolute",
        left: 0,
        top: 0,
        zIndex: 1000,
        userSelect: "none",
        touchAction: "none",
      })}
      onDoubleClick={() => onTransform(transform)}
    >
      <div
        className={css({
          position: "fixed",
          left: "-5000px",
          top: "-5000px",
          width: "10000px",
          height: "10000px",
        })}
        ref={backgroundRef}
      ></div>

      <div
        className={css({
          position: "absolute",
          left: `${rect.x}px`,
          top: `${rect.y}px`,
          width: `${rect.width}px`,
          height: `${rect.height}px`,
          backgroundColor: "#ddd",
        })}
        ref={workspaceRef}
      >
        <DraggableResizablePhoto
          bounds={{
            width: rect.width,
            height: rect.height,
          }}
          url={url}
          isMirrored={isMirrored}
          {...transform}
          collageTranslate={collageTranslate}
          onTransform={(transform) => {
            setTransform((prevTransform) => ({
              ...prevTransform,
              ...transform,
              ...(transform.width && { width: transform.width }),
              ...(transform.height && {
                height: transform.height,
              }),
              ...(transform.x && {
                x: transform.x,
              }),
              ...(transform.y && {
                y: transform.y,
              }),
            }));
            setIntermediateTranslate(({ x, y }) => ({
              x: Math.round(transform.x || x),
              y: Math.round(transform.y || y),
            }));
          }}
          bindDraggingListeners={bindDraggingListeners}
          intermediateTranslate={snappedIntermediateTranslate}
          isDragging={isDragging}
        />
        {isDragging && (
          <>
            {visibleGuideLines.map((guideLine) => (
              <GuideLineIndicator
                key={`${guideLine.axis}${guideLine.anchor}${guideLine.relativePosition}`}
                $style={{
                  ...(guideLine.axis === "x"
                    ? {
                        width: "1px",
                        height: "10000px",
                        top: "50%",
                        left: `${guideLine.relativePosition * 100}%`,
                      }
                    : {
                        width: "10000px",
                        height: "1px",
                        top: `${guideLine.relativePosition * 100}%`,
                        left: "50%",
                      }),
                }}
              />
            ))}
            <Ruler
              $style={{
                height: "1px",
                left: 0,
                top: `${
                  snappedIntermediateTranslate.y + transform.height / 2
                }px`,
                width: `${snappedIntermediateTranslate.x}px`,
                transform: "none",
              }}
            >
              <LabelXSmall
                $style={{
                  backgroundColor: theme.colors.primary,
                  color: "white",
                  borderRadius: "4px",
                  padding: "2px",
                }}
              >
                {snappedIntermediateTranslate.x.toFixed(0)}
              </LabelXSmall>
            </Ruler>
            <Ruler
              $style={{
                height: "1px",
                left: `${snappedIntermediateTranslate.x + transform.width}px`,
                top: `${
                  snappedIntermediateTranslate.y + transform.height / 2
                }px`,
                width: `${
                  rect.width -
                  (snappedIntermediateTranslate.x + transform.width)
                }px`,
                transform: "none",
              }}
            >
              <LabelXSmall
                $style={{
                  backgroundColor: theme.colors.primary,
                  color: "white",
                  borderRadius: "4px",
                  padding: "2px",
                }}
              >
                {(
                  rect.width -
                  (snappedIntermediateTranslate.x + transform.width)
                ).toFixed(0)}
              </LabelXSmall>
            </Ruler>
            <Ruler
              $style={{
                width: "1px",
                top: 0,
                left: `${
                  snappedIntermediateTranslate.x + transform.width / 2
                }px`,
                height: `${snappedIntermediateTranslate.y}px`,
                transform: "none",
              }}
            >
              <LabelXSmall
                $style={{
                  backgroundColor: theme.colors.primary,
                  color: "white",
                  borderRadius: "4px",
                  padding: "2px",
                }}
              >
                {snappedIntermediateTranslate.y.toFixed(0)}
              </LabelXSmall>
            </Ruler>
            <Ruler
              $style={{
                width: "1px",
                top: `${snappedIntermediateTranslate.y + transform.height}px`,
                left: `${
                  snappedIntermediateTranslate.x + transform.width / 2
                }px`,
                height: `${
                  rect.height -
                  (snappedIntermediateTranslate.y + transform.height)
                }px`,
                transform: "none",
              }}
            >
              <LabelXSmall
                $style={{
                  backgroundColor: theme.colors.primary,
                  color: "white",
                  borderRadius: "4px",
                  padding: "2px",
                }}
              >
                {(
                  rect.height -
                  (snappedIntermediateTranslate.y + transform.height)
                ).toFixed(0)}
              </LabelXSmall>
            </Ruler>
          </>
        )}
      </div>
    </div>
  );
}

// eslint-disable-next-line react/display-name
export const ForwardedPhotoCropper = forwardRef<
  PhotoCropperHandle,
  Omit<PhotoCropperProps, "forwardedRef">
>((props, ref) => <PhotoCropper {...props} forwardedRef={ref} />);
