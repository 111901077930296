import Logo from "app/core/components/Logo";
import { styled } from "baseui";
import { Cell, Grid } from "baseui/layout-grid";
import { StyledLink } from "baseui/link";
import { LabelSmall } from "baseui/typography";
import { ReactElement } from "react";
import { Link } from "react-router-dom";

const GuestHeaderRoot = styled("header", ({ $theme }) => ({
  marginTop: $theme.sizing.scale2400,
}));

type GuestHeaderProps = {
  isLogin?: boolean;
};

export default function GuestHeader({
  isLogin = true,
}: GuestHeaderProps): ReactElement {
  return (
    <GuestHeaderRoot>
      <Grid>
        <Cell span={[2, 4, 6]}>
          <a href="https://picnook.io" title="Picnook">
            <Logo />
          </a>
        </Cell>
        <Cell span={[2, 4, 6]}>
          <LabelSmall $style={{ textAlign: "right", paddingTop: "10px" }}>
            {isLogin ? (
              <>
                Don&apos;t have an account?{" "}
                <Link to="/register">
                  <StyledLink
                    $style={{
                      color: "#A48D79",
                      textDecoration: "none",
                    }}
                  >
                    Create free account
                  </StyledLink>
                </Link>
              </>
            ) : (
              <>
                Already have an account?{" "}
                <Link to="/login">
                  <StyledLink
                    $style={{
                      color: "#A48D79",
                      textDecoration: "none",
                    }}
                  >
                    Login
                  </StyledLink>
                </Link>
              </>
            )}
          </LabelSmall>
        </Cell>
      </Grid>
    </GuestHeaderRoot>
  );
}
