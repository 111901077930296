import { styled } from "baseui";
import { ReactElement } from "react";

const HorizontalLineRoot = styled("hr", {
  backgroundColor: "#8390AE",
  opacity: 0.32,
  height: "1px",
  border: "none",
});

type HorizontalLineProps = {
  marginTop?: number;
  marginBottom?: number;
};

export function HorizontalLine({
  marginTop = 28,
  marginBottom = 28,
}: HorizontalLineProps): ReactElement {
  return (
    <HorizontalLineRoot
      $style={{
        marginTop: `${marginTop}px`,
        marginBottom: `${marginBottom}px`,
      }}
    />
  );
}
