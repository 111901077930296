import { gql, useMutation, useQuery } from "@apollo/client";
import { useAuth } from "app/auth/context";
import FormControl from "app/collages/components/CollageDesigner/ui/FormControl";
import Button from "app/core/components/Button";
import Checkbox from "app/core/components/Checkbox";
import Input from "app/core/components/Input";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { STYLE_TYPE } from "baseui/checkbox";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import { Cell } from "baseui/layout-grid";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ROLE,
  SIZE,
} from "baseui/modal";
import { toaster } from "baseui/toast";
import {
  DisplaySmall,
  LabelLarge,
  LabelMedium,
  LabelSmall,
  LabelXSmall,
  ParagraphSmall,
} from "baseui/typography";
import { format, parseISO } from "date-fns";
import { ReactElement, useMemo, useState } from "react";

function determineIfUpgradeOrDowngrade(
  newPlan: Plan,
  currentPlan?: Plan
): "Upgrade" | "Downgrade" {
  if (!currentPlan || newPlan.type === "LIFETIME") {
    return "Upgrade";
  }

  if (newPlan.type === "ANNUAL" && currentPlan.type === "MONTHLY") {
    return "Upgrade";
  }

  if (newPlan.type === "MONTHLY" && currentPlan.type === "ANNUAL") {
    return "Downgrade";
  }

  return newPlan.price >= currentPlan.price ? "Upgrade" : "Downgrade";
}

type ChangePlanModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

enum BillingCycle {
  Monthly = "Monthly",
  Yearly = "Annual",
}

const FETCH_PLANS = gql`
  query Plans {
    plans {
      items {
        id
        name
        type
        price
        features
      }
    }
  }
`;

const FETCH_MY_SUBSCRIPTION = gql`
  query MySubscription {
    mySubscription {
      id
      nextPaymentOn
      nextPaymentAmount
      planId
      plan {
        name
        price
        type
      }
      updateUrl
      cancelUrl
    }
  }
`;

type Plan = {
  id: number;
  name: string;
  price: number;
  type: string;
  features: Record<string, boolean | number>;
};

const FEATURES_GROUPS = [
  {
    name: "Core features",
    features: [
      {
        name: "Export posts as JPEG",
        key: "blogExport",
      },
      {
        name: "Keep your published blogs forever",
        key: "blogForever",
      },
      {
        name: "Export image collages",
        key: "allowedCollageExportsCount",
      },
      {
        name: "Google Trends",
        key: "googleTrends",
      },
      {
        name: "Use Image SEO Optimization",
        key: "advancedImageSeo",
      },
      {
        name: "Use Advanced Content SEO",
        key: "advancedContentSeo",
      },
      {
        name: "Remove Picnook branding",
        key: "disableRefLink",
      },
      {
        name: "Works with any website",
      },
      {
        name: "Build lightning-fast blogs",
      },
      {
        name: "Use advanced text editor",
      },
      {
        name: "Use Google fonts",
      },
      {
        name: "Chat support",
      },
      {
        name: "Drag, drop, crop images",
      },
    ],
  },
  {
    name: "Integrations",
    features: [
      {
        name: "Add Youtube, Vimeo",
        key: "videoComponent",
      },
      {
        name: "Add GIFs",
        key: "gif",
      },
      {
        name: "Add Spotify",
        key: "spotify",
      },
      {
        name: "Add Pinterest plugin",
        key: "pinterest",
      },
    ],
  },
  {
    name: "Security",
    features: [
      {
        name: "Use two-factor authentication",
      },
      {
        name: "Daily backup",
      },
      {
        name: "Payments supported by Paddle",
      },
      {
        name: "HTTPS Standard",
      },
      {
        name: "Hosted on Amazon Web Services",
      },
    ],
  },
];

const UPDATE_SUBSCRIPTION = gql`
  mutation UpdateSubscription($input: UpdateSubscriptionInput!) {
    updateSubscription(input: $input)
  }
`;

export default function ChangePlanModal({
  isOpen,
  onClose,
}: ChangePlanModalProps): ReactElement {
  const [css, theme] = useStyletron();
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
  const [isDowngradeModalOpen, setIsDowngradeModalOpen] = useState(false);
  const [targetPlan, setTargetPlan] = useState<Plan | null>(null);
  const [coupon, setCoupon] = useState<string>("");
  const { data } = useQuery(FETCH_PLANS, {
    nextFetchPolicy: "no-cache",
  });
  const mySubscriptionQuery = useQuery(FETCH_MY_SUBSCRIPTION);
  const [updateSubscription] = useMutation(UPDATE_SUBSCRIPTION);

  const [billingCycle, setBillingCycle] = useState<BillingCycle>(
    BillingCycle.Yearly
  );

  const plans = useMemo(
    () =>
      data &&
      data.plans &&
      data.plans.items
        .filter(
          (plan: Plan) =>
            plan.type === billingCycle.toUpperCase() || plan.type === "LIFETIME"
        )
        .sort((a: Plan, b: Plan) => a.price - b.price),
    [data, billingCycle]
  );
  const { user } = useAuth();

  return (
    <>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        animate
        autoFocus={false}
        focusLock={false}
        size={SIZE.full}
        role={ROLE.dialog}
        overrides={{
          Root: {
            style: {
              zIndex: 10000,
            },
          },
          Dialog: {
            style: {
              borderRadius: "4px",
              display: "flex",
              flexDirection: "column",
            },
          },
        }}
      >
        <ModalHeader>
          <LabelLarge color="#535A68" $style={{ fontWeight: 400 }}>
            Change plan
          </LabelLarge>
        </ModalHeader>
        <ModalBody $style={{ flex: "1 1 0px", overflow: "scroll" }}>
          {mySubscriptionQuery.data && mySubscriptionQuery.data.mySubscription && (
            <>
              <Block marginBottom="scale800">
                <ParagraphSmall color="contentTertiary">
                  This workspace’s{" "}
                  {mySubscriptionQuery.data.mySubscription.plan.name} Plan is
                  set to{" "}
                  <strong>
                    $
                    {mySubscriptionQuery.data.mySubscription
                      .nextPaymentAmount ??
                      mySubscriptionQuery.data.mySubscription.plan.price *
                        (mySubscriptionQuery.data.mySubscription.plan.type ===
                        "ANNUAL"
                          ? 12
                          : 1)}{" "}
                    {mySubscriptionQuery.data.mySubscription.plan.type ===
                    "ANNUAL"
                      ? "per year"
                      : mySubscriptionQuery.data.mySubscription.plan.type ===
                        "MONTHLY"
                      ? "per month"
                      : ""}
                  </strong>
                  {mySubscriptionQuery.data.mySubscription.nextPaymentOn &&
                    ` and will
                renew on ${format(
                  parseISO(
                    mySubscriptionQuery.data.mySubscription.nextPaymentOn
                  ),
                  "LLLL d, y"
                )}.`}
                </ParagraphSmall>
              </Block>
            </>
          )}
          {plans && (
            <>
              <Block
                display="flex"
                flexDirection={["column", "column", "row", "row"]}
                marginBottom="scale800"
                justifyContent="space-between"
                flexWrap
              >
                <Block
                  display="flex"
                  alignItems="center"
                  justifyContent={["center", "center", "center", "flex-start"]}
                  marginTop="scale400"
                >
                  <LabelSmall color="primary" marginRight="scale200">
                    Monthly
                  </LabelSmall>
                  <Checkbox
                    checked={billingCycle === BillingCycle.Yearly}
                    checkmarkType={STYLE_TYPE.toggle_round}
                    onChange={() =>
                      setBillingCycle(
                        billingCycle === BillingCycle.Monthly
                          ? BillingCycle.Yearly
                          : BillingCycle.Monthly
                      )
                    }
                    overrides={{
                      Toggle: {
                        style: {
                          height: "20px",
                          width: "20px",
                          backgroundColor: theme.colors.primary,
                          boxShadow: "unset",
                        },
                      },
                      ToggleTrack: {
                        style: {
                          height: "24px",
                          width: "42px",
                          backgroundColor: theme.colors.backgroundSecondary,
                          borderTopLeftRadius: "50px",
                          borderTopRightRadius: "50px",
                          borderBottomRightRadius: "50px",
                          borderBottomLeftRadius: "50px",
                          paddingLeft: "1px",
                          marginLeft: "0px",
                          marginRight: "0px",
                          marginTop: "0px",
                          marginBottom: "0px",
                        },
                      },
                    }}
                  />
                  <LabelSmall marginLeft="scale400" color="primary">
                    Yearly
                  </LabelSmall>
                </Block>
              </Block>
              <div className={css({ display: "flex" })}>
                <Cell span={[4, 8, 3]}>
                  <Block
                    display={["none", "none", "none", "flex"]}
                    flexDirection="column"
                    alignItems="center"
                    marginTop="scale600"
                    marginBottom={[
                      "scale1200",
                      "scale1200",
                      "scale1200",
                      "scale0",
                    ]}
                  >
                    {/* <Briefcase /> */}
                    {/* <HeadingXSmall
                    as="div"
                    marginTop="scale800"
                    marginBottom="scale800"
                    $style={{ fontWeight: 400, textAlign: "center" }}
                  >
                    Use one of these{" "}
                    <span className={css({ color: "#AF9883" })}>codes</span>:
                  </HeadingXSmall>
                  <HeadingXSmall
                    as="div"
                    $style={{ fontWeight: 600, textAlign: "center" }}
                  >
                    <span className={css({ color: "#AF9883" })}>
                      CHRISTMAS20
                    </span>{" "}
                    - PLUS, PRO
                  </HeadingXSmall>
                  <HeadingXSmall
                    as="div"
                    $style={{ fontWeight: 600, textAlign: "center" }}
                  >
                    <span className={css({ color: "#AF9883" })}>
                      CHRISTMAS40
                    </span>{" "}
                    - LIFETIME
                  </HeadingXSmall> */}
                    {/* <ParagraphSmall marginTop="scale800" marginBottom="scale800">
                    Contact us today to find a better fit like personalized
                    access for multiple users, more advanced blog posts
                    management options, and more.
                  </ParagraphSmall> */}
                    {/* {slice.primary.actionlink.url ? (
                    <Link to={slice.primary.actionlink}>
                      <StyledLink
                        $as={LabelLarge}
                        color="primary"
                        $style={{ textDecoration: "none !important" }}
                      >
                        {slice?.primary?.actionlabel}
                      </StyledLink>
                    </Link>
                  ) : (
                    <Link to={slice.primary.actionlink}>
                      <StyledLink
                        $as={LabelLarge}
                        color="primary"
                        onClick={() =>
                          // eslint-disable-next-line @typescript-eslint/no-explicit-Plan
                          (window as Plan).$crisp &&
                          // eslint-disable-next-line @typescript-eslint/no-explicit-Plan
                          (window as Plan).$crisp.push(["do", "chat:open"])
                        }
                        $style={{ textDecoration: "none !important" }}
                      >
                        {slice?.primary?.actionlabel}
                      </StyledLink>
                    </Link>
                  )} */}
                  </Block>
                </Cell>
                <Cell span={[4, 8, 9]}>
                  <Block>
                    <FlexGrid
                      // isFlex
                      flexGridColumnCount={plans?.length || 0}
                      flexGridColumnGap="scale800"
                      flexGridRowGap="0px"
                    >
                      {plans?.map((plan: Plan, index: number) => (
                        <FlexGridItem key={index}>
                          <Block display="flex">
                            <div
                              className={css({
                                borderRadius: "4px",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                paddingBottom: theme.sizing.scale1400,
                                paddingLeft: theme.sizing.scale600,
                                paddingRight: theme.sizing.scale600,
                                position: "relative",
                                flexGrow: 1,
                              })}
                            >
                              <LabelMedium
                                color="contentTertiary"
                                $style={{
                                  textTransform: "uppercase",
                                  fontWeight: 700,
                                  letterSpacing: "0.1em",
                                }}
                              >
                                {plan.name}
                              </LabelMedium>
                              <DisplaySmall
                                marginTop="scale800"
                                marginBottom="scale800"
                                color="primary"
                                position="relative"
                                $style={{
                                  fontWeight: 700,
                                  textAlign: "center",
                                }}
                              >
                                ${plan.price}
                                {plan.type !== "LIFETIME" && (
                                  <LabelSmall display="inline" color="inherit">
                                    /
                                    {billingCycle === BillingCycle.Monthly
                                      ? "mo"
                                      : "mo"}
                                  </LabelSmall>
                                )}
                                {plan.price > 0 &&
                                  billingCycle === BillingCycle.Yearly &&
                                  plan.type !== "LIFETIME" && (
                                    <LabelXSmall
                                      color="inherit"
                                      position="absolute"
                                      bottom="-18px"
                                      left="50%"
                                      width={"save $xx annually".length + "ch"}
                                      $style={{
                                        transform: "translateX(-50%)",
                                        fontSize: "10px",
                                      }}
                                    >
                                      billed yearly
                                    </LabelXSmall>
                                  )}
                                {plan.price === 0.0 && (
                                  <LabelXSmall
                                    color="inherit"
                                    position="absolute"
                                    bottom="-18px"
                                    left="50%"
                                    width={
                                      "no credit card required".length + "ch"
                                    }
                                    $style={{
                                      transform: "translateX(-50%)",
                                      fontSize: "10px",
                                    }}
                                  >
                                    no credit card required
                                  </LabelXSmall>
                                )}
                              </DisplaySmall>
                              <Block>
                                <LabelSmall
                                  marginTop="scale400"
                                  $style={{
                                    textAlign: "center",
                                    fontWeight: 400,
                                  }}
                                >
                                  <strong>
                                    {(plan.features["publishedPostsCount"] ===
                                    -1
                                      ? "unlimited"
                                      : plan.features["publishedPostsCount"]) ||
                                      0}
                                  </strong>{" "}
                                  published posts
                                </LabelSmall>
                                <LabelSmall
                                  marginTop="scale400"
                                  $style={{
                                    textAlign: "center",
                                    fontWeight: 400,
                                  }}
                                >
                                  <strong>1</strong> user
                                </LabelSmall>
                              </Block>
                              {mySubscriptionQuery.data?.mySubscription?.plan
                                ?.type !== "LIFETIME" && (
                                <Button
                                  kind={"secondary"}
                                  shape="pill"
                                  $style={{
                                    color: theme.colors.contentPrimary,
                                    textTransform: "uppercase",
                                    fontWeight: 500,
                                    marginTop: theme.sizing.scale1000,
                                    borderTopColor: theme.colors.primary,
                                    borderBottomColor: theme.colors.primary,
                                    borderLeftColor: theme.colors.primary,
                                    borderRightColor: theme.colors.primary,
                                  }}
                                  disabled={
                                    plan.id ===
                                    mySubscriptionQuery.data?.mySubscription
                                      ?.planId
                                  }
                                  onClick={async () => {
                                    if (
                                      mySubscriptionQuery.data?.mySubscription
                                        ?.cancelUrl
                                    ) {
                                      setTargetPlan(plan);
                                      setCoupon("");
                                      if (
                                        determineIfUpgradeOrDowngrade(
                                          plan,
                                          mySubscriptionQuery.data
                                            ?.mySubscription?.plan
                                        ) === "Upgrade"
                                      ) {
                                        setIsUpgradeModalOpen(true);
                                      } else {
                                        setIsDowngradeModalOpen(true);
                                      }
                                    } else {
                                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                      (window as any).Paddle.Checkout.open({
                                        product: plan.id,
                                        email: user?.email,
                                        successCallback: () => {
                                          onClose();
                                          setIsDowngradeModalOpen(false);
                                          setIsUpgradeModalOpen(false);
                                          toaster.positive(
                                            "You've successfully bought the MyBlog's subscription. It's currently being processed and your subscription will be active shortly.",
                                            {
                                              autoHideDuration: 10000,
                                            }
                                          );
                                        },
                                      });
                                    }
                                  }}
                                >
                                  {plan.id ===
                                  mySubscriptionQuery.data?.mySubscription
                                    ?.planId
                                    ? "Current plan"
                                    : determineIfUpgradeOrDowngrade(
                                        plan,
                                        mySubscriptionQuery.data?.mySubscription
                                          ?.plan
                                      )}
                                </Button>
                              )}
                            </div>
                          </Block>
                        </FlexGridItem>
                      ))}
                    </FlexGrid>
                  </Block>
                </Cell>
              </div>
            </>
          )}
          {FEATURES_GROUPS.map((group, index) => (
            <>
              <div
                className={css({
                  display: "flex",

                  marginTop: theme.sizing.scale400,
                  marginBottom: theme.sizing.scale800,
                })}
                key={index}
              >
                <Cell span={[2, 5, 3]}>
                  <Block>
                    <LabelMedium
                      paddingLeft="scale400"
                      paddingBottom="scale400"
                      paddingTop="scale400"
                      color="contentTertiary"
                      $style={{ fontWeight: 700 }}
                    >
                      {group.name}
                    </LabelMedium>
                    <hr
                      className={css({
                        width: "100%",
                        height: "2px",
                        backgroundColor: theme.colors.primary,
                        outline: "unset",
                        border: "unset",
                        opacity: 0.2,
                      })}
                    />
                  </Block>
                </Cell>
                <Cell span={[2, 3, 9]} />
              </div>
              <div className={css({ display: "flex" })}>
                <Cell span={[2, 3, 3]}>
                  {group.features.map((feature, index) => (
                    <Block key={index}>
                      <LabelMedium
                        paddingLeft="scale400"
                        paddingBottom="scale400"
                        paddingTop="scale400"
                        color="contentSecondary"
                        $style={{
                          fontWeight: 400,
                        }}
                      >
                        {feature.name}
                      </LabelMedium>
                      <hr
                        className={css({
                          width: "100%",
                          height: "1px",
                          backgroundColor: theme.colors.colorSecondary,
                          outline: "unset",
                          border: "unset",
                          opacity: 0.3,
                        })}
                      />
                    </Block>
                  ))}
                </Cell>

                <Cell span={[2, 5, 9]}>
                  <FlexGrid
                    flexGridColumnCount={plans?.length || 0}
                    flexGridColumnGap="scale800"
                    flexGridRowGap="0px"
                  >
                    {plans?.map((plan: Plan, index: number) => (
                      <FlexGridItem key={index}>
                        <div
                          className={css({
                            borderRadius: "4px",
                            backgroundColor:
                              plan.id ===
                              mySubscriptionQuery.data?.mySubscription?.planId
                                ? theme.colors.backgroundTertiary
                                : theme.colors.backgroundPrimary,
                            textAlign: "center",
                            marginTop: "-12px",
                            paddingTop: "12px",
                          })}
                        >
                          {group.features.map((feature, index) => (
                            <Block key={index} position="relative">
                              {!feature.key ||
                              plan.features[feature.key] === true ? (
                                <Block
                                  paddingBottom="scale200"
                                  paddingTop="scale200"
                                  position="absolute"
                                  display="flex"
                                  height="100%"
                                  width="100%"
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M20 6L9 17L4 12"
                                      stroke="#AF9883"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </Block>
                              ) : (
                                <LabelSmall
                                  paddingLeft="scale400"
                                  paddingRight="scale400"
                                  paddingBottom="scale500"
                                  paddingTop="scale500"
                                  position="absolute"
                                  display="flex"
                                  height="100%"
                                  width="100%"
                                  alignItems="center"
                                  justifyContent="center"
                                  color={
                                    !plan.features[feature.key]
                                      ? "contentSecondary"
                                      : "contentTertiary"
                                  }
                                  $style={{
                                    fontWeight: 400,
                                  }}
                                >
                                  {!!plan.features[feature.key] &&
                                  feature.key === "allowedCollageExportsCount"
                                    ? plan.features[feature.key] === -1
                                      ? "unlimited"
                                      : `${
                                          plan.features[feature.key]
                                        } collages per month`
                                    : plan.features[feature.key] || "-"}
                                </LabelSmall>
                              )}

                              <LabelMedium
                                paddingBottom="scale400"
                                paddingTop="scale400"
                                color="contentSecondary"
                                $style={{
                                  fontSize:
                                    billingCycle === BillingCycle.Monthly
                                      ? "9.6px"
                                      : "12.8px",
                                  visibility: "hidden",
                                }}
                              >
                                {feature.name}
                              </LabelMedium>
                              <hr
                                className={css({
                                  width: "100%",
                                  height: "1px",
                                  backgroundColor: theme.colors.colorSecondary,
                                  outline: "unset",
                                  border: "unset",
                                  opacity: 0.3,
                                })}
                              />
                            </Block>
                          ))}
                        </div>
                      </FlexGridItem>
                    ))}
                  </FlexGrid>
                </Cell>
              </div>
            </>
          ))}
        </ModalBody>
      </Modal>
      <Modal
        onClose={() => setIsUpgradeModalOpen(false)}
        isOpen={isUpgradeModalOpen}
        animate
        autoFocus={false}
        focusLock={false}
        role={ROLE.dialog}
        overrides={{
          Root: {
            style: {
              zIndex: 10000,
            },
          },
          Dialog: {
            style: {
              borderRadius: "4px",
            },
          },
        }}
      >
        <ModalHeader>
          <LabelLarge color="#535A68" $style={{ fontWeight: 400 }}>
            Upgrade
          </LabelLarge>
        </ModalHeader>
        <ModalBody $style={{ flex: "1 1 0px", overflow: "scroll" }}>
          <Block marginBottom="scale800">
            <ParagraphSmall color="contentTertiary">
              You are about to upgrade your plan to{" "}
              <strong>
                {targetPlan?.name}{" "}
                {targetPlan?.type !== "LIFETIME" ? targetPlan?.type : ""}
              </strong>
              . You will be charged immediately.
            </ParagraphSmall>
          </Block>
          <Block>
            <FormControl label="Coupon code">
              <Input
                value={coupon}
                onChange={(e) => setCoupon(e.currentTarget.value)}
                type="text"
              />
            </FormControl>
          </Block>
        </ModalBody>
        <ModalFooter>
          <Block display="flex" justifyContent="space-between">
            <Button
              onClick={() => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (window as any).Paddle.Checkout.open({
                  override: mySubscriptionQuery.data.mySubscription.updateUrl,
                  success: "/",
                });
              }}
              kind="minimal"
              $style={{
                color: theme.colors.primary,
                fontWeight: 400,
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              Change payment method
            </Button>
            <Button
              onClick={async () => {
                try {
                  const response = await updateSubscription({
                    variables: {
                      input: {
                        subscriptionId:
                          mySubscriptionQuery.data?.mySubscription.id,
                        planId: targetPlan?.id,
                        coupon,
                      },
                    },
                  });

                  if (response.data.updateSubscription) {
                    toaster.positive("Saved successfully", {
                      autoHideDuration: 4000,
                    });
                    setIsDowngradeModalOpen(false);
                    setIsUpgradeModalOpen(false);
                  } else {
                    toaster.negative("Something went wrong", {
                      autoHideDuration: 4000,
                    });
                  }
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                } catch (error: any) {
                  if (
                    error.graphQLErrors &&
                    error.graphQLErrors[0] &&
                    error.graphQLErrors[0].extensions?.code ===
                      "INVALID_SUBSCRIPTION_ERROR"
                  ) {
                    toaster.negative("You can't update this subscription", {
                      autoHideDuration: 4000,
                    });
                  } else {
                    toaster.negative("The data provided is incorrect", {
                      autoHideDuration: 4000,
                    });
                  }
                }
              }}
            >
              Confirm
            </Button>
          </Block>
        </ModalFooter>
      </Modal>
      <Modal
        onClose={() => setIsDowngradeModalOpen(false)}
        isOpen={isDowngradeModalOpen}
        animate
        autoFocus={false}
        focusLock={false}
        role={ROLE.dialog}
        overrides={{
          Root: {
            style: {
              zIndex: 10000,
            },
          },
          Dialog: {
            style: {
              borderRadius: "4px",
            },
          },
        }}
      >
        <ModalHeader>
          <LabelLarge color="#535A68" $style={{ fontWeight: 400 }}>
            Downgrade
          </LabelLarge>
        </ModalHeader>
        <ModalBody $style={{ flex: "1 1 0px", overflow: "scroll" }}>
          <Block marginBottom="scale800">
            <ParagraphSmall color="contentTertiary">
              You are about to downgrade your plan to{" "}
              <strong>
                {targetPlan?.name}{" "}
                {targetPlan?.type !== "LIFETIME" ? targetPlan?.type : ""}
              </strong>
              .
            </ParagraphSmall>
          </Block>
        </ModalBody>
        <ModalFooter>
          <Block display="flex" justifyContent="flex-end">
            <Button
              onClick={async () => {
                try {
                  const response = await updateSubscription({
                    variables: {
                      input: {
                        subscriptionId:
                          mySubscriptionQuery.data?.mySubscription.id,
                        planId: targetPlan?.id,
                      },
                    },
                  });

                  if (response.data.updateSubscription) {
                    toaster.positive("Saved successfully", {
                      autoHideDuration: 4000,
                    });
                    setIsDowngradeModalOpen(false);
                    setIsUpgradeModalOpen(false);
                  } else {
                    toaster.negative("Something went wrong", {
                      autoHideDuration: 4000,
                    });
                  }
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                } catch (error: any) {
                  if (
                    error.graphQLErrors &&
                    error.graphQLErrors[0] &&
                    error.graphQLErrors[0].extensions?.code ===
                      "INVALID_SUBSCRIPTION_ERROR"
                  ) {
                    toaster.negative("You can't update this subscription", {
                      autoHideDuration: 4000,
                    });
                  } else {
                    toaster.negative("The data provided is incorrect", {
                      autoHideDuration: 4000,
                    });
                  }
                }
              }}
            >
              Confirm
            </Button>
          </Block>
        </ModalFooter>
      </Modal>
    </>
  );
}
