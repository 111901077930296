import { gql, useMutation } from "@apollo/client";
import GuestHeader from "app/auth/components/GuestHeader";
import { useAuth } from "app/auth/context";
import Button from "app/core/components/Button";
import { ControlledCheckbox } from "app/core/components/Checkbox";
import { GoogleIcon } from "app/core/components/Icon";
import { ControlledInput } from "app/core/components/Input";
import { useStyletron } from "baseui";
import { FlexGrid, FlexGridItem } from "baseui/flex-grid";
import { FormControl } from "baseui/form-control";
import { Cell, Grid } from "baseui/layout-grid";
import { StyledLink } from "baseui/link";
import { toaster } from "baseui/toast";
import { Display4, ParagraphSmall } from "baseui/typography";
import React, { ReactElement } from "react";
import { useForm } from "react-hook-form";
import { Redirect, useLocation } from "react-router-dom";

const REGISTER = gql`
  mutation Register($input: RegisterInput!) {
    register(input: $input)
  }
`;

type FormInputs = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  agreement: boolean;
};

export default function Register(): ReactElement {
  const [register] = useMutation(REGISTER);

  const {
    handleSubmit,
    errors,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm<FormInputs>({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      agreement: false,
    },
  });
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const [css] = useStyletron();

  const { from } = (location.state as LocationState) || {
    from: { pathname: "/projects" },
  };

  if (isAuthenticated) {
    return <Redirect to={from} />;
  }

  return (
    <>
      <GuestHeader isLogin={false} />

      <Grid>
        <Cell skip={[0, 1, 3]} span={[4, 6, 6]}>
          <div
            className={css({
              marginTop: "40px",
              padding: "20px",
              textAlign: "center",
              maxWidth: "430px",
              marginLeft: "auto",
              marginRight: "auto",
            })}
          >
            <form
              onSubmit={handleSubmit(
                async ({ firstName, lastName, email, password }) => {
                  try {
                    await register({
                      variables: {
                        input: { firstName, lastName, email, password },
                      },
                    });
                    reset();

                    toaster.positive(
                      "You've registered successfully. Check your inbox to confirm your e-mail address",
                      {
                        autoHideDuration: 4000,
                      }
                    );
                  } catch (error) {
                    toaster.negative("The data provided is incorrect", {
                      autoHideDuration: 4000,
                    });
                  }
                }
              )}
            >
              <Display4 $style={{ fontWeight: 300, marginBottom: "60px" }}>
                Create an account
              </Display4>
              <FlexGrid flexGridColumnCount={2} flexGridColumnGap="scale400">
                <FlexGridItem>
                  <FormControl
                    label={() => "First Name"}
                    error={errors.firstName && errors.firstName.message}
                    overrides={{
                      Caption: { style: { textAlign: "left" } },
                      Label: {
                        style: {
                          textTransform: "uppercase",
                          textAlign: "left",
                          fontSize: "12px",
                          color: "#8390AE",
                        },
                      },
                    }}
                  >
                    <ControlledInput
                      control={control}
                      name="firstName"
                      error={!!errors.firstName}
                      rules={{ required: "This field is required" }}
                      disabled={isSubmitting}
                      placeholder="First name"
                    />
                  </FormControl>
                </FlexGridItem>
                <FlexGridItem>
                  <FormControl
                    label={() => "Last Name"}
                    error={errors.lastName && errors.lastName.message}
                    overrides={{
                      Caption: { style: { textAlign: "left" } },
                      Label: {
                        style: {
                          textTransform: "uppercase",
                          textAlign: "left",
                          fontSize: "12px",
                          color: "#8390AE",
                        },
                      },
                    }}
                  >
                    <ControlledInput
                      control={control}
                      name="lastName"
                      error={!!errors.lastName}
                      rules={{ required: "This field is required" }}
                      disabled={isSubmitting}
                      placeholder="Last name"
                    />
                  </FormControl>
                </FlexGridItem>
              </FlexGrid>
              <FormControl
                label={() => "E-mail address"}
                error={errors.email && errors.email.message}
                overrides={{
                  Caption: { style: { textAlign: "left" } },
                  Label: {
                    style: {
                      textTransform: "uppercase",
                      textAlign: "left",
                      fontSize: "12px",
                      color: "#8390AE",
                    },
                  },
                }}
              >
                <ControlledInput
                  control={control}
                  name="email"
                  error={!!errors.email}
                  rules={{ required: "This field is required" }}
                  placeholder="E-mail address"
                  disabled={isSubmitting}
                />
              </FormControl>
              <FormControl
                label={() => "Password"}
                error={errors.password && errors.password.message}
                overrides={{
                  Caption: { style: { textAlign: "left" } },
                  Label: {
                    style: {
                      textTransform: "uppercase",
                      textAlign: "left",
                      fontSize: "12px",
                      color: "#8390AE",
                    },
                  },
                }}
              >
                <ControlledInput
                  control={control}
                  name="password"
                  error={!!errors.password}
                  rules={{
                    required: "This field is required",
                    minLength: {
                      value: 8,
                      message: "Password has to have at least 8 characters",
                    },
                  }}
                  placeholder="Password"
                  type="password"
                  disabled={isSubmitting}
                />
              </FormControl>
              <FormControl error={errors.agreement && errors.agreement.message}>
                <ControlledCheckbox
                  control={control}
                  name="agreement"
                  rules={{
                    required: "This field is required",
                  }}
                  disabled={isSubmitting}
                  error={!!errors.agreement}
                >
                  I accept{" "}
                  <StyledLink
                    target="_blank"
                    href="https://picnook.io/terms-and-conditions"
                  >
                    Terms and Conditions
                  </StyledLink>{" "}
                  and{" "}
                  <StyledLink
                    target="_blank"
                    href="https://picnook.io/privacy-policy"
                  >
                    Policy Privacy
                  </StyledLink>{" "}
                  of Picnook
                </ControlledCheckbox>
              </FormControl>
              <div
                className={css({
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "10px",
                  flexDirection: "column",
                })}
              >
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  $style={{
                    width: "100%",
                    display: "block",
                  }}
                >
                  Get started
                </Button>
                <div
                  className={css({
                    margin: "20px 0",
                    textAlign: "center",
                    position: "relative",
                  })}
                >
                  <div
                    className={css({
                      borderTop: "1px solid #dfdfdf",
                      margin: "0 auto",
                      position: "absolute",
                      top: "50%",
                      left: 0,
                      right: 0,
                      bottom: 0,
                      width: "100%",
                      zIndex: -1,
                    })}
                  ></div>
                  <ParagraphSmall
                    color="gray"
                    display={"inline"}
                    backgroundColor={"#F6F8F9"}
                    paddingLeft="scale600"
                    paddingRight="scale600"
                  >
                    or
                  </ParagraphSmall>
                </div>
                <Button
                  kind="secondary"
                  $as="a"
                  href={`${
                    process.env.REACT_APP_API_URL
                  }/auth/google?redirectUrl=${window.location.href
                    .split("?")[0]
                    .replace("register", "login")}`}
                  disabled={isSubmitting}
                  $style={{
                    width: "100%",
                  }}
                  startEnhancer={() => <GoogleIcon />}
                >
                  Sign in with Google
                </Button>
              </div>
            </form>
          </div>
        </Cell>
      </Grid>
    </>
  );
}
