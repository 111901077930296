import Button from "app/core/components/Button";
import { Block } from "baseui/block";
import { memo } from "react";

import {
  CollageNode,
  CollageNodeType,
  ExtractNodeParameters,
} from "../../CollageDesigner/types";
import { CommonNodeRendererProps, OptionalExceptFor } from "../types";
import NodeRendererWrapper from "./NodeRendererWrapper";

type DownloadNodeRendererProps = OptionalExceptFor<
  ExtractNodeParameters<CollageNode, CollageNodeType.Download>,
  "id"
> &
  CommonNodeRendererProps;

function DownloadNodeRenderer({
  rootDivElement,
  collageRootDivElement,
  collageTranslate,
  id,
  offset,
  normalizedDimensions,
  parent,
  collageWidth,
  dispatch,
  collageGap,
  rootNode,
  activeNodeIds,
  collageScale,
  bindDraggingListeners,
  status,
  croppingNodeId,
  label,
}: DownloadNodeRendererProps): React.ReactElement {
  return (
    <NodeRendererWrapper
      id={id}
      offset={offset}
      normalizedDimensions={normalizedDimensions}
      collageWidth={collageWidth}
      dispatch={dispatch}
      rootDivElement={rootDivElement}
      collageRootDivElement={collageRootDivElement}
      collageTranslate={collageTranslate}
      collageScale={collageScale}
      bindDraggingListeners={bindDraggingListeners.bind(
        null,
        `internal@0/${id}/${parent?.id}`,
        CollageNodeType.Download
      )}
      collageGap={collageGap}
      includeDragButton={true}
      rootNode={rootNode}
      croppingNodeId={croppingNodeId}
      activeNodeIds={activeNodeIds}
      status={status}
      type={CollageNodeType.Download}
    >
      <Block
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        {...bindDraggingListeners(
          `internal@0/${id}/${parent?.id}`,
          CollageNodeType.Download
        )}
      >
        <Button disabled={true} $style={{ alignSelf: "center" }}>
          {label}
        </Button>
      </Block>
    </NodeRendererWrapper>
  );
}

const MemoizedDownloadNodeRenderer = memo(DownloadNodeRenderer);

export default MemoizedDownloadNodeRenderer;
