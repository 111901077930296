import { gql, useMutation } from "@apollo/client";
import { useAuth } from "app/auth/context";
import { Check, Menu, X } from "app/core/components/Icon";
import { styled } from "baseui";
import { StatefulMenu } from "baseui/menu";
import { Popover } from "baseui/popover";
import { Theme } from "baseui/theme";
import { toaster } from "baseui/toast";
import { LabelSmall } from "baseui/typography";
import { useConfirm } from "contexts/ConfirmatorContext";
import { ReactElement, useState } from "react";

import RenameCollageModal from "./RenameCollageModal";

const DELETE_COLLAGE = gql`
  mutation DeleteCollage($input: DeleteCollageInput!) {
    deleteCollage(input: $input)
  }
`;

const DUPLICATE_COLLAGE = gql`
  mutation DuplicateCollage($input: DuplicateCollageInput!) {
    duplicateCollage(input: $input) {
      id
    }
  }
`;

const UNPUBLISH_COLLAGE = gql`
  mutation UnpublishCollage($input: UnpublishCollageInput!) {
    unpublishCollage(input: $input) {
      id
    }
  }
`;

export const CollageCardRoot = styled("div", ({ $theme }) => ({
  backgroundColor: $theme.colors.backgroundPrimary,
  boxShadow: $theme.lighting.shadow400,
  borderRadius: $theme.borders.radius200,
  overflow: "hidden",
  marginBottom: $theme.sizing.scale600,
}));

const CollageCardCoverPhoto = styled<{ photoUrl: string }, "div", Theme>(
  "div",
  ({ photoUrl }) => ({
    backgroundImage: `url(${photoUrl})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "200px",
  })
);

const CollageCardCoverPhotoHeader = styled("div", ({ $theme }) => ({
  background: `linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 90%)`,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingTop: $theme.sizing.scale600,
  paddingRight: $theme.sizing.scale600,
  paddingBottom: $theme.sizing.scale600,
  paddingLeft: $theme.sizing.scale600,
  color: $theme.colors.contentInversePrimary,
}));

const CollageCardCoverPhotoHeaderLabel = styled("div", () => ({
  display: "flex",
  alignItems: "center",
}));

const CollageCardBody = styled("div", ({ $theme }) => ({
  paddingTop: $theme.sizing.scale600,
  paddingRight: $theme.sizing.scale600,
  paddingBottom: $theme.sizing.scale600,
  paddingLeft: $theme.sizing.scale600,
}));

type CollageCardProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  onChange: () => void;
};

export default function CollageCard({
  data,
  onChange,
}: CollageCardProps): ReactElement {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [deleteCollage] = useMutation(DELETE_COLLAGE, {
    variables: { input: { id: data.id } },
  });
  const [duplicateCollage] = useMutation(DUPLICATE_COLLAGE, {
    variables: { input: { id: data.id } },
  });
  const [unpublishCollage] = useMutation(UNPUBLISH_COLLAGE, {
    variables: { input: { id: data.id } },
  });
  const confirm = useConfirm();
  const { refreshCounts } = useAuth();

  return (
    <>
      <CollageCardRoot>
        <CollageCardCoverPhoto
          photoUrl={
            data.thumbnailPhoto && data.thumbnailPhoto.url
              ? `${data.thumbnailPhoto.url}?width=300&format=webp`
              : "/placeholder.svg"
          }
        >
          <CollageCardCoverPhotoHeader>
            <CollageCardCoverPhotoHeaderLabel>
              {data.publishedAt ? <Check /> : <X />}
              <LabelSmall
                color="contentInversePrimary"
                $style={{
                  textTransform: "uppercase",
                  fontSize: "11px",
                  letterSpacing: "0.8px",
                  marginLeft: "4px",
                }}
              >
                {data.publishedAt ? "Published" : "Unpublished"}
              </LabelSmall>
            </CollageCardCoverPhotoHeaderLabel>
            <Popover
              overrides={{
                Body: {
                  style: {
                    zIndex: 20,
                    opacity: 1,
                    boxShadow: "unset",
                    borderRadius: "4px",
                    border: "1px solid #E6EAF0",
                    overflow: "hidden",
                  },
                },
              }}
              isOpen={isMenuOpen}
              onClickOutside={() => setIsMenuOpen(false)}
              content={
                <StatefulMenu
                  onItemSelect={({ item, event }) => {
                    event?.preventDefault();
                    event?.stopPropagation();

                    item.onClick && item.onClick();
                    setIsMenuOpen(false);
                  }}
                  overrides={{
                    List: {
                      style: {
                        paddingTop: "2px",
                        paddingBottom: "2px",
                        minWidth: "110px",
                      },
                    },
                    ListItem: {
                      style: {
                        borderBottomColor: "#E6E6E6",
                        borderBottomWidth: "1px",
                        borderBottomStyle: "solid",
                        ":last-of-type": {
                          borderBottomWidth: "0px",
                        },
                      },
                    },
                  }}
                  items={[
                    {
                      label: "Delete",
                      onClick: async () => {
                        confirm(async () => {
                          await deleteCollage();

                          toaster.positive("Deleted successfully", {
                            autoHideDuration: 4000,
                          });

                          onChange && onChange();
                        });
                      },
                    },
                    {
                      label: "Rename",
                      onClick: () => setIsRenameModalOpen(true),
                    },
                    {
                      label: "Duplicate",
                      onClick: async () => {
                        await duplicateCollage();

                        toaster.positive("Duplicated successfully", {
                          autoHideDuration: 4000,
                        });

                        onChange && onChange();
                      },
                    },
                    ...(data.publishedAt
                      ? [
                          {
                            label: "Unpublish",
                            onClick: async () => {
                              await unpublishCollage();

                              toaster.positive("Unpublished successfully", {
                                autoHideDuration: 4000,
                              });

                              refreshCounts();
                              onChange && onChange();
                            },
                          },
                        ]
                      : []),
                  ]}
                />
              }
            >
              <div>
                <a
                  href="#"
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();

                    setIsMenuOpen((isMenuOpen) => !isMenuOpen);
                  }}
                >
                  <Menu stroke="white" />
                </a>
              </div>
            </Popover>
          </CollageCardCoverPhotoHeader>
        </CollageCardCoverPhoto>
        <CollageCardBody>
          <LabelSmall color="#535A68">{data.name}</LabelSmall>
        </CollageCardBody>
      </CollageCardRoot>
      {data && (
        <div onClick={(e) => e.stopPropagation()}>
          <RenameCollageModal
            data={data}
            isOpen={isRenameModalOpen}
            onClose={() => setIsRenameModalOpen(false)}
            onSuccess={onChange}
          />
        </div>
      )}
    </>
  );
}
