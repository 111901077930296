import {
  Slider as BaseSlider,
  SliderProps as BaseSliderProps,
} from "baseui/slider";
import { ReactElement } from "react";

type SliderProps = BaseSliderProps;

export default function Slider({ value, ...props }: SliderProps): ReactElement {
  return (
    <BaseSlider
      value={value}
      min={Math.min(0, value[0])}
      max={Math.max(500, value[0])}
      {...props}
      overrides={{
        Root: {
          style: {
            overflow: "hidden",
          },
        },
        ThumbValue: {
          component: () => null,
        },
        InnerThumb: {
          component: () => null,
        },
        Thumb: {
          style: {
            backgroundColor: "#af9883",
            width: "16px",
            height: "16px",
          },
        },
        TickBar: {
          component: () => null,
        },
      }}
    />
  );
}
