import * as React from "react";
import { SVGAttributes } from "react";

export default function Logo(
  props: SVGAttributes<SVGElement>
): React.ReactElement<SVGSVGElement> {
  return (
    <svg
      width="130"
      height="30"
      viewBox="0 0 130 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M47.1507 18.1364H46.1069V20.6949H44V13.1167H47.1507C49.0294 13.1167 49.9907 14.1799 49.9907 15.6411C49.9858 16.9665 49.0731 18.1364 47.1507 18.1364ZM46.9468 16.4616C47.5828 16.4616 47.8401 16.1412 47.8401 15.646C47.8401 15.1411 47.5828 14.8158 46.9468 14.8158H46.1069V16.4616H46.9468Z"
          fill="#535A68"
        />
        <path
          d="M55.6753 13.1167H57.7822V20.6949H55.6753V13.1167Z"
          fill="#535A68"
        />
        <path
          d="M67.336 13.0439C69.239 13.0439 70.5935 14.1411 70.9673 15.8597H68.7002C68.4331 15.2917 67.9283 14.9761 67.302 14.9761C66.3359 14.9761 65.7 15.7286 65.7 16.8889C65.7 18.0588 66.3359 18.8113 67.302 18.8113C67.9234 18.8113 68.4283 18.5006 68.7002 17.9278H70.9673C70.5935 19.6463 69.239 20.7435 67.336 20.7435C65.0591 20.7435 63.5639 19.1511 63.5639 16.884C63.5639 14.6363 65.0591 13.0439 67.336 13.0439Z"
          fill="#535A68"
        />
        <path
          d="M83.8282 13.1167V20.6949H81.7212L78.9152 16.4616V20.6949H76.8083V13.1167H78.9152L81.7212 17.4034V13.1167H83.8282Z"
          fill="#535A68"
        />
        <path
          d="M93.5033 20.7724C91.3527 20.7724 89.6147 19.1606 89.6147 16.8838C89.6147 14.6069 91.3576 13.0049 93.5033 13.0049C95.6637 13.0049 97.3822 14.6069 97.3822 16.8838C97.3871 19.1606 95.654 20.7724 93.5033 20.7724ZM93.5033 18.8159C94.5908 18.8159 95.2462 18.0538 95.2462 16.8789C95.2462 15.6847 94.5908 14.9322 93.5033 14.9322C92.4062 14.9322 91.7605 15.6847 91.7605 16.8789C91.7654 18.0538 92.411 18.8159 93.5033 18.8159Z"
          fill="#535A68"
        />
        <path
          d="M106.805 20.7724C104.654 20.7724 102.916 19.1606 102.916 16.8838C102.916 14.6069 104.659 13.0049 106.805 13.0049C108.965 13.0049 110.684 14.6069 110.684 16.8838C110.684 19.1606 108.955 20.7724 106.805 20.7724ZM106.805 18.8159C107.892 18.8159 108.548 18.0538 108.548 16.8789C108.548 15.6847 107.892 14.9322 106.805 14.9322C105.708 14.9322 105.062 15.6847 105.062 16.8789C105.062 18.0538 105.708 18.8159 106.805 18.8159Z"
          fill="#535A68"
        />
        <path
          d="M118.577 17.3743V20.6949H116.47V13.1167H118.577V16.3839L120.932 13.1167H123.359L120.578 16.7917L123.5 20.6949H120.951L118.577 17.3743Z"
          fill="#535A68"
        />
      </g>
      <path
        d="M5.38919 5.38919H9.03888V0H0V9.03588H5.38919V5.38919Z"
        fill="#AF9883"
      />
      <path
        d="M22.7549 9.943C25.2816 9.53294 26.9974 7.15226 26.5874 4.6256C26.1773 2.09895 23.7966 0.383105 21.27 0.793165C18.7433 1.20323 17.0275 3.58391 17.4375 6.11056C17.8476 8.63722 20.2283 10.3531 22.7549 9.943Z"
        fill="#AF9883"
      />
      <path
        d="M12.961 8.82617H6.78142C6.78142 12.4699 3.81737 15.4339 0.173672 15.4339V21.4698C4.06287 21.4698 7.55088 19.7034 9.86823 16.9309C12.1886 19.7034 15.6736 21.4698 19.5628 21.4698H21.0209V24.4309L0.176666 24.377V29.85L26.4131 29.8201L26.425 15.4339H19.5658C15.9251 15.4339 12.961 12.4699 12.961 8.82617Z"
        fill="#AF9883"
      />
      <defs>
        <clipPath id="clip0">
          <rect
            width="79.5002"
            height="7.77234"
            fill="white"
            transform="translate(44 13)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
