import { TableBuilder, TableBuilderProps } from "baseui/table-semantic";
import React from "react";

type TableProps = {
  children: React.ReactNode;
  currentPage: number;
  pageSize: number;
  data: unknown[];
} & Partial<TableBuilderProps<unknown>>;

export default function Table({
  children,
  // currentPage,
  // pageSize,
  data,
  ...props
}: TableProps): React.ReactElement {
  // const window = () => {
  //   const min = (currentPage - 1) * pageSize;
  //   // eslint-disable-next-line no-console
  //   console.log(data, min, pageSize);
  //   return data.slice(min, min + pageSize);
  // };

  return (
    <TableBuilder data={data} {...props}>
      {children}
    </TableBuilder>
  );
}
