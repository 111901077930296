/* eslint-disable prettier/prettier */
import { Repeat, Rescale } from "app/core/components/Icon";
import useRect from "app/core/hooks/useRect";
import {
  CSSProperties,
  ElementRef,
  memo,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { createPortal } from "react-dom";

import {
  getRecycleOption,
  HORIZONTAL_RESCALE_OPTIONS,
  RECYCLE_OPTIONS,
  VERTICAL_RESCALE_OPTIONS,
} from "../../CollageDesigner/ActiveNodePanel";
import { ForwardedPhotoCropper } from "../../CollageDesigner/PhotoCropper";
import {
  CollageDesignerActionType,
  CollageDesignerStatus,
  CollageNode,
  CollageNodeType,
  ExtractNodeParameters,
  PhotoTransform,
} from "../../CollageDesigner/types";
import { CommonNodeRendererProps, OptionalExceptFor } from "../types";
import NodeRendererWrapper from "./NodeRendererWrapper";

type PhotoNodeRendererProps = OptionalExceptFor<
  ExtractNodeParameters<CollageNode, CollageNodeType.Photo>,
  "id"
> &
  CommonNodeRendererProps;

function PhotoNodeRenderer({
  rootDivElement,
  collageRootDivElement,
  collageTranslate,
  id,
  offset,
  normalizedDimensions,
  photoDimensions,
  dimensions,
  photoTransform,
  photoUrl,
  photoId,
  parent,
  isMirrored,
  collageWidth,
  collageScale,
  dispatch,
  collageGap,
  rootNode,
  activeNodeIds,
  activeTextNodeId,
  recycleOptionIndex,
  rescaleOptionIndex,
  bindDraggingListeners,
  status,
  croppingNodeId,
}: PhotoNodeRendererProps): React.ReactElement {
  const isCropping = useMemo(() => croppingNodeId === id, [croppingNodeId]);

  const wrapperDivElement = useRef<HTMLDivElement>(null);
  const wrapperDivElementRect = useRect(wrapperDivElement);

  const photoDivElement = useRef<HTMLDivElement>(null);

  const nodeRatio = dimensions && dimensions.width / dimensions.height;
  const photoRatio =
    photoDimensions && photoDimensions.width / photoDimensions.height;

  const currentRecycleOptionIndex = useMemo(() => recycleOptionIndex ?? -1, [
    recycleOptionIndex,
  ]);

  const currentRescaleOptionIndex = useMemo(() => rescaleOptionIndex ?? -1, [
    rescaleOptionIndex,
  ]);

  let width = normalizedDimensions
    ? normalizedDimensions.width * collageWidth
    : 0;

  if (offset && offset.left > 0) {
    width -= collageGap / 2;
  }

  if (
    offset &&
    normalizedDimensions &&
    offset.left + normalizedDimensions.width < 1
  ) {
    width -= collageGap / 2;
  }

  const bottom =
    rootNode && rootNode.dimensions
      ? rootNode.dimensions.height / rootNode.dimensions.width
      : 0;

  let height = normalizedDimensions
    ? normalizedDimensions.height * collageWidth
    : 0;

  if (offset && offset.top > 0) {
    height -= collageGap / 2;
  }

  if (
    offset &&
    normalizedDimensions &&
    Math.abs(offset.top + normalizedDimensions.height - bottom) > 0.1
  ) {
    height -= collageGap / 2;
  }

  const styles: React.CSSProperties =
    photoTransform && normalizedDimensions && photoRatio
      ? getStylesForPhotoTransform(
          photoTransform,
          width,
          height,
          !!isMirrored,
          photoRatio
        )
      : {
          ...(photoRatio &&
            normalizedDimensions &&
            photoDimensions &&
            nodeRatio && {
              position: "absolute",
              ...(photoRatio < nodeRatio && {
                width: "100%",
                height: `${width / photoRatio}px`,
                top: "50%",
                transform: `translateY(-50%)${isMirrored ? " scaleX(-1)" : ""}`,
                backgroundSize: "100% auto",
              }),
              ...(photoRatio > nodeRatio && {
                height: "100%",
                width: `${height * photoRatio}px`,
                left: "50%",
                transform: `translateX(-50%)${isMirrored ? " scaleX(-1)" : ""}`,
                backgroundSize: "auto 100%",
              }),
              ...(photoRatio === nodeRatio && {
                width: `${width}px`,
                height: `${height}px`,
                ...(isMirrored && { transform: "scaleX(-1)" }),
                backgroundSize: "100% 100%",
              }),
            }),
        };

  const photoCropperHandleRef = useRef<
    ElementRef<typeof ForwardedPhotoCropper>
  >(null);

  useEffect(() => {
    if (isCropping) {
      dispatch({
        type: CollageDesignerActionType.SetCroppingNode,
        payload: {
          croppingNodeId: id,
          croppingNodeHandleRef: photoCropperHandleRef,
        },
      });
    }
  }, [isCropping]);

  return (
    <NodeRendererWrapper
      id={id}
      offset={offset}
      normalizedDimensions={normalizedDimensions}
      collageWidth={collageWidth}
      dispatch={dispatch}
      rootDivElement={rootDivElement}
      collageGap={collageGap}
      collageTranslate={collageTranslate}
      collageRootDivElement={collageRootDivElement}
      collageScale={collageScale}
      bindDraggingListeners={bindDraggingListeners}
      ref={wrapperDivElement}
      rootNode={rootNode}
      activeNodeIds={activeNodeIds}
      activeTextNodeId={activeTextNodeId}
      status={status}
      croppingNodeId={croppingNodeId}
      actions={[
        {
          icon: <Repeat />,
          onClick: () => {
            const nextRecycleOptionIndex =
              currentRecycleOptionIndex < RECYCLE_OPTIONS.length
                ? currentRecycleOptionIndex + 1
                : 0;
            dispatch({
              type: CollageDesignerActionType.ModifyNode,
              payload: {
                targetId: id as number,
                node: {
                  recycleOptionIndex: nextRecycleOptionIndex,
                  ...(nextRecycleOptionIndex === RECYCLE_OPTIONS.length
                    ? { photoTransform: undefined }
                    : {
                        photoTransform: {
                          ...getRecycleOption(nextRecycleOptionIndex),
                        },
                      }),
                },
              },
            });
          },
        },
        {
          icon: <Rescale />,
          onClick: () => {
            const RESCALE_OPTIONS =
              photoRatio && photoRatio < 1
                ? VERTICAL_RESCALE_OPTIONS
                : HORIZONTAL_RESCALE_OPTIONS;

            const nextRescaleOptionIndex =
              currentRescaleOptionIndex < RESCALE_OPTIONS.length
                ? currentRescaleOptionIndex + 1
                : 0;
            dispatch({
              type: CollageDesignerActionType.ModifyNode,
              payload: {
                targetId: id as number,
                node: {
                  rescaleOptionIndex: nextRescaleOptionIndex,
                  ...(nextRescaleOptionIndex === RESCALE_OPTIONS.length
                    ? {
                        ...(photoRatio && {
                          dimensions: {
                            width: 1000,
                            height: 1000 / photoRatio,
                          },
                        }),
                        photoTransform: undefined,
                      }
                    : {
                        dimensions: {
                          width: 1000,
                          height:
                            1000 / RESCALE_OPTIONS[nextRescaleOptionIndex],
                        },
                        photoTransform: {
                          height: -1,
                          width: -1,
                          y: -1,
                          x: -1,
                        },
                      }),
                },
              },
            });
          },
        },
      ]}
      showActions={!isCropping}
      type={CollageNodeType.Photo}
    >
      {photoUrl && styles && (
        <div
          style={{
            ...styles,
            backgroundImage: `url(${photoUrl})`,
            backgroundPosition: "cover",
          }}
          onDoubleClick={() => {
            if (status === CollageDesignerStatus.Idle) {
              dispatch({
                type: CollageDesignerActionType.SetCroppingNode,
                payload: {
                  croppingNodeId: id,
                  croppingNodeHandleRef: photoCropperHandleRef,
                },
              });
            }
          }}
          ref={photoDivElement}
          {...bindDraggingListeners(
            `internal@${photoId}/${id}/${parent?.id}/photo`,
            CollageNodeType.Photo
          )}
        />
      )}
      {isCropping &&
        photoDivElement.current &&
        rootDivElement.current &&
        wrapperDivElement.current &&
        wrapperDivElementRect &&
        offset &&
        normalizedDimensions &&
        createPortal(
          <ForwardedPhotoCropper
            ref={photoCropperHandleRef}
            url={photoUrl}
            rect={{
              x:
                offset.left * collageWidth +
                (offset.left > 0 ? collageGap / 2 : 0),
              y:
                offset.top * collageWidth +
                (offset.top > 0 ? collageGap / 2 : 0),
              width,
              height,
            }}
            collageTranslate={collageTranslate}
            collageScale={collageScale}
            isMirrored={isMirrored || false}
            initialTransform={
              photoTransform && wrapperDivElement && photoRatio
                ? getInitialTransformForCropper(
                    photoTransform,
                    width,
                    height,
                    photoRatio
                  )
                : calculatePhotoTransform(
                    wrapperDivElement.current,
                    photoDivElement.current,
                    collageScale
                  )
            }
            onTransform={(photoTransform) => {
              dispatch({
                type: CollageDesignerActionType.SetCroppingNode,
                payload: {
                  croppingNodeId: null,
                  croppingNodeHandleRef: null,
                },
              });

              wrapperDivElement &&
                photoTransform &&
                dispatch({
                  type: CollageDesignerActionType.ApplyTransformToNode,
                  payload: {
                    targetId: id as number,
                    photoTransform: {
                      x: photoTransform.x / width,
                      y: photoTransform.y / height,
                      width: photoTransform.width / width,
                      height: photoTransform.height / height,
                    },
                  },
                });
            }}
          />,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          collageRootDivElement.current!
        )}
    </NodeRendererWrapper>
  );
}

const MemoizedPhotoNodeRenderer = memo(PhotoNodeRenderer);

export default MemoizedPhotoNodeRenderer;

function calculatePhotoTransform(
  wrapperDiv: HTMLDivElement,
  photoDiv: HTMLDivElement,
  scale: number
): PhotoTransform {
  const wrapperRect = wrapperDiv.getBoundingClientRect();
  const photoRect = photoDiv.getBoundingClientRect();

  return {
    x: (photoRect.left - wrapperRect.left) / scale,
    y: (photoRect.top - wrapperRect.top) / scale,
    width: photoRect.width / scale,
    height: photoRect.height / scale,
  };
}
function getStylesForPhotoTransform(
  photoTransform: PhotoTransform,
  width: number,
  height: number,
  isMirrored: boolean,
  photoRatio: number
): CSSProperties {
  let photoWidth = photoTransform.width * width;
  let photoHeight = (photoTransform.width * width) / photoRatio;
  let photoTransformX = Math.round(
    photoTransform.x === -1
      ? (width - photoWidth) / 2
      : photoTransform.x * width
  );
  let photoTransformY = Math.round(
    photoTransform.y === -1
      ? (height - photoHeight) / 2
      : photoTransform.y * height
  );

  if (photoTransform.width === -1 && photoTransform.height === -1) {
    const nodeRatio = width / height;

    if (nodeRatio > photoRatio) {
      photoWidth = height * photoRatio;
      photoHeight = height;
      photoTransformX = (width - photoWidth) / 2;
      photoTransformY = 0;
    } else {
      photoWidth = width;
      photoHeight = width / photoRatio;
      photoTransformY = (height - photoHeight) / 2;
      photoTransformX = 0;
    }
  }

  return {
    width: `${photoWidth}px`,
    height: `${photoHeight}px`,
    transform: `translate3d(${Math.round(photoTransformX)}px, ${Math.round(
      photoTransformY
    )}px, 0)${isMirrored ? " scaleX(-1)" : ""}`,
    backgroundSize: "100% 100%",
  };
}

function getInitialTransformForCropper(
  photoTransform: PhotoTransform,
  width: number,
  height: number,
  photoRatio: number
): PhotoTransform | undefined {
  let photoWidth = photoTransform.width * width;
  let photoHeight = (photoTransform.width * width) / photoRatio;
  let photoTransformX = Math.round(
    photoTransform.x === -1
      ? (width - photoWidth) / 2
      : photoTransform.x * width
  );
  let photoTransformY = Math.round(
    photoTransform.y === -1
      ? (height - photoHeight) / 2
      : photoTransform.y * height
  );

  if (photoTransform.width === -1 && photoTransform.height === -1) {
    const nodeRatio = width / height;

    if (nodeRatio > photoRatio) {
      photoWidth = height * photoRatio;
      photoHeight = height;
      photoTransformX = (width - photoWidth) / 2;
      photoTransformY = 0;
    } else {
      photoWidth = width;
      photoHeight = width / photoRatio;
      photoTransformY = (height - photoHeight) / 2;
      photoTransformX = 0;
    }
  }

  return {
    x: Math.round(photoTransformX),
    y: Math.round(photoTransformY),
    width: photoWidth,
    height: photoHeight,
  };
}
