import { ApolloProvider } from "@apollo/client";
import Account from "app/account/pages/AccountPage";
import adminPages from "app/admin/pages";
import { AuthProvider } from "app/auth/context";
import ForgotPasswordPage from "app/auth/pages/ForgotPassword";
import Login from "app/auth/pages/Login";
import Logout from "app/auth/pages/Logout";
import Register from "app/auth/pages/Register";
import ResetPasswordPage from "app/auth/pages/ResetPassword";
import VerifyEmailPage from "app/auth/pages/VerifyEmail";
import collagesPages from "app/collages/pages";
import PrivateRoute from "app/core/components/PrivateRoute";
import { BaseProvider } from "baseui";
import { SnackbarProvider } from "baseui/snackbar";
import { ToasterContainer } from "baseui/toast";
import ConfirmatorProvider from "contexts/ConfirmatorContext";
import React, { useEffect } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";
import client from "utils/apollo";

import theme from "../baseweb";
import BillingPage from "./account/pages/BillingPage";
import AdminRoute from "./core/components/AdminRoute";

const engine = new Styletron();

const PADDLE_VENDOR_ID = process.env.REACT_APP_PADDLE_VENDOR_ID as string;
const PADDLE_SANDBOX = !!process.env.REACT_APP_PADDLE_SANDBOX;

export default function App(): React.ReactElement {
  useEffect(() => {
    if (PADDLE_VENDOR_ID) {
      if (PADDLE_SANDBOX) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).Paddle.Environment.set("sandbox");
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).Paddle.Setup({
        vendor: +PADDLE_VENDOR_ID,
      });
    }
    const params = new URLSearchParams(window.location.search);

    if (params.get("paddleId")) {
      window.localStorage.setItem(
        "paddlePlanIdToBuy",
        params.get("paddleId") ?? ""
      );
    }

    if (params.get("coupon")) {
      window.localStorage.setItem("coupon", params.get("coupon") ?? "");
    }

    // window.location.search = "";
  }, []);

  return (
    <ApolloProvider client={client}>
      <StyletronProvider value={engine}>
        <BaseProvider theme={theme}>
          <SnackbarProvider
            overrides={{
              PlacementContainer: {
                style: {
                  zIndex: 12000,
                },
              },
            }}
          >
            <ConfirmatorProvider>
              <BrowserRouter>
                <QueryParamProvider adapter={ReactRouter5Adapter}>
                  <AuthProvider>
                    <Switch>
                      <Route exact path="/login">
                        <Login />
                      </Route>
                      <Route exact path="/logout">
                        <Logout />
                      </Route>
                      <Route exact path="/register">
                        <Register />
                      </Route>
                      <Route exact path="/verify-email/:token/:email">
                        <VerifyEmailPage />
                      </Route>
                      <Route exact path="/forgot-password">
                        <ForgotPasswordPage />
                      </Route>
                      <Route exact path="/reset-password/:token">
                        <ResetPasswordPage />
                      </Route>
                      <PrivateRoute exact path="/account">
                        <Account />
                      </PrivateRoute>
                      <PrivateRoute exact path="/billing">
                        <BillingPage />
                      </PrivateRoute>
                      <PrivateRoute path="/projects">
                        {collagesPages}
                      </PrivateRoute>
                      <AdminRoute path="/admin">{adminPages}</AdminRoute>
                      <Redirect to="/projects" />
                    </Switch>
                  </AuthProvider>
                </QueryParamProvider>
              </BrowserRouter>
              <ToasterContainer
                overrides={{
                  Root: {
                    style: {
                      zIndex: 10001,
                    },
                  },
                  ToastBody: {
                    style: ({ $theme, $kind }) => ({
                      borderLeftWidth: "4px",
                      borderLeftStyle: "solid",
                      borderLeftColor:
                        $kind === "positive"
                          ? $theme.colors.positive
                          : $theme.colors.negative,
                      color: $theme.colors.contentPrimary,
                      backgroundColor: $theme.colors.backgroundPrimary,
                      boxShadow: $theme.lighting.shadow500,
                      borderRadius: $theme.borders.radius100,
                    }),
                  },
                  ToastCloseIcon: {
                    style: ({ $theme }) => ({
                      color: $theme.colors.contentTertiary,
                    }),
                  },
                }}
              />
            </ConfirmatorProvider>
          </SnackbarProvider>
        </BaseProvider>
      </StyletronProvider>
    </ApolloProvider>
  );
}
